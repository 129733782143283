import { LauncherService } from './../../../../../../launcher/launcher.service';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlbumsService } from '../../albums/albums.service';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { SelectRecepient } from '../select-recepient/select-recepient.component';
import { ProjectDetailPermission } from '../../../../../../../shared/models/projectpermission.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';
import {LoaderService} from '../../../../../../../shared/services/loader.service';

@Component({
  selector: 'fs-send-email-notification',
  templateUrl: './send-email-notification.component.html',
  styleUrls: ['./send-email-notification.component.scss'],
})
export class SendEmailNotificationComponent
  extends UnsubscriberComponent
  implements OnInit
{
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  selectedEmailCount = 0;
  isDataEmitedFromChild = false;
  userDataInfo: any;
  arrLlistEmails: any = [];
  constantsGlobalData: any;
  currentProjectID: any = '';
  albumId: any = '';
  projectName = '';
  message = '';
  permissions: any = [];
  execUserIds: any = [];
  albumName = '';
  wideEditAlbumList = [];
  subject = '';

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private route: Router,
    private snackBarService: SnackBarService,
    private albumsService: AlbumsService,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private sidebarService: SidebarService,
    private launcherService: LauncherService,
    private viewProjectService: ViewProjectService,
    private dialogRef: MatDialogRef<SendEmailNotificationComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.launcherService.selectedProjectNameGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((name) => {
        this.albumName = name;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectDetailPermission = data;
        }
      });

    if (this.data) {
      this.albumId = this.data.id;
      this.albumName = this.data.value;
      this.subject =
        'New Wide Edit (' +
        this.albumName +
        ') available for ' +
        this.projectDetailPermission.projectGroupName +
        ' - ' +
        this.projectDetailPermission.projectName;
    } else {
      this.albumId = this.viewProjectService.getExecutiveAlbumID();
    }

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.execUserIds = res.execUsers;
        }
      });

    this.viewProjectService.wideEditAlbumsInfoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.wideEditAlbumList = albumList.albums;
        } else {
          this.wideEditAlbumList = [];
        }
      });

    this.albumsService.userDataInfoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.userDataInfo = this.keepexecUsersList(data);
      });

    if (this.data) {
      this.cunstructPermissionData(this.data);
    } else {
      const index = this.wideEditAlbumList
        .map(function (e) {
          return e.id;
        })
        .indexOf(this.viewProjectService.getExecutiveAlbumID());
      if (index !== -1) {
        this.cunstructPermissionData(this.wideEditAlbumList[index]);
      }
    }

    this.projectName =
      this.viewProjectService.getProjectDetailPermissionData().projectGroupName;
  }

  keepexecUsersList(data) {
    const tempArray = [];
    if (data.length > 0) {
      data.forEach((userGlobalDataObj) => {
        this.execUserIds.forEach((execUserId) => {
          if (userGlobalDataObj.id === execUserId) {
            tempArray.push(userGlobalDataObj);
          }
        });
      });
    }
    return tempArray;
  }

  cunstructPermissionData(data?) {
    this.permissions = [];

    if (this.userDataInfo.length > 0) {
      this.userDataInfo.forEach((element) => {
        const perData = {
          id: element.id,
          name: element.firstName + ' ' + element.lastName,
          permissionType: 0,
        };
        this.permissions.push(perData);
        if (data.permission.length === 0) {
          if (element.id === data.ownerId) {
            const index = this.permissions
              .map(function (e) {
                return e.id;
              })
              .indexOf(element.id);
            if (index !== -1) {
              this.permissions[index].permissionType = 2;
              this.permissions[index].isChecked = true;
            }
          }
        }
      });
    }

    if (data.permission.length !== 0) {
      data.permission.forEach((element2) => {
        const index = this.permissions
          .map(function (e) {
            return e.id;
          })
          .indexOf(element2.userId);
        if (index !== -1) {
          this.permissions[index].permissionType = element2.permission;
          this.permissions[index].isChecked = true;
        }
      });
    }
  }

  onClickSendRecepients() {
    this.dialogService
      .open(SelectRecepient, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-panel',
          'fs-dialog-link-panel',
          'fs-dialog-auto-height-panel',
        ],
        data: {
          arrListEmails: this.permissions,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data) {
          this.selectedEmailsData(data);
        }
      });
  }

  selectedEmailsData(evt) {
    this.selectedEmailCount = evt.selectedEmailCount;
    this.isDataEmitedFromChild = evt.isClosed;
    this.arrLlistEmails = evt.selectedList;
  }

  onClickSend() {
    const data = {
      albumId: this.albumId,
      execUsers: this.arrLlistEmails,
      message: this.message,
      projectId: this.viewProjectService.getProjectId(),
      subject: this.subject,
    };
    this.viewProjectService
      .sendEmailNotifications(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (response.status) {
          this.closeWindow();
          this.snackBarService.showSnackBar(response.message);
        } else {
          this.alertCustom(response.message);
          this.closeWindow();
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  closeWindow() {
    this.message = '';
    this.selectedEmailCount = 0;
    this.dialogRef.close();
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
