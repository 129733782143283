import { TopmenusService } from './../../../top-menu/top-menu.service';
import { UserRole } from './../../../../../../shared/enum/user-role.enum';
import { Component, OnInit, ViewChild } from '@angular/core';
import { WideEditService } from './wide-edit.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SnackBarService } from '../../../../../../shared/services/snack-bar.service';
import { AlbumType } from '../../../../../../shared/enum/album-type.enum';
import { UserDetails } from '../../../../../../shared/models/userdetails.model';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { Utils } from '../../../../../../shared/utils/utils';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { WIDE_EDIT } from '../shared-menus/albumType';
import { AppStateService } from '../../../../../../shared/services/app-state.service';
import { PrinttoolComponent } from '../../../top-menu/printtool/printtool.component';
import { LinkComponent } from '../../../top-menu/link/link.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { ContextMenuPosition } from '../../../../../../shared/models/contextMenuPosition.model';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { PaginationService } from '../../../pagination/pagination.service';
import { ProjectRole } from '../../../../../../shared/enum/project-role.enum';
import { AssetInfoDTOList } from '../../../../../../shared/models/assetInfo.model';
import { AlbumsService } from '../albums/albums.service';
import { ITreeOptions, TreeComponent, TreeModel, TreeNode } from '@bugsplat/angular-tree-component';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';
import {AssetErrorKeys} from '../../../../../../shared/enum/asset-error-keys.enum';

@Component({
  selector: 'fs-wide-edit',
  templateUrl: 'wide-edit.component.html',
  styleUrls: ['./wide-edit.component.scss'],
})
export class WideEditComponent extends UnsubscriberComponent implements OnInit {
  @ViewChild('tree', { static: true }) treeComponent: TreeComponent;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  modalStatusObser: any;
  routerObser: any;

  wideEditNode = [];
  isClickOnAlbumName = false;
  isEnabaleToAddActiveAlbumFolder = false;
  isClickOnAlbumFolder = false;
  indexAlbumName: number;
  indexAlbumFolder: number;
  adhocLinkAccess = false;

  selectedImageObser: any;

  isDragNodeTree = false;
  activeAlbumFolder: any;
  isEnlargeView = false;
  activateNode: TreeNode;
  noOfImages: number;
  isAlbumActive = false;
  advanceImageToAlbumID: any;
  options1: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'id',
    allowDrag: false,
  };
  albumTypeId: AlbumType = AlbumType.WideEdit;
  constantsGlobalData: any;
  menuTopLeftPosition: ContextMenuPosition = { x: '0', y: '0' };

  projectAuthority: string;
  userAuthority: string;
  SELECTION_LIMIT = 250;
  currentUser: UserDetails = new UserDetails();
  downloadType = 0;
  downloadOnlyLA = false;
  exclusiveStatus = 0;

  // PDF
  printAccess = false;
  SELECTION_PRINT_LIMIT = 1000;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private modalstatusservice: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private appStateService: AppStateService,
    private snackBarService: SnackBarService,
    private paginationService: PaginationService,
    private wideEditService: WideEditService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userService: UserService,
    private topmenusService: TopmenusService,
    private albumService: AlbumsService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
  }

  ngOnInit() {
    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.constantsGlobalData = ConstantsGlobal;
    this.resetSelectedAlbum();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
      }
    });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.adhocLinkAccess = res.permissionDTO.adhocLinkAccess;
          this.downloadType = res.permissionDTO.downloadType;
          this.downloadOnlyLA = res.permissionDTO.downloadOnlyLA;
          this.exclusiveStatus = res.permissionDTO.exclusiveStatus;
          this.printAccess = res.permissionDTO.printAccess;
          this.noOfImages = res.viewSettingDTO.imagePerPage;

          this.callAlbumDataAPI();
        }
      });

    this.handaleModalStatus();

    this.sidebarService.advanceImageToAlbumWideEditIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.advanceImageToAlbumID = id;
      });

    this.sidebarService.updateTaggingWideEditAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          if (this.activeAlbumFolder.id !== 0) {
            this.updateAlbumInNode1(album);
          }
        }
      });

    this.sidebarService.updateTaggingWideEditGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          if (!Object.prototype.hasOwnProperty.call(album, 'id')) {
            this.updateAlbumInNodeFromAdvanceImageTagging(album);
          }
        }
      });

    this.wideEditService.copyWideEdit
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        this.wideEditNode.push(album);
        this.treeComponent.treeModel.update();
      });

    const temp = {
      id: 0,
    };
    this.activeAlbumFolder = temp;

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.activeAlbumFolder = album;
        } else {
          const obj = {
            id: 0,
          };
          this.activeAlbumFolder = obj;
        }
      });
    // FIXME: Click twice to select album.
    this.viewProjectService.activatedLeftTabIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (index === -1 && this.activeAlbumFolder.id !== 0) {
          this.treeComponent.treeModel
            .getNodeById(this.activeAlbumFolder.id)
            .toggleActivated();
          this.sidebarService.setDeSelectedAlbum({});
        }
      });
  }

  callAlbumDataAPI() {
    this.viewProjectService
      .callWideEditAlbumsInfo(
        this.viewProjectService.getProjectId(),
        this.albumTypeId,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (Object.keys(res).length !== 0) {
          this.wideEditNode = [];
          this.wideEditNode = res.albums;
          this.treeComponent.treeModel.update();
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
      });
  }

  setUpContextMenuPosition(pos: ContextMenuPosition) {
    this.menuTopLeftPosition = pos;
  }

  handaleModalStatus() {
    this.appStateService.renameAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded && status.data.dialogName === WIDE_EDIT) {
          this.renameAlbuminNode(status.data);
        }
      });

    this.appStateService.addAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded && status.data.dialogName === WIDE_EDIT) {
          this.setAlbumInNode(status.data);
        }
      });

    this.appStateService.deleteAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded && status.data.dialogName === WIDE_EDIT) {
          this.removeAlbumInNode(status.data);
        }
      });

    this.appStateService.editAlbum$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.loaded && status.data.dialogName === WIDE_EDIT) {
          this.updatePermissionsInNode(status.data);
        }
      });
  }

  updatePermissionsInNode(data) {
    this.snackBarService.showSnackBar('Permission updated');
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    activateNode.data.permission = data;
    this.treeComponent.treeModel.update();
  }

  onClickAlbum(index) {
    this.indexAlbumFolder = index;
    this.isClickOnAlbumFolder = !this.isClickOnAlbumFolder;
    this.isEnabaleToAddActiveAlbumFolder = true;
    if (!this.isClickOnAlbumFolder) {
      this.resetSelectedAlbum();
    }
  }

  onClickAlbumName(index: number, node: TreeNode): void {
    this.activateNode = node;
    this.isEnabaleToAddActiveAlbumFolder = false;
    this.isClickOnAlbumName = true;
    this.indexAlbumName = index;
    this.activeAlbumFolder = node.data;

    this.isEnabaleToAddActiveAlbumFolder = false;
    this.sidebarService.removeHoverAlbum();
    this.activateNodeData();
  }

  activateNodeData() {
    if (this.isClickOnAlbumName) {
      if (this.activateNode.data.id === 0) {
        this.treeComponent.treeModel
          .getNodeById(this.activateNode.data.id)
          .toggleActivated();
      } else if (Number(this.activateNode.data.assetCount) === 0) {
        this.alertCustom('No image(s) found!');
      } else {
        this.loaderService.displayLoader(true);
        const currentFilter = 'album:' + this.activateNode.data.id;
        if (this.activateNode.data.executiveViewLocal) {
          this.viewProjectService.setExecutiveAlbumView(true);
          this.viewProjectService.setExecutiveAlbumID(
            this.activateNode.data.id,
          );

          this.viewProjectService
            .callProjectImageIDs(
              this.viewProjectService.getProjectId(),
              currentFilter,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (assetIds) => {
                if (assetIds) {
                  const assetsIds = Utils.getImageIdsBasedOnPage(
                    1,
                    this.noOfImages,
                    assetIds,
                  );
                  const data = {
                    assetIds: assetsIds,
                    projectId: this.viewProjectService.getProjectId(),
                    filter: currentFilter,
                    view: 'executive-album-2',
                  };
                  this.getExclusiveAssetsInfoBasedOnAssetsId(
                    data,
                    currentFilter,
                    this.activateNode.data.executive,
                  );
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant('view-project.alert2'),
                  );
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        } else {
          this.viewProjectService.setExecutiveAlbumView(false);
          this.viewProjectService
            .callProjectImageIDs(
              this.viewProjectService.getProjectId(),
              currentFilter,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res1) => {
                if (res1) {
                  const assetsIds = Utils.getImageIdsBasedOnPage(
                    1,
                    this.noOfImages,
                    res1,
                  );
                  const data = {
                    assetIds: assetsIds,
                    projectId: this.viewProjectService.getProjectId(),
                    filter: currentFilter,
                  };
                  this.getExclusiveAssetsInfoBasedOnAssetsId(
                    data,
                    currentFilter,
                    false,
                  );
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant('view-project.alert2'),
                  );
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        }
      }
      this.isClickOnAlbumName = false;
    }
  }

  getExclusiveAssetsInfoBasedOnAssetsId(
    data,
    currentFilter: string,
    isExecutiveAlbum: boolean,
  ): void {
    this.viewProjectService
      .callAssetsInfoBasedOnAssetsId(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (executiveAlbumData) => {
          this.router.navigate([
            Utils.prepareFilterNavigationURL(
              this.viewProjectService.getProjectRole(),
              this.viewProjectService.getProjectId(),
              currentFilter,
              1,
            ),
          ]);
          this.loaderService.displayLoader(false);
          this.viewProjectService.setExecutiveAlbum(isExecutiveAlbum);
          this.selectedImagesStore.clear();
          this.sidebarService.setAlbumImageData(executiveAlbumData);
          this.sidebarService.setSelectedAlbumName(this.activateNode.data);
          if (this.activeAlbumFolder.id !== 0) {
            this.treeComponent.treeModel
              .getNodeById(this.activeAlbumFolder.id)
              .setActiveAndVisible();
          } else {
            this.treeComponent.treeModel
              .getNodeById(this.activateNode.data.id)
              .toggleActivated();
          }
          this.paginationService.setPaginationIndex(1);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  handleActiveChange(e, index) {
    this.indexAlbumName = index;
    this.isEnabaleToAddActiveAlbumFolder = false;
    this.isClickOnAlbumName = true;
    if (!e.checked) {
      this.resetSelectedAlbum();
    }
    this.callAlbumStatusAPI(e.checked);
  }

  callAlbumStatusAPI(activeStatus) {
    const obj = {
      active: activeStatus,
      id: this.indexAlbumName,
      projectId: this.viewProjectService.getProjectId(),
    };

    this.wideEditService
      .putAlbumStatus(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.status) {
          this.snackBarService.showSnackBar(res.message);
        } else {
          this.snackBarService.showSnackBarError(res.message);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
      });
  }

  onEvent(event: any) {
    if (event.eventName === 'deactivate') {
      if (this.isClickOnAlbumName) {
        this.treeComponent.treeModel.getFocusedNode().blur();
        if (this.indexAlbumName === this.activeAlbumFolder.id) {
          this.treeComponent.treeModel
            .getNodeById(event.node.data.id)
            .toggleActivated();
        }
      }
      if (this.isEnabaleToAddActiveAlbumFolder) {
        this.isClickOnAlbumFolder = false;
      }
    }
    if (event.eventName === 'activate') {
      if (this.isClickOnAlbumName) {
        const albumdata = event.node.data;
        this.isClickOnAlbumName = false;
        if (this.activeAlbumFolder.id !== 0) {
          this.treeComponent.treeModel
            .getNodeById(this.activeAlbumFolder.id)
            .setActiveAndVisible();
        } else {
          this.treeComponent.treeModel
            .getNodeById(albumdata.id)
            .toggleActivated();
        }
      } else {
        if (this.isEnabaleToAddActiveAlbumFolder) {
          this.isClickOnAlbumFolder = true;
          this.activeAlbumFolder = event.node.data;
          this.sidebarService.setSelectedAlbum(event.node.data);
        }
      }
    }
  }

  setAlbumInNode(newAlbumData) {
    this.snackBarService.showSnackBar(
      this.translate.instant(
        'left-menu.tab-bar.sidemenus.albums.messageTitles.albumAdded',
      ),
    );
    this.wideEditNode.push(newAlbumData);
    this.treeComponent.treeModel.update();
    this.isEnabaleToAddActiveAlbumFolder = true;
    this.isClickOnAlbumFolder = true;
    this.viewProjectService.setWideEditalbumsInfo(this.wideEditNode);
    this.viewProjectService.setAlbumsInfo(this.wideEditNode);
  }

  removeAlbumInNode(removeAlbumData) {
    this.snackBarService.showSnackBar(
      this.translate.instant(
        'left-menu.tab-bar.sidemenus.albums.messageTitles.albumDeleted',
      ),
    );
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    if (activateNode && activateNode.parent) {
      activateNode.parent.data.children.splice(
        activateNode.parent.data.children.indexOf(removeAlbumData),
        1,
      );
      this.resetSelectedAlbum();
      this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
      this.treeComponent.treeModel.update();
    }
  }

  renameAlbuminNode(renameAlbumData) {
    this.snackBarService.showSnackBar(
      this.translate.instant(
        'left-menu.tab-bar.sidemenus.albums.messageTitles.albumRenamed',
      ),
    );
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();
    activateNode.data.value = renameAlbumData.value;
    this.treeComponent.treeModel.update();
  }

  updateAlbumInNode1(album) {
    if (album.status) {
      const treeModel: TreeModel = this.treeComponent.treeModel;
      const activateNode: TreeNode = treeModel.getActiveNode();
      if (activateNode) {
        if (album.status) {
          activateNode.data.assetCount = album.message;
        }
        this.treeComponent.treeModel.update();
      }
    } else {
      this.alertCustom(album.message);
    }
  }

  updateAlbumInNodeFromAdvanceImageTagging(album) {
    if (album.status) {
      const treeModel: TreeModel = this.treeComponent.treeModel;
      this.treeComponent.treeModel
        .getNodeById(this.advanceImageToAlbumID)
        .setActiveAndVisible();
      const activateNode1: TreeNode = treeModel.getActiveNode();
      if (activateNode1) {
        activateNode1.data.assetCount = album.message;
        this.treeComponent.treeModel.update();
        this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
      }
    } else {
      this.alertCustom(album.message);
    }
  }

  updateAlbumInNode(album) {
    const treeModel: TreeModel = this.treeComponent.treeModel;
    const activateNode: TreeNode = treeModel.getActiveNode();

    if (activateNode) {
      const data = {
        id: activateNode.data.id,
        value: activateNode.data.value,
        assetCount: album.message,
      };
      treeModel.nodes.splice(activateNode.index, 1, data);
      this.treeComponent.treeModel.update();
      this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
    }
  }

  getSelectedImagesOnDrop(evt) {
    if (!this.isDragNodeTree) {
      this.copyDropedImages(evt.target.id);
    }
  }

  copyDropedImages(id) {
    this.isEnabaleToAddActiveAlbumFolder = true;
    this.treeComponent.treeModel.getNodeById(Number(id)).setActiveAndVisible();
    this.treeComponent.treeModel.update();
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
      id: id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.status) {
          this.sidebarService.setUpdatedTaggingWideEditAlbum(res);
        } else {
          this.alertCustom(res.message);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
      });
  }

  onClickWideEditTab() {
    this.isAlbumActive = !this.isAlbumActive;
    if (!this.isAlbumActive) {
      this.resetSelectedAlbum();
      const node = this.treeComponent.treeModel.getActiveNode();
      if (node) {
        this.treeComponent.treeModel
          .getNodeById(node.data.id)
          .toggleActivated();
      }
    }
  }

  checkSentiveImage() {
    return this.selectedImagesStore
      .getAll()
      .some((imageObj) => imageObj.sensitive);
  }

  checkDownloadStatus() {
    if (this.downloadType) {
      return true;
    } else if (this.downloadOnlyLA) {
      let lockApproved = true;
      this.selectedImagesStore.getAll().forEach((item) => {
        if (item.status !== 5) {
          lockApproved = false;
          return lockApproved;
        }
      });
      return lockApproved;
    }
  }

  checkExclusiveStatus() {
    if (this.exclusiveStatus === 0) {
      return false;
    } else if (this.exclusiveStatus === 1 || this.exclusiveStatus === 2) {
      return true;
    }
  }

  callLinkAPI = (id, filteredAssetIds?) => {
    this.loaderService.displayLoader(true);
    const dataObj = this.albumService.buildActionPayload(
      id,
      filteredAssetIds,
      this.constantsGlobalData.MODELNAMES.LINK,
      this.userAuthority === UserRole.ROLE_ADMIN
        ? this.userAuthority
        : this.projectAuthority,
    );

    this.topmenusService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.dialogService.open(LinkComponent, {
              panelClass: [
                'fs-dialog-panel',
                'fs-dialog-size-lg-panel',
                'fs-dialog-link-panel',
                'fs-dialog-auto-height-panel',
              ],
              listener: {
                withListener: true,
                id: 'LinkComponent',
              },
              data: res,
            });
          } else {
            this.alertCustom(res.message);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  // Link
  linkPage(node: any) {
    const imageCount = node.data.assetCount;
    const albumId = node.data.id;
    if (imageCount > 0 && imageCount <= this.SELECTION_LIMIT) {
      this.albumService
        .getAlbumAssets(node.data.id, imageCount)
        .subscribe((assetInfo: number[]) => {
          if (assetInfo && assetInfo.length) {
            this.albumService
              .getAlbumSensitiveAndLinkExclusiveImages(
                assetInfo,
                imageCount,
                albumId,
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                ([sensitiveAssets, exclusiveAssets]: [
                  AssetInfoDTOList[],
                  AssetInfoDTOList[],
                ]) => {
                  if (!sensitiveAssets.length && !exclusiveAssets.length) {
                    if (
                      this.userAuthority === UserRole.ROLE_EXTERNAL &&
                      this.projectAuthority === ProjectRole.ROLE_EXEC
                    ) {
                      if (this.checkDownloadStatus()) {
                        if (this.checkExclusiveStatus()) {
                          this.callLinkAPI(albumId);
                        } else {
                          this.alertCustom(
                            "You do not have permission to share image'(s).",
                          );
                        }
                      } else {
                        this.alertCustom(
                          "You do not have permission to share imae'(s).",
                        );
                      }
                    } else {
                      this.callLinkAPI(albumId);
                    }
                  } else {
                    this.alertCustom(
                      this.translate.instant(
                        'top-menu.linkTool.error.exclusiveOrSensitive',
                      ),
                    );
                    this.loaderService.displayLoader(false);
                  }
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);

                  this.loaderService.displayLoader(false);
                },
              );
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
        });
    } else if (imageCount >= this.SELECTION_LIMIT + 1) {
      this.alertCustom(this.translate.instant('view-project.alert41'));
    } else {
      this.alertCustom('No image(s) found!');
    }
  }

  // PDF

  printTool(node: any) {
    const imageCount = node.data.assetCount;
    const albumId = node.data.id;
    if (imageCount > 0 && imageCount <= this.SELECTION_PRINT_LIMIT) {
      this.albumService
        .getAlbumAssets(node.data.id, imageCount)
        .subscribe((assetIds: number[]) => {
          if (assetIds && assetIds.length) {
            this.albumService
              .handleAlbumAssetDownloads(assetIds, imageCount, albumId)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                ([sensitiveAssets, exclusiveAssets]: [
                  AssetInfoDTOList[],
                  AssetInfoDTOList[],
                ]) => {
                  if (!sensitiveAssets.length && !exclusiveAssets.length) {
                    if (
                      this.userAuthority === UserRole.ROLE_EXTERNAL &&
                      this.projectAuthority === ProjectRole.ROLE_EXEC
                    ) {
                      if (this.printAccess) {
                        this.callPrintAPI(albumId);
                      } else {
                        this.alertCustom(
                          'You do not have permission to print.',
                        );
                      }
                    } else {
                      this.callPrintAPI(albumId);
                    }
                  } else if (
                    this.albumService.isAllAlbumSelectedAssetsExclusiveOrSensitive(
                      sensitiveAssets,
                      exclusiveAssets,
                      assetIds,
                    )
                  ) {
                    this.loaderService.displayLoader(false);
                    this.alertCustom(
                      this.translate.instant(
                        'top-menu.expressDownload.error.allAssetAreExclusiveOrSensitive',
                      ),
                    );
                  } else {
                    this.loaderService.displayLoader(false);
                    this.albumService.openAlbumExclusiveSensitiveAssetModal(
                      albumId,
                      assetIds,
                      Utils.mergeAndRemoveDuplicatesByKey<AssetInfoDTOList>(
                        [...sensitiveAssets, ...exclusiveAssets],
                        'id',
                      ),
                      this.callPrintAPI,
                    );
                  }
                },(error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                }
              );
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
        });
    } else if (imageCount >= this.SELECTION_PRINT_LIMIT + 1) {
      this.alertCustom(this.translate.instant('view-project.alert42'));
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  callPrintAPI = (id, filteredAssetIds?) => {
    this.loaderService.displayLoader(true);
    const dataObj = this.albumService.buildActionPayload(
      id,
      filteredAssetIds,
      'print',
      this.userAuthority === UserRole.ROLE_ADMIN
        ? this.userAuthority
        : this.projectAuthority,
    );

    this.topmenusService
      .postAssetsInfodata(dataObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.dialogService.open(PrinttoolComponent, {
              panelClass: [
                'fs-dialog-panel',
                'fs-dialog-size-lg-panel',
                'fs-dialog-link-panel',
              ],
              data: res,
            });
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(res.message);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  };

  resetSelectedAlbum() {
    this.sidebarService.setDeSelectedAlbum({});
    const temp = {
      id: 0,
    };
    this.activeAlbumFolder = temp;
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
