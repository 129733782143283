import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { AdvanceSearchService } from './advancesearch.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { HelpWindowComponent } from '../../../../../shared/layout/help/help.component';
import { Taggingview1Component } from '../menus/administration/taggingview-1/taggingview-1.component';
import { Taggingview2Component } from '../menus/administration/taggingview-2/taggingview-2.component';
import { AppStateService } from '../../../../../shared/services/app-state.service';
import { ProjectDetailPermission } from '../../../../../shared/models/projectpermission.model';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../shared/services/user.service';
import { SidebarService } from '../../../sidebar-service/sidebar-service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { Utils } from '../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { FilterEnum } from '../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'fs-advancesearch',
  templateUrl: './advancesearch.component.html',
  styleUrls: ['./advancesearch.component.scss'],
})
export class AdvancesearchComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild(MatSelect) matSelect: MatSelect;

  selected: boolean;
  adFilterOption: any;
  advanceSearchObj;
  customAlbumList: any;
  projectAlbumList = [];
  tagList: any;
  batchModelData: any;
  batchList: any;
  exclusiveList: any;
  authorities: string;
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  viewFilter = false;

  selectedBatch: any;
  photographerList: Array<{ id: number; name: string }> = [];
  keywordList: string[];
  currentPageNumber = 1;
  noOfImages: any;
  assetsIds: any;
  getFilterDetails: any;
  isTaggingWithkillRight: boolean;
  restartDataDTO: any = {};
  constantsGlobalData: any;
  tertiary = false;
  lockApprovedRestriction = false;

  constructor(
    private router: Router,
    private viewProjectService: ViewProjectService,
    private advanceSearchService: AdvanceSearchService,
    private loaderService: LoaderService,
    private modalStatusService: Modalstatusservice,
    private userService: UserService,
    private sidebarService: SidebarService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<AdvancesearchComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogService: DialogService,
    private applicationSettingsService: AppStateService,
    private selectedImagesStore: SelectedImagesStore,
    private appStateService: AppStateService,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.advanceSearchService.advanceSearchingDataDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.advanceSearchObj = data;
      });

    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.restartDataDTO
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.restartDataDTO = data;
      });

    this.batchModelData = {};
    this.adFilterOption = {
      statusAssets: [
        { id: 'All', name: 'All', key: 'All' },
        { id: 'Pending', name: 'Pending', key: 'Pending' },
        { id: 'Locked', name: 'Locked', key: 'Locked' },
      ],
      excludedAssets: [
        { id: 'All', name: 'All', key: 'All' },
        { id: 'Excluded', name: 'Excluded', key: 'Excluded' },
        { id: 'Released', name: 'Released', key: 'Released' },
      ],
      exclusives: [
        { id: 1, name: 'All Exclusives' },
        { id: 2, name: 'Select Exclusive', value: '' },
      ],
    };

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.customAlbumList = Utils.getValuesByKey(albumList.albums, 'id');
          if (albumList.projectAlbum) {
            this.projectAlbumList = albumList.projectAlbum.children;
          } else {
            this.projectAlbumList = [];
          }
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.tertiary = res.tertiary;
          this.noOfImages = res.viewSettingDTO.imagePerPage;
          this.batchList = [...res.batchListDTO];
          this.lockApprovedRestriction =
            res.permissionDTO.lockApprovedRestriction;
          if (this.batchList[0]) {
            this.selectedBatch = this.batchList[0].value;
            this.advanceSearchObj.imageBatch[1].value = this.batchList[0].value;
            this.sortImageBatches();
          }
          this.exclusiveList = res.exclusiveListDTO;
          if (res.projectTagListDTO.length > 0) {
            // Sensitive
            if (res.permissionDTO.viewSensitiveAccess) {
              this.tagList = res.projectTagListDTO;
            } else {
              this.tagList = res.projectTagListDTO.filter(
                (item) => item.tagName !== 'Sensitive',
              );
            }
          } else {
            this.tagList = [];
          }
        }
      });

    this.handleModalStatus();

    this.advanceSearchService
      .getAdvanceSearchBackCreators(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((list) => {
        if (list?.length) {
          this.photographerList =
            this.advanceSearchService.removeDuplicates(list);
        }
      });

    this.advanceSearchService
      .getAdvanceSearchKeywords(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((list) => {
        this.keywordList = this.advanceSearchService.getKeywordsFromArray(list);
      });

    this.viewProjectService.isTaggingWithkillRightGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.isTaggingWithkillRight = response;
      });

    this.getFilterDetails = this.viewProjectService.getCurrentFilter();
    this.checkedFilterOption();
  }

  checkedFilterOption() {
    const filterType = this.getFilterDetails.split(':');
    if (filterType[0] !== FilterEnum.Advancesearch) {
      this.onClikedReset();
    }

    if (filterType[0] === FilterEnum.Album) {
      this.onChangedAlbum({ id: Number(filterType[1]) });
    } else if (filterType[0].toLowerCase() === FilterEnum.General) {
      this.advanceSearchObj.approvalStatus.forEach((loopData) => {
        if (loopData.name.toLowerCase() === filterType[1].toLowerCase()) {
          loopData.isChecked = true;
        }
      });

      this.advanceSearchObj.talentAppearance.forEach((loopData) => {
        if (loopData.name.toLowerCase() === filterType[1].toLowerCase()) {
          loopData.isChecked = true;
        }
      });

      if (
        filterType[1].toLowerCase() === 'notes' ||
        filterType[1].toLowerCase() === 'retouch'
      ) {
        this.advanceSearchObj.other.forEach((loopData) => {
          if (
            loopData.name === 'Retouch Comment' &&
            filterType[1].toLowerCase() === 'retouch'
          ) {
            loopData.isChecked = true;
          }
          if (
            loopData.name === 'Image Notes' &&
            filterType[1].toLowerCase() === 'notes'
          ) {
            loopData.isChecked = true;
          }
        });
      }
    } else if (filterType[0] === FilterEnum.Taggedwith) {
      this.onChangedTag({
        id: Number(filterType[1]),
        killRight: this.isTaggingWithkillRight,
      });
    }
  }

  handleModalStatus() {
    combineLatest([
      this.dialogService.getDialogState(Taggingview1Component.identifier)
        .state$,
      this.dialogService.getDialogState(Taggingview2Component.identifier)
        .state$,
      this.applicationSettingsService.noConflicts$,
      this.appStateService.conflicts$,
      this.applicationSettingsService.bypass2ndtagger$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([
          isOpenedTaggingview1Data,
          isOpenedTaggingview2Data,
          noConflictsData,
          conflicts,
          bypass2ndtaggerData,
        ]) => {
          this.viewFilter =
            isOpenedTaggingview1Data.opened ||
            isOpenedTaggingview2Data.opened ||
            noConflictsData.loaded ||
            conflicts.loaded ||
            bypass2ndtaggerData.loaded;
        },
      );
  }

  onChangedTag(tagdata) {
    if (tagdata.killRight) {
      const index = this.advanceSearchObj.publishedTagIds.indexOf(tagdata.id);
      if (index === -1) {
        this.advanceSearchObj.publishedTagIds.push(tagdata.id);
      } else {
        this.advanceSearchObj.publishedTagIds.splice(index, 1);
      }
    } else {
      const index = this.advanceSearchObj.generalTagIds.indexOf(tagdata.id);
      if (index === -1) {
        this.advanceSearchObj.generalTagIds.push(tagdata.id);
      } else {
        this.advanceSearchObj.generalTagIds.splice(index, 1);
      }
    }
  }

  onClearArray(arrayName): void {
    this.advanceSearchObj[arrayName] = [];
  }

  onChangedProjectAlbum(albumData) {
    const index = this.advanceSearchObj.projectAlbum.indexOf(albumData.id);
    if (index === -1) {
      this.advanceSearchObj.projectAlbum.push(albumData.id);
    } else {
      this.advanceSearchObj.projectAlbum.splice(index, 1);
    }
  }

  onChangedAlbum(albumData) {
    const index = this.advanceSearchObj.albums.indexOf(albumData.id);
    if (index === -1) {
      this.advanceSearchObj.albums.push(albumData.id);
    } else {
      this.advanceSearchObj.albums.splice(index, 1);
    }
  }

  onStatusChange(statusAssets) {
    this.advanceSearchObj.status = statusAssets;
  }

  onExcludedStatusChange(excludedAssets) {
    this.advanceSearchObj.excludedReleasedStatus = excludedAssets;
  }

  onImageBatchChange(imageBatch) {
    imageBatch.isChecked = !imageBatch.isChecked;
    if (!this.advanceSearchObj.imageBatch[1].isChecked) {
      this.selectedBatch = '';
      if (this.advanceSearchObj.imageBatch[1].isAscSort) {
        this.advanceSearchObj.imageBatch[1].isAscSort = false;
        this.sortImageBatches();
      }
    }
  }

  onChnageExclusives(exclusives) {
    exclusives.isChecked = !exclusives.isChecked;
    if (exclusives.name === 'All Exclusives' && exclusives.isChecked) {
      this.advanceSearchObj.exclusives[1].isChecked = false;
    } else if (exclusives.name === 'Select Exclusive' && exclusives.isChecked) {
      this.advanceSearchObj.exclusives[0].isChecked = false;
    }

    if (!this.advanceSearchObj.exclusives[1].isChecked) {
      this.advanceSearchObj.exclusiveId = '';
    }
  }

  onClikedReset() {
    this.selectedBatch = '';
    this.advanceSearchService.clearAdvanceSearchSettings();
    this.advanceSearchObj.exclusiveId = '';
  }

  closeWindow() {
    this.constantsGlobalData.MODELNAMES.ADVANCESEARCHFILTERSTATUS = false;
    this.onClikedReset();
    this.dialogRef.close();
  }

  filterForImageBatch(value) {
    this.selectedBatch = value;
    this.advanceSearchObj.imageBatch[1].value = value;
  }

  onSelectExclusive(value) {
    this.advanceSearchObj.exclusiveId = value;
  }

  onClikedSubmit() {
    this.projectDetailPermission =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailPermission,
      );

    const obj = {
      metaSource: this.advanceSearchObj.source,
      photographer: this.advanceSearchObj.photographer,
      metaDescription: this.advanceSearchObj.description,
      metaKeywords: this.advanceSearchObj.keywords,
      albumIds: this.advanceSearchObj.albums,
      projectAlbumIds: this.advanceSearchObj.projectAlbum,
      allExclusive: this.advanceSearchObj.exclusives[0].isChecked,
      approved: this.lockApprovedRestriction
        ? true
        : this.advanceSearchObj.approvalStatus[0].isChecked,
      assetName: this.advanceSearchObj.imageBatch[0].isChecked
        ? this.advanceSearchObj.imageBatch[0].value
        : '',
      batchId: this.advanceSearchObj.imageBatch[1].isChecked
        ? this.selectedBatch
        : '',
      excludeReleaseStatus: this.advanceSearchObj.excludedReleasedStatus,
      excludedFrom: this.advanceSearchObj.talentExecAttributes[2].isChecked,
      exclusiveId: this.advanceSearchObj.exclusives[1].isChecked
        ? this.advanceSearchObj.exclusiveId
        : '',
      filterOption: this.advanceSearchObj.filterOpetion[0].isChecked,
      finalizedBy: this.advanceSearchObj.talentExecAttributes[0].isChecked,
      generalTagIds: this.advanceSearchObj.generalTagIds,
      solo: this.advanceSearchObj.talentAppearance[0].isChecked,
      group: this.advanceSearchObj.talentAppearance[1].isChecked,
      tertairy: this.tertiary
        ? this.advanceSearchObj.talentAppearanceGroupPlus.isChecked
        : false,
      lockedPendingStatus: this.lockApprovedRestriction
        ? 'Locked'
        : this.advanceSearchObj.status,
      not: this.advanceSearchObj.talentExecAttributes[3].isChecked,
      notes: this.advanceSearchObj.other[0].isChecked,
      priorityPix: this.advanceSearchObj.other[1].isChecked,
      projectId: this.viewProjectService.getProjectId(),
      publishedTagIds: this.advanceSearchObj.publishedTagIds,
      rejected: this.advanceSearchObj.approvalStatus[1].isChecked,
      rejectedBy: this.advanceSearchObj.talentExecAttributes[1].isChecked,
      retouch: this.advanceSearchObj.other[2].isChecked,
      viewFilter: this.viewFilter,
    };

    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    this.advanceSearchService
      .postAdvanceSearch(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && res.assetIds.length > 0) {
          this.viewProjectService.setProjectImageIDs(res.assetIds);
          this.assetsIds = [];
          this.assetsIds = Utils.getImageIdsBasedOnPage(
            1,
            this.noOfImages,
            res.assetIds,
          );
          const data = {
            assetIds: this.assetsIds,
            projectId: this.viewProjectService.getProjectId(),
            filter: 'advancesearch:' + res.filter,
          };

          this.viewProjectService
            .callAssetsInfoBasedOnAssetsId(data)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (resData) => {
                this.selectedImagesStore.clear();
                this.sidebarService.setAlbumImageData(resData);
                setTimeout(() => {
                  this.advanceSearchService.saveAdvanceSearchSettings(
                    this.advanceSearchObj,
                  );
                  this.appStateService.setAdvanceSearchCustomFilter({
                    loaded: true,
                  });
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      this.viewProjectService.getProjectId(),
                      'advancesearch:' + res.filter,
                      1,
                    ),
                  ]);
                  this.dialogRef.close();
                  this.loaderService.displayLoader(false);
                }, 0);
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        } else {
          this.alertCustom(this.translate.instant('view-project.alert2'));
        }
        this.loaderService.displayLoader(false);
        this.viewProjectService.setExecutiveAlbum(false);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
      this.loaderService.displayLoader(false);
    });
  }

  onSelectionChange() {
    if (this.keywordList.length === 1) {
      this.closeSelect();
    }
  }

  closeSelect() {
    this.matSelect.close();
  }

  onSortBatches(event) {
    this.advanceSearchObj.imageBatch[1].isAscSort = event.checked;
    this.sortImageBatches();
  }

  sortImageBatches() {
    this.advanceSearchObj.imageBatch[1].isAscSort
      ? (this.batchList = [
          ...this.batchList.sort((a, b) => a.label.localeCompare(b.label)),
        ])
      : (this.batchList = [
          ...this.viewProjectService.getProjectDetailPermissionData()
            .batchListDTO,
        ]);
  }

  onChangeTalentExecAttributes(talentExecAttributes) {
    talentExecAttributes.isChecked = !talentExecAttributes.isChecked;
    if (
      !this.advanceSearchObj.talentExecAttributes[0].isChecked &&
      !this.advanceSearchObj.talentExecAttributes[1].isChecked &&
      !this.advanceSearchObj.talentExecAttributes[2].isChecked
    ) {
      this.advanceSearchObj.talentExecAttributes[3].isChecked = false;
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  showHelpWindow(helpTopic?, videoName?) {
    this.dialogService.open(HelpWindowComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-no-overflow-panel',
      ],
      data: {
        topicName: helpTopic,
        videoName: videoName,
      },
    });
  }
}
