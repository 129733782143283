<div
  class="image-gallery enlarge_container"
  id="page-content-wrapper"
  [ngClass]="{ 'full-width': !isLeftMenuPartShown }"
  (click)="removeFocusout()"
>
  <div>
    <div id="mainGrid">
      <div class="db_container db_com rightside-gallerycontainer-panel">
        <div class="st-subdiv db_com">
          <div *ngIf="projectImageData.assetInfoDTOList" id="imgList">
            <div
              *ngFor="
                let imgData of enlargeImageData
                  | paginate
                    : {
                        itemsPerPage: noOfImages,
                        currentPage: currentImageNum,
                        id: 'EnlargeImage',
                        totalItems: projectImageIds.length
                      };
                index as ind
              "
            >
              <div
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_EXEC
                "
              >
                <div
                  *ngIf="
                    selectedProjectValue !== 3 &&
                    selectedProjectValue !== 4 &&
                    !isExecutiveAlbumView
                  "
                >
                  <div [ngSwitch]="imgData.assetType">
                    <div *ngSwitchCase="'image'">
                      <h5
                        [ngStyle]="{
                          color: ShowHideRejected === true ? 'white' : '#282828'
                        }"
                      >
                        <span
                          *ngIf="
                            imgData.rejectedBy.length > 0 &&
                            imageLoaded[ind] &&
                            !imgData.excluded
                          "
                        >
                          {{ 'view-project.enlarge-image.press' | translate }}
                        </span>
                      </h5>
                      <h5
                        [ngStyle]="{
                          color: ShowHideRejected === true ? 'white' : '#282828'
                        }"
                      >
                        <span *ngIf="imgData.excluded && imageLoaded[ind]">
                          {{
                            'view-project.enlarge-image.excluded' | translate
                          }}
                        </span>
                      </h5>

                      <div
                        *ngIf="!isSuperZoom"
                        class="img_dive middimage_scroll2"
                        (contextmenu)="onRightClick()"
                      >
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span
                                (mouseup)="showRejected(imageLoaded[ind])"
                                (mousedown)="hideRejected(imageLoaded[ind])"
                              >
                                <!-- Admin -->
                                <div
                                  *ngIf="
                                    imgData.status === 5 &&
                                    imgData.rejectedBy.length === 0 &&
                                    imageLoaded[ind]
                                  "
                                  class="approve_icon enlargeImage"
                                >
                                  <i class="approve_icon_sp bg_sprite"></i>
                                </div>
                                <div
                                  *ngIf="
                                    imgData.status === 5 &&
                                    imgData.rejectedBy.length >= 1 &&
                                    imageLoaded[ind]
                                  "
                                  class="rejected_icon enlargeImage"
                                >
                                  <i class="reject_icon_sp bg_sprite"></i>
                                </div>

                                <img
                                  class="border-active imgMarginTop"
                                  [ngClass]="{
                                    blur_img:
                                      imgData.excluded &&
                                      imageLoaded[ind] &&
                                      mouseExcluded
                                  }"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '700'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                                <span *ngIf="ShowHideRejected">
                                  <img
                                    preventdrag
                                    src="/assets/images/Rejected_X_Large.png"
                                    class="reject_image"
                                    *ngIf="
                                      imgData.rejectedBy.length > 0 &&
                                      imageLoaded[ind]
                                    "
                                  />
                                </span>
                                <span *ngIf="ShowHideRejected">
                                  <img
                                    preventdrag
                                    src="/assets/images/EXCLUSIVE.png"
                                    class="exclusiveImage"
                                    *ngIf="
                                      imgData.exclusive && imageLoaded[ind]
                                    "
                                  />
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>

                      <div
                        *ngIf="isSuperZoom"
                        class="eimg_dive superZoom_scroll"
                        (contextmenu)="onRightClick()"
                      >
                        <div class="span31">
                          <div class="eimage">
                            <a
                              href="javascript:void(0)"
                              class="eimg_dive_ver"
                              (dblclick)="selectZoomImageOnDoubleClick()"
                            >
                              <span
                                (mouseup)="showRejected(imageLoaded[ind])"
                                (mousedown)="hideRejected(imageLoaded[ind])"
                              >
                                <div
                                  *ngIf="
                                    imgData.status === 5 &&
                                    imgData.rejectedBy.length === 0 &&
                                    imageLoaded[ind]
                                  "
                                  class="approve_icon superZoom"
                                >
                                  <i class="approve_icon_sp bg_sprite"></i>
                                </div>
                                <div
                                  *ngIf="
                                    imgData.status === 5 &&
                                    imgData.rejectedBy.length >= 1 &&
                                    imageLoaded[ind]
                                  "
                                  class="rejected_icon superZoom"
                                >
                                  <i class="reject_icon_sp bg_sprite"></i>
                                </div>
                                <img
                                  preventdrag
                                  class="imgMarginTop"
                                  [ngClass]="{
                                    blur_img:
                                      imgData.excluded &&
                                      imageLoaded[ind] &&
                                      mouseExcluded
                                  }"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '1600'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                                <span *ngIf="ShowHideRejected">
                                  <img
                                    preventdrag
                                    src="/assets/images/Rejected_X_Large.png"
                                    class="reject_image1600"
                                    *ngIf="
                                      imgData.rejectedBy.length > 0 &&
                                      imageLoaded[ind]
                                    "
                                  />
                                </span>
                                <span *ngIf="ShowHideRejected">
                                  <img
                                    preventdrag
                                    src="/assets/images/EXCLUSIVE.png"
                                    class="exclusiveImage1600"
                                    *ngIf="
                                      imgData.exclusive && imageLoaded[ind]
                                    "
                                  />
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'video'">
                      <div class="img_dive middimage_scroll2">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '700'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'docx'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/doc_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'pdf'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/pdf_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'zip'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/zip_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'xlsx'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/xls_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_EXEC
                "
              >
                <div
                  *ngIf="
                    selectedProjectValue === 3 ||
                    selectedProjectValue === 4 ||
                    isExecutiveAlbumView
                  "
                >
                  <div [ngSwitch]="imgData.assetType">
                    <div *ngSwitchCase="'image'">
                      <div
                        *ngIf="!isSuperZoom"
                        class="img_dive middimage_scroll2"
                        (contextmenu)="onRightClick()"
                      >
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <div
                                  *ngIf="
                                    imgData.excluded &&
                                    imageLoaded[ind] &&
                                    mouseExcluded
                                  "
                                  class="blur_div"
                                ></div>
                                <h5
                                  *ngIf="imgData.exicutiveRejected === 1"
                                  class="maketextgreen fs-md-txt"
                                >
                                  {{
                                    'view-project.enlarge-image.approvedImage'
                                      | translate
                                  }}
                                </h5>
                                <h5
                                  *ngIf="imgData.exicutiveRejected === 3"
                                  class="maketextred fs-md-txt"
                                >
                                  {{
                                    'view-project.enlarge-image.rejectedImage'
                                      | translate
                                  }}
                                </h5>
                                <img
                                  preventdrag
                                  [ngClass]="{
                                    makeborderReject:
                                      imgData.exicutiveRejected === 3,
                                    makeborderApproved:
                                      imgData.exicutiveRejected === 1,
                                    notes_border:
                                      imgData.assetNoteDTOList.length > 0
                                  }"
                                  class="border-active"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '700'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>

                      <div
                        *ngIf="isSuperZoom"
                        class="eimg_dive superZoom_scroll"
                        (contextmenu)="onRightClick()"
                      >
                        <div class="span31">
                          <div class="eimage">
                            <a
                              href="javascript:void(0)"
                              class="eimg_dive_ver"
                              (dblclick)="selectZoomImageOnDoubleClick()"
                            >
                              <div
                                *ngIf="
                                  imgData.excluded &&
                                  imageLoaded[ind] &&
                                  mouseExcluded
                                "
                                class="blur_div_superzoom"
                              ></div>
                              <h5
                                *ngIf="imgData.exicutiveRejected === 1"
                                class="maketextgreen fs-md-txt"
                              >
                                {{
                                  'view-project.enlarge-image.approvedImage'
                                    | translate
                                }}
                              </h5>
                              <h5
                                *ngIf="imgData.exicutiveRejected === 3"
                                class="maketextred fs-md-txt"
                              >
                                {{
                                  'view-project.enlarge-image.rejectedImage'
                                    | translate
                                }}
                              </h5>
                              <img
                                preventdrag
                                [ngClass]="{
                                  makeborderReject:
                                    imgData.exicutiveRejected === 3,
                                  makeborderApproved:
                                    imgData.exicutiveRejected === 1,
                                  notes_border:
                                    imgData.assetNoteDTOList.length > 0
                                }"
                                [lazySrc]="
                                  viewProjectService.getWebImageURL(
                                    imgData,
                                    '1600'
                                  )
                                "
                                alt=""
                                (load)="onLoad(ind)"
                                draggable="false"
                              />
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>

                    <div *ngSwitchCase="'video'">
                      <div class="img_dive middimage_scroll2">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '700'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>

                    <div *ngSwitchCase="'docx'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/doc_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>

                    <div *ngSwitchCase="'pdf'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/pdf_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'zip'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/zip_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>

                    <div *ngSwitchCase="'xlsx'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/xls_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="userAuthority === userRole.ROLE_ADMIN">
                <div
                  *ngIf="
                    selectedProjectValue !== 3 &&
                    selectedProjectValue !== 4 &&
                    !isExecutiveAlbumView
                  "
                >
                  <div [ngSwitch]="imgData.assetType">
                    <div *ngSwitchCase="'image'">
                      <h5
                        [ngStyle]="{
                          color: ShowHideRejected === true ? 'white' : '#282828'
                        }"
                      >
                        <span
                          *ngIf="
                            imgData.rejectedBy.length > 0 &&
                            imageLoaded[ind] &&
                            !imgData.excluded
                          "
                        >
                          {{ 'view-project.enlarge-image.press' | translate }}
                        </span>
                      </h5>
                      <h5
                        [ngStyle]="{
                          color: ShowHideRejected === true ? 'white' : '#282828'
                        }"
                      >
                        <span *ngIf="imgData.excluded && imageLoaded[ind]">
                          This image is Excluded. Press & Hold Left Mouse Button
                          over image to view
                        </span>
                      </h5>

                      <div
                        *ngIf="!isSuperZoom"
                        class="img_dive middimage_scroll2"
                        (contextmenu)="onRightClick()"
                      >
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span
                                (mouseup)="showRejected(imageLoaded[ind])"
                                (mousedown)="hideRejected(imageLoaded[ind])"
                              >
                                <!-- Admin -->
                                <div
                                  *ngIf="
                                    imgData.status === 5 &&
                                    imgData.rejectedBy.length === 0 &&
                                    imageLoaded[ind]
                                  "
                                  class="approve_icon enlargeImage"
                                >
                                  <i class="approve_icon_sp bg_sprite"></i>
                                </div>
                                <div
                                  *ngIf="
                                    imgData.status === 5 &&
                                    imgData.rejectedBy.length >= 1 &&
                                    imageLoaded[ind]
                                  "
                                  class="rejected_icon enlargeImage"
                                >
                                  <i class="reject_icon_sp bg_sprite"></i>
                                </div>

                                <img
                                  preventdrag
                                  class="border-active imgMarginTop"
                                  [ngClass]="{
                                    blur_img:
                                      imgData.excluded &&
                                      imageLoaded[ind] &&
                                      mouseExcluded
                                  }"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '700'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                                <span *ngIf="ShowHideRejected">
                                  <img
                                    preventdrag
                                    src="/assets/images/Rejected_X_Large.png"
                                    class="reject_image"
                                    *ngIf="
                                      imgData.rejectedBy.length > 0 &&
                                      imageLoaded[ind]
                                    "
                                  />
                                </span>
                                <span *ngIf="ShowHideRejected">
                                  <img
                                    preventdrag
                                    src="/assets/images/EXCLUSIVE.png"
                                    class="exclusiveImage"
                                    *ngIf="
                                      imgData.exclusive && imageLoaded[ind]
                                    "
                                  />
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>

                      <div
                        *ngIf="isSuperZoom"
                        class="eimg_dive superZoom_scroll"
                        (contextmenu)="onRightClick()"
                      >
                        <div class="span31">
                          <div class="eimage">
                            <a
                              href="javascript:void(0)"
                              class="eimg_dive_ver"
                              (dblclick)="selectZoomImageOnDoubleClick()"
                            >
                              <span
                                (mouseup)="showRejected(imageLoaded[ind])"
                                (mousedown)="hideRejected(imageLoaded[ind])"
                              >
                                <div
                                  *ngIf="
                                    imgData.status === 5 &&
                                    imgData.rejectedBy.length === 0 &&
                                    imageLoaded[ind]
                                  "
                                  class="approve_icon superZoom"
                                >
                                  <i class="approve_icon_sp bg_sprite"></i>
                                </div>
                                <div
                                  *ngIf="
                                    imgData.status === 5 &&
                                    imgData.rejectedBy.length >= 1 &&
                                    imageLoaded[ind]
                                  "
                                  class="rejected_icon superZoom"
                                >
                                  <i class="reject_icon_sp bg_sprite"></i>
                                </div>
                                <img
                                  preventdrag
                                  class="imgMarginTop"
                                  [ngClass]="{
                                    blur_img:
                                      imgData.excluded &&
                                      imageLoaded[ind] &&
                                      mouseExcluded
                                  }"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '1600'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                                <span *ngIf="ShowHideRejected">
                                  <img
                                    preventdrag
                                    src="/assets/images/Rejected_X_Large.png"
                                    class="reject_image1600"
                                    *ngIf="
                                      imgData.rejectedBy.length > 0 &&
                                      imageLoaded[ind]
                                    "
                                  />
                                </span>
                                <span *ngIf="ShowHideRejected">
                                  <img
                                    preventdrag
                                    src="/assets/images/EXCLUSIVE.png"
                                    class="exclusiveImage1600"
                                    *ngIf="
                                      imgData.exclusive && imageLoaded[ind]
                                    "
                                  />
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'video'">
                      <div class="img_dive middimage_scroll2">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '700'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'docx'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/doc_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'pdf'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/pdf_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'zip'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/zip_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'xlsx'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/xls_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="userAuthority === userRole.ROLE_ADMIN">
                <div
                  *ngIf="
                    selectedProjectValue === 3 ||
                    selectedProjectValue === 4 ||
                    isExecutiveAlbumView
                  "
                >
                  <div [ngSwitch]="imgData.assetType">
                    <div *ngSwitchCase="'image'">
                      <div
                        *ngIf="!isSuperZoom"
                        class="img_dive middimage_scroll2"
                        (contextmenu)="onRightClick()"
                      >
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <div
                                  *ngIf="
                                    imgData.excluded &&
                                    imageLoaded[ind] &&
                                    mouseExcluded
                                  "
                                  class="blur_div"
                                ></div>
                                <h5
                                  *ngIf="imgData.exicutiveRejected === 1"
                                  class="maketextgreen fs-md-txt"
                                >
                                  {{
                                    'view-project.enlarge-image.approvedImage'
                                      | translate
                                  }}
                                </h5>
                                <h5
                                  *ngIf="imgData.exicutiveRejected === 3"
                                  class="maketextred fs-md-txt"
                                >
                                  {{
                                    'view-project.enlarge-image.rejectedImage'
                                      | translate
                                  }}
                                </h5>
                                <img
                                  preventdrag
                                  [ngClass]="{
                                    makeborderReject:
                                      imgData.exicutiveRejected === 3,
                                    makeborderApproved:
                                      imgData.exicutiveRejected === 1,
                                    notes_border:
                                      imgData.assetNoteDTOList.length > 0
                                  }"
                                  class="border-active"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '700'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>

                      <div
                        *ngIf="isSuperZoom"
                        class="eimg_dive superZoom_scroll"
                        (contextmenu)="onRightClick()"
                      >
                        <div class="span31">
                          <div class="eimage">
                            <a
                              href="javascript:void(0)"
                              class="eimg_dive_ver"
                              (dblclick)="selectZoomImageOnDoubleClick()"
                            >
                              <div
                                *ngIf="
                                  imgData.excluded &&
                                  imageLoaded[ind] &&
                                  mouseExcluded
                                "
                                class="blur_div_superzoom"
                              ></div>
                              <h5
                                *ngIf="imgData.exicutiveRejected === 1"
                                class="maketextgreen fs-md-txt"
                              >
                                {{
                                  'view-project.enlarge-image.approvedImage'
                                    | translate
                                }}
                              </h5>
                              <h5
                                *ngIf="imgData.exicutiveRejected === 3"
                                class="maketextred fs-md-txt"
                              >
                                {{
                                  'view-project.enlarge-image.rejectedImage'
                                    | translate
                                }}
                              </h5>
                              <img
                                preventdrag
                                [ngClass]="{
                                  makeborderReject:
                                    imgData.exicutiveRejected === 3,
                                  makeborderApproved:
                                    imgData.exicutiveRejected === 1,
                                  notes_border:
                                    imgData.assetNoteDTOList.length > 0
                                }"
                                [lazySrc]="
                                  viewProjectService.getWebImageURL(
                                    imgData,
                                    '1600'
                                  )
                                "
                                alt=""
                                (load)="onLoad(ind)"
                                draggable="false"
                              />
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>

                    <div *ngSwitchCase="'video'">
                      <div class="img_dive middimage_scroll2">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  [lazySrc]="
                                    viewProjectService.getWebImageURL(
                                      imgData,
                                      '700'
                                    )
                                  "
                                  alt=""
                                  (load)="onLoad(ind)"
                                  draggable="false"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>

                    <div *ngSwitchCase="'docx'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/doc_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>

                    <div *ngSwitchCase="'pdf'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/pdf_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                    <div *ngSwitchCase="'zip'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/zip_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>

                    <div *ngSwitchCase="'xlsx'">
                      <div class="img_dive">
                        <div class="span31 imgItem">
                          <div class="imgee">
                            <a
                              href="javascript:void(0)"
                              (dblclick)="selectImageOnDoubleClick()"
                            >
                              <span>
                                <img
                                  preventdrag
                                  class="border-active"
                                  src="/assets/images/xls_icon.png"
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                        <fs-image-name-panel
                          [imgData]="imgData"
                        ></fs-image-name-panel>
                        <fs-image-color-tag-panel
                          [imgData]="imgData"
                        ></fs-image-color-tag-panel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_TALENT
                "
              >
                <div>
                  <h5
                    [ngStyle]="{
                      color: mouseHide === true ? 'white' : '#282828'
                    }"
                  >
                    {{ 'view-project.enlarge-image.press' | translate }}
                  </h5>
                  <span
                    *ngIf="
                      (selectedProjectValue !== 3 ||
                        selectedProjectValue !== 4) &&
                      imgData.status === 1
                    "
                  >
                    <button
                      class="btn fs-custom-approve-btn"
                      (click)="onClickTalentApproveReject(imgData)"
                      *ngIf="imgData.rejected"
                    >
                      {{ 'view-project.enlarge-image.approved' | translate }}
                    </button>
                    <button
                      class="btn fs-custom-reject-btn"
                      *ngIf="!imgData.rejected"
                      (click)="onClickTalentApproveReject(imgData)"
                    >
                      {{ 'view-project.enlarge-image.rejected' | translate }}
                    </button>
                  </span>
                  <div
                    *ngIf="!isSuperZoom"
                    class="img_dive middimage_scroll2"
                    (contextmenu)="onRightClick()"
                  >
                    <div class="span31 imgItem">
                      <div
                        class="imgee"
                        id="disrightClick"
                        (mousedown)="onRightClickPress(imgData)"
                        (mouseup)="onRightClickLeave(imgData)"
                      >
                        <a
                          href="javascript:void(0)"
                          (dblclick)="selectImageOnDoubleClick()"
                        >
                          <img
                            preventdrag
                            class="border-active"
                            [lazySrc]="
                              viewProjectService.getWebImageURL(imgData, '700')
                            "
                            alt=""
                            (load)="onLoad(ind)"
                            draggable="false"
                          />
                          <img
                            preventdrag
                            src="/assets/images/Rejected_X_Large.png"
                            class="reject_image"
                            *ngIf="
                              imageLoaded[ind] && imgData.rejected && mouseHide
                            "
                          />
                        </a>
                      </div>
                    </div>
                    <fs-image-name-panel
                      [imgData]="imgData"
                    ></fs-image-name-panel>
                    <fs-image-color-tag-panel
                      [imgData]="imgData"
                    ></fs-image-color-tag-panel>
                  </div>

                  <div
                    *ngIf="isSuperZoom"
                    class="eimg_dive superZoom_scroll"
                    (contextmenu)="onRightClick()"
                  >
                    <div class="span31">
                      <div
                        class="eimage"
                        (mousedown)="onRightClickPress(imgData)"
                        (mouseup)="onRightClickLeave(imgData)"
                      >
                        <a
                          href="javascript:void(0)"
                          class="eimg_dive_ver"
                          (dblclick)="selectZoomImageOnDoubleClick()"
                        >
                          <img
                            preventdrag
                            [lazySrc]="
                              viewProjectService.getWebImageURL(imgData, '1600')
                            "
                            alt=""
                            (load)="onLoad(ind)"
                            draggable="false"
                          />

                          <img
                            preventdrag
                            src="/assets/images/Rejected_X_Large.png"
                            class="reject_image1600"
                            *ngIf="
                              imageLoaded[ind] && imgData.rejected && mouseHide
                            "
                          />
                        </a>
                      </div>
                    </div>
                    <fs-image-name-panel
                      [imgData]="imgData"
                    ></fs-image-name-panel>
                    <fs-image-color-tag-panel
                      [imgData]="imgData"
                    ></fs-image-color-tag-panel>
                  </div>
                </div>
              </div>

              <!-- TODO: -->

              <div
                *ngIf="
                  userAuthority === userRole.ROLE_EXTERNAL &&
                  projectAuthority === projectRole.ROLE_ALBUMVIEWER
                "
              >
                <div [ngSwitch]="imgData.assetType">
                  <div *ngSwitchCase="'image'">
                    <h5
                      [ngStyle]="{
                        color: ShowHideRejected === true ? 'white' : '#282828'
                      }"
                    >
                      <span
                        *ngIf="
                          imgData.rejectedBy.length > 0 &&
                          imageLoaded[ind] &&
                          !imgData.excluded
                        "
                      >
                        {{ 'view-project.enlarge-image.press' | translate }}
                      </span>
                    </h5>
                    <h5
                      [ngStyle]="{
                        color: ShowHideRejected === true ? 'white' : '#282828'
                      }"
                    >
                      <span *ngIf="imgData.excluded && imageLoaded[ind]">
                        This image is Excluded. Press & Hold Left Mouse Button
                        over image to view
                      </span>
                    </h5>

                    <div
                      *ngIf="!isSuperZoom"
                      class="img_dive middimage_scroll2"
                      (contextmenu)="onRightClick()"
                    >
                      <div class="span31 imgItem">
                        <div class="imgee">
                          <a
                            href="javascript:void(0)"
                            (dblclick)="selectImageOnDoubleClick()"
                          >
                            <span
                              (mouseup)="showRejected(imageLoaded[ind])"
                              (mousedown)="hideRejected(imageLoaded[ind])"
                            >
                              <!-- Admin -->
                              <div
                                *ngIf="
                                  imgData.status === 5 &&
                                  imgData.rejectedBy.length === 0 &&
                                  imageLoaded[ind]
                                "
                                class="approve_icon enlargeImage"
                              >
                                <i class="approve_icon_sp bg_sprite"></i>
                              </div>
                              <div
                                *ngIf="
                                  imgData.status === 5 &&
                                  imgData.rejectedBy.length >= 1 &&
                                  imageLoaded[ind]
                                "
                                class="rejected_icon enlargeImage"
                              >
                                <i class="reject_icon_sp bg_sprite"></i>
                              </div>

                              <img
                                preventdrag
                                class="border-active imgMarginTop"
                                [ngClass]="{
                                  blur_img:
                                    imgData.excluded &&
                                    imageLoaded[ind] &&
                                    mouseExcluded
                                }"
                                [lazySrc]="
                                  viewProjectService.getWebImageURL(
                                    imgData,
                                    '700'
                                  )
                                "
                                alt=""
                                (load)="onLoad(ind)"
                                draggable="false"
                              />
                              <span *ngIf="ShowHideRejected">
                                <img
                                  preventdrag
                                  src="/assets/images/Rejected_X_Large.png"
                                  class="reject_image"
                                  *ngIf="
                                    imgData.rejected &&
                                    imageLoaded[ind]
                                  "
                                />
                              </span>
                              <span *ngIf="ShowHideRejected">
                                <img
                                  preventdrag
                                  src="/assets/images/EXCLUSIVE.png"
                                  class="exclusiveImage"
                                  *ngIf="imgData.exclusive && imageLoaded[ind]"
                                />
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                      <fs-image-name-panel
                        [imgData]="imgData"
                      ></fs-image-name-panel>
                      <fs-image-color-tag-panel
                        [imgData]="imgData"
                      ></fs-image-color-tag-panel>
                    </div>

                    <div
                      *ngIf="isSuperZoom"
                      class="eimg_dive superZoom_scroll"
                      (contextmenu)="onRightClick()"
                    >
                      <div class="span31">
                        <div class="eimage">
                          <a
                            href="javascript:void(0)"
                            class="eimg_dive_ver"
                            (dblclick)="selectZoomImageOnDoubleClick()"
                          >
                            <span
                              (mouseup)="showRejected(imageLoaded[ind])"
                              (mousedown)="hideRejected(imageLoaded[ind])"
                            >
                              <div
                                *ngIf="
                                  imgData.status === 5 &&
                                  imgData.rejectedBy.length === 0 &&
                                  imageLoaded[ind]
                                "
                                class="approve_icon superZoom"
                              >
                                <i class="approve_icon_sp bg_sprite"></i>
                              </div>
                              <div
                                *ngIf="
                                  imgData.status === 5 &&
                                  imgData.rejectedBy.length >= 1 &&
                                  imageLoaded[ind]
                                "
                                class="rejected_icon superZoom"
                              >
                                <i class="reject_icon_sp bg_sprite"></i>
                              </div>
                              <img
                                preventdrag
                                class="imgMarginTop"
                                [ngClass]="{
                                  blur_img:
                                    imgData.excluded &&
                                    imageLoaded[ind] &&
                                    mouseExcluded
                                }"
                                [lazySrc]="
                                  viewProjectService.getWebImageURL(
                                    imgData,
                                    '1600'
                                  )
                                "
                                alt=""
                                (load)="onLoad(ind)"
                                draggable="false"
                              />
                              <span *ngIf="ShowHideRejected">
                                <img
                                  preventdrag
                                  src="/assets/images/Rejected_X_Large.png"
                                  class="reject_image1600"
                                  *ngIf="
                                    imgData.rejected &&
                                    imageLoaded[ind]
                                  "
                                />
                              </span>
                              <span *ngIf="ShowHideRejected">
                                <img
                                  preventdrag
                                  src="/assets/images/EXCLUSIVE.png"
                                  class="exclusiveImage1600"
                                  *ngIf="imgData.exclusive && imageLoaded[ind]"
                                />
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                      <fs-image-name-panel
                        [imgData]="imgData"
                      ></fs-image-name-panel>
                      <fs-image-color-tag-panel
                        [imgData]="imgData"
                      ></fs-image-color-tag-panel>
                    </div>
                  </div>
                  <div *ngSwitchCase="'video'">
                    <div class="img_dive middimage_scroll2">
                      <div class="span31 imgItem">
                        <div class="imgee">
                          <a
                            href="javascript:void(0)"
                            (dblclick)="selectImageOnDoubleClick()"
                          >
                            <span>
                              <img
                                preventdrag
                                class="border-active"
                                [lazySrc]="
                                  viewProjectService.getWebImageURL(
                                    imgData,
                                    '700'
                                  )
                                "
                                alt=""
                                (load)="onLoad(ind)"
                                draggable="false"
                              />
                            </span>
                          </a>
                        </div>
                      </div>
                      <fs-image-name-panel
                        [imgData]="imgData"
                      ></fs-image-name-panel>
                      <fs-image-color-tag-panel
                        [imgData]="imgData"
                      ></fs-image-color-tag-panel>
                    </div>
                  </div>
                  <div *ngSwitchCase="'docx'">
                    <div class="img_dive">
                      <div class="span31 imgItem">
                        <div class="imgee">
                          <a
                            href="javascript:void(0)"
                            (dblclick)="selectImageOnDoubleClick()"
                          >
                            <span>
                              <img
                                preventdrag
                                class="border-active"
                                src="/assets/images/doc_icon.png"
                              />
                            </span>
                          </a>
                        </div>
                      </div>
                      <fs-image-name-panel
                        [imgData]="imgData"
                      ></fs-image-name-panel>
                      <fs-image-color-tag-panel
                        [imgData]="imgData"
                      ></fs-image-color-tag-panel>
                    </div>
                  </div>
                  <div *ngSwitchCase="'pdf'">
                    <div class="img_dive">
                      <div class="span31 imgItem">
                        <div class="imgee">
                          <a
                            href="javascript:void(0)"
                            (dblclick)="selectImageOnDoubleClick()"
                          >
                            <span>
                              <img
                                preventdrag
                                class="border-active"
                                src="/assets/images/pdf_icon.png"
                              />
                            </span>
                          </a>
                        </div>
                      </div>
                      <fs-image-name-panel
                        [imgData]="imgData"
                      ></fs-image-name-panel>
                      <fs-image-color-tag-panel
                        [imgData]="imgData"
                      ></fs-image-color-tag-panel>
                    </div>
                  </div>
                  <div *ngSwitchCase="'zip'">
                    <div class="img_dive">
                      <div class="span31 imgItem">
                        <div class="imgee">
                          <a
                            href="javascript:void(0)"
                            (dblclick)="selectImageOnDoubleClick()"
                          >
                            <span>
                              <img
                                preventdrag
                                class="border-active"
                                src="/assets/images/zip_icon.png"
                              />
                            </span>
                          </a>
                        </div>
                      </div>
                      <fs-image-name-panel
                        [imgData]="imgData"
                      ></fs-image-name-panel>
                      <fs-image-color-tag-panel
                        [imgData]="imgData"
                      ></fs-image-color-tag-panel>
                    </div>
                  </div>
                  <div *ngSwitchCase="'xlsx'">
                    <div class="img_dive">
                      <div class="span31 imgItem">
                        <div class="imgee">
                          <a
                            href="javascript:void(0)"
                            (dblclick)="selectImageOnDoubleClick()"
                          >
                            <span>
                              <img
                                preventdrag
                                class="border-active"
                                src="/assets/images/xls_icon.png"
                              />
                            </span>
                          </a>
                        </div>
                      </div>
                      <fs-image-name-panel
                        [imgData]="imgData"
                      ></fs-image-name-panel>
                      <fs-image-color-tag-panel
                        [imgData]="imgData"
                      ></fs-image-color-tag-panel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="albumGrid"></div>
  </div>
</div>
<div
  *ngIf="
    (userAuthority === userRole.ROLE_ADMIN ||
      projectAuthority === projectRole.ROLE_EXEC) &&
    !isSuperZoom
  "
>
  <fs-enlarge-slider></fs-enlarge-slider>
</div>
