<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
      'left-menu.tab-bar.sidemenus.administration.actorid-report.title'
        | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div>
        <h5 class="fs-md-txt d-inline">
          {{
            'left-menu.tab-bar.sidemenus.administration.actorid-report.labels.project'
              | translate
          }}
        </h5>
        :
        <h6 class="d-inline">{{ projectName }}</h6>
      </div>
    </div>
  </div>
  <div class="db_com cbackground fs-header-wrapper_fixed">
    <div
      *ngIf="dataSource.data.length > 0"
      class="fs-report-table fs-scroll-table-body fs-header-sort-color table-responsive"
    >
      <mat-table
        class="actorId-report-table"
        [hidden]="!dataSource.data.length"
        [dataSource]="dataSource"
      >
        <ng-container matColumnDef="actorName">
          <mat-header-cell class="fs-sm-txt pl-2" *matHeaderCellDef>
            {{
              'left-menu.tab-bar.sidemenus.administration.actorid-report.table.actorname'
                | translate
            }}
          </mat-header-cell>
          <mat-cell
            class="px-2 py-1 fs-sm-txt fs-txt-white-grey-color-hover"
            *matCellDef="let element"
          >
            {{ element.actorName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="count">
          <mat-header-cell class="fs-sm-txt pl-2" *matHeaderCellDef>
            {{ originalData[0].createdDate | localizeDatePipe: 'MMMM do, yyyy' }}
          </mat-header-cell>
          <mat-cell
            class="px-2 py-1 fs-sm-txt fs-txt-white-grey-color-hover"
            *matCellDef="let element"
          >
            {{ element.count }}
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end" class='fs-actions-wrapper'>
  <button
    class="fs-dialog-btn w-auto"
    (click)="exportReport(exportType.Excel)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as xls
  </button>

  <button
    class="fs-dialog-btn w-auto"
    (click)="exportReport(exportType.Pdf)"
  >
    {{
      'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
        | translate
    }}
    as PDF
  </button>

  <button class="fs-dialog-btn w-auto" (click)="actorIdHistory()">
    {{
      'left-menu.tab-bar.sidemenus.administration.actorid-report.button.id-history'
        | translate
    }}
  </button>

    <button class="fs-dialog-btn w-auto" (click)="closeWindow()">
      {{
        'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.close'
          | translate
      }}
    </button>
</div>
