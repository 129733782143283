<div class="fs-paginator-wrapper">
  <mat-paginator
    [pageIndex]="pageIndex"
    class="d-none"
    [length]="length"
    [pageSize]="pageSize"
  >
  </mat-paginator>
  <div class="pagination">
    <div
      class="pagination-action-container pgination-last-first firstpage"
      [ngClass]="{ disabled: isFirstElement }"
      (click)="goToFirst.emit()"
    >
      <span class="fa fa-step-backward" aria-hidden="true"></span>
    </div>

    <div
      class="pagination-action-container"
      [ngClass]="{ disabled: isFirstElement }"
      (click)="goToPrevious.emit()"
    >
      <span
        class="fa fa-caret-left fa-caret"
        aria-hidden="true"
      ></span>
    </div>

    <div class="pagination-action-container page-num-container active">
      <span> {{ pageIndex }} of {{ pageSize ? pageSize : 0 }}</span>
    </div>
    <div
      class="pagination-action-container"
      [ngClass]="{ disabled: isLastElement }"
      (click)="goToNext.emit()"
    >
      <span
        class="fa fa-caret-right fa-caret"
        aria-hidden="true"
      >
      </span>
    </div>

    <div
      class="pagination-action-container pgination-last-first lastpage"
      (click)="goToLast.emit()"
      [ngClass]="{ disabled: isLastElement }"
    >
      <span class="fa fa-step-forward" aria-hidden="true"></span>
    </div>
  </div>
</div>
