import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConvertAssetTypesService } from './convert-asset-types.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  AssetInfo,
  AssetInfoDTOList,
} from '../../../../../../../shared/models/assetInfo.model';
import {
  AssetTypesEnum,
  ConvertAssetInfoModel,
  ConvertAssetModel,
} from '../../../../../../../shared/models/convert-asset.model';
import { SelectItem } from '../../../../../../../shared/models/select-item';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

const AssetTypesOptions: SelectItem[] = [
  {
    label: 'image',
    value: AssetTypesEnum.image,
  },
  {
    label: 'video',
    value: AssetTypesEnum.video,
  },
  {
    label: 'docx',
    value: AssetTypesEnum.docx,
  },
  {
    label: 'pdf',
    value: AssetTypesEnum.pdf,
  },
  {
    label: 'zip',
    value: AssetTypesEnum.zip,
  },
];

@Component({
  selector: 'fs-change-asset-type',
  templateUrl: './convert-asset-type.component.html',
  styleUrls: ['./convert-asset-type.component.scss'],
})
export class ConvertAssetTypeComponent implements OnInit, OnDestroy {
  assetsTypeForm: UntypedFormGroup;
  assetTypes = AssetTypesOptions;
  projectImageData: AssetInfo = new AssetInfo();
  selectedTypeValue: number;

  private destroy$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private selectedImagesStore: SelectedImagesStore,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private viewProjectService: ViewProjectService,
    private convertAssetTypesService: ConvertAssetTypesService,
    private dialogRef: MatDialogRef<ConvertAssetTypeComponent>,
  ) {}

  ngOnInit(): void {
    this.assetsTypeForm = this.fb.group({
      assetType: ['', Validators.required],
    });
  }

  onSubmitTypeChange() {
    if (this.selectedImagesStore.hasEntities()) {
      this.callChangeAssetTypesAPI();
    }
  }

  callChangeAssetTypesAPI() {
    this.selectedTypeValue = this.assetsTypeForm.get('assetType').value;
    this.loaderService.displayLoader(true);
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: Number(this.viewProjectService.getProjectId()),
      typeToUpdate: this.selectedTypeValue,
    };

    this.convertAssetTypesService
      .postConvertAssetTypes(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ConvertAssetModel) => {
          this.loaderService.displayLoader(false);
          this.updateProjectData(res.t);
          this.closeWindow();
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
          this.closeWindow();
        },
      );
  }

  updateProjectData(res: ConvertAssetInfoModel[]) {
    const typeToUpdate = AssetTypesOptions.find(
      (item: SelectItem) => item.value === this.selectedTypeValue,
    );

    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    res.forEach((eachObj) => {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map(function (e: AssetInfoDTOList) {
          return e.id;
        })
        .indexOf(eachObj.id);

      if (imageIndex !== -1) {
        this.projectImageData.assetInfoDTOList[imageIndex].assetType =
          typeToUpdate.label;
      }
    });
  }

  closeWindow() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
