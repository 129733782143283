import { Utils } from './../../../../shared/utils/utils';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppSettings } from '../../../../appSettings/appSettings';
import { ApiService } from '../../../../shared/services/api.service';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { SelectedImagesStore } from '../../../../shared/store/selected-images-store';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ExpressDownloadErrorKeysEnum } from './express-download/express-download-error-keys.enum';
import { AssetInfoDTOList } from '../../../../shared/models/assetInfo.model';

@Injectable()
export class TopmenusService {
  private isShowImgInfo = new BehaviorSubject<boolean>(false);
  cast = this.isShowImgInfo.asObservable();

  private dynamicTopMenu = new BehaviorSubject<any>({});
  topMenuStatus = this.dynamicTopMenu.asObservable();

  private dynamicTopMenuPagination = new BehaviorSubject<any>({});
  topMenuPagination = this.dynamicTopMenuPagination.asObservable();

  constructor(
    private apiService: ApiService,
    private viewProjectService: ViewProjectService,
    private translate: TranslateService,
    private selectedImagesStore: SelectedImagesStore,
  ) {}

  setShowHideImageInfo(isShowImgInfo) {
    this.isShowImgInfo.next(isShowImgInfo);
  }

  setTopMenuStatus(status) {
    this.dynamicTopMenu.next(status);
  }

  setTopMenuPagination(value) {
    this.dynamicTopMenuPagination.next(value);
  }

  postAssetsInfodata(body: any): Observable<any> {
    const path = AppSettings.ASEETS_INFODATA;
    return this.apiService.post(path, body).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  getExclusiveAssets(data): Observable<AssetInfoDTOList[]> {
    const path = AppSettings.GET_EXCLUSIVE_ASSETS;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  getLinkExclusiveAssets(data): Observable<AssetInfoDTOList[]> {
    const path = AppSettings.GET_LINK_EXCLUSIVE_ASSETS;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  postAssetsDetail(body: any): Observable<any> {
    const path = AppSettings.ASEET_DETAIL;
    return this.apiService
      .postAssets(this.viewProjectService.getBaseUrl(), path, body)
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }

  postAssetsDetailSingleSize(body: any): Observable<any> {
    const path = AppSettings.ASEET_DETAIL_SINGLE_SIZE;
    return this.apiService
      .postAssets(
        this.viewProjectService.getBaseUrl(),
        path,
        body,
      )
      .pipe(map((data) => data));
  }

  checkExclusiveImage() {
    return Utils.checkExclusiveImage(this.selectedImagesStore.getAll());
  }
}
