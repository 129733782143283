import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthServerProvider } from '../services/auth.jwt.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthServerProvider) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.auth.getToken();
    const authReq = req.clone({
      setHeaders: { Authorization: 'Bearer ' + authToken },
    });

    if (req.url.includes('authenticate')) {
      return next.handle(req);
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
