import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { AngularDeviceInformationService } from 'angular-device-information';
import { catchError, delay, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AppSettings } from '../../appSettings/appSettings';
import { DuplicateAssetListModel } from '../models/duplicate-asset-list.model';
import printJS from 'print-js';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

@Injectable()
export class DuplicateMenuService {
  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
  ) {}

  getDuplicateAssets(projectId): Observable<DuplicateAssetListModel> {
    const path = AppSettings.DUPLICATE_PROJECT_ASSETS(projectId);
    return this.apiService.get(path).pipe(
      map((res) => res)
    );
  }

  exportPDFData(jsonData, documentTitle: string) {
    const columns = [
      { header: 'Warning', dataKey: 'WARNING' }
    ];

    const url = window.location.href;
    const doc = new jsPDF();

    const date = new Date();
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(8);
    doc.text(`${formattedDate}, ${formattedTime}`, 10, 10);
    doc.text(documentTitle, pageWidth - 10, 10, { align: 'right' });

    autoTable(doc, {
      columns: columns,
      body: jsonData,
      theme: 'grid',
      startY: 20,
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0],
        halign: 'center',
        fontSize: 11
      },
      styles: { fontSize: 10, cellPadding: 1, overflow: 'linebreak' },
      columnStyles: { warning: { cellWidth: 'wrap' } },
      didDrawPage: function () {
        const pageNumber = doc.getCurrentPageInfo().pageNumber;
        const pageWidth = doc.internal.pageSize.getWidth();

        doc.setFontSize(8);
        doc.text(url, 10, doc.internal.pageSize.getHeight() - 10);
        doc.text(`${pageNumber}`, pageWidth - 10, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
      }
    });

    doc.save(documentTitle);
  }
}
