import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-image-history',
  templateUrl: './image-history.component.html',
  styleUrls: ['./image-history.component.scss'],
})
export class ImageHistoryComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'actionTaken',
    'transactionTime',
    'transactionDetail',
    'username',
  ];
  dataSource = new MatTableDataSource([]);

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeWindow();
  }

  constructor(
    private viewProjectService: ViewProjectService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private dialogRef: MatDialogRef<ImageHistoryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      projectId: any;
      currentImageID: any;
      historyReportType: any;
    },
  ) {
    super();
  }

  ngOnInit() {
    this.loaderService.displayLoader(true);
    this.viewProjectService
      .getHistoryData(
        this.data.projectId,
        this.data.currentImageID,
        this.data.historyReportType,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          if (response) {
            this.dataSource.data = response;
            this.dataSource.sort = this.sort;
          }

          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
