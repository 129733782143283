import { Component, OnInit } from '@angular/core';
import { CommentsService } from './comments.service';
import { DrawImageService } from '../../../../project-detail/draw-image/draw-image.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { UserDataService } from '../../../../../../shared/services/userdata.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';
import {LoaderService} from '../../../../../../shared/services/loader.service';

@Component({
  selector: 'fs-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent extends UnsubscriberComponent implements OnInit {
  customeFilter = [{ name: 'Custom Filter' }];
  commentList: Array<any> = [];
  userData: Array<any> = [];
  currentLoggedInUserData = [];
  distinctUserData;
  currentLoggedInId: number;

  constructor(
    private commentsService: CommentsService,
    private drawImageService: DrawImageService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private viewProjectService: ViewProjectService,
    private userService: UserService,
    private userDataService: UserDataService,
  ) {
    super();
  }

  ngOnInit() {
    this.commentsService.setUserListData([]);

    this.userService.currentUserGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.currentLoggedInId = Number(res.id);
      });

    this.drawImageService.retouchCommentsGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.commentList = res;
        if (!Utils.isObjectEmpty(res)) {
          const userIds = res.map((userData) => {
            return userData.userId;
          });
          if (userIds.length && this.currentLoggedInId) {
            {
              userIds.push(this.currentLoggedInId);
              this.userDataService
                .getUserData(this.viewProjectService.getProjectId(), [
                  ...new Set(userIds),
                ])
                .pipe(takeUntil(this.destroy$))
                .subscribe((value) => {
                  this.distinctUserData = value;
                  this.currentLoggedInUserData = this.distinctUserData;
                  this.currentLoggedInUserData.forEach((user) => {
                    this.commentList.forEach((comment) => {
                      if (user.id === comment.userId) {
                        comment[
                          'userName'
                        ] = `${user.firstName} ${user.lastName}`;
                      }
                    });
                  });
                },(error) => {
                    this.apiErrorHandlerService.getHandler().handle(error);
                    this.loaderService.displayLoader(false);
                  });
            }
          } else {
            this.commentList = [];
          }
        }
      });
  }
}
