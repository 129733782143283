import { AlbumsService } from './../../dashboard-shared/left-menu/menus/albums/albums.service';
import { UserDataService } from './../../../../shared/services/userdata.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewGalleryService } from './view-gallery.service';
import { AppSettings } from '../../../../appSettings/appSettings';
import { LauncherService } from '../../../launcher/launcher.service';
import { DepartmentService } from '../../../department/department.service';
import { SidebarService } from '../../sidebar-service/sidebar-service';
import { TopmenusService } from '../../dashboard-shared/top-menu/top-menu.service';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { AuthServerProvider } from '../../../../shared/services/auth.jwt.service';
import { UserService } from '../../../../shared/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { SelectedImagesStore } from '../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../shared/store/last-selected-image-store';
import { SessionTypeEnum } from '../../../../shared/enum/session-type.enum';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { UserRole } from '../../../../shared/enum/user-role.enum';
import { ViewGalleryPriorityPixStateService } from '../../../../shared/services/view-gallery-priority-pix.service';
import { ViewGalleryResumeModalStateService } from '../../../../shared/services/view-gallery-resume-modal.service';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';
import { ProjectRole } from '../../../../shared/enum/project-role.enum';
import {ApiErrorHandlerService} from '../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-view-gallery',
  templateUrl: './view-gallery.component.html',
  styleUrls: ['./view-gallery.component.scss'],
})
export class ViewGalleryComponent
  extends UnsubscriberComponent
  implements OnInit
{
  isLeftMenuPartShown = true;
  gValue = '';
  isPotrait: boolean[] = [];
  imageLoaded: boolean[] = [];
  galvalue: any;
  imagePerPage: number;
  selectedProjectValue: any;
  isShowedResumeDialog: boolean;
  isFavGallaryMode: boolean;
  isGallaryMode: boolean;
  subgalValue: any;
  viewGalleryList: any;
  talentFirstTimePriorityCount: boolean;
  priritypixImageCount: number;
  projectRole: string;
  projectAuthority: string;
  userAuthority: string;
  viewGallery: any = [];
  width: number;
  height: number;
  totalSetups: number;
  deleteAssets: number;
  token: string;
  showFinalizationAccess = false;

  setUpId: any;
  setUpName: any;
  setUpGroupName: any;

  totalGalleryImage = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private viewProjectService: ViewProjectService,
    private topmenusService: TopmenusService,
    private loaderService: LoaderService,
    private viewGalleryService: ViewGalleryService,
    private auth: AuthServerProvider,
    private launcherService: LauncherService,
    private departmentService: DepartmentService,
    private sidebarService: SidebarService,
    private userService: UserService,
    private userDataService: UserDataService,
    private snackBarService: SnackBarService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private albumsService: AlbumsService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    private dialogService: DialogService,
    private viewGalleryResumeModalService: ViewGalleryResumeModalStateService,
    private viewGalleryPriorityPixStateService: ViewGalleryPriorityPixStateService,
    private translate: TranslateService,
  ) {
    super();
    this.token = this.auth.getToken();
  }

  ngOnInit() {
    this.viewProjectService.resetProjectData();
    this.viewProjectService.resetProjectImageIDs();
    this.selectedImagesStore.clear();
    this.lastSelectedImageStore.clear();
    this.viewProjectService.setIsSessionAcceptedFromGallery(false);
    this.viewProjectService.setSelectedBatchId(undefined);
    this.viewProjectService.priorityPixHandling.next(false);
    this.galvalue = this.route.snapshot.params.galvalue;
    this.subgalValue = this.route.snapshot.params.subgalValue;
    this.topmenusService.setTopMenuStatus('ImageGallery');

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });
    this.departmentService.setUpIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((setupid) => {
        this.setUpId = setupid;
      });

    this.departmentService.setUpNameGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((setupid) => {
        this.setUpName = setupid;
      });

    this.departmentService.setUpNameGroupGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((setupid) => {
        this.setUpGroupName = setupid;
      });

    this.viewProjectService.priorityPixHandling$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          this.viewGalleryService
            .callPriorityPixFromGalleryView(this.gValue, this.imagePerPage)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {},
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              }
            );
        }
      });

    this.viewProjectService.talentFirstTimePriorityCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((flage) => {
        this.talentFirstTimePriorityCount = flage;
      });

    this.viewProjectService.priorityPixImageCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.priritypixImageCount = res;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          if (data.internalUsers) {
            this.callUserDataInfoAPI(data);
          }
          this.imagePerPage = data.viewSettingDTO.imagePerPage;
          this.showFinalizationAccess = data.permissionDTO.showFinalizationAccess;
        }
      });

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority: string) => {
        this.projectAuthority = projectAuthority;
      });

    this.launcherService.isGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isGallaryMode: boolean) => {
        this.isGallaryMode = isGallaryMode;
      });

    this.launcherService.isFavGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isFavGallaryMode: boolean) => {
        this.isFavGallaryMode = isFavGallaryMode;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.launcherService.projectRoleGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectRole) => {
        this.projectRole = projectRole;
      });

    this.viewProjectService.deleteAssetGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.deleteAssets = status;
      });

    this.viewGalleryResumeModalService.handledResumeSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isShowedResumeDialog = status;
      });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((parameters) => {
      // g_albumid - project id of a project inside a department.
      this.gValue = parameters.gAlbumid;
      this.viewProjectService.setProjectID(this.gValue);
      if (history.state.selectedProjectRole) {
        this.viewProjectService.setProjectRole(
          history.state.selectedProjectRole,
        );
      }
      this.departmentService.setSetUpIDGlobal(this.gValue);
      this.viewGallery = [];
      this.isPotrait = [];
      this.loadProjectDetailPermission();
    });
  }

  handleProjectDetailPermission(res) {
    if (res.talentProjectInfoDTO.currentBatch !== 0 || this.showFinalizationAccess) {
      this.viewProjectService.isPiorityPixDataLoaded.next(false);
      this.viewProjectService.priorityPixImageCount.next(res.priorityPixCount);
      if (
        res.restartDataDTO.pageNo >= 1 &&
        res.restartDataDTO.assetId >= 0 &&
        !this.isShowedResumeDialog &&
        !this.deleteAssets
      ) {
        if (
          this.talentFirstTimePriorityCount &&
          this.projectAuthority === ProjectRole.ROLE_TALENT &&
          this.priritypixImageCount > 0
        ) {
          this.callPriorityPixModal(res);
        } else {
          this.openResumeSessionDialog(res);
        }
      } else {
        this.callPriorityPixModal(res);
      }
    }
  }

  callPriorityPixModal(res) {
    if (this.talentFirstTimePriorityCount) {
      this.viewProjectService.talentFirstTimePriorityCount.next(false);
      if (
        this.projectAuthority === ProjectRole.ROLE_TALENT &&
        this.priritypixImageCount > 0 &&
        !this.viewGalleryPriorityPixStateService.isHandledPrioritySession()
      ) {
        this.dialogService
          .openConfirmationDialog({
            message: this.translate.instant('view-project.confirm19'),
            title: this.translate.instant('view-project.confirm20'),
            // icon: 'fa fa-info-circle', //temp removed by Abhi on Mar 25, 2019
          })
          .subscribe((state: boolean) => {
            if (state) {
              this.viewProjectService.setCurrentFilter('prioritypix');
              this.viewGalleryPriorityPixStateService.setHandledPrioritySession(
                true,
              );
              this.viewGalleryResumeModalService.setHandledResumeSession(true);
              this.viewProjectService.priorityPixHandling.next(true);
            } else {
              this.viewGalleryPriorityPixStateService.setHandledPrioritySession(
                true,
              );
              if (
                res.restartDataDTO.pageNo >= 1 &&
                res.restartDataDTO.assetId >= 0 &&
                !this.isShowedResumeDialog &&
                !this.deleteAssets
              ) {
                setTimeout(() => {
                  this.openResumeSessionDialog(res);
                }, 0);
              }
              this.snackBarService.showSnackBarWarning(
                this.translate.instant('confirmDialog.resume.rejectMsg'),
              );
            }
          });
      }
    }
  }

  openResumeSessionDialog(res) {
    if (!this.isGallaryMode && !this.isFavGallaryMode) {
      if (this.selectedProjectValue !== 3 && this.selectedProjectValue !== 4) {
        const galInfoFilter = res.restartDataDTO.filter.substr(6);
        const currentSetUp =
          this.findCurrentSetUpWithResumeSession(galInfoFilter);
        this.dialogService
          .openConfirmationDialog({
            message: this.translate.instant('confirmDialog.resume.message'),
            title: this.translate.instant('confirmDialog.resume.title'),
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((state: boolean) => {
            if (state) {
              if (currentSetUp) {
                this.launcherService.setSelectedProjectName(currentSetUp.label);
                this.launcherService.setSelectedSetUpId(currentSetUp.value);
                this.viewProjectService.setSelectedBatchId(currentSetUp.value);
              }

              this.sidebarService.resetAlbumImageData();
              this.viewGalleryResumeModalService.setDialogActionState(
                SessionTypeEnum.Restore,
              );
              const batchFilter = res.talentProjectInfoDTO.currentBatch === 0 && this.showFinalizationAccess
                ? 'showall'
                : 'batch:' + galInfoFilter;

                this.navigateToViewProject(batchFilter);
            } else {
              this.snackBarService.showSnackBar(
                this.translate.instant('confirmDialog.resume.rejectMsg'),
                '',
                5000,
              );
              this.viewGalleryResumeModalService.setDialogActionState(
                SessionTypeEnum.Continue,
              );

              if (res.talentProjectInfoDTO.currentBatch === 0 && this.showFinalizationAccess) {
                this.router.navigate([
                  '/dashboard/viewproject/' +
                  this.viewProjectService.getProjectRole() +
                  this.gValue +
                  '/filter/showall' +
                  '/page/1',
                ]);
              }
            }
          });
      }
    }
  }

  handleResumeSetUpsSession(res) {
    const galInfoFilter = res.restartDataDTO.filter.substr(6);
    const currentSetUp = this.findCurrentSetUpWithResumeSession(galInfoFilter);
    this.dialogService
      .openConfirmationDialog({
        message: this.translate.instant('confirmDialog.resume.message'),
        title: this.translate.instant('confirmDialog.resume.title'),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          if (currentSetUp) {
            this.launcherService.setSelectedProjectName(currentSetUp.label);
            this.launcherService.setSelectedSetUpId(currentSetUp.value);
            this.viewProjectService.setSelectedBatchId(currentSetUp.value);
          }

          this.sidebarService.resetAlbumImageData();
          this.viewProjectService.setRestartDataDTO(res.restartDataDTO);
          this.viewProjectService.setIsSessionAcceptedFromGallery(true);
          this.router.navigate([
            '/dashboard/viewproject/' +
              this.viewProjectService.getProjectRole() +
              this.gValue +
              '/filter/' +
              res.restartDataDTO.filter +
              '/page/1',
          ]);
        } else {
          this.viewProjectService.setIsSessionAcceptedFromGallery(false);
        }
      });
  }

  findCurrentSetUpWithResumeSession(galInfoFilter) {
    return this.viewGallery.find(
      (setUp) => setUp.value === parseInt(galInfoFilter, 10),
    );
  }

  onLoad(event, index) {
    this.imageLoaded[index] = true;
    try {
      if (event.path[0].naturalHeight > event.path[0].naturalWidth) {
        this.isPotrait[index] = true;
      }
    } catch {
      if (event.target.height > event.target.width) {
        this.isPotrait[index] = true;
      }
    }
  }

  loadProjectDetailPermission() {
    this.loaderService.displayLoader(true);
    this.viewProjectService
      .callProjectDetailPermissionAPI(
        this.viewProjectService.getProjectId(),
        this.viewProjectService.getProjectRole(),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.viewProjectService.setCurrentProjectAuthority(
          this.userService.getCurrentUser().authorities,
        );
        this.userService.setUserAuthority(UserRole[res.authorities[0]]);
        this.viewProjectService.setProjectDetailPermissionData(res);
        this.viewProjectService.setCurrentFilter(res.restartDataDTO.filter);
        this.loaderService.displayLoader(false);
        if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
          this.setAllProjectAssetsId();
        } else {
          this.callAlbumDataAPI();
        }
        this.getSetUpData(res);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  callAlbumDataAPI() {
    this.albumsService
      .getAlbumGroupInfo(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (Object.keys(res).length !== 0) {
          this.sidebarService.setAlbumList(res);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  setAllProjectAssetsId(): void {
    this.viewProjectService
      .callProjectImageIDs(this.gValue, 'showall')
      .pipe(takeUntil(this.destroy$))
      .subscribe((assetsIds: number[]) => {
        this.viewProjectService.setTotalProjectPages(
          assetsIds.length,
          this.imagePerPage,
        );
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  callUserDataInfoAPI(data) {
    const results = [...data.internalUsers, ...data.execUsers];
    this.userDataService
      .getUserData(this.viewProjectService.getProjectId(), results)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.albumsService.setUserDataInfo(value);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  getSetUpData(res) {
    this.loaderService.displayLoader(true);
    setTimeout(() => {
      const path = AppSettings.GET_SETUP_DATA(this.gValue);
      this.viewProjectService.setProjectID(this.gValue);
      this.viewGalleryService
        .getSetUpDataApiCall(path)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data) => {
            if (Object.keys(data || {}).length) {
              this.totalGalleryImage = 0;
              this.viewGalleryList = data;
              this.departmentService.setSetUpNameGroupGlobal(
                this.viewGalleryList.projectDataDTO.projectGroupName,
              );
              this.departmentService.setSetUpNameGlobal(
                this.viewGalleryList.projectDataDTO.projectName,
              );
              this.viewGallery = this.generateURL(data.setupDTOList);
              this.totalSetups = this.viewGalleryList.setupDTOList.length;
              this.loaderService.displayLoader(false);
              this.viewGalleryList.setupDTOList.forEach((list) => {
                this.totalGalleryImage =
                  this.totalGalleryImage + list.assetCount;
              });
              this.viewGalleryService.setTotalGalleryImage(
                this.totalGalleryImage,
              );
            }
            this.loaderService.displayLoader(false);

            if (
              this.selectedProjectValue !== 3 &&
              this.selectedProjectValue !== 4 &&
              !this.deleteAssets
            ) {
              if (
                this.userAuthority === UserRole.ROLE_ADMIN ||
                this.projectAuthority === ProjectRole.ROLE_EXEC
              ) {
                if (
                  res.restartDataDTO.pageNo >= 1 &&
                  res.restartDataDTO.assetId >= 0 &&
                  res.restartDataDTO.filter
                ) {
                  this.handleResumeSetUpsSession(res);
                }
              }
            }

            if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
              this.handleProjectDetailPermission(res);
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }, 0);
  }

  generateURL(obj) {
    obj.forEach((element) => {
      element.url = this.getGalleryWebImageURL(element, '200');
    });
    return obj;
  }

  viewGalAlbumInfo(galInfo) {
    this.launcherService.setSelectedProjectName(galInfo.label);
    this.launcherService.setSelectedSetUpId(galInfo.value);
    this.sidebarService.resetAlbumImageData();
    this.viewProjectService.setSelectedBatchId(galInfo.value);
    this.router.navigate([
      '/dashboard/viewproject/' +
        this.viewProjectService.getProjectRole() +
        this.gValue +
        '/filter/batch:' +
        galInfo.value +
        '/page/1',
    ]);
  }

  onDragStart(event: MouseEvent) {
    event.preventDefault();
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  getGalleryWebImageURL(setUpObj, imageDim) {
    return this.viewProjectService.getWebImageURL(setUpObj, imageDim);
  }

  private navigateToViewProject(batchFilter: string) {
    const projectRole = this.viewProjectService.getProjectRole();
    const route = `/dashboard/viewproject/${projectRole}${this.gValue}/filter/${batchFilter}/page/1`;
    this.router.navigate([route]);
  }
}
