import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { TaggerService } from '../../../../../../../shared/services/tagger.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../../../shared/store/last-selected-image-store';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

// TODO: need  to test
@Component({
  selector: 'fs-taggingview-2',
  templateUrl: './taggingview-2.component.html',
  styleUrls: ['./taggingview-2.component.scss'],
})
export class Taggingview2Component
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  static identifier = 'Taggingview2Component';
  lastSelectedImageOnRestat: any;
  restartDataDTO: any;
  taggingList = [];
  isMouseHold: any = 0;
  projectImageData: AssetInfo = new AssetInfo();
  noOfImages: any;
  currentFilter: string;
  constantsGlobalData: any;
  @ViewChild('taggingpopup') taggingpopup: ElementRef;

  constructor(
    private modalService: Modalstatusservice,
    private taggerService: TaggerService,
    private loaderService: LoaderService,
    private snackBarService: SnackBarService,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private router: Router,
    private translate: TranslateService,
    private dialogService: DialogService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<Taggingview2Component>,
    private lastSelectedImageStore: LastSelectedImageStore,
    @Inject(MAT_DIALOG_DATA)
    public data: { res: any; isOwnLockAsTagger: boolean },
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.viewProjectService.restartDataDTO
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.restartDataDTO = data;
      });

    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );

    this.initTaggingData();

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.noOfImages = data.viewSettingDTO.imagePerPage;
          this.taggingList = JSON.parse(JSON.stringify(data.projectTagListDTO));
          this.taggingList.sort((a, b) => a.hotkey.localeCompare(b.hotkey));
        }
      });

    this.viewProjectService.tagginView2Data
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.taggingList.forEach((eachObj, index) => {
          if (eachObj.hotkey === data.hotKey) {
            this.taggingList[index].imageCount = data.count;
          }
        });
      });
  }

  initTaggingData() {
    if (this.data.isOwnLockAsTagger) {
      this.updateExistingCount(this.data.res);
      this.updateExistingTaggHotKey(this.data.res);
      this.viewProjectService.setIsTaggingView2Open(true);
    } else {
      this.callTaggingConfirm(this.createModel());
    }
  }

  getLastSelectedImagesOnRestatData(res, assetId) {
    return res.assetInfoDTOList.filter((imageBbj) => {
      return imageBbj.id === assetId;
    });
  }

  onClickTagList(taglist) {
    if (taglist.imageCount > 0) {
      this.loaderService.displayLoader(true);
      const filter = 'tagging:2-' + taglist.id;

      this.viewProjectService
        .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res1) => {
            if (res1) {
              this.currentFilter = filter;
              const assetsIds = Utils.getImageIdsBasedOnPage(
                1,
                this.noOfImages,
                res1,
              );
              const data = {
                assetIds: assetsIds,
                projectId: this.viewProjectService.getProjectId(),
                filter: filter,
              };
              this.viewProjectService
                .callAssetsInfoBasedOnAssetsId(data)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  (resData) => {
                    if (resData.assetInfoDTOList.length > 0) {
                      this.loaderService.displayLoader(false);
                      this.viewProjectService.setCurrentFilter(
                        this.currentFilter,
                      );
                      this.router.navigate([
                        Utils.prepareFilterNavigationURL(
                          this.viewProjectService.getProjectRole(),
                          this.viewProjectService.getProjectId(),
                          this.currentFilter,
                          1,
                        ),
                      ]);
                      this.selectedImagesStore.clear();
                      this.sidebarService.setAlbumImageData(resData);
                    } else {
                      this.alertCustom(
                        this.translate.instant('view-project.alert2'),
                      );
                    }
                    this.loaderService.displayLoader(false);
                    this.viewProjectService.setExecutiveAlbum(false);
                  },
                  (error) => {
                    this.apiErrorHandlerService.getHandler().handle(error);
                    this.loaderService.displayLoader(false);
                  },
                );
            } else {
              this.loaderService.displayLoader(false);
              this.alertCustom(this.translate.instant('view-project.alert2'));
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.alertCustom(this.translate.instant('view-project.alert2'));
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  callTaggingConfirm(data) {
    this.loaderService.displayLoader(true);
    this.taggerService
      .postTaggingConfirm(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.updateExistingCount(res);
            this.updateExistingTaggHotKey(res);
            this.viewProjectService.setIsTaggingView2Open(true);
          } else {
            this.alertCustom(this.translate.instant(res.message));
          }

          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  callTaggingStop(data) {
    this.loaderService.displayLoader(true);
    this.taggerService
      .putTaggingStop(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.viewProjectService.setIsTaggingView2Open(false);
          this.loaderService.displayLoader(false);
          this.closeWindow();
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  createModel() {
    return {
      projectId: this.viewProjectService.getProjectId(),
      tagView: 2,
    };
  }

  updateExistingCount(data) {
    this.taggingList.forEach((eachObj) => {
      const imageIndex = data.t.counts
        .map(function (e) {
          return e.id;
        })
        .indexOf(eachObj.id);

      if (imageIndex !== -1) {
        eachObj.imageCount = data.t.counts[imageIndex].value;
      }
    });
  }

  updateExistingTaggHotKey(data) {
    if (Object.keys(this.projectImageData).length !== 0) {
      if (data.t.tags.length > 0) {
        data.t.tags.forEach((eachObj) => {
          const tagsIndex = this.projectImageData.assetInfoDTOList
            .map(function (e) {
              return e.id;
            })
            .indexOf(eachObj.id);
          if (tagsIndex !== -1) {
            this.projectImageData.assetInfoDTOList[tagsIndex].taggingView2 =
              eachObj.value;
            this.projectImageData.assetInfoDTOList[
              tagsIndex
            ].submittedByTagView2 = eachObj.submittedByTagView;
          }
        });
      } else {
        this.projectImageData.assetInfoDTOList.forEach((img) => {
          data.tagobj = [];
          img.taggingView2 = data.tagobj;
          img.submittedByTagView2 = false;
        });
      }
    }
  }

  stopPopupMovement() {
    this.isMouseHold = 0;
    this.taggingpopup.nativeElement.style.cursor = 'default';
  }

  closeWindow() {
    this.dialogRef.close();
  }

  onStopTagging() {
    this.callTaggingStop(this.createModel());
  }

  submitForReview() {
    if (this.selectedImagesStore.hasEntities()) {
      this.dialogService
        .openConfirmationDialog({
          message:
            this.translate.instant('view-project.confirm4') +
            ' ' +
            this.selectedImagesStore.size() +
            ' ' +
            this.translate.instant('view-project.confirm5'),
          title: this.translate.instant('view-project.confirm6'),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          if (state) {
            this.callSubmitForReview(this.createModelForSubmit());
          }
        });
    } else {
      {
        this.snackBarService.showSnackBarWarning(
          this.translate.instant(
            'left-menu.tab-bar.sidemenus.administration.taggingview-1.warnMessage',
          ),
        );
      }
    }
  }

  createModelForSubmit() {
    return {
      projectId: this.viewProjectService.getProjectId(),
      tagView: 2,
      assetIds: this.selectedImagesStore.getItemsIds(),
    };
  }

  callSubmitForReview(data) {
    this.loaderService.displayLoader(true);
    this.taggerService
      .putTaggingSubmitForReview(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.t) {
            this.updateSubmitForReviewFlag(res.t);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  updateSubmitForReviewFlag(res) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    res.forEach((eachObj) => {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map(function (e) {
          return e.id;
        })
        .indexOf(eachObj.id);

      if (imageIndex !== -1) {
        this.projectImageData.assetInfoDTOList[imageIndex].submittedByTagView2 =
          eachObj.value;
      }
    });
  }

  ngOnDestroy() {
    this.viewProjectService.setIsTaggingView2Open(false);
    super.ngOnDestroy();
  }
}
