import { Utils } from './../../../../../shared/utils/utils';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { GroupService } from './group-service';
import { AdministrationService } from '../../../dashboard-shared/left-menu/menus/administration/administration.service';
import { AssetInfo, AssetSoloGroupModel } from '../../../../../shared/models/assetInfo.model';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { takeUntil } from 'rxjs/operators';
import { PopupRef } from '../../../../../shared/services/popup/popup-ref';
import { ImageInfoComponent } from '../../../dashboard-shared/left-menu/menus/imageinfo/imageinfo.component';
import { FS_POPUP_DATA } from '../../../../../shared/services/popup/popup.constants';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { GroupActionType } from './group-action-type.enum';
import { SelectedImages } from '../../../../../shared/models/selectedImages.model';
import { SingleExcludeService } from '../../../../../shared/services/single-exclude.service';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-group-popup',
  templateUrl: './group-component.html',
  styleUrls: ['./group-component.scss'],
})
export class GroupComponent extends UnsubscriberComponent implements OnInit {
  @Output() parentGrouppopup = new EventEmitter();
  isGPopupShownChild: any;
  isGPopupShown1: any;
  selectedImageId: any;
  groupPopupId: any;
  groupedDataCalculated: any;
  imgData;
  allselectedImagesList: Array<any> = []; // todo Vadym why do we need this if we also have allSelectedImages?
  currentPageNumber: any;
  projectImageIds: any;
  noOfImages: number;
  allSelectedImages: AssetSoloGroupModel[] = [];
  tagList: Array<any> = [];
  tagList2: Array<any> = [];
  projectImageData: AssetInfo = new AssetInfo();
  projectName: any;
  selectedProjectData: any;

  constructor(
    private viewProjectService: ViewProjectService,
    private groupService: GroupService,
    private administrationService: AdministrationService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private popupRef: PopupRef<ImageInfoComponent>,
    private ref: ChangeDetectorRef,
    private selectedImagesStore: SelectedImagesStore,
    private singleExcludeService: SingleExcludeService,
    @Inject(FS_POPUP_DATA)
    private data: {
      imgData: any;
      groupedDataCalculated: any;
      selectedImageId: any;
      groupPopupId: any;
      projectImageIds: any;
      noOfImages: number;
      isGPopupShown: boolean;
    },
  ) {
    super();
    this.groupedDataCalculated = data.groupedDataCalculated;
    this.selectedImageId = data.selectedImageId;
    this.groupPopupId = data.groupPopupId;
    this.imgData = data.imgData;
    this.isGPopupShown1 = data.isGPopupShown;
    this.projectImageIds = data.projectImageIds;
    this.noOfImages = data.noOfImages;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler() {
    this.closePopup();
  }

  ngOnInit() {
    this.singleExcludeService.selectedImagesFullAssetsInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val: AssetSoloGroupModel[]) => {
        this.allSelectedImages = val;
      });

    this.handleModalStatus();
  }

  handleModalStatus() {
    if (this.allSelectedImages.length === 1) {
      let localArr = [];
      for (const pubTag of this.allSelectedImages) {
        localArr = [...localArr, ...pubTag.publishedTags];
      }
      this.allselectedImagesList = [...new Set(localArr)];
      this.viewProjectService.projectImageIdsGLobal
        .pipe(takeUntil(this.destroy$))
        .subscribe((imageIds) => {
          this.projectImageIds = imageIds;
        });
      this.tagList2 = [];
      this.viewProjectService.projectDetailPermissionGLobal
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (!Utils.isObjectEmpty(res)) {
            this.noOfImages = res.viewSettingDTO.imagePerPage;
            if (res.projectTagListDTO.length > 0) {
              this.tagList = res.projectTagListDTO.filter((item, key) => {
                if (this.allselectedImagesList.indexOf(item.id) !== -1) {
                  return true;
                } else {
                  return false;
                }
              });
              this.tagList2 = [];
              this.tagList.forEach((tagListData) => {
                let excluded = false;
                let released = false;

                for (const imageData of this.allSelectedImages) {
                  if (imageData.excludedFrom.indexOf(tagListData.id) === -1) {
                    excluded = true;
                  } else {
                    released = true;
                  }
                }

                this.tagList2.push({
                  excluded: excluded,
                  released: released,
                  status: released,
                  tagName: tagListData.tagName,
                  tagId: tagListData.id,
                });
              });
            } else {
              this.tagList = [];
              this.tagList2 = [];
            }
          }
        });
    }

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
          this.projectName = res.projectName;
        }
      });

    this.viewProjectService.selectedProjectDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.selectedProjectData = res;
        }
      });
  }

  handleReleaseFrom(userId: number) {
    const filteredSelectedImages = this.groupService.getExcludedSelectedImages(
      this.allSelectedImages,
      userId,
    );

    this.handleImageAccessibility(
      GroupActionType.ReleaseFrom,
      filteredSelectedImages,
      userId,
    );
  }

  handleExcludeFrom(userId: number) {
    const filteredSelectedImages =
      this.groupService.getNotExcludedSelectedImages(
        this.allSelectedImages,
        userId,
      );

    this.handleImageAccessibility(
      GroupActionType.ExcludeFrom,
      filteredSelectedImages,
      userId,
    );
  }

  handleImageAccessibility(actionName, selectedImages, userID) {
    this.currentPageNumber =
      this.viewProjectService.getCurrentPageNumberClone();
    const obj = {
      action: actionName,
      assetIds: selectedImages.map((v) => v.id),
      projectId: this.viewProjectService.getProjectId(),
      tagId: userID,
      currentAssetIds: this.allSelectedImages.map(asset => asset.id),
      projectName: this.projectName,
      useSetup:
        this.selectedProjectData && this.selectedProjectData.useSetup
          ? this.selectedProjectData.useSetup
          : false,
    };

    this.loaderService.displayLoader(true);
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );

    this.administrationService
      .putAssetsToggle(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);
          res.forEach((element) => {
            const index = this.projectImageData.assetInfoDTOList
              .map((v) => v.id)
              .indexOf(element.assetId);
            if (index >= 0) {
              this.projectImageData.assetInfoDTOList[index].approvedBy =
                element.approvedBy;
              this.projectImageData.assetInfoDTOList[index].excludedFrom =
                element.excludedFrom;
              this.projectImageData.assetInfoDTOList[index].rejectedBy =
                element.rejectedBy;
              this.projectImageData.assetInfoDTOList[index].status =
                element.status;
            }

            this.changeSelectedTaggerData(userID);

            this.updateSelectedAssets(element);
          });

          if (this.groupedDataCalculated.length) {
            const indexList = this.groupedDataCalculated
              .map((list) => {
                return list.tagInfo.value;
              })
              .indexOf(userID);
            if (actionName === GroupActionType.ExcludeFrom) {
              this.groupedDataCalculated[indexList].exclude = false;
              this.groupedDataCalculated[indexList].release = true;
              this.ref.markForCheck();
            } else {
              this.groupedDataCalculated[indexList].exclude = true;
              this.groupedDataCalculated[indexList].release = false;
              this.ref.markForCheck();
            }
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  updateSelectedAssets(element) {
    this.allSelectedImages.map((image) => {
      if (image.id === element.assetId) {
        image.excludedFrom = element.excludedFrom;
      }
    })
  }

  changeSelectedTaggerData(userId: number): void {
    if (this.groupedDataCalculated.length === 0) {
      this.tagList2.map(tagger => {
        if (tagger.tagId === userId) {
          tagger.excluded = !tagger.excluded;
          tagger.released = !tagger.released;
        }
      })
      this.ref.markForCheck();
    }
  }

  closePopup() {
    this.popupRef.hide();
    this.isGPopupShownChild = false;
    this.groupService.setStatusOfGroup(false);
  }
}
