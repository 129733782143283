import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'projectAssetsBaseUrl',
})
export class ProjectAssetsBaseUrl implements PipeTransform {

  transform(src: string): string {
    return environment.projectAssetBaseUrl + src;
  }
}
