<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
    'left-menu.tab-bar.sidemenus.administration.buttonse.duplicate-list-menu' | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="d-flex flex-row">
  <div class="flex-grow-1">
    <div class="fs-md-txt duplicate-list-title p-1 py-2">
      {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.details' | translate}}:
    </div>
    <div class="duplicate-info-container d-flex flex-column">
      <div class="duplicate-info py-1 p-1">
        <span class="font-weight-bold">
          {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.projectName' | translate}}:
        </span>
        <span>{{data.projectName}}</span>
      </div>
      <div class="duplicate-info py-1 p-1">
        <span class="font-weight-bold">
          {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.projectId' | translate}}:
        </span>
        <span>{{data.projectId}}</span>
      </div>
      <div class="duplicate-info py-1 p-1">
        <span class="font-weight-bold">
          {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.projectLocator' | translate}}:
        </span>
        <span>{{data.projectLocator}}</span>
      </div>
      <div class="duplicate-info py-1 p-1">
        <span class="font-weight-bold">
          {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.diskLocationId' | translate}}:
        </span>
        <span>{{data.diskLocationId}}</span>
      </div>
      <div class="duplicate-info py-1 p-1">
        <span class="font-weight-bold">
          {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.diskLocationName' | translate}}:
        </span>
        <span>{{data.diskLocationName}}</span>
      </div>
      <div class="duplicate-info py-1 p-1">
        <span class="font-weight-bold">
          {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.remoteDiskLocationId' | translate}}:
        </span>
        <span>{{data.remoteDiskLocationId}}</span>
      </div>
      <div class="duplicate-info py-1 p-1">
        <span class="font-weight-bold">
          {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.remoteDiskLocationName' | translate}}:
        </span>
        <span>{{data.remoteDiskLocationName}}</span>
      </div>
    </div>
  </div>

  <div class="flex-grow-1">
    <div class="fs-md-txt duplicate-list-title p-1 py-2">
      {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.warning' | translate}}:
    </div>
    <div>
      <cdk-virtual-scroll-viewport
        class="fs-virtual-scroll-content-margin"
        minBufferPx="58"
        maxBufferPx="832"
        itemSize="58"
      >
        <div
          class="d-flex py-1 p-1 duplicate-info flex-column"
          *cdkVirtualFor="let selectedImage of duplicatedPerProject; index as ind"
        >
          <div class="is-name">
          <span class="font-weight-bold">
            {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.assetName' | translate}}:
          </span>
              <span> {{ selectedImage.assetName }}</span>
            </div>
            <div class="is-name">
          <span class="font-weight-bold">
            {{'left-menu.tab-bar.sidemenus.administration.duplicate-menu.labels.batchName' | translate}}:
          </span>
              <span> {{ selectedImage.batchName }}</span>
            </div>
        </div>
      </cdk-virtual-scroll-viewport>

    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExport(exportType.Excel)"
  >
    {{
    'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
      | translate
    }}
    as xls
  </button>

  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExport(exportType.Pdf)"
  >
    {{
    'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
      | translate
    }}
    as pdf
  </button>
  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{ 'left-menu.tab-bar.advance-search.buttons.cancel' | translate }}
  </button>
</div>
