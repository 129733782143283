<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">{{ 'top-menu.sendMail.title' | translate }}</div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    data-dismiss="modal"
    aria-hidden="true"
  >
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="theme-form">
  <div class="container-bottom-line">
    <div class="form-group mb-2 row">
      <div class="fs-chips col-12">
        <mat-form-field class="fs-chip-list" appearance="fill">
          <mat-chip-grid #toEmail [(ngModel)]="emailData.toEmail">
            <ng-container *ngIf="emailData.toEmail">
              <mat-chip-row
                *ngFor="let email of arrListEmailTo; let i = index"
                [removable]="true"
                (removed)="onRemoveEmailId('to', i)"
              >
                {{ email }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
              <input
                matInput
                [placeholder]="'top-menu.sendMail.to' | translate"
                [matChipInputFor]="toEmail"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="onAdValidateEmail($event, 'to')"
              />
            </ng-container>
          </mat-chip-grid>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row mb-2">
      <div class="fs-chips col-12">
        <mat-form-field class="fs-chip-list" appearance="fill">
          <mat-chip-grid class="w-100" #ccEmail [(ngModel)]="emailData.ccEmail">
            <ng-container *ngIf="emailData.ccEmail">
              <mat-chip-row
                *ngFor="let email of arrListEmailCC; let i = index"
                [removable]="true"
                (removed)="onRemoveEmailId('cc', i)"
              >
                {{ email }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
              <input
                matInput
                [placeholder]="'top-menu.sendMail.cc' | translate"
                [matChipInputFor]="ccEmail"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="onAdValidateEmail($event, 'cc')"
              />
            </ng-container>
          </mat-chip-grid>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row mb-2">
      <div class="col-12">
        <input
          id="subject"
          type="text"
          class="sendmail-msg"
          [(ngModel)]="emailData.subject"
          [placeholder]="'top-menu.sendMail.subject' | translate"
        />
      </div>
    </div>
    <div class="form-group mb-2 row">
      <div class="col-12">
      <textarea
        class="form-control send-mail-msg sendmail-msg"
        id="messageBody"
        rows="7"
        [(ngModel)]="emailData.messageBody"
        [placeholder]="'top-menu.sendMail.bodyPlaceholder' | translate"
      >
      </textarea>
      </div>
    </div>
  </div>

  <div class="mt-2 d-flex w-100">
    <form class="pl-0 col-3" autocomplete="off">
      <div *ngFor="let displayType of imageTypeDisplay; let indx = index">
        <div>
          <label class="custom-radio">
            <input
              type="radio"
              name="radiogroup"
              [checked]="indx === 0"
              [value]="displayType.value"
              (change)="onChangeImageType(displayType)"
            />
            <span class="cb-title">{{ displayType.name }}</span>
          </label>
        </div>
      </div>
    </form>

    <div class="col-9 pr-0">
      <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
        <div class="clearfix d-flex flex-wrap scrollpad-20 btm-images mr-2">
          <div
            class="selected-image-container d-flex justify-content-center m-0 pl-1 pr-1"
            *ngFor="let img of selectedImagesList"
          >
            <div
              [ngSwitch]="img.assetType"
              class="d-flex justify-content-center align-items-start"
            >
              <div
                class="img-sngl d-flex flex-column align-content-start"
                *ngSwitchCase="'image'"
              >
                <div class="is-img p-0">
                  <img
                    [lazySrc]="viewProjectService.getWebImageURL(img, '125')"
                    alt=""
                  />
                </div>
                <div class="isName w-100">{{ img.titleName }}</div>
                <div class="isName w-100">
                  ({{ utils.formatBytes(img.size) }})
                </div>
              </div>

              <div class="img-sngl w-100" *ngSwitchCase="'video'">
                <div
                  class="is-img w-100 d-flex flex-column align-content-start"
                >
                  <img
                    [lazySrc]="viewProjectService.getWebImageURL(img, '125')"
                    alt=""
                  />
                </div>
                <div class="isName w-100">{{ img.titleName }}</div>
                <div class="isName w-100">
                  ({{ utils.formatBytes(img.size) }})
                </div>
              </div>

              <div class="img-sngl" *ngSwitchCase="'docx'">
                <div class="is-img">
                  <img src="/assets/images/doc_icon.png" alt="" />
                </div>
                <div class="isName">{{ img.titleName }}</div>
                <div class="isName">({{ utils.formatBytes(img.size) }})</div>
              </div>

              <div class="img-sngl" *ngSwitchCase="'pdf'">
                <div class="is-img">
                  <img src="/assets/images/pdf_icon.png" alt="" />
                </div>
                <div class="isName">{{ img.titleName }}</div>
                <div class="isName">({{ utils.formatBytes(img.size) }})</div>
              </div>

              <div class="img-sngl" *ngSwitchCase="'zip'">
                <div class="is-img">
                  <img src="/assets/images/zip_icon.png" alt="" />
                </div>
                <div class="isName">{{ img.titleName }}</div>
                <div class="isName">({{ utils.formatBytes(img.size) }})</div>
              </div>

              <div class="img-sngl" *ngSwitchCase="'xlsx'">
                <div class="is-img">
                  <img src="/assets/images/xls_icon.png" alt="" />
                </div>
                <div class="isName">{{ img.titleName }}</div>
                <div class="isName">({{ img.size / 1024 | number }})</div>
              </div>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <div *ngIf="totalAttachedSize > 25600" class="mf-sngle col-8">
    <span class="fs-text-color-red">
      Files are too large to send via email!
    </span>
  </div>

  <div class="mf-sngle d-flex justify-content-end">
    <button
      type="button"
      data-dismiss="modal"
      class="fs-dialog-btn w-auto ml-3"
      (click)="closeWindow()"
    >
      {{ 'top-menu.sendMail.cancel' | translate }}
    </button>

    <button
      class="fs-dialog-btn w-auto ml-3"
      data-dismiss="modal"
      id="mail_send_btn"
      [disabled]="arrListEmailTo.length < 1 || totalAttachedSize > 25600"
      (click)="sendMail(emailData)"
    >
      {{ 'top-menu.sendMail.send' | translate }}
    </button>
  </div>
</div>
