import { Component, Input, OnInit } from '@angular/core';
import { PublishImagesService } from './publish-images.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../../../../shared/services/snack-bar.service';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { ProjectDetailPermission } from '../../../../../../../shared/models/projectpermission.model';
import { Activemodal } from '../../../../../../../shared/models/activemodal.model';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { FilterEnum } from '../../../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';
import { MetaDataService } from '../../../../../../../shared/services/meta-data.service';

@Component({
  selector: 'fs-publish-images',
  templateUrl: './publish-images.component.html',
  styleUrls: ['./publish-images.component.scss'],
})
export class PublishImagesComponent
  extends UnsubscriberComponent
  implements OnInit
{
  projectImageData: AssetInfo = new AssetInfo();
  projectDetailData: ProjectDetailPermission = new ProjectDetailPermission();
  activemodal: Activemodal = new Activemodal();
  currentFilter = '';

  constructor(
    private viewProjectService: ViewProjectService,
    private snackBarService: SnackBarService,
    private modalStatusService: Modalstatusservice,
    private metaDataService: MetaDataService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private publishImagesService: PublishImagesService,
    private translate: TranslateService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.viewProjectService.currentFilterGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentFilterName) => {
        this.currentFilter = currentFilterName;
      });
  }

  onPublishImagesClick() {
    if (this.selectedImagesStore.hasEntities()) {
      this.dialogService
        .openConfirmationDialog({
          message:
            this.translate.instant('view-project.confirm10') +
            ' ' +
            this.selectedImagesStore.size() +
            ' ' +
            this.translate.instant('view-project.confirm11'),
          title: this.translate.instant('view-project.confirm12'),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((state) => {
          if (state) {
            this.callPublishImageAPI();
          }
        });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert24'));
    }
  }

  callPublishImageAPI() {
    if (
      this.currentFilter === FilterEnum.AuditAndNoConflicts ||
      this.currentFilter === FilterEnum.AuditAndConflicts ||
      this.currentFilter === FilterEnum.AuditAndBypassTagger2
    ) {
      this.loaderService.displayLoader(true);
      let filter = '';
      if (this.currentFilter === FilterEnum.AuditAndConflicts) {
        filter = FilterEnum.Conflicts;
      } else if (this.currentFilter === FilterEnum.AuditAndNoConflicts) {
        filter = FilterEnum.NoConflicts;
      } else if (this.currentFilter === FilterEnum.AuditAndBypassTagger2) {
        filter = FilterEnum.BypassTagger2;
      }

      const obj = {
        assetIds: this.selectedImagesStore.getItemsIds(),
        filter: filter,
        projectId: this.viewProjectService.getProjectId(),
      };

      this.publishImagesService
        .postPublishImagesImages(obj)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (res.status) {
              this.updateProjectData(res.t);
              this.snackBarService.showSnackBar(res.message);
            } else {
              this.alertCustom(res.message);
            }

            this.loaderService.displayLoader(false);
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.alertCustom('Please run audit filter.');
    }
  }

  updateProjectData(res) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    this.projectDetailData =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailData,
      );

    this.projectDetailData.unpublishedAssets = res.unpublishedAssets;

    if (res.projectTagListDTO) {
      this.projectDetailData.projectTagListDTO = res.projectTagListDTO;
    }

    this.viewProjectService.setProjectDetailPermissionData(
      this.projectDetailData,
    );
    if (res.assets) {
      res.assets.forEach((eachObj) => {
        const imageIndex = this.projectImageData.assetInfoDTOList
          .map(function (e) {
            return e.id;
          })
          .indexOf(eachObj.id);

        if (imageIndex !== -1) {
          this.projectImageData.assetInfoDTOList[imageIndex].generalTags =
            eachObj.generalTags;
          this.projectImageData.assetInfoDTOList[imageIndex].publishedTags =
            eachObj.publishedTags;
          this.projectImageData.assetInfoDTOList[imageIndex].tagKeys =
            eachObj.tagKeys;
          if (this.currentFilter === FilterEnum.AuditAndBypassTagger2) {
            res.data = [];
            this.projectImageData.assetInfoDTOList[imageIndex].taggingView1 =
              res.data;
          } else if (
            this.currentFilter === FilterEnum.AuditAndConflicts ||
            this.currentFilter === FilterEnum.AuditAndNoConflicts
          ) {
            res.data = [];
            this.projectImageData.assetInfoDTOList[imageIndex].taggingView1 =
              res.data;
            this.projectImageData.assetInfoDTOList[imageIndex].taggingView2 =
              res.data;
          }
        }
      });
    }

    if (this.projectImageData.assetInfoDTOList.length > 1) {
      this.selectedImagesStore.clear();
      this.viewProjectService.setProjectData(this.projectImageData);
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
