import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Utils } from './../../../../shared/utils/utils';
import { ActivatedRoute, Router } from '@angular/router';

import { CompareService } from './compare.service';
import { ConfigKey } from '../../../../appSettings/key-config';
import { TopmenusService } from '../../dashboard-shared/top-menu/top-menu.service';
import { AssetInfo } from '../../../../shared/models/assetInfo.model';
import { ProjectDetailPermission } from '../../../../shared/models/projectpermission.model';
import { SelectedImages } from '../../../../shared/models/selectedImages.model';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { PaginationService } from '../../dashboard-shared/pagination/pagination.service';
import { Modalstatusservice } from '../../../../shared/services/modalstatus.service';
import { SidebarService } from '../../sidebar-service/sidebar-service';
import { takeUntil } from 'rxjs/operators';
import { LastSelectedImageStore } from '../../../../shared/store/last-selected-image-store';
import { SelectedImagesStore } from '../../../../shared/store/selected-images-store';
import { AppStateService } from '../../../../shared/services/app-state.service';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { ConflictsComponent } from '../../dashboard-shared/left-menu/menus/audit/conflicts/conflicts.component';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';
import { LoaderService } from '../../../../shared/services/loader.service';
import {ApiErrorHandlerService} from '../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  projectId: string;
  copy: AssetInfo = new AssetInfo();
  projectImageData: any;
  updateProjectImageData: AssetInfo = new AssetInfo();
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  isLeftMenuPartShown = true;
  noOfImages = 1;
  numberOfPerPage = 1;
  totalPages: number;
  selectedImageIndex: any = [];
  clickRangeArr = [];
  clickPageRangeArr = [];
  compareSelectedImages: Map<number, SelectedImages> = new Map<
    number,
    SelectedImages
  >();
  count = 0;
  pageCount = 0;
  currentIndex: number;
  rangeValue;
  rangeValue1 = 25;
  imgWidth = 400;
  height = 400;
  span3Width: number;
  compareContWidth: any = 400;
  currentPageNumberIdDraw: any;
  currentFilter = 'showall';
  retouchAccess = false;
  viewProjectPageNumber;
  currentPageNumber: number[] = [];
  projectImageIds: Array<number> = [];
  isResetObjectOfSelectedImage = false;
  isRejectIconShown = true;
  selectedImageStatus = [];
  @ViewChild('compimage') compimage: ElementRef;
  selectedImages2: Array<any> = [];
  isStatusCheckBoxShown = true;
  isLeftAlbumActive = false;
  selectedLeftAlbum: any;
  activatedLeftProjectAlbum = false;
  selectedLeftAlbumKeys: any;
  selectedLeftProjectAlbumKeys: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public viewProjectService: ViewProjectService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private compareService: CompareService,
    private topmenusService: TopmenusService,
    private paginationService: PaginationService,
    private modalstatusservice: Modalstatusservice,
    private loaderService: LoaderService,
    private sidebarService: SidebarService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    private appStateService: AppStateService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.compareService.removeAllSelectedImages();
    this.projectDetailPermission =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailPermission,
      );
  }

  ngOnInit() {
    this.dialogService.closeDialogById(ConflictsComponent.identifier, {
      withoutChanges: true,
    });
    this.projectId = this.route.snapshot.params.projectId;
    this.currentPageNumberIdDraw = this.route.snapshot.params.pageNumber;
    this.currentFilter = this.route.snapshot.params.filterId;
    this.paginationService.setPaginationTempalte(true);
    this.topmenusService.setTopMenuPagination(false);
    const valueInServ = this.compareService.getDefaultRangValue();
    this.topmenusService.setTopMenuStatus('CompareImage');

    this.compareService.compareSelectedImagesGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageArray) => {
        this.compareSelectedImages = imageArray;
      });

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        //**//
      });

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });

    this.selectedImagesStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((images) => {
        const selectedImages: Map<number, SelectedImages> = new Map<
          number,
          SelectedImages
        >();
        images.forEach((image) => selectedImages.set(image.id, image));
        this.compareService.setSelectedImagesCompare(selectedImages);
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.projectDetailPermission = data;
        if (!Utils.isObjectEmpty(data)) {
          if (data.permissionDTO) {
            this.numberOfPerPage = data.viewSettingDTO.imagePerPage;
            this.retouchAccess = data.permissionDTO.retouchAccess;
          }
        }
      });

    if (valueInServ > 35) {
      this.rangeValue1 = ((valueInServ - 35) * 100) / 1600;
      this.handleChange({ target: { value: ((valueInServ - 35) * 100) / 1600 } });
    }

    this.closeCompareView();

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((parameters) => {
      this.projectId = parameters.projectId.slice(
        1,
        parameters.projectId.length,
      );
      this.viewProjectPageNumber = parameters.pageNumber;
      this.currentFilter = parameters.filterId;
      const selectImageIds = Array.from(
        this.compareService.getSelectedImagesCompare().keys(),
      );

      if (selectImageIds.length) {
        this.compareImagesList(selectImageIds);
      } else {
        this.selectedImagesStore
          .getCollection()
          .pipe(takeUntil(this.destroy$))
          .subscribe((images) => {
            this.compareService.setSelectedImagesCompare(images);
            const selectImageIds1 = Array.from(
              this.compareService.getSelectedImagesCompare().keys(),
            );
            this.compareImagesList(selectImageIds1);
          });
      }
    });

    this.viewProjectService.activatedLeftTabIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (index === 0) {
          this.isLeftAlbumActive = true;
        } else {
          this.isLeftAlbumActive = false;
        }
      });

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        this.selectedLeftAlbum = album;
      });

    this.viewProjectService.activatedLeftProjectAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.activatedLeftProjectAlbum = status;
      });

    this.sidebarService.selectedAlbumKeys
      .pipe(takeUntil(this.destroy$))
      .subscribe((albums) => {
        this.selectedLeftAlbumKeys = albums;
      });

    this.sidebarService.projectAlbumKeysGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((albums) => {
        this.selectedLeftProjectAlbumKeys = albums;
      });
  }

  closeCompareView() {
    this.appStateService.compare$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (!status.active) {
          this.loaderService.displayLoader(true);

          this.viewProjectService
            .callProjectImageIDs(
              this.viewProjectService.getProjectId(),
              this.currentFilter,
            )
            .subscribe(
              (res1) => {
                if (res1) {
                  const assetsIds = Utils.getImageIdsBasedOnPage(
                    1,
                    this.numberOfPerPage,
                    res1,
                  );

                  if (this.viewProjectService.getProjectId()) {
                    const data = {
                      assetIds: assetsIds,
                      projectId: this.viewProjectService.getProjectId(),
                      filter: this.currentFilter,
                    };

                    this.viewProjectService
                      .callAssetsInfoBasedOnAssetsId(data)
                      .subscribe(
                        (resData) => {
                          this.viewProjectService.setProjectData(resData);
                          this.viewProjectService.setCurrentPageNumber(null);
                          this.viewProjectService.setCurrentFilter(
                            this.currentFilter,
                          );

                          if (
                            !this.viewProjectService.getIsDrawSessionOpenFromCompareMenu()
                          ) {
                            this.router.navigate([
                              Utils.prepareFilterNavigationURL(
                                this.viewProjectService.getProjectRole(),
                                this.viewProjectService.getProjectId(),
                                this.currentFilter,
                                this.viewProjectPageNumber,
                              ),
                            ]);
                          }


                            this.viewProjectService.setCurrentPageNumber(
                              this.viewProjectPageNumber,
                            );
                            this.paginationService.setPaginationIndex(
                              this.viewProjectPageNumber,
                            );


                          this.loaderService.displayLoader(false);
                          this.viewProjectService.setsDrawSessionOpenFromCompareMenu(
                            false,
                          );
                        },
                        (error) => {
                          this.apiErrorHandlerService.getHandler().handle(error);
                        this.loaderService.displayLoader(false);
                      },
                    );}
                } else {
                  this.loaderService.displayLoader(false);
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        }
      });
  }

  showHideStatusCheckBox() {
    for (let i = 0; i < this.selectedImages2.length; i++) {
      if (
        this.selectedImages2[i].rejectedBy.length > 0 ||
        (this.selectedImages2[i].status === 5 &&
          this.selectedImages2[i].rejectedBy.length === 0) ||
        (this.selectedImages2[i].status === 5 &&
          this.selectedImages2[i].rejectedBy.length > 0)
      ) {
        this.isStatusCheckBoxShown = true;
        break;
      } else {
        this.isStatusCheckBoxShown = false;
      }
    }

    this.cdr.markForCheck();
  }

  compareImagesList(selectImageIds) {
    const filter = 'showall';
    this.viewProjectService
      .callProjectImageIDs(this.projectId, filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res1) => {
        const payload = {
          assetIds: selectImageIds,
          projectRoleId: this.viewProjectService.getProjectRole(),
          projectId: this.projectId,
          filter: this.currentFilter,
        };
        this.compareService
          .callAssetsInfoBasedOnAssetsId(payload)
          .pipe(takeUntil(this.destroy$))
          .subscribe((images) => {
            this.projectImageData = JSON.parse(JSON.stringify(images));
            this.selectedImages2 = images.assetInfoDTOList;
            const ids = this.projectImageData.assetInfoDTOList.map(
              (item) => item.id,
            );
            this.selectedImageIndex =
              this.compareService.getAllSelectedImageIDsIndex3(ids);
            this.currentPageNumber = this.selectedImageIndex;
            this.projectImageIds = res1;
            this.totalPages = res1?.length;
            this.addLastSelectedImage();
            this.selectedImageStatus = [];
            this.selectedImages2.forEach((item) => {
              this.selectedImageStatus.push({
                id: item.id,
                status: false,
              });
            });
            this.cdr.markForCheck();
            this.cdr.detectChanges();
          },(error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          });
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        this.showHideStatusCheckBox();
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  setpaginationTempate(id) {
    this.paginationService.setPaginationTempalte(id);
    return true;
  }

  getAssets(id) {
    this.cdr.markForCheck();
    return this.projectImageData.assetInfoDTOList.filter(
      (item) => item.id === id,
    )[0];
  }

  getIndexOf(id) {
    return Array.from(this.compareSelectedImages.keys()).indexOf(id);
  }

  getAssetsExit(id) {
    if (
      this.projectImageData.assetInfoDTOList.filter((item) => item.id === id)
        .length
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleChange(event) {
    if (Number(event.target.value)) {
      this.rangeValue = (Number(event.target.value) * 1600) / 100;
      this.imgWidth = this.rangeValue;
      this.height = this.rangeValue;
      if (this.rangeValue >= 300) {
        this.span3Width = this.rangeValue - 12;
      }

      if (this.rangeValue <= 1000) {
        this.compareContWidth = this.rangeValue + 'px';
      }

      if (this.rangeValue > 1000) {
        this.compareContWidth = 100 + '%';
      }

      if (this.rangeValue < 300) {
        this.span3Width = 288;
        this.compareContWidth = 300 + 'px';
      }
      this.compareService.setDefaultRangValue(Number(this.rangeValue) + 35);
    }
  }

  addLastSelectedImage() {
    const assetsInfoList = this.selectedImageIndex.map(
      (_, i) => this.projectImageData.assetInfoDTOList[i],
    );
    this.lastSelectedImageStore.set(assetsInfoList);
    this.selectedImagesStore.add(assetsInfoList);
  }

  selectImageOnClick(selectImg, index, event: KeyboardEvent, ind) {
    event.stopPropagation();
    this.currentIndex = this.currentPageNumber[ind] - 1;

    if (event.shiftKey) {
      const alreadySelectedImageId = Array.from(
        this.compareSelectedImages.keys(),
      )[Array.from(this.compareSelectedImages.keys()).length - 1];
      const alreadySelectedImageIndex = this.selectedImages2.findIndex(
        (item) => item.id === alreadySelectedImageId,
      );
      const currentSelectedImageIndex = ind;
      this.selectedImageStatus.forEach((item) => {
        item.status = false;
      });
      if (alreadySelectedImageIndex < currentSelectedImageIndex) {
        this.compareService.removeAllSelectedImages();
        for (
          let i = alreadySelectedImageIndex;
          i <= currentSelectedImageIndex;
          i++
        ) {
          if (i !== -1) {
            const key = this.selectedImages2[i].id;
            const imagData = this.compareService
              .getSelectedImagesCompare()
              .get(key);
            this.compareService.addCompareSelectedImages(key, imagData);
            if (this.selectedImageStatus[i]) {
              this.selectedImageStatus[i]['status'] = true;
            }
          }
        }
      } else {
        this.compareService.removeAllSelectedImages();
        for (
          let i = alreadySelectedImageIndex;
          i >= currentSelectedImageIndex;
          i--
        ) {
          if (i !== -1) {
            const key = this.selectedImages2[i].id;
            const imagData = this.compareService
              .getSelectedImagesCompare()
              .get(key);
            this.compareService.addCompareSelectedImages(key, imagData);
            if (this.selectedImageStatus[i]) {
              this.selectedImageStatus[i]['status'] = true;
            }
          }
        }
      }
    } else if (event.metaKey || event.ctrlKey) {
      if (
        Array.from(this.compareSelectedImages.keys()).indexOf(selectImg.id) ===
        -1
      ) {
        this.selectedImageStatus[ind]['status'] = true;
        this.compareService.addCompareSelectedImages(
          this.projectImageData.assetInfoDTOList[ind].id,
          this.projectImageData.assetInfoDTOList[ind],
        );
      } else {
        this.selectedImageStatus[ind]['status'] = false;
        this.compareService.removeSelectedImage(selectImg.id);
      }
    } else {
      this.pageCount = 1;
      this.clickPageRangeArr = [];
      this.clickPageRangeArr.push(ind);

      this.count = 1;
      this.clickRangeArr = [];
      this.clickRangeArr.push(this.currentIndex);

      this.compareService.removeAllSelectedImages();
      this.selectedImageStatus.forEach((item) => {
        item.status = false;
      });
      this.selectedImageStatus[ind]['status'] = true;
      this.compareService.addCompareSelectedImages(selectImg.id, selectImg);
    }
  }

  currentImage(selImgData) {
    return this.projectImageData.assetInfoDTOList.indexOf(selImgData) + 1;
  }

  isRejected(imagData) {
    if (imagData && imagData.rejectedBy && imagData.rejectedBy.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  onPageChange(event, page, index) {
    this.currentPageNumber[index] = event;
    this.selectedImagesStore.clear();
    this.lastSelectedImageStore.clear();
    this.compareService.removeSelectedImage(this.projectImageIds[index]);
    const payload = {
      assetIds: [this.projectImageIds[event - 1]],
      projectId: this.projectId,
      projectRoleId: this.viewProjectService.getProjectRole(),
      filter: this.currentFilter,
    };
    this.compareService
      .callAssetsInfoBasedOnAssetsId(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.selectedImages2[index] = res.assetInfoDTOList[0];
        this.projectImageData = {
          ...this.projectImageData,
          assetInfoDTOList: this.selectedImages2,
        };
        const ids = this.projectImageData.assetInfoDTOList.map(
          (item) => item.id,
        );
        this.selectedImageIndex =
          this.compareService.getAllSelectedImageIDsIndex3(ids);
        this.addLastSelectedImage();
        this.selectedImageStatus[index]['status'] = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  fistPage(i): void {
    this.currentPageNumber[i] = 1;
  }

  lastPage(i): void {
    this.currentPageNumber[i] = this.projectImageData.assetInfoDTOList.length;
  }

  drawImage(imagedata) {
    this.viewProjectService.setsDrawSessionOpenFromCompareMenu(true);
    this.router.navigate([
      '/dashboard/viewproject/' +
        this.viewProjectService.getProjectRole() +
        this.projectId +
        '/filter/ ' +
        this.currentFilter +
        ' /page/' +
        this.currentPageNumberIdDraw +
        '/draw/' +
        imagedata.id,
    ]);
  }

  // TODO: -COMPARE
  callAlbumTagging(id) {
    const data = {
      assetIds: this.compareService.getAllCompareSelectedImageIDs(),
      projectId: this.projectId,
      id: id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.updateCustomeAlbumsID(id, res.t);
        this.sidebarService.setUpdatedTaggingAlbum(res);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  // TODO: -COMPARE
  callAlbumTaggingKeys(id) {
    const data = {
      assetIds: this.compareService.getAllCompareSelectedImageIDs(),
      projectId: this.projectId,
      id: id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const obj = {
          status: res.status,
          message: res.message,
          id: id,
        };
        this.sidebarService.setUpdatedTaggingAlbum(obj);
        if (res.status) {
          this.updateCustomeAlbumsID(id, res.t);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  // TODO: Compare
  callProjectAlbumTaggingKeys(albumObj) {
    const data = {
      assetIds: this.compareService.getAllCompareSelectedImageIDs(),
      projectId: this.projectId,
      id: albumObj.id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const obj = {
          status: res.status,
          message: res.message,
          id: albumObj.id,
        };
        this.sidebarService.setUpdatedTaggingProjectAlbum(obj);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  // TODO: Compare
  updateCustomeAlbumsID(id, isAdd) {
    this.updateProjectImageData = this.viewProjectService.mergeWithProjectData(
      this.updateProjectImageData,
    );
    isAdd = isAdd.tag;
    const updatedSelectedImages = [];
    for (const imageId of this.compareSelectedImages.keys()) {
      const index = this.updateProjectImageData.assetInfoDTOList
        .map(function (e) {
          return e.id;
        })
        .indexOf(imageId);
      if (index !== -1) {
        if (isAdd) {
          this.updateProjectImageData.assetInfoDTOList[index].customAlbums.push(
            id,
          );
          updatedSelectedImages.push(
            this.updateProjectImageData.assetInfoDTOList[index],
          );
        } else {
          const customeAlbumIndex: number =
            this.updateProjectImageData.assetInfoDTOList[
              index
            ].customAlbums.indexOf(id);
          if (customeAlbumIndex !== -1) {
            this.updateProjectImageData.assetInfoDTOList[
              index
            ].customAlbums.splice(customeAlbumIndex, 1);
            updatedSelectedImages.push(
              this.updateProjectImageData.assetInfoDTOList[index],
            );
          }
        }
      } else {
        const data = {
          id: imageId,
        };
        updatedSelectedImages.push(data);
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (
      !this.dialogService.isAtLeastOneOpened() ||
      this.dialogService.isHandlingUpKeyEnabled()
    ) {
      if (event.keyCode === ConfigKey.Esc) {
        this.router.navigate([
          Utils.prepareFilterNavigationURL(
            this.viewProjectService.getProjectRole(),
            this.viewProjectService.getProjectId(),
            this.currentFilter,
            this.currentPageNumberIdDraw,
          ),
        ]);
      }

      // TODO: -COMPARE
      // Album Tagging
      if (
        event.keyCode === ConfigKey.lowerT ||
        event.keyCode === ConfigKey.upperT
      ) {
        if (
          !Utils.isObjectEmpty(this.selectedLeftAlbum) &&
          this.compareService.getAllCompareSelectedImageIDs().length > 0
        ) {
          this.callAlbumTagging(this.selectedLeftAlbum.id);
        }
      }

      // TODO: -COMPARE
      // Custom Album Tagging using Tag Keys
      if (this.isLeftAlbumActive && !this.activatedLeftProjectAlbum) {
        this.selectedLeftAlbumKeys.forEach((albumKeyObj) => {
          if (event.key === albumKeyObj.tagKey.toString()) {
            this.callAlbumTaggingKeys(albumKeyObj.id);
          }
        });
      }

      // TODO: -COMPARE
      // Project Album Tagging using Tag Keys
      if (this.isLeftAlbumActive && this.activatedLeftProjectAlbum) {
        this.selectedLeftProjectAlbumKeys.forEach((albumKeyObj) => {
          if (
            event.key.toLowerCase() ===
            albumKeyObj.tagKey.toString().toLowerCase()
          ) {
            this.callProjectAlbumTaggingKeys(albumKeyObj);
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.appStateService.setCompare({ active: false });
    this.isResetObjectOfSelectedImage = false;
    this.compareService.removeAllSelectedImages();
    this.addLastSelectedImage();
    super.ngOnDestroy();
  }
}
