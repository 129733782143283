<mat-expansion-panel
  [expanded]="expanded"
  [hideToggle]="true"
  [togglePosition]="togglePosition"
  (afterExpand)="afterExpand.emit($event)"
  (opened)="opened.emit($event)"
  (closed)="closed.emit($event)"
>
  <mat-expansion-panel-header
    [collapsedHeight]="'30px'"
    [expandedHeight]="'30px'"
  >
    <mat-panel-title>
      <mat-icon
        *ngIf="!hideToggle"
        class="fs-accordion-icon"
        [ngClass]="{
          'fs-element-highlighted fs-accordion-rotate-arrow-down': expanded
        }"
      >
        keyboard_arrow_right
      </mat-icon>

      <span
        fsTextOverflowEllipsis
        [matTooltip]="expanded && tooltip ? tooltip : ''"
        class="fs-sm-txt position-relative text-uppercase fs-accordion-title"
        [ngClass]="{ 'fs-element-highlighted fs-text-underline fs-accordion-title': expanded }"
      >
        {{ title }}
      </span>
      <ng-content
        select="[fs-expansion-panel-accordion-header-title]"
      ></ng-content>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <ng-content
    select="[fs-expansion-panel-accordion-header-content]"
  ></ng-content>
</mat-expansion-panel>
