import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { Router } from '@angular/router';
import { LauncherService } from '../../../../../launcher/launcher.service';
import { DomainConfigService } from './../../../../../../shared/services/domain-config.service';
import { AdministrationService } from './administration.service';
import { SnackBarService } from '../../../../../../shared/services/snack-bar.service';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { ActoreapprovalReportComponent } from './actoreapproval-report/actoreapproval-report.component';
import { ApplyExifComponent } from './apply-exif/apply-exif.component';
import { FilteredfilenameListComponent } from './filteredfilename-list/filteredfilename-list.component';
import { SelectedfilenameListComponent } from './selectedfilename-list/selectedfilename-list.component';
import { ImageExclusivesComponent } from './image-exclusives/image-exclusives.component';
import { TalentManagementComponent } from './talent-management/talent-management.component';
import { ImagenotesReportComponent } from './imagenotes-report/imagenotes-report.component';
import { Taggingview1Component } from './taggingview-1/taggingview-1.component';
import { ImagecaptionReportComponent } from './imagecaption-report/imagecaption-report.component';
import { Taggingview2Component } from './taggingview-2/taggingview-2.component';
import { RejectionToggleComponent } from './rejection-toggle/rejection-toggle.component';
import { FinalizeUnfinalizeComponent } from './finalize-unfinalize/finalize-unfinalize.component';
import { FsdeliveryComponent } from './fs-delivery/fsdelivery.component';
import { BatchManagementComponent } from './batch-management/batch-management.component';
import { AdvanceimageTaggingComponent } from './advanceimage-tagging/advanceimage-tagging.component';
import { ProjectsummaryReportComponent } from './projectsummary-report/projectsummary-report.component';
import { ActoreidReportComponent } from './actoreid-report/actoreid-report.component';
import { HelpWindowComponent } from '../../../../../../shared/layout/help/help.component';
import { AppStateService } from '../../../../../../shared/services/app-state.service';
import { Activemodal } from '../../../../../../shared/models/activemodal.model';
import { AssetInfo } from '../../../../../../shared/models/assetInfo.model';
import { UserRole } from '../../../../../../shared/enum/user-role.enum';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../shared/utils/utils';
import { ConflictsComponent } from '../audit/conflicts/conflicts.component';
import { take, takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { TaggerService } from '../../../../../../shared/services/tagger.service';
import { TaggingViewComponent } from '../../../../../../shared/models/tagging-view-component.model';
import { FilterEnum } from '../../../../../../shared/enum/filter.enum';
import { TaggingViewEnum } from '../../../../../../shared/enum/tagging-view.enum';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { AppSource } from '../../../../../../shared/models/enum/AppSource';
import { ProjectRole } from '../../../../../../shared/enum/project-role.enum';
import { MetaDataService } from '../../../../../../shared/services/meta-data.service';
import { forkJoin } from 'rxjs';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';
import { DuplicateListMenuComponent } from './duplicate-list-menu/duplicate-list-menu.component';
import { DuplicateMenuService } from '../../../../../../shared/services/duplicate-menu.service';
import { DuplicateAssetListModel } from '../../../../../../shared/models/duplicate-asset-list.model';
import { OriginalNamesComponent } from './original-names/original-names.component';
import { OriginalNamesService } from '../../../../../../shared/services/original-names.service';
import { OriginalNamesModel } from './original-names/original-names.model';
import { OriginalNamesReportComponent } from './original-names/original-names-report/original-names-report.component';

@Component({
  selector: 'fs-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent
  extends UnsubscriberComponent
  implements OnInit
{
  activemodal: Activemodal = new Activemodal();
  projectImageData: AssetInfo = new AssetInfo();
  noOfImages: any;
  taggingView1Open = false;
  taggingView2Open = false;
  constantsGlobalData: any;

  // Project Permission
  internalAccess = false;
  exclusiveAccess = false;
  captionAccess = false;
  batchManagementAccess = false;
  talentManagementAccess = false;
  metaTool = false;
  projectAuthority: string;
  isTaggerRole: boolean;
  userAuthority: string;
  userRole = UserRole;
  projectRole = ProjectRole;
  selectedProjectValue: any;
  isVisisbleCaptionReport = false;
  domainObjAbbr = '';
  SELECTION_LIMIT = 250;
  appSource = AppSource;

  constructor(
    private translate: TranslateService,
    private modalStatusService: Modalstatusservice,
    private sidebarService: SidebarService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private router: Router,
    private taggerService: TaggerService,
    private metaDataService: MetaDataService,
    private selectedImageStore: SelectedImagesStore,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private launcherService: LauncherService,
    private administrationService: AdministrationService,
    private domainConfigService: DomainConfigService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
    private selectedImagesStore: SelectedImagesStore,
    private duplicateMenuService: DuplicateMenuService,
    private originalNamesService: OriginalNamesService,
    private applicationSettingsService: AppStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.isTaggerRole = this.userService.isAdminWithTaggerRole();

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.noOfImages = data.viewSettingDTO.imagePerPage;
          this.internalAccess = data.permissionDTO.internalAccess;
          this.exclusiveAccess = data.permissionDTO.exclusiveAccess;
          this.captionAccess = data.permissionDTO.captionAccess;
          this.metaTool = data.permissionDTO.metaTool;
          this.batchManagementAccess = data.permissionDTO.batchManagementAccess;
          this.talentManagementAccess =
            data.permissionDTO.talentManagementAccess;
        } else {
          this.internalAccess = false;
          this.exclusiveAccess = false;
          this.captionAccess = false;
          this.batchManagementAccess = false;
          this.talentManagementAccess = false;
        }
      });

    this.viewProjectService.isTaggingView1OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView1Open = status;
      });

    this.viewProjectService.isTaggingView2OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView2Open = status;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData) => {
        if (domainData) {
          this.domainObjAbbr = domainData.abbr;
        }
      });
  }

  selectedfileName() {
    if (this.selectedImagesStore.hasEntities()) {
      this.checkTaggingDialogStatus();
      this.dialogService.open(SelectedfilenameListComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-lg-panel',
          'fs-dialog-link-panel',
        ],
        listener: {
          withListener: true,
          id: 'SelectedfilenameListComponent',
        },
      });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  filteredfileName() {
    this.checkTaggingDialogStatus();
    this.dialogService.open(FilteredfilenameListComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'FilteredfilenameListComponent',
      },
    });
  }

  imageExclusives() {
    if (this.selectedImagesStore.hasEntities()) {
      this.checkTaggingDialogStatus();
      this.dialogService.open(ImageExclusivesComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-lg-panel',
          'fs-dialog-link-panel',
        ],
        listener: {
          withListener: true,
          id: 'ImageExclusivesComponent',
        },
      });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  telentManagement() {
    this.checkTaggingDialogStatus();
    this.dialogService.open(TalentManagementComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-width-1123-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'TalentManagementComponent',
      },
    });
  }

  approvalReport() {
    this.checkTaggingDialogStatus();
    this.dialogService.open(ActoreapprovalReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'ActoreapprovalReportComponent',
      },
    });
  }

  imageNotesReport() {
    this.checkTaggingDialogStatus();
    this.dialogService.open(ImagenotesReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'ImagenotesReportComponent',
      },
    });
  }

  imageCaptionReport() {
    this.checkTaggingDialogStatus();
    this.dialogService.open(ImagecaptionReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'ImagecaptionReportComponent',
      },
    });
  }

  projectSummaryReport() {
    this.checkTaggingDialogStatus();
    this.dialogService.open(ProjectsummaryReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-width-1123-panel',
        'fs-dialog-link-panel',
      ],
    });
  }

  makeCoverTool() {
    if (this.selectedImagesStore.size() === 1) {
      this.dialogService
        .openConfirmationDialog({
          message: this.translate.instant(
            'left-menu.tab-bar.sidemenus.administration.make-cover.message',
          ),
          title: this.translate.instant(
            'left-menu.tab-bar.sidemenus.administration.make-cover.header',
          ),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          if (state) {
            const obj = {
              assetId: this.selectedImagesStore.getItemsIds()[0],
              batchId: this.selectedImagesStore.getHeadItem().batchId,
              projectId: Number(this.viewProjectService.getProjectId()),
            };
            this.loaderService.displayLoader(true);
            this.administrationService.putMakeCover(obj).subscribe(
              (res) => {
                this.loaderService.displayLoader(false);
                if (res.status) {
                  this.snackBarService.showSnackBar(res.message);
                } else {
                  this.snackBarService.showSnackBarError(res.message);
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false)
              },
            );
          }
        });
    } else {
      this.alertCustom(
        this.translate.instant(
          'left-menu.tab-bar.sidemenus.administration.make-cover.alert2',
        ),
      );
    }
  }

  onTagView1() {
    this.loaderService.displayLoader(true);
    this.taggerService
      .postTaggingStart(this.getTaggingPayload(TaggingViewEnum.One))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res.status) {
          this.confirmDialogForTagging(
            res.message,
            Taggingview1Component,
            TaggingViewEnum.One,
          );
        } else {
          this.openTagViewDialog(Taggingview1Component, res, true);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  onTagView2() {
    this.loaderService.displayLoader(true);
    this.taggerService
      .postTaggingStart(this.getTaggingPayload(TaggingViewEnum.Two))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res.status) {
          this.confirmDialogForTagging(
            res.message,
            Taggingview2Component,
            TaggingViewEnum.Two,
          );
        } else {
          this.openTagViewDialog(Taggingview2Component, res, true);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  openTagViewDialog(component: TaggingViewComponent, res, isOwnLockAsTagger) {
    this.loaderService.displayLoader(false);
    this.closeTaggingDialogById(component.identifier);
    this.dialogService.open(component, {
      data: {
        res: res,
        isOwnLockAsTagger: isOwnLockAsTagger,
      },
      hasBackdrop: false,
      position: {
        top: '10px',
        left: '10%',
      },
      panelClass: ['fs-dialog-panel', 'fs-dialog-auto-width-panel'],
      enableHandlingUpKey: true,
      listener: {
        withListener: true,
        id: component.identifier,
      },
    });
  }

  closeTaggingDialogById(dialogId: string) {
    dialogId === Taggingview1Component.identifier
      ? this.dialogService.closeDialogById(Taggingview2Component.identifier)
      : this.dialogService.closeDialogById(Taggingview1Component.identifier);
  }

  confirmDialogForTagging(
    msg: string,
    component: TaggingViewComponent,
    tagView,
  ) {
    this.loaderService.displayLoader(false);
    this.dialogService
      .openConfirmationDialog({
        message: msg.trim(),
        title: this.translate.instant('view-project.confirm3'),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          this.openTagViewDialog(
            component,
            this.getTaggingPayload(tagView),
            false,
          );
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  advancedImageTagging() {
    this.checkTaggingDialogStatus();
    this.openAdvancedImageTaggingDialog();
    if (this.viewProjectService.getCurrentFilter() !== FilterEnum.ShowAll) {
      this.callShowAll();
    }
  }

  openAdvancedImageTaggingDialog() {
    this.dialogService.open(AdvanceimageTaggingComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'AdvanceimageTaggingComponent',
      },
    });
  }

  applyExif() {
    if (this.selectedImagesStore.hasEntities()) {
      this.checkTaggingDialogStatus();
      this.dialogService.open(ApplyExifComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-md-panel',
          'fs-dialog-link-panel',
        ],
        listener: {
          withListener: true,
          id: 'ApplyExifComponent',
        },
      });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  callShowAll() {
    this.loaderService.displayLoader(true);
    this.viewProjectService
      .callProjectImageIDs(
        this.viewProjectService.getProjectId(),
        FilterEnum.ShowAll,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            const data = {
              assetIds: Utils.getImageIdsBasedOnPage(1, this.noOfImages, res1),
              projectId: this.viewProjectService.getProjectId(),
              filter: FilterEnum.ShowAll,
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.resetAuditConfig();
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      this.viewProjectService.getProjectId(),
                      FilterEnum.ShowAll,
                      1,
                    ),
                  ]);
                  this.handleProjectData(resData);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  handleProjectData(res) {
    this.selectedImagesStore.clear();
    this.viewProjectService.setExecutiveAlbum(false);
    this.sidebarService.setAlbumImageData(res);
    this.loaderService.displayLoader(false);
  }

  checkTaggingDialogStatus() {
    if (this.taggingView1Open) {
      this.dialogService.closeDialogById(Taggingview1Component.identifier);
    }
    if (this.taggingView2Open) {
      this.dialogService.closeDialogById(Taggingview2Component.identifier);
    }
  }

  resetAuditConfig() {
    this.viewProjectService.setCurrentPageNumber(null);
    this.dialogService.closeDialogById(ConflictsComponent.identifier);
    this.applicationSettingsService.setNoConflicts({ loaded: false });
    this.applicationSettingsService.setBypass2ndtagger({ loaded: false });
  }

  actorIdReport() {
    this.checkTaggingDialogStatus();
    this.loaderService.displayLoader(true);
    this.viewProjectService
      .getActorIDReport(this.viewProjectService.getProjectId())
      .pipe(take(1))
      .subscribe((reportData) => {
        this.loaderService.displayLoader(false);
        if (reportData) {
          this.checkTaggingDialogStatus();
          this.dialogService.open(ActoreidReportComponent, {
            panelClass: [
              'fs-dialog-panel',
              'fs-dialog-width-600-panel',
              'fs-dialog-link-panel',
            ],
            data: {
              reportData,
            },
          });
        } else {
          this.alertCustom('No data available.');
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  Delivery() {
    this.checkTaggingDialogStatus();
    this.dialogService.open(FsdeliveryComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-ls-panel',
        'fs-dialog-link-panel',
        'fs-dialog-container-auto-height-panel',
      ],
    });
  }

  finalizeUnfinalize() {
    if (this.selectedImagesStore.hasEntities()) {
      setTimeout(() => {
        this.checkTaggingDialogStatus();
        this.dialogService.open(FinalizeUnfinalizeComponent, {
          panelClass: ['fs-dialog-panel', 'fs-dialog-link-panel'],
          width: 'auto',
          minWidth: 300,
          listener: {
            withListener: true,
            id: 'FinalizeUnfinalizeComponent',
          },
        });
      }, 100);
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  rejectionToggle() {
    if (this.selectedImagesStore.hasEntities()) {
      setTimeout(() => {
        this.checkTaggingDialogStatus();
        this.dialogService.open(RejectionToggleComponent, {
          panelClass: [
            'fs-dialog-panel',
            'fs-dialog-width-300-panel',
            'fs-dialog-link-panel',
          ],
          listener: {
            withListener: true,
            id: 'RejectionToggleComponent',
          },
        });
      }, 100);
    } else {
      this.alertCustom(this.translate.instant('view-project.alert13'));
    }
  }

  onOpenDuplicateListMenu() {
    this.loaderService.displayLoader(true)
    this.duplicateMenuService.getDuplicateAssets(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: DuplicateAssetListModel) => {
        this.loaderService.displayLoader(false);
        if (res.duplicatedPerProjectAssets.length) {
          this.dialogService.open(DuplicateListMenuComponent, {
            panelClass: [
              'fs-dialog-panel',
              'fs-dialog-size-ls-panel',
              'fs-dialog-link-panel',
              'fs-dialog-container-auto-height-panel',
            ],
            data: res
          });
        } else {
          this.alertCustom(this.translate.instant('left-menu.tab-bar.sidemenus.administration.duplicate-menu.warnings.notFound'))
        }
    }, () => {
        this.loaderService.displayLoader(false);
      })
  }

  onOpenOriginalNameListMenu(): void {
    this.dialogService.open(OriginalNamesReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
    });
  }

  batchManagement() {
    this.checkTaggingDialogStatus();
    this.dialogService.open(BatchManagementComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'BatchManagementComponent',
      },
    });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  showHelpWindow(helpTopic?, videoName?) {
    this.dialogService.open(HelpWindowComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-no-overflow-panel',
      ],
      data: {
        topicName: helpTopic,
        videoName: videoName,
      },
    });
  }

  getTaggingPayload(tagView) {
    return {
      projectId: this.viewProjectService.getProjectId(),
      tagView: tagView,
    };
  }
}
