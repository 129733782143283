import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AssetTransactionData } from './transmodel';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UserService } from '../../../../../shared/services/user.service';
import { ExportType } from '../../../../../shared/models/enum/ExportType';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { HttpClient } from '@angular/common/http';
import { MatStepper } from '@angular/material/stepper';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ImageTransactionsService } from './image-transactions.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ImageTypeSize } from '../../../../../shared/models/enum/download-size';
import { Utils } from '../../../../../shared/utils/utils';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-image-transaction',
  templateUrl: './image-transaction.component.html',
  styleUrls: ['./image-transaction.component.scss'],
})
export class ImageTransactionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('stepper') stepper: MatStepper;

  displayedColumns: string[] = [
    'id',
    'transactionTime',
    'transactionType',
    'ip',
    'projectName',
    'userId',
    'userName',
    'authorityName',
  ];
  dataSource = new MatTableDataSource([]);
  exportType = ExportType;
  assetId: number;
  assetName: string;

  private destroy$: Subject<any> = new Subject<any>();

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeWindow();
  }

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private dialogService: DialogService,
    private imageTransactionsService: ImageTransactionsService,
    private dialogRef: MatDialogRef<ImageTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) data: { imgData },
  ) {
    this.assetId = data.imgData.id;
    this.assetName = data.imgData.name;
  }

  ngOnInit() {
    this.loaderService.displayLoader(true);
    this.imageTransactionsService
      .receiveAssetTransactionData(this.assetId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: AssetTransactionData[]) => {
        if (response && response.length) {
          response.forEach((item: AssetTransactionData, index) => {
            item.id = index + 1;
            item.browserInfo = this.parseBrowserIngoData(item.browserInfo);
          });
          this.dataSource.data = response;
        }
        this.loaderService.displayLoader(false);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  onOpenDetailTable(selectedRow: AssetTransactionData): void {
    this.imageTransactionsService.setImageTransactionDetail(selectedRow);
    this.stepper.next();
  }

  exportToExcel(reportType) {
    const dataToExport = JSON.parse(JSON.stringify(this.dataSource.data));

    dataToExport.forEach((item: AssetTransactionData) => {
      item.browserInfo = Object.keys(item.browserInfo).length
        ? JSON.stringify(item.browserInfo)
        : '';
      item.transactionDetails = Object.keys(item.transactionDetails).length
        ? JSON.stringify(item.transactionDetails)
        : '';
    });

    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Note Report',
        dataToExport,
        'ImageTransactionReport.xlsx',
      );
    } else {
      if (!Utils.isObjectEmpty(dataToExport[0])) {
        const colNames = this.userService.getColumnsName(dataToExport[0]);
        this.userService.exportPDFDataInHorizontalOrientation(
          dataToExport,
          colNames,
        );
      }
    }
  }

  parseBrowserIngoData(data: string) {
    if (data) {
      return JSON.parse(data);
    } else {
      return {};
    }
  }
  closeWindow() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
