<header
  id="header"
  class="header navbar-fixed-top"
  *ngIf="currentState.displaySetting.header.visible"
>
  <div
    class="main-header"
    [ngClass]="{ 'shake-little': viewShaking, 'shake-constant': viewShaking }"
  >
    <div class="h-top-left">
      <fs-breadcrumb></fs-breadcrumb>
    </div>
    <div class="brand-logo" preventdrag>
      <a>
        <img [src]="'/assets/images/' + logoName" [alt]="altString" />
      </a>
    </div>

    <div class="h-top-right">
      <div class="user-info d-flex justify-content-end align-items-center">
        <div *ngIf="selectedGroupLogo" class="ml-3">
          <div class="selected_logo">
            <img [src]="'assets/images/branding/' + selectedGroupLogo | projectAssetsBaseUrl" />
          </div>
        </div>
        <div
          *ngIf="projectAuthority === projectRole.ROLE_TALENT"
          (click)="onCallImDone()"
          class="fs-done-button-wrapper d-flex justify-content-center align-items-center fs-cursor-pointer py-1 px-1"
        >
          <div
            class="text-uppercase"
            title="{{ 'top-menu.done' | translate }}"
          ></div>
          {{ 'top-menu.done' | translate }}
        </div>
        <div class="dropdown_li ml-3">
          <div
            class="dept-dropMenu"
            *ngIf="currentState.displaySetting.header.items.dropdown"
          >
            <form>
              <ng-select
                #api
                class="department-list"
                [items]="arrProjectDepartmentList"
                placeholder="{{ getProjectSelectPlaceholder() | translate }}"
                [virtualScroll]="true"
                bindLabel="label"
                bindValue="value"
                [(ngModel)]="selectedProject"
                [ngModelOptions]="{ standalone: true }"
                (change)="selectedItems($event)"
                (close)="onClickAlbum()"
                [searchable]="false"
              >
                <ng-template ng-header-tmp>
                  <div style="display: flex">
                    <input
                      fsAutofocus
                      fsNgSelectDropDownMinHeight
                      #filterInput
                      style="width: 100%; line-height: 24px"
                      type="text"
                      (input)="api.filter($event.target.value)"
                    />
                    <i
                      [hidden]="filterInput.value.length === 0"
                      class="fa fa-times-circle paddingClose"
                      (click)="api.filter(''); filterInput.value = ''"
                    ></i>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span class="select-option-item">{{ item.label }}</span>
                </ng-template>
              </ng-select>
            </form>
          </div>
        </div>

        <div class="small_devhide ml-3">
          <span
            (click)="onClickPhoneIcon()"
            *ngIf="currentState.displaySetting.header.items.phone"
            title="Film Solutions, LLC @ (818) 588 3500"
          >
            <img
              src="/assets/images/icon-phone.png"
              class="phone_icons"
              alt="icon"
            />
          </span>
        </div>

        <div
          class="ml-3 help_li small_devhide fs-cursor-pointer"
          #appUpdatesPopup
          *ngIf="hasNewFeatureAnnounce()"
          (click)="openAppUpdatesPopup()"
        >
          <div
            class="fs-app-update-icon-container d-flex justify-content-center align-items-center"
            [class.ripple-effect-container]="showNewFeatureAnnounceIcon"
            [class.fs-app-update-icon-highlighted]="showNewFeatureAnnounceIcon"
          >
            <span class="fs-app-update-icon"></span>
          </div>
        </div>

        <div class="ml-3 help_li small_devhide">
          <a>
            <i
              #helpIcon
              (click)="onClickHelpIcon()"
              class="fa fa-question-circle"
            >
            </i>
          </a>
        </div>
        <!-- Notification -->
        <div class="ml-3 notification" *ngIf="isNotification">
          <a
            href="#"
            class="dropdown-toggle title2"
            data-toggle="dropdown"
            title="Notification"
            role="button"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <i class="fa fa-bell-o"></i>
          </a>

          <div class="dropdown-menu user_pro">
            <div class="user-header">
              <span class="arrow-up"> </span>
              <h4 id="user-info">Notification</h4>
            </div>
            <div
              href="javascript:void(0)"
              class="header-menu-link"
              *ngFor="let item of notificationData; let i = index"
              id="elem-{{ i }}"
            >
              <a href="javascript:void(0)" (click)="notification(item)">
                <i
                  class="fa fa-circle notification-read"
                  [ngClass]="checkRead(item)"
                ></i>
                {{ item.title }}
                <hr *ngIf="i !== notificationData.length - 1" class="solid" />
              </a>
            </div>
          </div>
        </div>

        <!-- Profile -->
        <div
          class="ml-3"
          *ngIf="currentState.displaySetting.header.items.profile"
        >
          <div
            class="fs-avatar fs-txt-white-grey-color-hover fs-cursor-pointer"
            role="button"
            aria-haspopup="true"
            aria-expanded="true"
            #userAvatarRef
            [attr.data-initial]="userNameInitial"
            [title]="'header.profile' | translate"
            (click)="onClickUserAvatar()"
          ></div>
        </div>
      </div>
    </div>
  </div>
</header>
