import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AppSettings } from '../../appSettings/appSettings';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { OriginalNamesModel } from '../../dashboard/project/dashboard-shared/left-menu/menus/administration/original-names/original-names.model';

@Injectable()
export class OriginalNamesService {
  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
  ) {}

  getOriginalNames(projectId, assetIds): Observable<OriginalNamesModel[]> {
    const path = AppSettings.ORIGINAL_ASSET_NAMES();
    return this.apiService.post(path, {projectId, assetIds}).pipe(
      map((res) => res)
    );
  }

  exportPDFDataWithDocName(jsonData, documentTitle: string, columns) {
    const url = window.location.href;
    const doc = new jsPDF();

    const date = new Date();
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageWidthWithoutMargins = doc.internal.pageSize.getWidth() - 2 * 10;

    doc.setFontSize(8);
    doc.text(`${formattedDate}, ${formattedTime}`, 10, 10);
    doc.text(documentTitle, pageWidth - 10, 10, { align: 'right' });

    autoTable(doc, {
      columns: columns,
      body: jsonData,
      theme: 'grid',
      startY: 20,
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [0, 0, 0],
        halign: 'center',
        fontSize: 11,
        cellPadding: 2,
        lineWidth: 0.1,
      },
      styles: { fontSize: 10, cellPadding: 1, overflow: 'linebreak' },
      columnStyles: {
        0: { cellWidth: 10 / 100 * pageWidthWithoutMargins },
        1: { cellWidth: 25 / 100 * pageWidthWithoutMargins },
        2: { cellWidth: 25 / 100 * pageWidthWithoutMargins },
        3: { cellWidth: 40 / 100 * pageWidthWithoutMargins }
      },
      didDrawPage: function () {
        const pageNumber = doc.getCurrentPageInfo().pageNumber;
        const pageWidth = doc.internal.pageSize.getWidth();

        doc.setFontSize(8);
        doc.text(url, 10, doc.internal.pageSize.getHeight() - 10);
        doc.text(`${pageNumber}`, pageWidth - 10, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
      }
    });

    doc.save(documentTitle);
  }
}
