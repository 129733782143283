import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppStateService } from '../../../../../../../shared/services/app-state.service';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../shared/utils/utils';
import { ConflictsComponent } from '../conflicts/conflicts.component';
import { takeUntil } from 'rxjs/operators';
import { Taggingview1Component } from '../../administration/taggingview-1/taggingview-1.component';
import { Taggingview2Component } from '../../administration/taggingview-2/taggingview-2.component';
import { FilterEnum } from '../../../../../../../shared/enum/filter.enum';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

// TODO: refactor dialogs
@Component({
  selector: 'fs-no-conflicts',
  templateUrl: './no-conflicts.component.html',
  styleUrls: ['./no-conflicts.component.scss'],
})
export class NoConflictsComponent
  extends UnsubscriberComponent
  implements OnInit
{
  projectImageData: AssetInfo = new AssetInfo();
  noOfImages: any;
  taggingView1Open = false;
  taggingView2Open = false;
  constantsGlobalData: any;

  constructor(
    private viewProjectService: ViewProjectService,
    private modalStatusService: Modalstatusservice,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private router: Router,
    private applicationSettingsService: AppStateService,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.noOfImages = data.viewSettingDTO.imagePerPage;
        }
      });

    this.viewProjectService.isTaggingView1OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView1Open = status;
      });

    this.viewProjectService.isTaggingView2OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView2Open = status;
      });
  }

  onNoConflictsClick() {
    this.checkTaggingDialogStatus();
    this.callNoConflictAPI();
  }

  checkTaggingDialogStatus() {
    if (this.taggingView1Open) {
      this.dialogService.closeDialogById(Taggingview1Component.identifier);
    }
    if (this.taggingView2Open) {
      this.dialogService.closeDialogById(Taggingview2Component.identifier);
    }
  }

  callNoConflictAPI() {
    this.resetAuditConfig();
    this.loaderService.displayLoader(true);
    const filter = FilterEnum.AuditAndNoConflicts;
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.viewProjectService.setCurrentFilter(filter);
            const assetsIds = Utils.getImageIdsBasedOnPage(
              1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: filter,
              view: this.constantsGlobalData.MODELNAMES.NOCONFLICTS,
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  if (resData.assetInfoDTOList.length > 0) {
                    this.projectImageData =
                      this.viewProjectService.mergeWithProjectData(
                        this.projectImageData,
                      );
                    this.projectImageData.assetInfoDTOList =
                      resData.assetInfoDTOList;
                    this.viewProjectService.setProjectData(
                      this.projectImageData,
                    );
                    this.applicationSettingsService.setNoConflicts({
                      loaded: true,
                    });
                    this.router.navigate([
                      Utils.prepareFilterNavigationURL(
                        this.viewProjectService.getProjectRole(),
                        this.viewProjectService.getProjectId(),
                        filter,
                        1,
                      ),
                    ]);
                    this.loaderService.displayLoader(false);
                  } else {
                    this.loaderService.displayLoader(false);
                    this.applicationSettingsService.setNoConflicts({
                      loaded: false,
                    });
                    this.alertCustom(
                      this.translate.instant('view-project.alert2'),
                    );
                  }
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.applicationSettingsService.setNoConflicts({ loaded: false });
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  resetAuditConfig() {
    this.dialogService.closeDialogById(ConflictsComponent.identifier);
    this.applicationSettingsService.setNoConflicts({ loaded: false });
    this.applicationSettingsService.setBypass2ndtagger({ loaded: false });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
