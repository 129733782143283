<div
  class="image-gallery-new"
  id="page-content-wrapper"
  [ngClass]="{ 'full-width': !isLeftMenuPartShown }"
>
  <div class="container-fluid">
    <div class="range-cont d-flex align-items-end">
      <div class="range-inner">
        <div class="range-indicator-cont">
          <div class="indicator-rule indicator-rule1"></div>
          <div class="indicator-rule indicator-rule2"></div>
          <div class="indicator-rule indicator-rule3"></div>
          <div class="indicator-rule indicator-rule4"></div>
          <div class="indicator-rule indicator-rule5"></div>
          <div class="indicator-num indicator-rule1">0</div>
          <div class="indicator-num indicator-rule2">25</div>
          <div class="indicator-num indicator-rule3">50</div>
          <div class="indicator-num indicator-rule4">75</div>
          <div class="indicator-num indicator-rule5 lableMargin">100</div>
        </div>
        <mat-slider
          class="slider-wrapper"
          [max]="100"
          name="rageValue"
          [step]="1"
          [min]="1"
        >
          <input
            matSliderThumb
            (input)="handleChange($event)"
            [(ngModel)]="rangeValue1"
            #slider
          />
        </mat-slider>
      </div>

      <div
        class="d-inline-flex align-items-center mb-2"
        *ngIf="isStatusCheckBoxShown"
      >
        <mat-checkbox
          class="fs-checkbox fs-checkbox-margin"
          name="isRejectIconShown"
          [(ngModel)]="isRejectIconShown"
        >
          <span class="cb-title"> {{'global.showimgstatue' | translate}} </span>
        </mat-checkbox>
      </div>
    </div>

    <div class="compare-scroll">
      <ng-scrollbar autoHeightDisabled="false" track="all">
        <div class="wd pull-left comp-cont" *ngIf="selectedImages2.length > 0">
          <div
            *ngFor="let selImgData of selectedImages2; let i = index"
            class="compare-cont"
            [ngStyle]="{ width: compareContWidth }"
          >
            <div id="mainGrid" class="pagination_width">
              <fs-pagination
                [totalRecord]="projectImageIds.length"
                id="{{ i + 1 }}"
                (pageChange)="onPageChange($event, currentPageNumber[i], i)"
              ></fs-pagination>

              <div class="db-container db_com rightside-gallerycontainer-panel">
                <div #horizontalscroll class="st-subdiv db_com">
                  <div id="imgList">
                    <div
                      *ngFor="
                        let imgData of projectImageIds
                          | paginate
                            : {
                                itemsPerPage: noOfImages,
                                currentPage: currentPageNumber[i],
                                id: i + 1
                              };
                        index as ind
                      "
                      class="img-div"
                    >
                      <div class="span3">
                        <div class="imge">
                          <a
                            class="thumbnail d-flex justify-content-center img-check"
                            (mousedown)="
                              selectImageOnClick(
                                getAssets(selImgData.id),
                                ind,
                                $event,
                                i
                              )
                            "
                          >
                            <img
                              preventdrag
                              class="getindex"
                              [imageSrcWithAuthToken]="
                                viewProjectService.getWebImageURL(
                                  getAssets(selImgData.id),
                                  '1600'
                                )
                              "
                              alt=""
                              [name]="getAssets(selImgData.id).name"
                              [id]="getAssets(selImgData.id).id"
                              [width]="imgWidth"
                              [ngStyle]="{ 'max-width.px': imgWidth }"
                              [ngClass]="{
                                'border-active': selectedImageStatus[i].status
                              }"
                            />

                            <img
                              preventdrag
                              *ngIf="
                                isRejected(getAssets(selImgData.id)) &&
                                isRejectIconShown
                              "
                              class="reject-image ng-star-inserted mCS_img_loaded"
                              src="/assets/images/Rejected_X_Large.png"
                            />

                            <div
                              class="approve-icon"
                              *ngIf="
                                getAssets(selImgData.id).status === 5 &&
                                getAssets(selImgData.id).rejectedBy.length === 0
                              "
                              [ngClass]="{
                                'apr-rej-small-img':
                                  projectDetailPermission.viewSettingDTO.size ==
                                  '3',
                                'apr-rej-large-img':
                                  projectDetailPermission.viewSettingDTO.size ==
                                  '1',
                                'apr-rej-medium-img':
                                  projectDetailPermission.viewSettingDTO.size ==
                                  '2'
                              }"
                            >
                              <i
                                *ngIf="isRejectIconShown"
                                class="fs-approve-icon-sp bg-sprite"
                              ></i>
                            </div>

                            <div
                              class="rejected-icon"
                              *ngIf="
                                getAssets(selImgData.id).status === 5 &&
                                getAssets(selImgData.id).rejectedBy.length > 0
                              "
                              [ngClass]="{
                                'apr-rej-small-img':
                                  projectDetailPermission.viewSettingDTO.size ==
                                  '3',
                                'apr-rej-large-img':
                                  projectDetailPermission.viewSettingDTO.size ==
                                  '1',
                                'apr-rej-medium-img':
                                  projectDetailPermission.viewSettingDTO.size ==
                                  '2'
                              }"
                            >
                              <i
                                *ngIf="isRejectIconShown"
                                class="fs-reject-icon-sp bg-sprite"
                              ></i>
                            </div>
                          </a>
                        </div>
                        <div class="tile">
                          <h3>
                            <div
                              *ngIf="retouchAccess"
                              title="{{
                                'image-caption.buttons.retouch' | translate
                              }}"
                              class="button1"
                              (click)="drawImage(getAssets(selImgData.id))"
                            >
                              <img
                                preventdrag
                                src="/assets/images/icon-pencil_previous.png"
                              />
                            </div>
                            <span class="fs-txt-white-dark-color">
                              {{ getAssets(selImgData.id).name }}</span
                            >
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>
