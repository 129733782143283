import { UserDataService } from './../../shared/services/userdata.service';
import { Utils } from './../../shared/utils/utils';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentService } from './department.service';
import { LauncherService } from '../launcher/launcher.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { LoaderService } from '../../shared/services/loader.service';
import { UserService } from '../../shared/services/user.service';
import { ViewProjectService } from '../../shared/services/view-project.service';
import { SidebarService } from '../project/sidebar-service/sidebar-service';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { LogoService } from '../../shared/services/logo.service';
import { UnsubscriberComponent } from '../../shared/components/unsubscriber/unsubscriber.component';
import { ISearchList } from '../../shared/models/projectlist.model';
import { DomainConfigService } from '../../shared/services/domain-config.service';
import { DomainConfig } from '../../shared/models/domainConfig.model';
import { DashboardEventBus } from '../dashboard-manager/dashboard-event-bus';
import { DashboardDepartmentEvent } from '../dashboard-manager/dashboard-manager-rules/dashboard-department/events/dashboard-department-event';
import { DashboardDepartmentHandler } from '../dashboard-manager/dashboard-manager-rules/dashboard-department/handler/dashboard-department-handler';
import { DashboardDepartmentFavouriteIconHandler } from '../dashboard-manager/dashboard-manager-rules/dashboard-department/handler/dashboard-department-favourite-icon-handler';
import {ApiErrorHandlerService} from '../../shared/services/api-error-handler.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'fs-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss'],
})
export class DepartmentComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  galleryInfo: any = [];
  routerObser: any;
  selectedProject: any = null;
  completeHeight: any = 0;
  alternativeLogo$: Observable<string>;
  searchProjectList: ISearchList[] = [];
  domainObjAbbr: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private departmentService: DepartmentService,
    private domainConfigService: DomainConfigService,
    private loaderService: LoaderService,
    private launcherService: LauncherService,
    private translate: TranslateService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userService: UserService,
    private dashboardEventBus: DashboardEventBus,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private userDataService: UserDataService,
    private dialogService: DialogService,
    private logger: NGXLogger,
    private logoService: LogoService,
  ) {
    super();
  }

  ngOnInit() {
    this.sidebarService.resetAlbumImageData();
    this.viewProjectService.resetProjectData();
    this.viewProjectService.resetProjectImageIDs();
    this.viewProjectService.resetProjectDetailPermissionData();
    this.sidebarService.setUpdatedTaggingWideEdit({});
    this.alternativeLogo$ = this.logoService.getDepartmentLogo();
    this.dialogService.closeAll();

    this.launcherService.projectListGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.searchProjectList = res.search;
        }
      });
    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData: DomainConfig) => {
        if (domainData) {
          this.domainObjAbbr = domainData.abbr;
        }
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedProject) => {
        if (selectedProject) {
          this.selectedProject = Number(selectedProject);
        } else {
          this.selectedProject = null;
        }
      });

    this.routerObser = this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((parameters) => {
        this.getDepartmentProjects(parameters.deptid);
      });

    this.departmentService.setSetUpNameGroupGlobal('');
    this.departmentService.setSetUpNameGlobal('');
  }

  getDepartmentProjects(departmentId) {
    this.loaderService.displayLoader(true);
    if (this.selectedProject === 3 || this.selectedProject === 4) {
      this.launcherService.resetSelectedDepLogo();
      setTimeout(() => {
        this.departmentService
          .getDepartmentAlbumsDataApiCall(this.selectedProject)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.galleryInfo = data;
            this.galleryInfo = this.swapingArray(data);
            this.loaderService.displayLoader(false);
          },(error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          });
      }, 0);
    } else {
      this.userService.resetData();
      setTimeout(() => {
        this.departmentService
          .getDepartmentDataApiCall(departmentId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.galleryInfo = this.swapingArray(data);
            this.loaderService.displayLoader(false);
          },(error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          });
      }, 0);
    }
  }

  swapingArray(data: any) {
    const array1 = [];
    const array2 = [];
    const arrayFinal = [];
    data.forEach((galData, index) => {
      if (index < Math.round(data.length / 2)) {
        array1.push(galData);
      } else {
        array2.push(galData);
      }
    });
    array1.forEach((array1Data, index) => {
      try {
        arrayFinal.push(array1[index]);
        if (array2[index]) {
          arrayFinal.push(array2[index]);
        }
      } catch {
        //**//
      }
    });
    return arrayFinal;
  }

  galListInfo(group, galBotInfo) {
    this.dashboardEventBus.emit(
      new DashboardDepartmentEvent(
        new DashboardDepartmentHandler(group, galBotInfo, this.selectedProject),
      ),
    );
  }

  addToFav(dataObj) {
    this.dashboardEventBus.emit(
      new DashboardDepartmentEvent(
        new DashboardDepartmentFavouriteIconHandler(true, dataObj),
      ),
    );
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  ngOnDestroy() {
    if (this.routerObser) {
      this.routerObser.unsubscribe();
    }
    super.ngOnDestroy();
  }
}
