<div
  class="fs-sm-txt fs-txt-white-dark-color list-unstyled components sideBar-menu py-2"
>
  <div class="right-content">
    <div
      class="container d-block sub-menu-list sub-menu-text-spacing m-0 admin-list"
    >
      <div class="row">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="onConflictsClick()"
        >
          {{ 'left-menu.tab-bar.sidemenus.audit.labels.label1' | translate }}
        </span>
      </div>

      <fs-no-conflicts></fs-no-conflicts>
      <fs-publish-images></fs-publish-images>
      <fs-hide-images [isTaggerRole]="isTaggerRole"></fs-hide-images>
      <fs-unhide-images [isTaggerRole]="isTaggerRole"></fs-unhide-images>

      <div class="row" *ngIf="!isTaggerRole">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="onOpenConvertAssetTypeDialog()"
        >
          {{
            'left-menu.tab-bar.sidemenus.audit.buttonse.convertAssetType'
              | translate
          }}
        </span>
      </div>
      <div class="row">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="onClickClearTag()"
        >
          {{
            'left-menu.tab-bar.sidemenus.audit.buttonse.clear_tags'
              | translate
          }}
        </span>
      </div>

      <div class="row">
        <span
          class="col fs-cursor-pointer fs-txt-white-grey-color-hover"
          (click)="onClickClearSingleTag()"
        >
          {{
            'left-menu.tab-bar.sidemenus.audit.buttonse.clear_tag' | translate
          }}
        </span>
      </div>

      <fs-bypass-2nd-tagger></fs-bypass-2nd-tagger>
    </div>
  </div>
</div>
