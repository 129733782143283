import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { ImageExclusivesDialogComponent } from '../image-exclusives/image-exclusives-dialog-component/image-exclusives-dialog-component.component';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { ExportType } from '../../../../../../../shared/models/enum/ExportType';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-selectedfilename-list',
  templateUrl: './selectedfilename-list.component.html',
  styleUrls: ['./selectedfilename-list.component.scss'],
})
export class SelectedfilenameListComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild('listTextArea') listTextArea;
  selectedList: Array<object>;
  popupMsg = '';
  selectedImageObj: any[];
  strSelectedImageList = '';
  selectedImageObser: any;
  selectAll: boolean;
  arrListToExport: any = [];
  constantsGlobalData: any;
  exportType = ExportType;

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userService: UserService,
    private loaderService: LoaderService,
    private viewProjectService: ViewProjectService,
    private dialogService: DialogService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<SelectedfilenameListComponent>,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.selectedImageObser = this.selectedImagesStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageArray) => {
        this.selectedImageObj = imageArray;
      });

    this.strSelectedImageList = '';
    this.loaderService.displayLoader(true);
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
    };
    if (this.selectedImageObj.length >= 1) {
      this.viewProjectService
        .getImagesNameBasedOnAssetsId(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resData) => {
          this.loaderService.displayLoader(false);
          this.filterImageNames(resData);
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    } else {
      this.popupMsg = 'No Image(s) selected';
      this.openImageExclusivesDialog();
    }
  }

  openImageExclusivesDialog() {
    this.dialogService.open(ImageExclusivesDialogComponent, {
      data: {
        popupMsg: this.popupMsg,
      },
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-message-base-panel',
      ],
    });
  }

  filterImageNames(imageList: any) {
    for (const list of imageList) {
      this.strSelectedImageList += list + '\n';
    }
  }

  onClickExport(reportType) {
    this.arrListToExport = [];
    const tempArray = this.strSelectedImageList.split('\n');

    tempArray.forEach((item) => {
      this.arrListToExport.push({
        name: item,
      });
    });

    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Selected Filename List',
        this.arrListToExport,
        'SelectedFilenameList.xlsx',
      );
    } else {
      const colNames = this.userService.getColumnsName(this.arrListToExport[0]);
      this.userService.exportPDFData(this.arrListToExport, colNames);
    }
  }

  print() {
    const printContents = document.getElementById('filteredList').innerHTML;
    const popupWin = window.open(
      '',
      '_blank',
      'top=0,left=200,height=1000,width=1000',
    );
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  closeWindow() {
    this.selectAll = false;
    this.dialogRef.close();
  }
}
