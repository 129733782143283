export enum AssetNavigateActionType {
  Next = 'next',
  Previous = 'previous',
  Last = 'last',
  First = 'first'
}

export const CopyrightStatuses = [
  { label: 'Unknown', value: 'null' },
  { label: 'Copyrighted', value: '1' },
  { label: 'Public Domain', value: '0' },
];
