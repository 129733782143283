import { Utils } from './../../utils/utils';
import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LauncherService } from '../../../dashboard/launcher/launcher.service';
import { SidebarService } from '../../../dashboard/project/sidebar-service/sidebar-service';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Notification } from './notification-model';
import { DialogService } from '../../services/dialog/dialog.service';
import { PopupService } from '../../services/popup/popup.service';
import { UserAvatarMenuPopupComponent } from './user-avatar-menu-popup/user-avatar-menu-popup.component';
import { AppStateService } from '../../services/app-state.service';
import { UserDetails } from '../../models/userdetails.model';
import { UserService } from '../../services/user.service';
import { ViewProjectService } from '../../services/view-project.service';
import { DomainConfigService } from '../../services/domain-config.service';
import { Modalstatusservice } from '../../services/modalstatus.service';
import { CurrentStateService } from '../../services/current.state.service';
import { PreviousRouteService } from '../../services/previous-route.service';
import { ConstantsGlobal } from '../../constants/constants';
import { ConflictsComponent } from '../../../dashboard/project/dashboard-shared/left-menu/menus/audit/conflicts/conflicts.component';
import { ContactDetailsComponent } from './fs-contact-details/contact-details.component';
import { HelperDialogComponent } from './fs-helper-dialog/helper-dialog.component';
import { SelectedImagesStore } from '../../store/selected-images-store';
import { SelectItemGroup } from '../../models/select-item';
import { FsCookieService } from '../../services/fs-cookie.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscriberComponent } from '../../components/unsubscriber/unsubscriber.component';
import { UserRole } from '../../enum/user-role.enum';
import { PkoLogoutComponent } from '../../../dashboard/project/dashboard-shared/left-menu/talent-user/finalize-images/pko-logout/pko-logout.component';
import { DepartmentService } from '../../../dashboard/department/department.service';
import { ProjectRole, ProjectRoleId } from '../../enum/project-role.enum';
import { ViewGalleryResumeModalStateService } from '../../services/view-gallery-resume-modal.service';
import { AppUpdatesComponent } from '../../components/app-updates/app-updates.component';
import {
  DomainConfig,
  INewFeatureAnnounce,
} from '../../models/domainConfig.model';
import { LocalStorageService } from 'ngx-webstorage';
import { AppUpdatesService } from '../../components/app-updates/app-updates.service';
import { DashboardEventBus } from '../../../dashboard/dashboard-manager/dashboard-event-bus';
import { DashboardSelectProjectEvent } from '../../../dashboard/dashboard-manager/dashboard-manager-rules/dashboard-select-project/events/dashboard-select-project-event';
import { DashboardSelectProjectHandler } from '../../../dashboard/dashboard-manager/dashboard-manager-rules/dashboard-select-project/handler/dashboard-select-project-handler';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'fs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends UnsubscriberComponent implements OnInit {
  @ViewChild('userAvatarRef') private userAvatarElement: ElementRef;
  @ViewChild('helpIcon') private helpIcon: ElementRef;
  @ViewChild('appUpdatesPopup') private appUpdatesPopup: ElementRef;
  logoName = '';
  altString = '';
  online: Observable<boolean>;
  currentState: any;
  selectedProject: string;
  groupedProject: any;
  currentUser: UserDetails;
  isEnlargeView = false;
  userNameInitial: any = 'N/A';
  isContactDetailsShown = false;
  projectAuthority = '';
  selectedAlbum: any;
  arrProjectDepartmentList: any = [];
  constantsGlobalData: any;
  viewShaking = false;
  selectedGroupLogo: any;
  notificationData = Notification;
  isNotification = false;
  projectImageIds: any = null;
  PLACE_HOLDER = 'header.select.selectProjectPlaceHolder';
  currentAppVersion: string;
  userRole = UserRole;
  projectRole = ProjectRole;
  newFeatureAnnounce: INewFeatureAnnounce = {} as INewFeatureAnnounce;
  showNewFeatureAnnounceIcon: boolean;
  domainConfig: DomainConfig;

  constructor(
    private userService: UserService,
    private router: Router,
    private viewProjectService: ViewProjectService,
    private appStateService: AppStateService,
    private localStorage: LocalStorageService,
    private appUpdatesService: AppUpdatesService,
    private launcherService: LauncherService,
    private modalStatusService: Modalstatusservice,
    private currentStateService: CurrentStateService,
    private sidebarService: SidebarService,
    private previousRouteService: PreviousRouteService,
    private departmentService: DepartmentService,
    private logger: NGXLogger,
    private cookieService: FsCookieService,
    private dialogService: DialogService,
    private popupService: PopupService,
    private applicationSettings: AppStateService,
    private dashboardEventBus: DashboardEventBus,
    private viewGalleryResumeModalService: ViewGalleryResumeModalStateService,
    private translate: TranslateService,
    private selectedImagesStore: SelectedImagesStore,
    private domainConfigService: DomainConfigService,
  ) {
    super();
    this.online = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false)),
    );
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.appUpdatesService.newFeatureAnnounceVersion$.subscribe(
      (version: string) =>
        (this.showNewFeatureAnnounceIcon =
          this.appUpdatesService.getLocalStorageNewFeatureAnnounceVersion() !==
          version),
    );

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData: DomainConfig) => {
        if (!Utils.isObjectEmpty(domainData)) {
          this.domainConfig = domainData;
          this.currentAppVersion = domainData.version;
          this.logoName = domainData.logo;
          this.altString = domainData.alt;
          this.newFeatureAnnounce =
            domainData.newFeatureAnnounce as INewFeatureAnnounce;
        }
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
        if (event.url === '/dashboard') {
          this.selectedProject = null;
        }
      }
    });

    this.userService.currentUserGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData) => {
        try {
          if (!Utils.isObjectEmpty(userData)) {
            this.currentUser = userData;
            this.userNameInitial = userData.firstName
              ? userData.firstName.charAt(0) + userData.lastName.charAt(0)
              : 'N/A';
          }
        } catch {
          //**//
        }
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.launcherService.projectListGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.arrProjectDepartmentList = [];
        if (!Utils.isObjectEmpty(res)) {
          if (res.executiveAlbums.length > 0) {
            const translatedLabels = res.executiveAlbums.map(item => ({
              ...item,
              label: this.translateExecutiveAlbumLabel(item.value)
            }));

            const speraterData = {
              value: -1,
              label: '--------------------',
              disabled: true,
            };

            translatedLabels.push(speraterData);

            if (!this.userService.isAdminWithTaggerRole()) {
              this.arrProjectDepartmentList.push(...translatedLabels);
            }
          }

          this.groupedProject = res.list as SelectItemGroup[];

          if (this.groupedProject) {
            this.groupedProject.forEach((projectAlbumObj) => {
              this.arrProjectDepartmentList.push(...projectAlbumObj.items);
            });
          }
        }
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedProject) => {
        if (selectedProject) {
          this.selectedProject = selectedProject;
        }
      });

    this.currentStateService.getCurrentState
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentState) => {
        this.currentState = currentState;
      });

    this.launcherService.selectedGroupLogoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((groupLogo) => {
        this.selectedGroupLogo = groupLogo;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.selectedGroupLogo = data.groupLogo;
        }
      });

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectImageIds = data;
        }
      });
  }

  onClickAlbum() {
    if (
      this.selectedAlbum &&
      this.selectedAlbum.value &&
      this.selectedAlbum.value !== 0 &&
      Number(this.selectedAlbum.value) !==
        Number(this.viewProjectService.getProjectId())
    ) {
      this.onSubmitDropdown();
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onSubmitDropdown() {
    let currentSelectedProjectData: any = {};
    this.viewProjectService.updateLeftMenuState(true);
    if (this.selectedAlbum.value === -1) {
      this.alertCustom('Please select valid project');
      this.selectedProject = null;
    } else {
      this.launcherService.setIsGallaryModeStatus(false);
      const value = this.selectedAlbum.value;
      this.userService.resetGalleryData();
      this.viewProjectService.setIsSessionAcceptedFromGallery(false);
      this.viewProjectService.resetProjectID();
      this.launcherService.setSelectedProject(value);

      this.resetAuditConfig();
      let selectedGroup = '';
      const selectedRole = this.groupedProject.find((x) => {
        const selectedItem = x.items.find((y) => {
          if (y.value === value) {
            if (
              this.selectedProject.toString() !== '3' &&
              this.selectedProject.toString() !== '4'
            ) {
              this.launcherService.setSelectedDepLogo(y.logo);
            } else {
              this.launcherService.setSelectedDepLogo('');
            }
            selectedGroup = x.label;
            this.launcherService.setSelectedProjectName(y.label);
            currentSelectedProjectData = y;
            return true;
          } else {
            return false;
          }
        });

        if (selectedItem) {
          if (selectedItem.value === value) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });

      this.viewProjectService.setProjectID(this.selectedProject);
      this.selectedImagesStore.clear();
      this.viewProjectService.setSelectedProjectData(
        currentSelectedProjectData,
      );

      this.dashboardEventBus.emit(
        new DashboardSelectProjectEvent(
          new DashboardSelectProjectHandler(
            currentSelectedProjectData,
            this.selectedProject,
            selectedRole,
            this.selectedAlbum,
          ),
        ),
      );
      this.groupedProject = JSON.parse(JSON.stringify(this.groupedProject));
    }
  }

  getProjectSelectPlaceholder() {
    return this.selectedProject ? '' : this.PLACE_HOLDER;
  }

  selectedItems(event: any) {
    if (event && event.value) {
      // FIXME: ERROR TypeError: Cannot read property 'value' of undefined
      this.selectedAlbum = event;
    }
  }

  resetAuditConfig() {
    this.applicationSettings.setBypass2ndtagger({ loaded: false });
    this.applicationSettings.setNoConflicts({ loaded: false });
    this.applicationSettings.setShowAll({ loaded: false });
    this.dialogService.closeDialogById(ConflictsComponent.identifier);
  }

  onClickPhoneIcon() {
    this.dialogService.open(ContactDetailsComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
    });
    this.isContactDetailsShown = true;
  }

  closeContactWindow(evt: boolean) {
    this.isContactDetailsShown = evt;
  }

  hasNewFeatureAnnounce(): boolean {
    return this.newFeatureAnnounce?.announces?.length > 0;
  }

  openAppUpdatesPopup(): void {
    if (this.hasNewFeatureAnnounce()) {
      this.appUpdatesService.setNewFeatureAnnounceVersion(
        this.currentAppVersion,
        true,
      );
      this.popupService.open(
        AppUpdatesComponent,
        this.appUpdatesPopup.nativeElement,
        {
          position: 'below',
          hasBackdrop: true,
          closeOnBackdropClick: true,
          data: {
            newFeatureAnnounce: this.newFeatureAnnounce,
          },
          panelClass: [
            'fs-popup-base-panel',
            'fs-popup-sm-panel',
            'fs-app-update-popup-panel',
          ],
        },
      );
    }
  }

  onClickHelpIcon() {
    this.popupService.open(HelperDialogComponent, this.helpIcon.nativeElement, {
      panelClass: ['fs-popup-base-panel'],
      position: 'left',
      withArrow: true,
      closeOnBackdropClick: true,
      hasBackdrop: true,
      data: {
        projectAuthority: this.projectAuthority,
      },
    });
  }

  notification(item) {
    let ids = this.cookieService.getCookie('notificationArray');
    if (ids) {
      const array = ids.split(',');
      const index = array
        .map(function (e) {
          return e;
        })
        .indexOf(item.id.toString());

      if (index === -1) {
        ids = ids + ',' + item.id;
        this.cookieService.setCookieIfAllowed('notificationArray', ids);
      }
    } else {
      this.cookieService.setCookieIfAllowed('notificationArray', item.id);
    }
  }

  checkRead(item) {
    const ids = this.cookieService.getCookie('notificationArray');
    const array = ids.split(',');
    const index = array
      .map(function (e) {
        return e;
      })
      .indexOf(item.id.toString());
    if (index !== -1) {
      return 'notification-readed';
    } else {
      return '';
    }
  }

  onCallImDone() {
    if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
      if (!this.projectImageIds) {
        this.router.navigateByUrl('/');
      } else if (this.projectImageIds && !this.projectImageIds.length) {
        this.router.navigateByUrl('/');
      } else {
        const currentUrl = this.previousRouteService.getCurrentUrl();
        if (currentUrl === '/dashboard') {
          this.appStateService.cleanAppState();
          this.router.navigateByUrl('/');
        } else {
          this.dialogService.open(PkoLogoutComponent, {
            panelClass: [
              'fs-dialog-panel',
              'fs-dialog-size-lg-panel',
              'fs-dialog-link-panel',
            ],
          });
        }
      }
    }
  }

  onClickUserAvatar() {
    this.popupService.open(
      UserAvatarMenuPopupComponent,
      this.userAvatarElement.nativeElement,
      {
        panelClass: ['fs-popup-base-panel', 'fs-popup-xs-panel'],
        closeOnBackdropClick: true,
        hasBackdrop: true,
        position: 'below',
        data: {
          projectAuthority: this.projectAuthority,
          projectImageIds: this.projectImageIds,
          currentUser: this.currentUser,
        },
      },
    );
  }

  private translateExecutiveAlbumLabel(value: number): string {
    switch (value) {
      case 4:
        return this.translate.instant('left-menu.tab-bar.sidemenus.wideeditalbum.title');
      case 3:
        return this.translate.instant('left-menu.tab-bar.sidemenus.signOff.title');
      default:
        return '';
    }
  }
}
