import { Utils } from './../../../../../../../shared/utils/utils';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdministrationService } from '../administration.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Activemodal } from '../../../../../../../shared/models/activemodal.model';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-rejection-toggle',
  templateUrl: './rejection-toggle.component.html',
  styleUrls: ['./rejection-toggle.component.scss'],
})
export class RejectionToggleComponent
  extends UnsubscriberComponent
  implements OnInit
{
  activemodal: Activemodal = new Activemodal();
  tagList: any[] = [];
  selectedName: any;
  selectvalue: any;
  msgpopup = false;
  popupMsg = '';
  allselectedImages: any;
  projectImageData: AssetInfo = new AssetInfo();

  selectedAssestIdList: any = [];
  constantsGlobalData: any;

  currentPageNumber: any;
  projectImageIds: any;
  noOfImages: number;
  projectName: any;
  selectedProjectData: any;

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private administrationService: AdministrationService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<RejectionToggleComponent>,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
          this.projectName = res.projectName;
        }
      });

    this.viewProjectService.selectedProjectDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.selectedProjectData = res;
        }
      });

    this.selectvalue = 'unreject';
    this.selectedAssestIdList = this.selectedImagesStore.getItemsIds();
    const data = {
      assetIds: this.selectedAssestIdList,
      projectId: this.viewProjectService.getProjectId(),
    };

    this.viewProjectService
      .getClearSingleTagsData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((clearSingleTagsData) => {
        this.tagList = clearSingleTagsData;
      });
  }

  execute() {
    this.currentPageNumber =
      this.viewProjectService.getCurrentPageNumberClone();

    const obj = {
      action: this.selectvalue,
      assetIds: this.selectedAssestIdList,
      projectId: this.viewProjectService.getProjectId(),
      tagId: this.selectedName.id,
      currentAssetIds: Utils.getImageIdsBasedOnPage(
        this.currentPageNumber,
        this.noOfImages,
        this.projectImageIds,
      ),
      projectName: this.projectName,
      useSetup:
        this.selectedProjectData && this.selectedProjectData.useSetup
          ? this.selectedProjectData.useSetup
          : false,
    };

    this.loaderService.displayLoader(true);
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    this.administrationService
      .putAssetsToggle(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loaderService.displayLoader(false);

          res.forEach((element) => {
            const index = this.projectImageData.assetInfoDTOList
              .map(function (e) {
                return e.id;
              })
              .indexOf(element.assetId);
            this.projectImageData.assetInfoDTOList[index].approvedBy =
              element.approvedBy;
            this.projectImageData.assetInfoDTOList[index].excludedFrom =
              element.excludedFrom;
            this.projectImageData.assetInfoDTOList[index].rejectedBy =
              element.rejectedBy;
            this.projectImageData.assetInfoDTOList[index].status =
              element.status;
            if (this.selectedImagesStore.size() === 1) {
              this.selectedImagesStore.set(
                this.projectImageData.assetInfoDTOList[index],
              );
            }
          });
          this.closeWindow();
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  closeWindow() {
    this.selectvalue = 'unreject';
    this.dialogRef.close();
  }
}
