<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{ 'header.announceImgViewerTitle' | translate }}
    {{ data.appAnnounceVersion }}
  </div>

  <button
    type="button"
    class="fs-close-btn w-auto"
    aria-hidden="true"
    (click)="closeWindow()"
  >
    <i class="fa fa-times-circle"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="imreport-divtable">
    <img class="my-2"
         [src]="'assets/images/announces/' + data.announce.announceImgPath | projectAssetsBaseUrl" />
  </div>
</div>
