export class MetadataModel {
  constructor (
    public metaCreator?: string,
    public metaCredit?: string,
    public metaTitle?: string,
    public metaDescription?: string,
    public metaSource?: string,
    public metaCopyrightStatus?: string,
    public metaCopyrightNotice?: string,
    public metaHeadline?: string,
    public metaPersonsShown?: string,
    public metaKeywords?: string,
  ) {
    this.metaCopyrightStatus = metaCopyrightStatus || 'null';
  }
}

export interface MetadataPayloadModel {
  fileName: string;
  batchName: string;
  assetId: number;
  parameterNameToValueMap: ParameterNameToValueModel;
}

export interface MetadataTransactionDetails {
  ip: string;
  browserInfo: string;
  userName: string;
  authorityName: string;
  userId: string;
}

export interface ParameterNameToValueModel {
  Creator: string;
  Credit: string;
  Title: string;
  Description: string;
  Source: string;
  CopyrightFlag: string;
  CopyrightNotice: string;
  Headline: string;
  PersonInImage: string;
  Keywords: string;
}
