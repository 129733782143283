import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../../shared/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AssetErrorKeys } from '../../../../../../shared/enum/asset-error-keys.enum';

@Injectable()
export class ImageInfoService {
  private whichImageTabSubject = new BehaviorSubject<any>([]);
  whichImageTabGlobal = this.whichImageTabSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
  ) {}

  callCreateAssetsNotes(data): Observable<any> {
    const path = AppSettings.CREATE_ASSET_NOTE;
    return this.apiService
      .post(path, data)
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  getUserData(projectId: number, userIds: number[]): Observable<any> {
    const path = AppSettings.USER_DETAILS(projectId) + userIds;
    return this.apiService.get(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  setImageTab(tab) {
    this.whichImageTabSubject.next(tab);
  }

  getImageTab() {
    return this.whichImageTabSubject.value;
  }

  callDeleteNote(projectId, data): Observable<any> {
    const path = AppSettings.DELETE_NOTE(projectId, data.id);
    return this.apiService.delete(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
