import { Utils } from './../../../../../../shared/utils/utils';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AdhocService } from './adhoc.service';
import { Activemodal } from '../../../../../../shared/models/activemodal.model';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { Modalstatusservice } from '../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ITreeOptions, TreeComponent, TreeModel, TreeNode } from '@bugsplat/angular-tree-component';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-adhoc',
  templateUrl: 'adhoc.component.html',
  styleUrls: ['./adhoc.component.scss'],
  providers: [AdhocService],
})
export class AdhocComponent extends UnsubscriberComponent implements OnInit {
  @ViewChild('tree') treeComponent: TreeComponent;
  adhocNode = [];
  isClickOnAlbumName = false;
  isEnabaleToAddActiveAlbumFolder = false;
  isClickOnAlbumFolder = false;
  indexAlbumName: number;
  indexAlbumFolder: number;
  projectId = '';
  activeAlbumFolder: any;
  isEnlargeView = false;
  isAlbumActive = false;
  activemodal: Activemodal = new Activemodal();
  options1: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'id',
    allowDrag: false,
  };

  constantsGlobalData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sidebarService: SidebarService,
    private modalstatusservice: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private adhocService: AdhocService,
    private translate: TranslateService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogService: DialogService,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
      }
    });

    this.modalstatusservice.getActiveModal
      .pipe(takeUntil(this.destroy$))
      .subscribe((activeModal) => {
        this.handaleModalStatus(activeModal);
      });

    this.sidebarService.updateTaggingCustomAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.updateAlbumInNode1(album);
        }
      });

    this.viewProjectService.projectIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        if (id !== '') {
          if (this.projectId === '') {
            this.projectId = id;
            this.callAlbumDataAPI();
          } else if (this.projectId !== id) {
            this.projectId = id;
            this.callAlbumDataAPI();
          }
        }
      });

    const temp = {
      id: 0,
    };
    this.activeAlbumFolder = temp;
  }

  callAlbumDataAPI() {
    this.adhocService
      .getAdhocAlbums(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (Object.keys(res).length !== 0) {
          this.adhocNode = [];
          this.treeComponent.treeModel.update();
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  handaleModalStatus(activeModal: any) {
    switch (activeModal.modalName) {
      case this.constantsGlobalData.MODELNAMES.LINK:
        if (activeModal.modalData) {
          this.setAlbumInNode(activeModal.modalData.t);
        }
        break;
      default:
    }
  }

  setAlbumInNode(newAlbumData) {
    this.adhocNode.push(newAlbumData);
    this.treeComponent.treeModel.update();
    this.isEnabaleToAddActiveAlbumFolder = true;
    this.isClickOnAlbumFolder = true;
  }

  onClickAlbum(index) {
    this.indexAlbumFolder = index;
    this.isClickOnAlbumFolder = !this.isClickOnAlbumFolder;
    this.isEnabaleToAddActiveAlbumFolder = true;
    if (!this.isClickOnAlbumFolder) {
      this.resetSelectedAlbum();
    }
  }

  onClickAlbumName(index) {
    this.indexAlbumName = index;
    this.isEnabaleToAddActiveAlbumFolder = false;
    this.isClickOnAlbumName = true;
  }

  onEvent(event: any) {
    if (event.eventName === 'deactivate') {
      if (this.isClickOnAlbumName) {
        this.treeComponent.treeModel.getFocusedNode().blur();
        if (this.indexAlbumName === this.indexAlbumFolder) {
          this.treeComponent.treeModel
            .getNodeById(event.node.data.id)
            .toggleActivated();
        }
      }
      if (this.isEnabaleToAddActiveAlbumFolder) {
        this.isClickOnAlbumFolder = false;
      }
    }
    if (event.eventName === 'activate') {
      if (this.isClickOnAlbumName) {
        const albumdata = event.node.data;
        if (parseInt(albumdata.assetCount, 10) === 0) {
          this.alertCustom(this.translate.instant('view-project.alert2'));
        } else {
          const obj = {
            projectId: this.projectId,
            albumId: albumdata.id,
          };
          this.loaderService.displayLoader(true);
          this.adhocService
            .getAlbumImageData(obj)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              if (this.isEnlargeView) {
                this.router.navigate([
                  Utils.prepareFilterNavigationURL(
                    this.viewProjectService.getProjectRole(),
                    this.viewProjectService.getProjectId(),
                    albumdata.id,
                    1,
                  ),
                ]);
              }
              this.selectedImagesStore.clear();
              this.sidebarService.setAlbumImageData(res);
              this.loaderService.displayLoader(false);
            },(error) => {
              this.apiErrorHandlerService.getHandler().handle(error);
              this.loaderService.displayLoader(false);
            });
          this.sidebarService.setSelectedAlbumName(albumdata);
        }
        this.isClickOnAlbumName = false;
        if (this.activeAlbumFolder.id !== 0) {
          this.treeComponent.treeModel
            .getNodeById(this.activeAlbumFolder.id)
            .setActiveAndVisible();
        } else {
          this.treeComponent.treeModel
            .getNodeById(albumdata.id)
            .toggleActivated();
        }
      } else {
        if (this.isEnabaleToAddActiveAlbumFolder) {
          this.isClickOnAlbumFolder = true;
          this.activeAlbumFolder = event.node.data;
          this.sidebarService.setSelectedAlbum(event.node.data);
        }
      }
    }
  }

  updateAlbumInNode1(album) {
    if (album.status) {
      const treeModel: TreeModel = this.treeComponent.treeModel;
      const activateNode: TreeNode = treeModel.getActiveNode();
      if (activateNode) {
        if (album.status) {
          activateNode.data.assetCount = album.message;
        }
        this.treeComponent.treeModel.update();
        this.sidebarService.setUpdatedAlbumList(treeModel.nodes);
      }
    } else {
      this.alertCustom(album.message);
    }
  }

  onClickAdhocTab() {
    this.isAlbumActive = !this.isAlbumActive;
    if (!this.isAlbumActive) {
      this.resetSelectedAlbum();
      const node = this.treeComponent.treeModel.getActiveNode();
      if (node) {
        this.treeComponent.treeModel
          .getNodeById(node.data.id)
          .toggleActivated();
      }
    }
  }

  resetSelectedAlbum() {
    this.sidebarService.setDeSelectedAlbum({});
    const temp = {
      id: 0,
    };
    this.activeAlbumFolder = temp;
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
