import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewsettingsService } from './viewsettings.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectDetailPermission } from '../../../../../shared/models/projectpermission.model';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { viewsettingconfig } from '../../../../../shared/config/viewsettingconfig';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../shared/utils/utils';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-viewsettings',
  templateUrl: './viewsettings.component.html',
  styleUrls: ['./viewsettings.component.scss'],
})
export class ViewsettingsComponent
  extends UnsubscriberComponent
  implements OnInit
{
  static identifier = 'ViewsettingsComponent';
  selectedProjectValue: any;
  selectedSizes: any;
  selectedImages: any;
  selectedColumns: any;
  imageSize: Array<object>;
  dataImages: Array<object>;
  dataColumns: Array<object>;
  global = false;
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  constantsGlobalData: any;

  constructor(
    private viewSettingsService: ViewsettingsService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private launcherService: LauncherService,
    private dialogRef: MatDialogRef<ViewsettingsComponent>,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.imageSize = viewsettingconfig.size;
    this.dataImages = viewsettingconfig.imagePerPage;
    this.dataColumns = viewsettingconfig.columnPerPage;
    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.selectedColumns = data.viewSettingDTO.columnPerPage;
          this.selectedImages = data.viewSettingDTO.imagePerPage;
          this.selectedSizes = data.viewSettingDTO.size;
        }
      });
  }

  setViewSettings(size, images, columns): void {
    this.loaderService.displayLoader(true);
    const newViewSettings = this.viewSettingsService.defaultViewSettings(
      columns,
      images,
      size,
      this.global,
    );
    this.viewSettingsService
      .setViewSettingsApiCall(newViewSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.dialogRef.close(newViewSettings);
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeViewSetting();
  }

  closeViewSetting() {
    this.dialogRef.close();
  }
}
