import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigKey } from '../../../../appSettings/key-config';
import { LauncherService } from '../../../launcher/launcher.service';
import { combineLatest, concatMap, fromEvent, Subject } from 'rxjs';
import { ImageInfoService } from '../../dashboard-shared/left-menu/menus/imageinfo/imageinfo.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { NGXLogger } from 'ngx-logger';
import { SnackBarService } from '../../../../shared/services/snack-bar.service';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { CustomAlbumComponent } from './custom-album/custom-album.component';
import { ImageHistoryComponent } from './image-history/image-history.component';
import { ImageTransactionComponent } from './image-transaction/image-transaction.component';
import { BookMarkComponent } from './book-mark/bookmark.component';
import { AlbumColorTagsService } from '../../../../shared/services/album-color-tags.service';
import { ImageVideoComponent } from './image-video/image-video.component';
import { TopmenusService } from '../../dashboard-shared/top-menu/top-menu.service';
import { AppStateService } from '../../../../shared/services/app-state.service';
import { ViewsettingsComponent } from '../../dashboard-shared/top-menu/viewsettings/viewsettings.component';
import { GotoComponent } from '../../dashboard-shared/top-menu/goto/goto.component';
import { map, take, takeUntil } from 'rxjs/operators';
import { UserRole } from '../../../../shared/enum/user-role.enum';
import {
  AssetInfo,
  AssetInfoDTOList,
  AssetSoloGroupModel,
} from '../../../../shared/models/assetInfo.model';
import { SelectedImages } from '../../../../shared/models/selectedImages.model';
import { ProjectDetailPermission } from '../../../../shared/models/projectpermission.model';
import { Activemodal } from '../../../../shared/models/activemodal.model';
import { UserDetails } from '../../../../shared/models/userdetails.model';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { SidebarService } from '../../sidebar-service/sidebar-service';
import { Modalstatusservice } from '../../../../shared/services/modalstatus.service';
import { AuthServerProvider } from '../../../../shared/services/auth.jwt.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { TaggerService } from '../../../../shared/services/tagger.service';
import { PaginationService } from '../../dashboard-shared/pagination/pagination.service';
import { AlbumsService } from '../../dashboard-shared/left-menu/menus/albums/albums.service';
import { UserService } from '../../../../shared/services/user.service';
import { PreviousRouteService } from '../../../../shared/services/previous-route.service';
import { GroupService } from './group/group-service';
import { UserDataService } from '../../../../shared/services/userdata.service';
import { Utils } from '../../../../shared/utils/utils';
import { PopupService } from '../../../../shared/services/popup/popup.service';
import { ConstantsGlobal } from '../../../../shared/constants/constants';
import { GroupComponent } from './group/group-component';
import { Designation } from './designation/designation';
import { GroupSingleComponent } from './group-single/group-single.component';
import { DomainConfigService } from '../../../../shared/services/domain-config.service';
import { SelectedImagesStore } from '../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../shared/store/last-selected-image-store';
import { ImageNotesDialogComponent } from '../../dashboard-shared/left-menu/menus/imageinfo/image-info-dialog/image-notes-dialog.component';
import { AdvanceSearchService } from '../../dashboard-shared/left-menu/advancesearch/advancesearch.service';
import { SingleExcludeService } from '../../../../shared/services/single-exclude.service';
import { SessionTypeEnum } from '../../../../shared/enum/session-type.enum';
import { ViewGalleryResumeModalStateService } from '../../../../shared/services/view-gallery-resume-modal.service';
import { tap } from 'rxjs/internal/operators/tap';
import { DialogsStateService } from '../../../../shared/services/dialog/dialogs-state.service';
import { FilterEnum } from '../../../../shared/enum/filter.enum';
import { ViewGalleryService } from '../view-gallery/view-gallery.service';
import { ImageGridSizeType } from '../../../../shared/models/enum/image-grid-size-type';
import { ViewSettingsSize } from '../../../../shared/models/enum/view-settings-size';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';
import {
  OverlayContainerProvider,
  OverlayProvider,
} from '../../../../shared/services/overlay/overlay.providers';
import { AppSource } from '../../../../shared/models/enum/AppSource';
import { AssetType } from '../../../../shared/models/asset-type.model';
import { AppConfig } from '../../../../app.configuration';
import { DownloadService } from '../../dashboard-shared/top-menu/download/download.service';
import { ProjectRole } from '../../../../shared/enum/project-role.enum';
import { ApiErrorHandlerService } from '../../../../shared/services/api-error-handler.service';

enum LeftMenu {
  DeactivateAll = -1,
  ActivatePanel = 0,
  SetImage = 1,
  DoNothing = 2,
  SignOff = 3,
  WideEditOrSignOff = 4,
  WideEdit = 5,
}

@Component({
  selector: 'fs-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.scss'],
  providers: [
    AdvanceSearchService,
    OverlayContainerProvider,
    OverlayProvider,
    PopupService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewProjectComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild('scrollportion', { read: ElementRef }) scrollportion: ElementRef;
  @ViewChild('scrolling') scrolling: ElementRef;
  @ViewChild('galleryList') galleryList: ElementRef;
  @ViewChild('horizontalscroll') horizontalscroll: ElementRef;
  @ViewChild('sectionToDrag') sectionToDrag: ElementRef;
  addNoteIcon: HTMLElement;
  assetType = AssetType;
  userRole = UserRole;
  projectRole = ProjectRole;

  projectImageData: AssetInfo = new AssetInfo();
  lastSelectedImage2: SelectedImages[] = [];
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  activemodal: Activemodal = new Activemodal();
  currentUser: UserDetails = new UserDetails();
  lastIImageSelectedIndex: number;
  lastSelectedImage2find: any;
  isLeftMenuPartShown: boolean;
  isLeftAlbumActive = false;
  activatedLeftProjectAlbum = false;
  columnsNumber;
  isLeftSignOffAlbumActive = false;
  isLeftWideEditAlbumActive = false;
  selectedLeftAlbum: any;
  selectedLeftAlbumKeys: any;
  selectedLeftProjectAlbumKeys: any;
  isModelWindowOpen = false;
  hoverAlbum: any;
  hoverAlbumClicked: any;
  hoverAlbumDataClicked: any;
  isTaggingInProgress = false;

  pendingRequest: any;
  currentPageNumber = 1;

  projectId: any;
  domainObjAbbr = '';
  appSource = AppSource;

  currentIndex: number;
  count = 0;
  // Shift Range from array index to array index
  clickRangeArr = [];

  isTaggingView1Active = false;
  isTaggingView2Active = false;
  isConflictsActive: boolean;
  isNoConflictsActive: boolean;
  isByPass2ndTaggerActive: boolean;

  selectedImageId: any;
  isGPopupShown: any = false;

  // Image Loading flag
  imageLoaded: boolean[] = [];
  totalPages: number;
  noOfImages = 0;
  noOfColum = 6;

  // ghost image
  getImgSizeOnViewProject: any;
  setGhostWidth: number;
  setGhostHeight: number;
  boxWidth = 0;
  parentInnerWidth = 100 + '%';
  isImageAuto = false;

  timer: any;
  paginationTime: any;
  preventSingleClickTimer: any;
  preventSingleClick = false;

  restartDataDTO: any;
  hasDownloadAccess = false;
  isEnlargeView = false;

  // project component
  imageDim = 0;

  isDesignationPopupShow = false;
  isGroupSinglePopupShown = false;
  exicutiveAssetIdforToggle: number;

  groupImageId: any;
  groupedData: any;

  executiveAlbumID;
  isExecutiveLoad = false;
  selectedProjectValue: any;
  token: any;
  isExecutiveAlbumView = false;
  userDataInfo: any;
  currentFilter = 'showall';
  isDragover = true;

  isAlbumActivated = false;
  blockedHover = false;

  mouseCount = 1;
  isClickedOnViewNoteFromViewProject = false;
  // Load setup
  isGallaryMode = false;
  isFavGallaryMode = false;
  setUpID: any;

  projectImageIds: any = [];
  ImageIDsPerPage = [];
  isClickedOnAddNote = false; // todo remove this
  currentImageID: any;
  objToViewNote: any;
  historyReportType = '';
  constantsGlobalData: any;

  // Project Permission
  priorityPixAccess = false;
  retouchAccess = false;
  showFinalizationAccess = false;
  captionAccess = false;
  albumList: any;
  albumData: any;
  selectedImages2: SelectedImages[] = this.selectedImagesStore.getAll();
  // Scroll
  verticalScrollToppos: any;
  horiZontalScrollPos: any;
  totalScrollHeight: any;

  // Talent
  priritypixImageCount: number;
  talentFirstTimePriorityCount: boolean;
  talentUseSetup = false;
  projectAuthority: string;
  userAuthority: string;
  gototSearchShowallImageID: any = null;
  isRestartFistTime = true;
  deleteAssets = false;
  taggingView1Open: boolean;
  taggingView2Open: boolean;
  isTaggerRole: boolean;
  isUserApprovedImage = false;
  readonly IMAGE_GRID_GAP: number = 329;
  private tagRequestSubject: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private modalstatusservice: Modalstatusservice,
    private topmenusService: TopmenusService,
    private auth: AuthServerProvider,
    private loaderService: LoaderService,
    private downloadService: DownloadService,
    private launcherService: LauncherService,
    private snackBarService: SnackBarService,
    private taggerService: TaggerService,
    private paginationService: PaginationService,
    private translate: TranslateService,
    private albumsService: AlbumsService,
    private userService: UserService,
    private previousRouteService: PreviousRouteService,
    private groupService: GroupService,
    private imageInfoService: ImageInfoService,
    private userDataService: UserDataService,
    private logger: NGXLogger,
    private dialogService: DialogService,
    public albumColorTagsService: AlbumColorTagsService,
    private appStateService: AppStateService,
    private popupService: PopupService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private domainConfigService: DomainConfigService,
    public selectedImagesStore: SelectedImagesStore,
    public lastSelectedImageStore: LastSelectedImageStore,
    private ref: ChangeDetectorRef,
    private singleExcludeService: SingleExcludeService,
    private viewGalleryService: ViewGalleryService,
    private viewGalleryResumeModalService: ViewGalleryResumeModalStateService,
    private dialogSateService: DialogsStateService,
  ) {
    super();
    this.token = this.auth.getToken();
    this.count = 0;
    this.updateAssetTagging();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);
    this.isTaggerRole = this.userService.isAdminWithTaggerRole();

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftMenuState) => {
        this.isLeftMenuPartShown = leftMenuState;
      });

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData) => {
        if (domainData) {
          this.domainObjAbbr = domainData.abbr;
        }
      });

    this.topmenusService.setTopMenuStatus('ViewProject');
    this.paginationService.setPaginationTempalte('ViewProject');

    this.viewProjectService.isAddNoteFromAddNoteGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.isClickedOnAddNote = response;
      });

    this.viewProjectService.restartDataDTO
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.restartDataDTO = data;
      });

    this.viewProjectService.isModelPopupWindowOpen
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.isModelWindowOpen = response;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.noOfImages = data.viewSettingDTO
            ? data.viewSettingDTO.imagePerPage
            : undefined;
          this.showFinalizationAccess =
            data.permissionDTO.showFinalizationAccess;
          if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
            this.talentUseSetup = data.useSetup;
          }
        }
      });

    this.viewProjectService.priorityPixImageCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.priritypixImageCount = res;
      });

    this.viewProjectService.talentFirstTimePriorityCountGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((flage) => {
        this.talentFirstTimePriorityCount = flage;
      });

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
        this.paginationService.setPaginationLastIndexGlobal(
          Math.ceil(this.projectImageIds.length / this.noOfImages),
        );
      });

    this.launcherService.isGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isGallaryMode) => {
        this.isGallaryMode = isGallaryMode;
      });

    this.launcherService.isFavGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isFavGallaryMode) => {
        this.isFavGallaryMode = isFavGallaryMode;
      });

    this.launcherService.selectedSetUpIdGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((setUpID) => {
        this.setUpID = setUpID;
      });

    this.lastSelectedImageStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageArray) => {
        this.lastSelectedImage2 = imageArray;
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isEnlargeView =
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1;
      }
    });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((parameters) => {
      this.currentPageNumber = Number(parameters.pageNumber);
      this.paginationService.setPaginationIndex(this.currentPageNumber);
      this.currentFilter = parameters.filterId;
      this.viewProjectService.setCurrentFilter(this.currentFilter);
      this.viewProjectService.setCurrentPageNumber(this.currentPageNumber);

      if (parameters.projectId && parameters.projectId !== 'undefined') {
        this.projectId = parameters.projectId.slice(
          1,
          parameters.projectId.length,
        );
        this.viewProjectService.setProjectID(this.projectId);
        this.viewProjectService.setProjectRole(
          parameters.projectId.slice(0, 1),
        );
      }
      this.viewSettingImage();
    });

    this.viewProjectService.currentFilterGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentFilterName) => {
        this.currentFilter = currentFilterName;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.paginationService.paginationIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (index === -1) {
          this.onClickLastPage();
        } else if (index > 0) {
          this.onPageChange(index);
        }
      });

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        this.selectedLeftAlbum = album;
      });

    this.sidebarService.selectedAlbumKeys
      .pipe(takeUntil(this.destroy$))
      .subscribe((albums) => {
        this.selectedLeftAlbumKeys = albums;
      });

    this.sidebarService.projectAlbumKeysGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((albums) => {
        this.selectedLeftProjectAlbumKeys = albums;
      });
    // selectedLeftProjectAlbumKeys

    this.sidebarService.hoverAlbumClickedGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((hoverAlbumsClicked) => {
        if (!Utils.isObjectEmpty(hoverAlbumsClicked)) {
          this.hoverAlbumClicked = hoverAlbumsClicked;
          this.hoverAlbumDataClicked = hoverAlbumsClicked;
        } else {
          const obj = {
            id: 0,
          };
          this.hoverAlbumClicked = obj;
          this.hoverAlbumDataClicked = obj;
        }
      });

    this.sidebarService.hoverAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((hoverAlbums) => {
        if (!Utils.isObjectEmpty(hoverAlbums)) {
          this.hoverAlbum = hoverAlbums;
          const obj = {
            id: 0,
          };
          this.hoverAlbumClicked = obj;
          this.ref.markForCheck();
        } else {
          const obj = {
            id: 0,
          };
          this.hoverAlbum = obj;
          this.hoverAlbumClicked = this.hoverAlbumDataClicked;
        }
      });

    this.sidebarService.albumImageDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.isByPass2ndTaggerActive = false;
        if (!Utils.isObjectEmpty(res)) {
          this.imageLoaded = [];
          this.projectImageData = res;
          this.viewProjectService.setProjectData(this.projectImageData);
          this.albumColorTagsService.updateImageDataWithColorCodes(
            this.projectImageData.assetInfoDTOList,
            this.albumList,
          );
          this.projectData();
          if (this.isExecutiveAlbumView) {
            this.updateExecutiveRejected(res);
          }
          setTimeout(() => {
            this.scrollsectionToTop();
          }, 1000);
        } else {
          this.projectImageData = this.viewProjectService.mergeWithProjectData(
            this.projectImageData,
          );
          if (!this.projectImageData.projectId) {
            this.loadProjectDetailPermission();
          } else {
            this.loadProjectData();
          }
        }
        this.ref.markForCheck();
      });

    this.handleModalStatus();

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumData = albumList;
          this.albumList = Utils.getValuesByKey(albumList.albums, 'id');
          this.albumColorTagsService.updateImageDataWithColorCodes(
            this.projectImageData.assetInfoDTOList,
            this.albumList,
          );
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.projectDetailPermission = data;
        if (!Utils.isObjectEmpty(data)) {
          this.noOfImages = data.viewSettingDTO
            ? data.viewSettingDTO.imagePerPage
            : undefined;
          if (data.permissionDTO) {
            this.hasDownloadAccess = !!data.permissionDTO.downloadType;
            this.priorityPixAccess = data.permissionDTO.priorityPixAccess;
            this.retouchAccess = data.permissionDTO.retouchAccess;
          }
          if (
            this.userAuthority === UserRole.ROLE_ADMIN ||
            this.projectAuthority === ProjectRole.ROLE_EXEC
          ) {
            this.captionAccess = data.permissionDTO.captionAccess;
          }
          if (data.internalUsers) {
            this.callUserDataInfoAPI(data);
          }
        } else {
          this.captionAccess = false;
        }
      });

    this.launcherService.isProjectChangeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status) {
          this.projectImageData = new AssetInfo();
        }
      });

    this.viewProjectService.projectDataGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.imageLoaded = [];
        // TODO for pagination.
        this.projectImageData = data;
        if (!this.deleteAssets) {
          this.viewSettingImage();
        }
        this.ref.markForCheck();
      });

    this.viewProjectService.priorityPixHandling$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.loaderService.displayLoader(true);
          const filter = 'prioritypix';
          this.viewProjectService
            .callProjectImageIDs(this.projectId, filter)
            .subscribe(
              (res1) => {
                if (res1 !== null) {
                  this.currentFilter = filter;
                  this.viewProjectService.setCurrentFilter('prioritypix');
                  this.viewProjectService.setPriorityPixAssetIds(res1);
                  const assetsIds = Utils.getImageIdsBasedOnPage(
                    1,
                    this.noOfImages,
                    res1,
                  );
                  const data = {
                    assetIds: assetsIds,
                    projectId: this.projectId,
                    filter: this.currentFilter,
                  };
                  this.viewProjectService
                    .callAssetsInfoBasedOnAssetsId(data)
                    .subscribe(
                      (resData) => {
                        if (resData.assetInfoDTOList.length > 0) {
                          this.choosePriorityPixData(resData);
                          this.viewProjectService.isPiorityPixDataLoaded.next(
                            true,
                          );
                          this.loaderService.displayLoader(false);
                        } else {
                          this.alertCustom(
                            this.translate.instant('view-project.alert2'),
                          );
                        }
                        this.loaderService.displayLoader(false);
                      },
                      (error) => {
                        this.apiErrorHandlerService.getHandler().handle(error);
                        this.loaderService.displayLoader(false);
                      },
                    );
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant('view-project.alert2'),
                  );
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        }
      });

    this.topmenusService.setTopMenuPagination(true);
    this.viewProjectService.executiveAlbumIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((id) => {
        this.executiveAlbumID = id;
      });

    this.viewProjectService.isExecutiveAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isExecutiveAlbumView = status;
      });

    this.albumsService.userDataInfoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.userDataInfo = data;
      });

    this.viewProjectService.isTaggingView1OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView1Open = status;
      });

    this.viewProjectService.isTaggingView2OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView2Open = status;
      });

    this.viewProjectService.tagginView1DataEnlarge
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isTaggingView1Active = status;
      });

    this.viewProjectService.tagginView2DataEnlarge
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isTaggingView2Active = status;
      });

    this.viewProjectService.isTaggingView1OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isTaggingView1Active = status;
      });

    this.viewProjectService.isTaggingView2OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isTaggingView2Active = status;
      });

    this.viewProjectService.ConflictsEnlarge.pipe(
      takeUntil(this.destroy$),
    ).subscribe((status) => {
      this.isConflictsActive = status;
    });

    this.viewProjectService.deleteAssetGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.deleteAssets = status;
        if (status) {
          this.callUpdatedProjectDetailPermission();
        }
      });

    this.viewProjectService.viewProjectNoteIconClick
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isClickedOnViewNoteFromViewProject = status;
      });

    this.viewProjectService.activatedLeftTabIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (index === LeftMenu.DeactivateAll) {
          this.isLeftAlbumActive = false;
          this.isLeftWideEditAlbumActive = false;
          this.isLeftSignOffAlbumActive = false;
          this.isClickedOnViewNoteFromViewProject = false;
        } else if (index === LeftMenu.ActivatePanel) {
          this.isLeftAlbumActive = true;
        } else if (
          index === LeftMenu.SetImage &&
          !this.isClickedOnViewNoteFromViewProject
        ) {
          this.imageInfoService.setImageTab('data');
        } else if (index === LeftMenu.DoNothing) {
          //**//
        } else if (
          index === LeftMenu.SignOff &&
          this.domainObjAbbr === AppSource.WDTV &&
          (this.isGallaryMode || this.isFavGallaryMode) &&
          this.userAuthority === UserRole.ROLE_EXTERNAL &&
          this.projectAuthority === ProjectRole.ROLE_EXEC
        ) {
          this.isLeftSignOffAlbumActive = true;
        } else if (
          index === LeftMenu.SignOff &&
          this.domainObjAbbr === AppSource.ALL &&
          !(
            (this.isGallaryMode || this.isFavGallaryMode) &&
            this.userAuthority === UserRole.ROLE_EXTERNAL &&
            this.projectAuthority === ProjectRole.ROLE_EXEC
          )
        ) {
          this.isLeftWideEditAlbumActive = true;
        } else if (
          index === LeftMenu.WideEditOrSignOff &&
          (this.isGallaryMode || this.isFavGallaryMode) &&
          this.userAuthority === UserRole.ROLE_EXTERNAL &&
          this.projectAuthority === ProjectRole.ROLE_EXEC
        ) {
          this.isLeftWideEditAlbumActive = true;
        } else if (
          index === LeftMenu.WideEditOrSignOff &&
          !(
            (this.isGallaryMode || this.isFavGallaryMode) &&
            this.userAuthority === UserRole.ROLE_EXTERNAL &&
            this.projectAuthority === ProjectRole.ROLE_EXEC
          )
        ) {
          this.isLeftSignOffAlbumActive = true;
        } else if (
          index === LeftMenu.WideEdit &&
          !(
            (this.isGallaryMode || this.isFavGallaryMode) &&
            this.userAuthority === UserRole.ROLE_EXTERNAL &&
            this.projectAuthority === ProjectRole.ROLE_EXEC
          )
        ) {
          this.isLeftWideEditAlbumActive = true;
        }
      });

    this.viewProjectService.activatedLeftProjectAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.activatedLeftProjectAlbum = status;
      });

    this.viewProjectService.isRedirectedFromEnlargeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.blockedHover = res));

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList, 'id');
          this.albumColorTagsService.updateImageDataWithColorCodes(
            this.projectImageData.assetInfoDTOList,
            this.albumList,
          );
        }
      });

    this.albumColorTagsService.isColorPanelEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status) {
          this.albumColorTagsService.updateImageDataWithColorCodes(
            this.projectImageData.assetInfoDTOList,
            this.albumList,
          );
        }
      });
  }

  ngAfterViewInit() {
    fromEvent(this.scrollportion.nativeElement, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {
        this.getScrollPos(e.target);
      });

    if (!this.lastSelectedImageStore.size()) {
      this.scrollportion.nativeElement.scrollTo({ top: 1 });
    }
  }

  callUserDataInfoAPI(data) {
    const results = [...data.internalUsers, ...data.execUsers];
    this.userDataService
      .getUserData(this.viewProjectService.getProjectId(), results)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) => {
          this.albumsService.setUserDataInfo(value);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  sessionStorageRequire(res) {
    if (this.selectedProjectValue === 3 || this.selectedProjectValue === 4) {
      this.loaderService.displayLoader(true);
      this.snackBarService.showSnackBarWarning(
        this.translate.instant('confirmDialog.resume.rejectMsg'),
        '',
        5000,
      );
      this.chooseDataSet();
    } else {
      if (this.deleteAssets) {
        this.viewProjectService.setDeleteAssetGlobal(false);
        this.viewProjectService.setRestartDataDTO(undefined);
        this.loaderService.displayLoader(true);
        this.snackBarService.showSnackBarWarning(
          this.translate.instant('confirmDialog.resume.rejectMsg'),
          '',
          5000,
        );
        this.chooseDataSet();
      } else {
        if (
          this.projectAuthority === ProjectRole.ROLE_TALENT &&
          this.domainObjAbbr === AppSource.WDTV
        ) {
          this.viewGalleryResumeModalService
            .getDialogActionAndClear()
            .pipe(
              takeUntil(this.destroy$),
              take(1),
              tap((action: SessionTypeEnum) => {
                this.handleDialogActions(action)(res);
                this.viewGalleryResumeModalService.setHandledResumeSession(
                  true,
                );
              }),
            )
            .subscribe(
              () => {},
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        } else {
          this.dialogService
            .openConfirmationDialog({
              message: this.translate.instant('confirmDialog.resume.message'),
              title: this.translate.instant('confirmDialog.resume.title'),
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe((state: boolean) => {
              if (state) {
                this.handleResumeSessionAccept(res);
              } else {
                this.handleResumeSessionReject(true);
              }
            });
        }
      }
    }
  }

  handleDialogActions(type: SessionTypeEnum) {
    return {
      [SessionTypeEnum.Restore]: (res) => this.handleResumeSessionAccept(res),
      [SessionTypeEnum.Continue]: () => this.handleResumeSessionReject(false),
    }[type];
  }

  handleResumeSessionAccept(res) {
    this.loaderService.displayLoader(true);
    this.snackBarService.showSnackBar(
      this.translate.instant('confirmDialog.resume.successMsg'),
    );
    this.viewProjectService.setRestartDataDTO(res.restartDataDTO);
    this.chooseDataSet();
  }

  handleResumeSessionReject(withToast: boolean) {
    this.viewProjectService.setRestartDataDTO(undefined);
    this.loaderService.displayLoader(true);
    if (withToast) {
      this.snackBarService.showSnackBarWarning(
        this.translate.instant('confirmDialog.resume.rejectMsg'),
        '',
        5000,
      );
    }
    this.chooseDataSet();
  }

  clickOnGroupImages({ imgData, event }) {
    this.loaderService.displayLoader(true);
    const clickedImageId = imgData.id;
    const allSelectedImageIDs = this.selectedImagesStore.getItemsIds();
    if (allSelectedImageIDs.indexOf(clickedImageId) >= 0) {
      this.singleExcludeService
        .getSelectedImagesFullAssetsInfo(allSelectedImageIDs)
        .then(
          (res: AssetSoloGroupModel[]) => {
            this.processSingleExcludePopupData(imgData, res, event);
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      // if no, then refresh the service data
      this.selectedImagesStore.set(imgData);
      this.loadLastSelectedImage(imgData.id);

      // and, then send request only for clicked image id
      this.singleExcludeService
        .getSelectedImagesFullAssetsInfo([clickedImageId])
        .then(
          (res: AssetSoloGroupModel[]) => {
            // cal processSingleExcludePopupData after receiving response from BE
            this.processSingleExcludePopupData(imgData, res, event);
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  /**
   * Function to process single Exclude Data receiving from BE
   * @param imgData
   * @param assetInfoDTOList
   */
  processSingleExcludePopupData(
    imgData: any,
    assetInfoDTOList: AssetSoloGroupModel[],
    event,
  ) {
    // todo find imgData interface
    if (this.projectDetailPermission.permissionDTO.releaseExcludeAccess) {
      if (assetInfoDTOList.length > 0) {
        this.handleGroupPopup(assetInfoDTOList, imgData, event);
      } else {
        // if no assetInfoDTOList then refresh service data and handle popup with a single imgData
        this.selectedImagesStore.set(imgData);
        this.loadLastSelectedImage(imgData.id);
        this.handleGroupPopup([imgData], imgData, event);
      }
    }
  }

  handleGroupPopup(selectedImages, imgData, event) {
    if (selectedImages.length === 1) {
      this.selectedImageId = imgData.id;
      this.groupedData = [];
      this.isGPopupShown = true;
      if (this.isGPopupShown === true) {
        const getElemHeight = this.horizontalscroll.nativeElement.offsetHeight;
        this.horizontalscroll.nativeElement.style.height =
          getElemHeight + 150 + 'px';
        this.scrolling.nativeElement.style.height = getElemHeight + 150 + 'px';
      } else {
        this.horizontalscroll.nativeElement.style.height = 'auto';
        this.scrolling.nativeElement.style.height = 'auto';
      }
      this.loaderService.displayLoader(false);
      this.openGroupPopup(imgData, event, this.groupedData);
    } else {
      this.loaderService.displayLoader(true);
      this.viewProjectService
        .getImageGroupData({
          projectId: this.viewProjectService.getProjectId(),
          assetIds: selectedImages.map((v) => v.id),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (resData) => {
            this.groupedData = resData;
            this.selectedImageId = imgData.id;
            this.isGPopupShown = true;
            if (this.isGPopupShown === true) {
              const getElemHeight =
                this.horizontalscroll.nativeElement.offsetHeight;
              this.horizontalscroll.nativeElement.style.height =
                getElemHeight + 150 + 'px';
              this.scrolling.nativeElement.style.height =
                getElemHeight + 150 + 'px';
            } else {
              this.horizontalscroll.nativeElement.style.height = 'auto';
              this.scrolling.nativeElement.style.height = 'auto';
            }
            this.loaderService.displayLoader(false);
            this.openGroupPopup(imgData, event, resData);
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  openGroupPopup(imgData, event, resData) {
    this.popupService.closePopupById$('GroupComponent').subscribe(() => {
      this.popupService.open(GroupComponent, event.target, {
        position: 'below',
        hasBackdrop: false,
        withArrow: false,
        cdkOverlayContainerClass: 'fs-overlay-998',
        data: {
          projectImageIds: this.projectImageIds,
          groupedDataCalculated: resData,
          groupPopupId: imgData.id,
          selectedImageId: this.selectedImageId,
          imgData: imgData,
          isGPopupShown: true,
          noOfImages: this.noOfImages,
        },
        id: 'GroupComponent',
        panelClass: [
          'fs-popup-group-panel',
          'fs-popup-base-panel',
          'fs-popup-width-210-panel',
        ],
      });
    });
  }

  // TODO:
  loadProjectDetailPermission() {
    this.projectDetailPermission =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailPermission,
      );
    if (Object.keys(this.projectDetailPermission).length) {
      this.handleProjectDetailPermission(this.projectDetailPermission);
    } else {
      this.loaderService.displayLoader(true);
      this.viewProjectService
        .callProjectDetailPermissionAPI(
          this.viewProjectService.getProjectId(),
          this.viewProjectService.getProjectRole(),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.viewProjectService.setCurrentProjectAuthority(
              this.userService.getCurrentUser().authorities,
            );
            if (
              this.viewProjectService.getCurrentProjectAuthority() ===
                ProjectRole.ROLE_TALENT &&
              res.talentProjectInfoDTO &&
              res.talentProjectInfoDTO.welcomeMessage &&
              res.talentProjectInfoDTO.welcomeMessage !== ''
            ) {
              this.loaderService.displayLoader(false);
              this.dialogService.openInformationDialog({
                title: this.translate.instant('view-project.confirm22'),
                message: res.talentProjectInfoDTO.welcomeMessage,
              });
              this.userService.setUserAuthority(UserRole[res.authorities[0]]);
              this.viewProjectService.setProjectDetailPermissionData(res);
              this.loaderService.displayLoader(false);
              this.handleProjectDetailPermission(res);
            } else {
              this.userService.setUserAuthority(UserRole[res.authorities[0]]);
              this.viewProjectService.setProjectDetailPermissionData(res);
              this.loaderService.displayLoader(false);
              this.handleProjectDetailPermission(res);
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  callUpdatedProjectDetailPermission() {
    this.loaderService.displayLoader(true);
    this.viewProjectService
      .callProjectDetailPermissionAPI(
        this.viewProjectService.getProjectId(),
        this.viewProjectService.getProjectRole(),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.callAlbumDataAPI();
          this.viewProjectService.setCurrentFilter(FilterEnum.ShowAll);
          this.handleViewProjectRouting();
          this.viewProjectService.setCurrentPageNumber(1);
          this.paginationService.setPaginationIndex(1);
          this.viewProjectService.resetProjectData();
          this.viewProjectService.setCurrentProjectAuthority(
            this.userService.getCurrentUser().authorities,
          );
          this.userService.setUserAuthority(UserRole[res.authorities[0]]);
          this.viewProjectService.setProjectDetailPermissionData(res);
          this.loaderService.displayLoader(false);
          this.handleProjectDetailPermission(res);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  callAlbumDataAPI() {
    this.albumsService
      .getAlbumGroupInfo(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (Object.keys(res).length !== 0) {
            this.sidebarService.setAlbumList(res);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  handleProjectDetailPermission(res: any) {
    this.noOfImages = res.viewSettingDTO
      ? res.viewSettingDTO.imagePerPage
      : undefined;
    if (this.projectAuthority === ProjectRole.ROLE_ALBUMVIEWER) {
      this.callAlbumViewerData();
    } else if (this.isGallaryMode || this.isFavGallaryMode) {
      if (
        this.viewProjectService.getIsSessionAcceptedFromGallery() &&
        this.isResumeSessionFromGallery()
      ) {
        this.handleResumeSessionAccept(res);
      } else {
        this.chooseDataSet();
      }
    } else {
      if (
        this.projectAuthority === ProjectRole.ROLE_TALENT &&
        !this.showFinalizationAccess
      ) {
        if (res.talentProjectInfoDTO.currentBatch === 0) {
          this.alertCustom(this.translate.instant('view-project.alert2'));
          this.loaderService.displayLoader(false);
          return;
        } else {
          this.viewProjectService.isPiorityPixDataLoaded.next(false);
          this.viewProjectService.priorityPixImageCount.next(
            res.priorityPixCount,
          );
          if (
            res.restartDataDTO.pageNo === 1 &&
            res.restartDataDTO.assetId <= 0 &&
            res.restartDataDTO.filter === FilterEnum.ShowAll
          ) {
            this.chooseDataSet();
          } else if (
            res.restartDataDTO.pageNo >= 1 &&
            res.restartDataDTO.assetId >= 0 &&
            !this.viewGalleryResumeModalService.getHandledResumeSession()
          ) {
            this.viewProjectService.setRestartDataDTO(res.restartDataDTO);
            this.loaderService.displayLoader(false);

            this.sessionStorageRequire(res);
          } else {
            this.chooseDataSet();
          }
        }
      } else {
        if (res.restartDataDTO.filter === '') {
          this.loaderService.displayLoader(true);
          this.snackBarService.showSnackBarWarning(
            this.translate.instant('confirmDialog.resume.rejectMsg'),
            '',
            5000,
          );
          this.chooseDataSet();
          this.viewProjectService.isPiorityPixDataLoaded.next(false);
          return true;
        }
        if (
          res.restartDataDTO.assetId < 0 &&
          this.userAuthority === UserRole.ROLE_EXTERNAL
        ) {
          this.loaderService.displayLoader(true);
          this.snackBarService.showSnackBarWarning(
            this.translate.instant('confirmDialog.resume.rejectMsg'),
            '',
            5000,
          );
          this.chooseDataSet();
          this.viewProjectService.isPiorityPixDataLoaded.next(false);
          return true;
        }
        if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
          this.chooseDataSet();
        } else if (
          this.userAuthority === UserRole.ROLE_ADMIN ||
          this.projectAuthority === ProjectRole.ROLE_EXEC
        ) {
          // Refecture logic
          if (
            res.restartDataDTO.pageNo === 1 &&
            res.restartDataDTO.assetId <= 0 &&
            res.restartDataDTO.filter === FilterEnum.ShowAll
          ) {
            this.viewProjectService.setRestartDataDTO(undefined);
            this.chooseDataSet();
          } else if (res.restartDataDTO.pageNo >= 1) {
            this.loaderService.displayLoader(false);
            this.sessionStorageRequire(res);
          } else {
            this.viewProjectService.setRestartDataDTO(res.restartDataDTO);
            this.chooseDataSet();
          }
        }
      }
    }
  }

  isResumeSessionFromGallery() {
    return (
      (this.userAuthority === UserRole.ROLE_ADMIN ||
        this.projectAuthority === ProjectRole.ROLE_EXEC) &&
      this.selectedProjectValue !== 3 &&
      this.selectedProjectValue !== 4
    );
  }

  choosePriorityPixData(res) {
    this.projectImageData = res;
    this.viewProjectService.setProjectData(res);
    this.viewProjectService.setMainProjectData(res);
    this.projectData();
    this.viewProjectService.setTotalImageCount(res.assetInfoDTOList.length);
  }

  chooseDataSet() {
    const preURL = this.previousRouteService.getPreviousUrl();
    if (!this.isExecutiveLoad && preURL.indexOf('executive') !== -1) {
      if (this.viewProjectService.getExecutiveAlbumID() !== '') {
        this.isExecutiveLoad = true;
        this.loadExecutiveAlbumData(
          this.viewProjectService.getExecutiveAlbumID(),
        );
      } else {
        this.isExecutiveLoad = false;
        this.loadProjectData();
      }
    } else {
      this.isExecutiveLoad = false;
      if (this.isGallaryMode || this.isFavGallaryMode) {
        if (this.restartDataDTO && this.isResumeSessionFromGallery()) {
          this.viewProjectService.assignFilter(
            this.restartDataDTO.filter
              ? this.restartDataDTO.filter
              : FilterEnum.ShowAll,
          );
        } else {
          this.callAssetsID();
        }
      } else {
        if (this.restartDataDTO) {
          this.viewProjectService.assignFilter(
            this.restartDataDTO.filter
              ? this.restartDataDTO.filter
              : FilterEnum.ShowAll,
          );
        } else {
          this.callAssetsID();
        }
      }
    }
  }

  callAssetsID() {
    let filterUrl;
    if (
      (this.isGallaryMode || this.isFavGallaryMode) &&
      this.selectedProjectValue !== 3 &&
      this.selectedProjectValue !== 4
    ) {
      filterUrl = 'batch:' + this.setUpID;
    } else {
      filterUrl = this.currentFilter;
    }
    this.loaderService.displayLoader(true);
    this.pendingRequest = this.viewProjectService
      .callProjectImageIDs(this.projectId, filterUrl)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res) {
            this.projectImageIds = res;
            this.ImageIDsPerPage = Utils.getImageIdsBasedOnPage(
              this.currentPageNumber ? this.currentPageNumber : 1,
              this.noOfImages,
              this.projectImageIds,
            );
            setTimeout(() => {
              this.loadProjectDataWithAssetIds();
            }, 200);
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  loadProjectDataWithAssetIds() {
    if (this.projectImageData && Object.keys(this.projectImageData).length) {
      this.projectData();
    } else {
      if (this.pendingRequest) {
        this.pendingRequest.unsubscribe();
      }
      const data = {
        assetIds: this.ImageIDsPerPage,
        projectId: this.projectId,
        filter: this.currentFilter,
      };
      this.loaderService.displayLoader(true);
      this.pendingRequest = this.viewProjectService
        .callAssetsInfoBasedOnAssetsId(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.viewProjectService.setCurrentPageNumber(
              this.currentPageNumber,
            );
            this.projectImageData = res;
            this.projectData();
            this.viewProjectService.setTotalImageCount(
              this.projectImageIds.length,
            );
            this.paginationService.setPaginationLastIndexGlobal(
              Math.ceil(this.projectImageIds.length / this.noOfImages),
            );
            this.albumColorTagsService.updateImageDataWithColorCodes(
              this.projectImageData.assetInfoDTOList,
              this.albumList,
            );
            if (this.lastSelectedImage2find) {
              const newIndex = res.assetInfoDTOList.findIndex((item, i) => {
                return item.name.includes(this.lastSelectedImage2find);
              });
              if (
                newIndex !== -1 &&
                this.projectImageData.assetInfoDTOList[newIndex].id
              ) {
                this.lastSelectedImageStore.set(
                  this.projectImageData.assetInfoDTOList[newIndex],
                );
                setTimeout(() => {
                  const selectedElement = document.getElementById(
                    'parent-' +
                      this.projectImageData.assetInfoDTOList[
                        newIndex
                      ].id.toString(),
                  );
                  this.scrollportion.nativeElement.scrollTo({
                    top: selectedElement.offsetTop,
                    left: selectedElement.offsetLeft,
                    duration: 200,
                  });
                }, 200);
              }
              this.lastSelectedImage2find = '';
            } else {
              setTimeout(() => {
                this.scrollsectionToTop();
              }, 200);
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  loadProjectData() {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    if (Object.keys(this.projectImageData).length) {
      this.projectData();
      this.albumColorTagsService.updateImageDataWithColorCodes(
        this.projectImageData.assetInfoDTOList,
        this.albumList,
      );
    }
  }

  loadExecutiveAlbumData(albumID) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    if (Object.keys(this.projectImageData).length) {
      this.projectData();
    } else {
      if (this.pendingRequest) {
        this.pendingRequest.unsubscribe();
      }
      const obj = {
        projectId: this.projectId,
        albumId: albumID,
      };
      this.pendingRequest = this.albumsService
        .getExecutiveAlbumImageData(obj, this.selectedProjectValue)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.projectImageData = res;
            this.updateExecutiveRejected(res);
            this.viewProjectService.setProjectData(res);
            this.viewProjectService.setMainProjectData(res);
            this.projectData();
            this.viewProjectService.setTotalImageCount(
              res.assetInfoDTOList.length,
            );
            this.paginationService.setPaginationLastIndexGlobal(
              Math.ceil(res.assetInfoDTOList.length / this.noOfImages),
            );
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  updateExecutiveRejected(res: any) {
    res.assetInfoDTOList.forEach((element) => {
      if (this.selectedProjectValue === 3 || this.isExecutiveAlbumView) {
        element.executiveSignoffDTOList.forEach((signOff) => {
          if (signOff.userId === this.currentUser.id) {
            if (signOff.actionTypeId === 3) {
              element.exicutiveRejected = 3;
            } else if (signOff.actionTypeId === 1) {
              element.exicutiveRejected = 1;
            }
          }
        });
      } else {
        element.executiveWideEditDTOList.forEach((datg) => {
          if (datg.userId === this.currentUser.id) {
            if (datg.actionTypeId === 3) {
              element.exicutiveRejected = 3;
              element.exicutiveLike = datg.like;
            } else if (datg.actionTypeId === 1) {
              element.exicutiveRejected = 1;
              element.exicutiveLike = datg.like;
            }
          }
        });
      }
    });
  }

  callAlbumViewerData() {
    this.lastSelectedImageStore.clear();
    const filterName = this.viewProjectService.getSelectedProjectData().albumId
      ? 'album:' + this.viewProjectService.getSelectedProjectData().albumId
      : this.projectDetailPermission.restartDataDTO.filter;
    this.currentPageNumber = 1;
    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filterName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.currentFilter = filterName;
            this.paginationService.setPaginationIndex(this.currentPageNumber);
            const assetsIds = Utils.getImageIdsBasedOnPage(
              this.currentPageNumber,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: this.currentFilter,
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.viewProjectService.setProjectData(resData);
                  this.viewProjectService.setCurrentFilter(this.currentFilter);
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      this.viewProjectService.getProjectId(),
                      this.currentFilter,
                      this.currentPageNumber,
                    ),
                  ]);
                  this.selectedImagesStore.clear();
                  this.sidebarService.setAlbumImageData(resData);
                  this.loaderService.displayLoader(false);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.loaderService.displayLoader(false);
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  getFullName(id: number) {
    let name;
    if (this.userDataInfo) {
      this.userDataInfo.forEach((element) => {
        if (Number(element.id) === id) {
          name = element.firstName + ' ' + element.lastName;
        }
      });
    } else {
      name = this.userDataService.fetchUserName(id);
    }
    return name;
  }

  imageCornerColor(imgData) {
    return Utils.imageCornerColor(
      imgData,
      this.hoverAlbumClicked,
      this.hoverAlbum,
    );
  }

  loadLastSelectedImage(imageID?) {
    if (!this.isGallaryMode && !this.isFavGallaryMode) {
      this.saveResetData(imageID);
    } else if (this.isResumeSessionFromGallery()) {
      this.saveResetData(imageID);
    }
  }

  saveResetData(imageID: number) {
    const assetId = imageID
      ? imageID
      : this.lastSelectedImageStore.getHeadItem()?.id;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let restartData: any;
      if (assetId) {
        restartData = {
          filter: this.currentFilter,
          assetId: assetId,
          projectId: this.viewProjectService.getProjectId(),
          pageNo: this.currentPageNumber,
        };
      } else {
        restartData = {
          filter: this.currentFilter,
          assetId: null,
          projectId: this.viewProjectService.getProjectId(),
          pageNo: this.currentPageNumber,
        };
      }
      this.viewProjectService
        .callLastSelectedImage(restartData)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }, AppConfig.TIME_OUT.LAST_IMAGE_SELECTION);
  }

  projectData() {
    this.projectDetailPermission =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.projectDetailPermission,
      );
    const pageNumber = this.viewProjectService.getCurrentPageNumber();
    if (pageNumber) {
      this.viewProjectService.setCurrentPageNumber(null);
      this.currentPageNumber = pageNumber;
    } else {
      this.currentPageNumber = 1;
    }

    if (this.restartDataDTO) {
      if (this.isGallaryMode || this.isFavGallaryMode) {
        const currentImageNum =
          this.viewProjectService.assignLastSelectedImageForSetUp(
            this.restartDataDTO.assetId,
          );
        const lastSelectedImage = this.projectImageData.assetInfoDTOList.filter(
          (item) => item.id === this.restartDataDTO.assetId,
        );
        this.lastSelectedImageStore.clear();
        if (lastSelectedImage.length) {
          this.lastSelectedImageStore.add(lastSelectedImage);
        }
        if (
          currentImageNum !== -1 &&
          this.projectDetailPermission.viewSettingDTO
        ) {
          this.currentPageNumber = Math.ceil(
            currentImageNum /
              this.projectDetailPermission.viewSettingDTO.imagePerPage,
          );
        } else {
          this.currentPageNumber = 1;
        }
      } else {
        const lastSelectedImage = this.projectImageData.assetInfoDTOList.filter(
          (item) => item.id === this.restartDataDTO.assetId,
        );
        this.lastSelectedImageStore.clear();
        if (lastSelectedImage.length) {
          this.lastSelectedImageStore.add(lastSelectedImage);
        }
      }
    }

    this.viewSettingsChange(this.projectDetailPermission.viewSettingDTO);
    this.loaderService.displayLoader(false);
    if (this.talentFirstTimePriorityCount) {
      this.viewProjectService.talentFirstTimePriorityCount.next(false);
      if (
        this.projectAuthority === ProjectRole.ROLE_TALENT &&
        this.priritypixImageCount > 0 &&
        this.domainObjAbbr !== AppSource.WDTV
      ) {
        this.dialogService
          .openConfirmationDialog({
            message: this.translate.instant('view-project.confirm19'),
            title: this.translate.instant('view-project.confirm20'),
          })
          .subscribe((state: boolean) => {
            if (state) {
              this.viewProjectService.setCurrentFilter('prioritypix');
              this.viewProjectService.priorityPixHandling.next(true);
            } else {
              this.snackBarService.showSnackBarWarning(
                this.translate.instant('confirmDialog.resume.rejectMsg'),
                '',
                5000,
              );
            }
          });
      }
    }

    if (this.restartDataDTO && this.restartDataDTO.assetId !== 0) {
      setTimeout(() => {
        const selectedElement = document.getElementById(
          'parent-' + this.restartDataDTO.assetId.toString(),
        );
        if (selectedElement && this.scrollportion) {
          this.scrollportion.nativeElement.scrollTo({
            top: selectedElement.offsetTop,
            left: selectedElement.offsetLeft,
            duration: 200,
          });
          this.viewProjectService.setRestartDataDTO(undefined);
          this.restartDataDTO = undefined;
        }
      }, 1500);
    } else {
      this.viewProjectService.setRestartDataDTO(undefined);
      this.restartDataDTO = undefined;
    }
    if (
      this.lastSelectedImageStore.hasEntities() &&
      this.lastSelectedImageStore.getHeadItem()?.id !== 0
    ) {
      setTimeout(() => {
        try {
          const selectedElement = document.getElementById(
            'parent-' + this.lastSelectedImageStore.getHeadItem().id.toString(),
          );
          this.scrollportion.nativeElement.scrollTo({
            top: selectedElement.offsetTop,
            left: selectedElement.offsetLeft,
            duration: 200,
          });
        } catch {
          //**//
        }
      }, 0);
    }
  }

  onPageChange(pageNumberEvent) {
    if (pageNumberEvent) {
      this.currentPageNumber = pageNumberEvent;
      this.viewProjectService.setCurrentPageNumber(this.currentPageNumber);
    }

    clearTimeout(this.paginationTime);
    this.paginationTime = setTimeout(() => {
      this.projectImageData = this.viewProjectService.mergeWithProjectData(
        this.projectImageData,
      );
      if (Object.keys(this.projectImageData).length) {
        this.loadLastSelectedImage();
        this.ImageIDsPerPage = Utils.getImageIdsBasedOnPage(
          this.currentPageNumber,
          this.noOfImages,
          this.projectImageIds,
        );
        const index = this.projectImageData.assetInfoDTOList
          .map(function (e) {
            return e.id;
          })
          .indexOf(
            this.gototSearchShowallImageID
              ? this.gototSearchShowallImageID
              : this.ImageIDsPerPage[0],
          );
        this.gototSearchShowallImageID = null;
        if (index === -1) {
          this.viewProjectService.resetProjectData();
          this.loadProjectDataWithAssetIds();
          this.handleViewProjectRouting();
        } else {
          this.loaderService.displayLoader(false);
        }
      }
    }, 300);
  }

  onClickLastPage() {
    this.currentPageNumber = this.totalPages;
    this.handleViewProjectRouting();
  }

  getScrollPos(response) {
    this.verticalScrollToppos = response.scrollTop;
    this.horiZontalScrollPos = response.scrollLeft;
    this.totalScrollHeight = response.scrollHeight;
  }

  scrollSectionUpToImage(singleImgData) {
    try {
      const selectedElement = document.getElementById(
        'parent-' + singleImgData.id.toString(),
      );
      const availableHeight = screen.availHeight - 115;
      const selectedImageHeight = selectedElement.offsetHeight;
      const SelectedImageTopPosFromParent = selectedElement.offsetTop;
      const selectedImageOffsetTop =
        SelectedImageTopPosFromParent - this.verticalScrollToppos;
      const remainingVerticalSpace = availableHeight - selectedImageOffsetTop;
      const invisibleTopPortionOfImage =
        selectedImageHeight - remainingVerticalSpace;
      const topScrollPointPos =
        this.verticalScrollToppos - SelectedImageTopPosFromParent;
      const actualTopScroll =
        invisibleTopPortionOfImage + this.verticalScrollToppos;
      const availableWidth = screen.availWidth - 320;
      const selectedImageWidth = selectedElement.offsetWidth;
      const SelectedImageLeftPosFromParent = selectedElement.offsetLeft;
      const selectedImageOffsetLeft =
        SelectedImageLeftPosFromParent - this.horiZontalScrollPos;
      const remainingHorizontalSpace = availableWidth - selectedImageOffsetLeft;
      const invisibleLeftPortionOfImage =
        selectedImageWidth - remainingHorizontalSpace;
      const leftScrollPointPos =
        this.horiZontalScrollPos - SelectedImageLeftPosFromParent;
      const actualLeftScroll =
        invisibleLeftPortionOfImage + this.horiZontalScrollPos;

      if (remainingHorizontalSpace < selectedImageWidth) {
        this.scrollportion.nativeElement.scrollTo({
          left: actualLeftScroll + 25,
          duration: 400,
        });
      } else if (leftScrollPointPos > 0) {
        this.scrollportion.nativeElement.scrollTo({
          left: this.horiZontalScrollPos - leftScrollPointPos - 20,
          duration: 400,
        });
      }

      setTimeout(() => {
        if (remainingVerticalSpace < selectedImageHeight) {
          this.scrollportion.nativeElement.scrollTo({
            top: actualTopScroll + 100,
            duration: 400,
          });
        } else if (topScrollPointPos > 0) {
          this.scrollportion.nativeElement.scrollTo({
            top: this.verticalScrollToppos - topScrollPointPos - 25,
            duration: 400,
          });
        }
      }, 600);
    } catch {
      //**//
    }
  }

  scrollsectionToTop() {
    this.scrollportion.nativeElement.scrollTo({ top: 0, duration: 400 });
  }

  viewSettingsChange(data: any) {
    if (data) {
      this.noOfColum = Number(data.columnPerPage);
      this.projectDetailPermission =
        this.viewProjectService.mergeWithProjectDetailPermissionData(
          this.projectDetailPermission,
        );
      this.projectImageData = this.viewProjectService.mergeWithProjectData(
        this.projectImageData,
      );
      if (
        this.projectImageData &&
        this.projectImageData.assetInfoDTOList &&
        this.projectImageData.assetInfoDTOList.length > 0
      ) {
        this.projectImageData.assetInfoDTOList = JSON.parse(
          JSON.stringify(this.projectImageData.assetInfoDTOList),
        );
      }
      this.noOfImages = data ? data.imagePerPage : undefined;

      if (Number(data.size) === 1) {
        this.imageDim = 300;
      } else if (Number(data.size) === 2) {
        this.imageDim = 200;
      } else if (Number(data.size) === 3) {
        this.imageDim = 125;
      }

      if (this.projectImageIds.length > this.noOfImages) {
        this.totalPages = Math.ceil(
          this.projectImageIds.length / this.noOfImages,
        );
      } else {
        this.totalPages = 1;
      }
      this.viewProjectService.setTotalPageNumber(this.totalPages);

      if (Number(data.size) === 3 && Number(data.columnPerPage) !== 8) {
        this.boxWidth = 124;
        this.parentInnerWidth = this.boxWidth * data.columnPerPage + 'px';
        this.isImageAuto = false;
      } else if (Number(data.size) === 2 && Number(data.columnPerPage) !== 8) {
        this.boxWidth = 224;
        this.parentInnerWidth = this.boxWidth * data.columnPerPage + 'px';
        this.isImageAuto = false;
      } else if (Number(data.size) === 1 && Number(data.columnPerPage) !== 8) {
        this.boxWidth = 324;
        this.parentInnerWidth = this.boxWidth * data.columnPerPage + 'px';
        this.isImageAuto = false;
      } else {
        this.parentInnerWidth = 100 + '%';
        this.isImageAuto = true;
      }
      if (Number(data.columnPerPage) !== 8) {
        this.viewSettingImage();
      } else {
        const imageGridSize = this.getImageItemWidth(
          this.projectDetailPermission.viewSettingDTO.size,
        );
        this.columnsNumber = `repeat(auto-fill, ${imageGridSize}px)`;
      }
    }
  }

  viewSettingImage() {
    if (
      Number(this.noOfColum) !== 8 &&
      Number(this.noOfColum) !== 0 &&
      this.projectDetailPermission?.viewSettingDTO
    ) {
      this.viewProjectService.setisViewsettingChangedGlobal(true);
      this.noOfColum = this.noOfColum ? this.noOfColum : 4;
      const imageGridSize = this.getImageItemWidth(
        this.projectDetailPermission.viewSettingDTO.size,
      );
      this.columnsNumber = `repeat(${Number(
        this.noOfColum,
      )}, ${imageGridSize}px)`;
    } else if (
      Number(this.projectDetailPermission?.viewSettingDTO?.columnPerPage) === 8
    ) {
      const imageGridSize = this.getImageItemWidth(
        this.projectDetailPermission.viewSettingDTO.size,
      );
      this.columnsNumber = `repeat(auto-fill, ${imageGridSize}px)`;
    }
  }

  getImageItemWidth(viewSettingDTOSize: string) {
    return {
      [ViewSettingsSize.Large]: ImageGridSizeType.IMAGE_GRID_GAP_LARGE,
      [ViewSettingsSize.Medium]: ImageGridSizeType.IMAGE_GRID_GAP_MEDIUM,
      [ViewSettingsSize.Small]: ImageGridSizeType.IMAGE_GRID_GAP_SMALL,
    }[viewSettingDTOSize];
  }

  gotoSettings(data: any, isSearchByFileName, isSearchByAlbumName) {
    if (isSearchByFileName) {
      this.lastSelectedImage2find = data.gotoFileName;
      let val = data.gotoFileName;
      const index = this.projectImageData.assetInfoDTOList.findIndex(function (
        item,
        i,
      ) {
        return item.name.includes(val);
      });

      const indices = this.viewProjectService.getAssetIndices(
        this.projectImageData.assetInfoDTOList,
        val,
      );

      data.gotoFileName = '';
      if (index >= 0) {
        this.selectedImagesStore.clear();

        this.viewProjectService.selectFoundAssets(
          this.projectImageData.assetInfoDTOList,
          indices,
        );

        this.lastSelectedImageStore.set(
          this.projectImageData.assetInfoDTOList[index],
        );
        const selectedElement = document.getElementById(
          'parent-' +
            this.projectImageData.assetInfoDTOList[index].id.toString(),
        );
        this.scrollportion.nativeElement.scrollTo({
          top: selectedElement.offsetTop,
          left: selectedElement.offsetLeft,
          duration: 200,
        });
        this.lastSelectedImage2find = '';
      } else if (Object.prototype.hasOwnProperty.call(data, 'customName')) {
        val = data.customName;
        if (this.currentFilter === FilterEnum.ShowAll) {
          const newIndex = this.projectImageData.assetInfoDTOList.findIndex(
            function (item, i) {
              return item.name.includes(val);
            },
          );
          this.selectedImagesStore.clear();
          this.lastSelectedImageStore.set(
            this.projectImageData.assetInfoDTOList[newIndex],
          );
        } else {
          this.loaderService.displayLoader(true);
          this.viewProjectService
            .callProjectImageIDs(this.projectId, FilterEnum.ShowAll)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res1) => {
                if (res1) {
                  this.currentFilter = FilterEnum.ShowAll;
                  this.viewProjectService
                    .callAssetsIdOnAssetsName(this.projectId, val)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                      const assetsIdFoundByName = res;
                      if (assetsIdFoundByName) {
                        const getPage =
                          this.viewProjectService.getPageByImageID(
                            assetsIdFoundByName,
                          );
                        const assetsIds = Utils.getImageIdsBasedOnPage(
                          getPage,
                          this.noOfImages,
                          res1,
                        );
                        const data1 = {
                          assetIds: assetsIds,
                          projectId: this.projectId,
                          filter: this.currentFilter,
                        };
                        this.viewProjectService
                          .callAssetsInfoBasedOnAssetsId(data1)
                          .pipe(takeUntil(this.destroy$))
                          .subscribe(
                            (resData) => {
                              if (resData.assetInfoDTOList.length > 0) {
                                this.projectImageData = resData;
                                this.projectData();

                                const newIndex =
                                  this.projectImageData.assetInfoDTOList.findIndex(
                                    function (item, i) {
                                      return item.name.includes(val);
                                    },
                                  );

                                this.selectedImagesStore.clear();
                                this.lastSelectedImageStore.set(
                                  this.projectImageData.assetInfoDTOList[
                                    newIndex
                                  ],
                                );
                                const getPage1 =
                                  this.viewProjectService.getPageByImageID(
                                    this.projectImageData.assetInfoDTOList[
                                      newIndex
                                    ].id,
                                  );
                                this.paginationService.setPaginationIndex(
                                  getPage1,
                                );
                                this.viewProjectService.setCurrentPageNumber(
                                  getPage1,
                                );

                                this.currentIndex = newIndex;
                                this.currentPageNumber = getPage1;
                                this.currentFilter = FilterEnum.ShowAll;
                                this.handleViewProjectRouting();
                                this.loaderService.displayLoader(false);

                                setTimeout(() => {
                                  const selectedElement =
                                    document.getElementById(
                                      'parent-' +
                                        this.projectImageData.assetInfoDTOList[
                                          newIndex
                                        ].id.toString(),
                                    );
                                  this.scrollportion.nativeElement.scrollTo({
                                    top: selectedElement?.offsetTop,
                                    left: selectedElement?.offsetLeft,
                                    duration: 200,
                                  });
                                }, 400);
                              } else {
                                this.alertCustom(
                                  this.translate.instant('view-project.alert2'),
                                );
                              }
                              this.loaderService.displayLoader(false);
                            },
                            (error) => {
                              this.apiErrorHandlerService
                                .getHandler()
                                .handle(error);
                              this.loaderService.displayLoader(false);
                            },
                          );
                      }
                    });
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant('view-project.alert2'),
                  );
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        }
      } else {
        this.viewProjectService
          .callAssetsIdOnAssetsName(this.projectId, val)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => {
              const assetsIdFoundByName = res;
              this.gototSearchShowallImageID = res;
              if (assetsIdFoundByName) {
                const getPage =
                  this.viewProjectService.getPageByImageID(assetsIdFoundByName);
                if (getPage) {
                  this.paginationService.setPaginationIndex(getPage);
                  this.viewProjectService.setCurrentPageNumber(getPage);
                } else {
                  if (
                    getPage === 0 &&
                    this.currentFilter === FilterEnum.ShowAll
                  ) {
                    this.alertCustom(
                      this.translate.instant('view-project.alert1')!,
                    );
                  } else {
                    data.gotoFileName = '';

                    this.dialogService
                      .openConfirmationDialog({
                        message: this.translate.instant(
                          'view-project.confirm21',
                        ),
                        title: this.translate.instant('view-project.confirm22'),
                      })
                      .pipe(takeUntil(this.destroy$))
                      .subscribe(
                        (state: boolean) => {
                          if (state) {
                            this.loaderService.displayLoader(true);
                            this.viewProjectService
                              .callProjectImageIDs(
                                this.projectId,
                                FilterEnum.ShowAll,
                              )
                              .pipe(takeUntil(this.destroy$))
                              .subscribe(
                                () => {
                                  const getPage1 =
                                    this.viewProjectService.getPageByImageID(
                                      assetsIdFoundByName,
                                    );
                                  if (getPage1) {
                                    data.gotoFileName = '';
                                    this.currentFilter = FilterEnum.ShowAll;
                                    this.paginationService.setPaginationIndex(
                                      getPage1,
                                    );
                                    this.viewProjectService.setCurrentPageNumber(
                                      getPage1,
                                    );
                                  } else {
                                    this.alertCustom(
                                      this.translate.instant(
                                        'view-project.alert1',
                                      )!,
                                    );
                                  }
                                  this.loaderService.displayLoader(false);
                                },
                                (error) => {
                                  this.apiErrorHandlerService
                                    .getHandler()
                                    .handle(error);
                                  this.loaderService.displayLoader(false);
                                },
                              );
                          }
                        },
                        (error) => {
                          this.apiErrorHandlerService
                            .getHandler()
                            .handle(error);
                          this.loaderService.displayLoader(false);
                        },
                      );
                  }
                }
              } else {
                this.alertCustom(
                  this.translate.instant('view-project.alert1')!,
                );
              }
            },
            (error) => {
              this.apiErrorHandlerService.getHandler().handle(error);
              this.loaderService.displayLoader(false);
            },
          );
      }
      return;
      // End search by fileName
    }
    if (isSearchByAlbumName) {
      if (data.type === FilterEnum.Actor) {
        if ('taggedwith:' + data.id !== this.currentFilter) {
          this.loaderService.displayLoader(true);
          const filter = 'taggedwith:' + data.id;
          this.viewProjectService
            .callProjectImageIDs(this.projectId, filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res1) => {
                if (res1) {
                  this.viewProjectService
                    .callAssetsIdOnAssetsName(this.projectId, data.imageName)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                      const assetsIdFoundByName = res;
                      if (assetsIdFoundByName) {
                        const getPage =
                          this.viewProjectService.getPageByImageID(
                            assetsIdFoundByName,
                          );
                        const assetsIds = Utils.getImageIdsBasedOnPage(
                          getPage,
                          this.noOfImages,
                          res1,
                        );
                        const data1 = {
                          assetIds: assetsIds,
                          projectId: this.projectId,
                          filter: this.currentFilter,
                        };

                        this.viewProjectService
                          .callAssetsInfoBasedOnAssetsId(data1)
                          .pipe(takeUntil(this.destroy$))
                          .subscribe(
                            (resData) => {
                              this.projectImageData = resData;
                              this.projectData();

                              this.currentPageNumber = getPage;
                              this.viewProjectService.setCurrentPageNumber(
                                this.currentPageNumber,
                              );
                              this.paginationService.setPaginationIndex(
                                getPage,
                              );

                              this.viewProjectService.setTotalImageCount(
                                this.projectImageIds.length,
                              );
                              this.paginationService.setPaginationLastIndexGlobal(
                                Math.ceil(
                                  this.projectImageIds.length / this.noOfImages,
                                ),
                              );
                              const newIndex =
                                this.projectImageData.assetInfoDTOList.findIndex(
                                  function (item, i) {
                                    return item.name.includes(data.imageName);
                                  },
                                );
                              this.currentIndex = newIndex;

                              this.selectedImagesStore.clear();
                              this.lastSelectedImageStore.set(
                                this.projectImageData.assetInfoDTOList[
                                  newIndex
                                ],
                              );
                              this.viewProjectService.setCurrentFilter(filter);
                              this.handleViewProjectRouting();
                              this.handleGoTOAlbumActor(data);
                              this.loaderService.displayLoader(false);

                              setTimeout(() => {
                                const selectedElement = document.getElementById(
                                  'parent-' +
                                    this.projectImageData.assetInfoDTOList[
                                      newIndex
                                    ].id.toString(),
                                );
                                this.scrollportion.nativeElement.scrollTo({
                                  top: selectedElement.offsetTop,
                                  left: selectedElement.offsetLeft,
                                  duration: 200,
                                });
                              }, 400);
                            },
                            (error) => {
                              this.apiErrorHandlerService
                                .getHandler()
                                .handle(error);
                              this.loaderService.displayLoader(false);
                            },
                          );
                      }
                    });
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant('view-project.alert2'),
                  );
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        } else {
          const newIndex = this.projectImageData.assetInfoDTOList.findIndex(
            function (item, i) {
              return item.name.includes(data.imageName);
            },
          );
          this.selectedImagesStore.clear();
          this.lastSelectedImageStore.set(
            this.projectImageData.assetInfoDTOList[newIndex],
          );
          const selectedElement = document.getElementById(
            'parent-' +
              this.projectImageData.assetInfoDTOList[newIndex].id.toString(),
          );
          this.scrollportion.nativeElement.scrollTo({
            top: selectedElement.offsetTop,
            left: selectedElement.offsetLeft,
            duration: 200,
          });
        }
      } else {
        if ('album:' + data.id !== this.currentFilter) {
          this.loaderService.displayLoader(true);
          const filter = 'album:' + data.id;
          this.viewProjectService
            .callProjectImageIDs(this.projectId, filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res1) => {
                if (res1) {
                  this.viewProjectService
                    .callAssetsIdOnAssetsName(this.projectId, data.imageName)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                      (res) => {
                        const assetsIdFoundByName = res;
                        if (assetsIdFoundByName) {
                          const getPage =
                            this.viewProjectService.getPageByImageID(
                              assetsIdFoundByName,
                            );
                          const assetsIds = Utils.getImageIdsBasedOnPage(
                            getPage,
                            this.noOfImages,
                            res1,
                          );
                          const data1 = {
                            assetIds: assetsIds,
                            projectId: this.projectId,
                            filter: this.currentFilter,
                          };

                          this.viewProjectService
                            .callAssetsInfoBasedOnAssetsId(data1)
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(
                              (resData) => {
                                this.projectImageData = resData;
                                this.projectData();

                                const newIndex =
                                  this.projectImageData.assetInfoDTOList.findIndex(
                                    function (item, i) {
                                      return item.name.includes(data.imageName);
                                    },
                                  );
                                this.selectedImagesStore.clear();
                                this.lastSelectedImageStore.set(
                                  this.projectImageData.assetInfoDTOList[
                                    newIndex
                                  ],
                                );

                                this.paginationService.setPaginationIndex(
                                  getPage,
                                );
                                this.viewProjectService.setCurrentPageNumber(
                                  getPage,
                                );

                                this.currentIndex = newIndex;
                                this.currentPageNumber = getPage;
                                this.viewProjectService.setCurrentFilter(
                                  filter,
                                );
                                this.handleViewProjectRouting();
                                this.loaderService.displayLoader(false);
                                this.handleGoTOAlbumActor(data);
                                setTimeout(() => {
                                  const selectedElement =
                                    document.getElementById(
                                      'parent-' +
                                        this.projectImageData.assetInfoDTOList[
                                          newIndex
                                        ].id.toString(),
                                    );
                                  this.scrollportion.nativeElement.scrollTo({
                                    top: selectedElement.offsetTop,
                                    left: selectedElement.offsetLeft,
                                    duration: 200,
                                  });
                                }, 400);
                              },
                              (error) => {
                                this.apiErrorHandlerService
                                  .getHandler()
                                  .handle(error);
                                this.loaderService.displayLoader(false);
                              },
                            );
                        }
                      },
                      (error) => {
                        this.apiErrorHandlerService.getHandler().handle(error);
                        this.loaderService.displayLoader(false);
                      },
                    );
                } else {
                  this.loaderService.displayLoader(false);
                  this.alertCustom(
                    this.translate.instant('view-project.alert2'),
                  );
                }
              },
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );

          this.handleGoTOAlbumActor(data);
        } else {
          const newIndex = this.projectImageData.assetInfoDTOList.findIndex(
            function (item, i) {
              return item.name.includes(data.imageName);
            },
          );
          this.selectedImagesStore.clear();
          this.lastSelectedImageStore.set(
            this.projectImageData.assetInfoDTOList[newIndex],
          );
        }
      }
    } else {
      if (data.gotoPageNumber <= this.totalPages && data.gotoPageNumber >= 0) {
        this.paginationService.setPaginationIndex(Number(data.gotoPageNumber));
      } else {
        data.gotoPageNumber = '';
        this.alertCustom(
          this.translate.instant('view-project.alert3') + ' ' + this.totalPages,
        );
      }
      return;
    }
  }

  handleGoTOAlbumActor(data: any) {
    this.activemodal.isActive = false;
    this.activemodal.isDataLoaded = true;
    const modalData = {
      type: data.type,
      id: data.id,
      gotoback: true,
    };
    this.activemodal.modalData = modalData;
    this.activemodal.modalName = 'GoTOAlbumActor';
    this.modalstatusservice.setActiveModal(this.activemodal);
  }

  selectAllImg() {
    this.selectedImagesStore.clear();
    if (this.projectDetailPermission.viewSettingDTO) {
      const images = this.projectImageData.assetInfoDTOList.filter(
        (item, i) => i < this.projectImageData.assetInfoDTOList.length,
      );
      this.selectedImagesStore.add(images);
      this.loadLastSelectedImage(
        this.projectImageData.assetInfoDTOList[
          this.projectImageData.assetInfoDTOList.length - 1
        ].id,
      );
    }
  }

  deSelectAllImg() {
    this.selectedImagesStore.clear();
  }

  drawImage(imagedata) {
    this.selectedImagesStore.set(imagedata);
    this.router.navigate([
      '/dashboard/viewproject/' +
        this.viewProjectService.getProjectRole() +
        this.viewProjectService.getProjectId() +
        '/filter/' +
        this.currentFilter +
        '/page/' +
        this.currentPageNumber +
        '/draw/' +
        imagedata.id,
    ]);
  }

  enlargeView(imgData) {
    this.selectedImagesStore.set(imgData);
    this.router.navigate([
      '/dashboard/viewproject/' +
        this.viewProjectService.getProjectRole() +
        this.viewProjectService.getProjectId() +
        '/filter/' +
        this.currentFilter +
        '/page/' +
        this.currentPageNumber +
        '/enlarge/' +
        imgData.id,
    ]);

    setTimeout(() => {
      if (this.isTaggingView1Active) {
        this.viewProjectService.setTagginView1DataEnlarge(true);
      } else if (this.isTaggingView2Active) {
        this.viewProjectService.setTagginView2DataEnlarge(true);
      } else if (this.isConflictsActive) {
        this.viewProjectService.setConflictsEnlarge(true);
      } else {
        this.viewProjectService.setTagginView1DataEnlarge(false);
        this.viewProjectService.setTagginView2DataEnlarge(false);
      }
    }, 500);
  }

  captionView(imagedata) {
    const index = this.selectedImagesStore
      .getItemsIds()
      .map(function (e) {
        return e;
      })
      .indexOf(imagedata.id);
    if (index === -1) {
      this.selectedImagesStore.set(imagedata);
      this.loadLastSelectedImage(imagedata.id);
    }
    this.lastSelectedImageStore.set(imagedata);

    if (this.selectedImagesStore.hasEntities()) {
      const data = {
        assetIds: this.selectedImagesStore.getItemsIds(),
        projectId: this.projectId,
        filter: this.currentFilter,
      };
      this.viewProjectService
        .callSingleAssetsInfoBasedOnAssetsId(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.selectedImagesStore.set(res.assetInfoDTOList);
            this.router.navigate([
              '/dashboard/viewproject/' +
                this.viewProjectService.getProjectRole() +
                this.viewProjectService.getProjectId() +
                '/filter/' +
                this.currentFilter +
                '/page/' +
                this.currentPageNumber +
                '/enlarge/' +
                imagedata.id +
                '/' +
                'caption',
            ]);
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.router.navigate([
        '/dashboard/viewproject/' +
          this.viewProjectService.getProjectRole() +
          this.viewProjectService.getProjectId() +
          '/filter/' +
          this.currentFilter +
          '/page/' +
          this.currentPageNumber +
          '/enlarge/' +
          imagedata.id +
          '/' +
          'caption',
      ]);
    }
  }

  onClickNoteIcon({ selectImg, index, event }) {
    this.addNoteIcon = event.target;
    this.viewProjectService.isAddNoteFromTopMenu.next(false);
    this.objToViewNote = {
      selectImg: selectImg,
      index: index,
      event: event,
    };

    this.currentImageID = selectImg.id;
    this.selectedImagesStore.set(selectImg);
    if (selectImg.assetNoteDTOList.length === 0) {
      this.isClickedOnAddNote = true;
      this.viewProjectService.isAddNoteFromAddNote.next(true);
    }
  }

  openNotePopup() {
    this.popupService
      .closePopupById$('ImageNotesDialogComponent')
      .subscribe(() => {
        this.popupService.open(ImageNotesDialogComponent, this.addNoteIcon, {
          data: {
            isFromTopMenu: false,
            projectAuthority: this.projectAuthority,
          },
          panelClass: ['fs-popup-base-panel', 'fs-popup-md-panel'],
          withArrow: false,
          position: 'below',
          cdkOverlayContainerClass: 'fs-overlay-998',
          id: 'ImageNotesDialogComponent',
          hasBackdrop: false,
        });
      });
  }

  selectImageOnClickNotes = ({ selectImg, index, event, noteOptionType }) => {
    this.onClickNoteIcon({ selectImg, index, event });
    this.currentImageID = selectImg.id;

    if (noteOptionType === 'add') {
      this.isClickedOnAddNote = true;
      this.openNotePopup();
      this.viewProjectService.isAddNoteFromAddNote.next(true);
    } else {
      this.isClickedOnViewNoteFromViewProject = true;
      this.viewProjectService.isAddNoteFromAddNote.next(false);
      this.selectImageOnClick({ selectImg, index, event });
      this.viewProjectService.setViewProjectNoteIconClick(true);
    }
  };

  selectImageOnClick({ selectImg, index, event }) {
    this.appStateService.setSelectAll({ loaded: false });
    this.appStateService.setDeselectAll({ loaded: false });
    this.lastSelectedImageStore.clear();
    this.preventSingleClickTimer = 0;
    this.preventSingleClick = false;
    this.currentIndex = (this.currentPageNumber - 1) * this.noOfImages + index;
    if (this.count === 0) {
      this.clickRangeArr.push(this.currentIndex);
    } else if (this.count === 1) {
      this.clickRangeArr.push(this.currentIndex);
    } else {
      this.clickRangeArr.shift();
      this.clickRangeArr.push(this.currentIndex);
    }
    this.count++;

    if (!this.preventSingleClick) {
      this.scrollSectionUpToImage(selectImg);
      if (event.shiftKey && this.clickRangeArr.length > 1) {
        const ind1 = Math.min(this.lastIImageSelectedIndex, this.currentIndex);
        const ind2 = Math.max(this.lastIImageSelectedIndex, this.currentIndex);
        let images: SelectedImages[] = [];
        for (let i = ind1; i <= ind2; i++) {
          const indexShift = this.projectImageData.assetInfoDTOList
            .map(function (e) {
              return e.id;
            })
            .indexOf(this.projectImageIds[i]);

          if (indexShift !== -1) {
            images.push(this.projectImageData.assetInfoDTOList[indexShift]);
          } else {
            const selectedImgObj: SelectedImages = new SelectedImages({
              id: this.projectImageIds[i],
            });
            images.push(selectedImgObj);
          }
        }
        this.selectedImagesStore.set(images);
        images = null;
        this.loadLastSelectedImage(this.projectImageIds[ind2]);
      } else if (event.metaKey || event.ctrlKey) {
        if (this.selectedImagesStore.hasEntity(selectImg.id)) {
          this.selectedImagesStore.remove(selectImg.id);
        } else {
          this.selectedImagesStore.add(selectImg);
          this.loadLastSelectedImage(selectImg.id);
        }
      } else {
        this.lastIImageSelectedIndex = this.currentIndex;

        if (!this.selectedImagesStore.hasEntity(selectImg.id)) {
          this.selectedImagesStore.set(selectImg);
        }
      }
    } else {
      this.lastIImageSelectedIndex = this.currentIndex;
    }
  }

  onClickVideoIcon(selectImg) {
    const imageVideoData = {
      projectId: this.projectId,
      id: selectImg.id,
    };

    this.dialogService.open(ImageVideoComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
      ],
      data: { imageVideoData },
    });
  }

  selectImageOnClickUP({ selectImg, index, event }) {
    if (!event.metaKey && !event.ctrlKey && !event.shiftKey) {
      this.selectedImagesStore.set(selectImg);
      this.loadLastSelectedImage(selectImg.id);
    }
  }

  selectImageOnDoubleClick(selectImg, event) {
    this.preventSingleClick = true;
    clearTimeout(this.preventSingleClickTimer);
    if (!event.metaKey && !event.ctrlKey && !event.shiftKey) {
      this.enlargeView(selectImg);
    }
  }

  handleModalStatus() {
    this.dialogSateService
      .isAtLeastOneOpened$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((opened: boolean) => {
        return (this.isModelWindowOpen = opened);
      });

    const observables = [];

    const openedViewSettingsComponentData = this.dialogService.getDialogState(
      ViewsettingsComponent.identifier,
    );
    openedViewSettingsComponentData.state$
      .pipe(
        tap(({ data }) => {
          if (data) {
            if (
              this.projectDetailPermission.viewSettingDTO &&
              Number(
                this.projectDetailPermission.viewSettingDTO.imagePerPage,
              ) !== Number(data.imagePerPage)
            ) {
              this.viewProjectService.resetProjectData();
              this.ImageIDsPerPage = Utils.getImageIdsBasedOnPage(
                this.currentPageNumber,
                data.imagePerPage,
                this.projectImageIds,
              );
            }
            this.viewProjectService.setCurrentPageNumber(
              this.currentPageNumber,
            );
            this.projectDetailPermission.viewSettingDTO = data;
            this.viewProjectService.setProjectDetailPermissionData(
              this.projectDetailPermission,
            );
            this.loadProjectDataWithAssetIds();
            this.viewSettingsChange(data);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    observables.push(
      this.appStateService.noConflicts$.pipe(
        tap(({ loaded }) => {
          this.isNoConflictsActive = loaded;
          if (this.isNoConflictsActive) {
            this.selectedImagesStore.clear();
            setTimeout(() => {
              this.scrollsectionToTop();
            }, 1000);
          }
        }),
      ),
    );

    observables.push(
      this.appStateService.bypass2ndtagger$.pipe(
        tap(({ loaded }) => {
          this.isByPass2ndTaggerActive = loaded;
          if (this.isByPass2ndTaggerActive) {
            this.selectedImagesStore.clear();
            setTimeout(() => {
              this.scrollsectionToTop();
            }, 1000);
          }
        }),
      ),
    );

    observables.push(
      this.appStateService.conflicts$.pipe(
        tap(({ loaded }) => {
          this.isConflictsActive = loaded;
          if (this.isConflictsActive) {
            this.selectedImagesStore.clear();
            setTimeout(() => {
              this.scrollsectionToTop();
            }, 1000);
            this.ref.markForCheck();
          }
        }),
      ),
    );

    observables.push(
      this.appStateService.compare$.pipe(
        takeUntil(this.destroy$),
        tap(({ active }) => {
          if (active) {
            if (this.selectedImagesStore.size() === 0) {
              this.alertCustom(this.translate.instant('view-project.alert5'));
            } else if (this.selectedImagesStore.size() === 1) {
              this.alertCustom(this.translate.instant('view-project.alert4'));
            } else {
              this.router.navigate([
                '/dashboard/viewproject/' +
                  this.viewProjectService.getProjectRole() +
                  this.viewProjectService.getProjectId() +
                  '/filter/' +
                  this.currentFilter +
                  '/page/' +
                  this.currentPageNumber +
                  '/compare',
              ]);
            }
          }
        }),
      ),
    );

    observables.push(
      this.appStateService.goTOFileName$.pipe(
        takeUntil(this.destroy$),
        tap(({ data }) => {
          if (data) {
            this.gotoSettings(data, true, false);
          }
          this.ref.markForCheck();
        }),
      ),
    );

    observables.push(
      this.appStateService.goToPageName$.pipe(
        takeUntil(this.destroy$),
        tap(({ data }) => {
          if (data) {
            this.gotoSettings(data, false, false);
          }
          this.ref.markForCheck();
        }),
      ),
    );

    observables.push(
      this.appStateService.goTOAlbumActor$.pipe(
        tap(({ data }) => {
          if (data) {
            if (!Object.prototype.hasOwnProperty.call(data, 'gotoback')) {
              if (Object.prototype.hasOwnProperty.call(data, 'customName')) {
                this.gotoSettings(data, true, false);
              } else {
                this.gotoSettings(data, false, true);
              }
            }
          }
        }),
      ),
    );

    const isOpenedGotoComponentData = this.dialogService.getDialogState(
      GotoComponent.identifier,
    );
    observables.push(
      isOpenedGotoComponentData.state$.pipe(
        tap(({ data }) => {
          if (data) {
            this.gotoSettings(data, false, false);
          }
          this.ref.markForCheck();
        }),
      ),
    );

    observables.push(
      this.appStateService.selectAll$.pipe(
        takeUntil(this.destroy$),
        tap((state) => {
          if (state.loaded) {
            this.selectAllImg();
          }
          this.ref.markForCheck();
        }),
      ),
    );

    observables.push(
      this.appStateService.deSelectAll$.pipe(
        takeUntil(this.destroy$),
        tap((state) => {
          if (state.loaded) {
            this.deSelectAllImg();
          }
          this.ref.markForCheck();
        }),
      ),
    );

    combineLatest(observables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([
          isOpenedConflicts,
          noConflicts,
          bypass2ndtagger,
          conflicts,
          compare,
          goTOFileName,
          goToPageName,
          goTOAlbumActor,
          isOpenedGoto,
          selectAll,
          deSelectAll,
        ]: any[]) => {
          if (
            !bypass2ndtagger.loaded &&
            !goToPageName.loaded &&
            !goTOFileName.loaded &&
            !conflicts.loaded &&
            !selectAll.loaded &&
            !deSelectAll.loaded &&
            !compare.active &&
            !goTOAlbumActor.loaded &&
            !noConflicts.loaded &&
            !isOpenedConflicts.closed &&
            !isOpenedGoto.closed
          ) {
            this.isByPass2ndTaggerActive = false;
          }
        },
      );
  }

  handleViewProjectRouting() {
    this.viewSettingImage();
    this.router.navigate([
      Utils.prepareFilterNavigationURL(
        this.viewProjectService.getProjectRole(),
        this.viewProjectService.getProjectId(),
        this.currentFilter,
        this.currentPageNumber,
      ),
    ]);
    this.imageLoaded = [];
  }

  callAlbumTagging(id) {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.projectId,
      currentAssetIds: Utils.getImageIdsBasedOnPage(
        this.currentPageNumber,
        this.noOfImages,
        this.projectImageIds,
      ),
      trash: false,
      id: id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            if (
              !this.isLeftSignOffAlbumActive &&
              !this.isLeftWideEditAlbumActive
            ) {
              this.updateCustomeAlbumsID(id, res.t);
            }
            if (this.isLeftAlbumActive) {
              this.sidebarService.setUpdatedTaggingAlbum(res);
            } else if (this.isLeftSignOffAlbumActive) {
              this.sidebarService.setUpdatedTaggingSignOffAlbum(res);
            } else if (this.isLeftWideEditAlbumActive) {
              this.sidebarService.setUpdatedTaggingWideEditAlbum(res);
            }
          } else {
            this.alertCustom(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  callAlbumTaggingKeys(id) {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.projectId,
      id: id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const obj = {
            status: res.status,
            message: res.message,
            id: id,
          };
          this.sidebarService.setUpdatedTaggingAlbum(obj);
          if (res.status) {
            this.updateCustomeAlbumsID(id, res.t);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  callProjectAlbumTaggingKeys(albumObj) {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.projectId,
      id: albumObj.id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const obj = {
            status: res.status,
            message: res.message,
            id: albumObj.id,
          };
          this.sidebarService.setUpdatedTaggingProjectAlbum(obj);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  updateCustomeAlbumsID(id, isAdd) {
    isAdd = isAdd.tag;
    const updatedSelectedImages: SelectedImages[] = [];

    this.selectedImagesStore.getItemsIds().forEach((imageId) => {
      const index = this.projectImageData.assetInfoDTOList
        .map((e) => e.id)
        .indexOf(imageId);
      if (index !== -1) {
        if (isAdd) {
          this.projectImageData.assetInfoDTOList[index].customAlbums.push(id);
          updatedSelectedImages.push(
            this.projectImageData.assetInfoDTOList[index],
          );
        } else {
          const customeAlbumIndex: number =
            this.projectImageData.assetInfoDTOList[index].customAlbums.indexOf(
              id,
            );
          if (customeAlbumIndex !== -1) {
            this.projectImageData.assetInfoDTOList[index].customAlbums.splice(
              customeAlbumIndex,
              1,
            );
            updatedSelectedImages.push(
              this.projectImageData.assetInfoDTOList[index],
            );
          }
        }
      } else {
        updatedSelectedImages.push({
          id: imageId,
        } as any);
      }
    });
    this.albumColorTagsService.updateImageDataWithColorCodes(
      this.projectImageData.assetInfoDTOList,
      this.albumList,
    );
    this.selectedImagesStore.set(updatedSelectedImages);
  }

  callTaggingImages(index: number, tagView: number) {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      tagId: this.projectDetailPermission.projectTagListDTO[index].id,
      tagView: tagView,
      projectId: Number(this.projectId),
      index: index,
    };

    this.tagRequestSubject.next(data);
  }

  updateAssetTagging() {
    this.tagRequestSubject
      .pipe(
        concatMap((data) =>
          this.taggerService.postTaggingImages(data).pipe(
            takeUntil(this.destroy$),
            map((res) => ({ response: res, request: data })),
          ),
        ),
      )
      .subscribe(
        ({ response, request }) => {
          if (response.status) {
            this.updateTaggingData(request.index, response, request.assetIds);
            const obj = {
              count: response.message,
              index: request.index,
              hotKey:
                this.projectDetailPermission.projectTagListDTO[request.index]
                  .hotkey,
            };
            if (request.tagView === 1) {
              this.viewProjectService.setTagginView1Data(obj);
            } else {
              this.viewProjectService.setTagginView2Data(obj);
            }
            this.ref.markForCheck();
          } else {
            this.alertCustom(response.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.isTaggingInProgress = false;
          this.loaderService.displayLoader(false);
        },
      );
  }

  updateTaggingData(indexTag, res, assetIds: number[]) {
    assetIds.forEach((imageId) => {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map((e: AssetInfoDTOList) => e.id)
        .indexOf(imageId);

      if (imageIndex !== -1) {
        if (this.isTaggingView1Active) {
          if (
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].taggingView1.indexOf(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            ) === -1
          ) {
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].taggingView1.push(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            );
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].taggingView1.sort((a, b) => a.localeCompare(b));
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].submittedByTagView1 = false;
          } else {
            const index = this.projectImageData.assetInfoDTOList[
              imageIndex
            ].taggingView1.indexOf(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            );
            if (index > -1) {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].taggingView1.splice(index, 1);
              if (
                this.projectImageData.assetInfoDTOList[imageIndex].taggingView1
                  .length <= 0
              ) {
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].submittedByTagView1 = false;
              }
            }
          }
        } else if (this.isTaggingView2Active) {
          if (
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].taggingView2.indexOf(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            ) === -1
          ) {
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].taggingView2.push(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            );
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].taggingView2.sort((a, b) => a.localeCompare(b));
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].submittedByTagView2 = false;
          } else {
            const index = this.projectImageData.assetInfoDTOList[
              imageIndex
            ].taggingView2.indexOf(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            );
            if (index > -1) {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].taggingView2.splice(index, 1);
              if (
                this.projectImageData.assetInfoDTOList[imageIndex].taggingView2
                  .length <= 0
              ) {
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].submittedByTagView2 = false;
              }
            }
          }
        } else if (this.isConflictsActive) {
          if (
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].auditTags.indexOf(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            ) === -1
          ) {
            this.projectImageData.assetInfoDTOList[imageIndex].auditTags.push(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            );
            this.projectImageData.assetInfoDTOList[imageIndex].auditTags.sort(
              (a, b) => a.localeCompare(b),
            );
          } else {
            const index = this.projectImageData.assetInfoDTOList[
              imageIndex
            ].auditTags.indexOf(
              this.projectDetailPermission.projectTagListDTO[indexTag].hotkey,
            );
            if (index > -1) {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].auditTags.splice(index, 1);
            }
          }
        }
      }
      this.ref.markForCheck();
    });
  }

  callConflictsTaggingImages(index) {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      tagId: this.projectDetailPermission.projectTagListDTO[index].id,
      projectId: this.viewProjectService.getProjectId(),
    };

    this.taggerService
      .postAuditTaggingImages(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.updateTaggingData(
              index,
              res,
              this.selectedImagesStore.getItemsIds(),
            );
            const obj = {
              count: res.message,
              index: index,
              hotKey:
                this.projectDetailPermission.projectTagListDTO[index].hotkey,
            };
            this.viewProjectService.setConflictsTagginData(obj);
          } else {
            this.alertCustom(res.message);
            // Handale Alert Message
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  callPriorityPix() {
    const data = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      projectId: this.viewProjectService.getProjectId(),
    };

    this.viewProjectService
      .putSetPriorityPix(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            this.updatePriorityPixFlage(res);
          } else {
            this.alertCustom(res.message);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  isTaggingViewOpen() {
    return (
      this.taggingView1Open || this.taggingView2Open || this.isConflictsActive
    );
  }

  isDialogOpenedWithAllowedKeys() {
    return (
      !this.dialogService.isAtLeastOneOpened() ||
      this.dialogService.isHandlingUpKeyEnabled()
    );
  }

  openDownloadModal(event: KeyboardEvent) {
    const isTaggingViewOpen = this.isTaggingViewOpen();
    const hasDownloadAccess = this.hasDownloadAccess;
    const isTalentRole = this.projectAuthority === ProjectRole.ROLE_TALENT;
    const isKeyD =
      event.keyCode === ConfigKey.lowerD || event.keyCode === ConfigKey.upperD;

    if (!isTaggingViewOpen && hasDownloadAccess && !isTalentRole && isKeyD) {
      this.downloadService
        .downloadAssets(
          this.isEnlargeView,
          this.projectDetailPermission,
          this.userAuthority,
          this.projectAuthority,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {},
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  updatePriorityPixFlage(res) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    res.t.forEach((data) => {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map(function (e) {
          return e.id;
        })
        .indexOf(data.id);
      this.projectImageData.assetInfoDTOList[imageIndex].priorityPix =
        data.value;
    });
  }

  @HostListener('document:mouseover', ['$event'])
  onMouseover() {
    if (this.blockedHover && this.mouseCount > 3) {
      this.blockedHover = false;
      this.mouseCount = 1;
    } else {
      this.mouseCount = this.mouseCount + 1;
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyEvents(event: KeyboardEvent) {
    // Enlarge View
    if (
      this.isDialogOpenedWithAllowedKeys() &&
      !this.viewProjectService.isInputFocused()
    ) {
      if (event.keyCode === ConfigKey.Space) {
        if (this.selectedImagesStore.hasEntities()) {
          this.enlargeView(this.selectedImagesStore.getTailItem());
        }
      }

      // TaggingView-1
      if (this.isTaggingView1Active && this.selectedImagesStore.hasEntities()) {
        this.projectDetailPermission.projectTagListDTO.forEach(
          (element, index) => {
            if (
              event.key === element.hotkey.toLowerCase() ||
              event.key === element.hotkey.toUpperCase()
            ) {
              this.callTaggingImages(index, 1);
            }
          },
        );
      }

      // TaggingView-2
      if (this.isTaggingView2Active && this.selectedImagesStore.hasEntities()) {
        this.projectDetailPermission.projectTagListDTO.forEach(
          (element, index) => {
            if (
              event.key === element.hotkey.toLowerCase() ||
              event.key === element.hotkey.toUpperCase()
            ) {
              this.callTaggingImages(index, 2);
            }
          },
        );
      }

      // Conflicts
      if (this.isConflictsActive && this.selectedImagesStore.hasEntities()) {
        for (
          let i = 0;
          i < this.projectDetailPermission.projectTagListDTO.length;
          i++
        ) {
          if (
            event.key ===
              this.projectDetailPermission.projectTagListDTO[
                i
              ].hotkey.toLowerCase() ||
            event.key ===
              this.projectDetailPermission.projectTagListDTO[
                i
              ].hotkey.toUpperCase()
          ) {
            this.callConflictsTaggingImages(i);
            this.ref.markForCheck();
          }
        }
      }

      // If CurrentIndex Undefined after moving from other page
      if (!this.currentIndex && this.lastSelectedImageStore.hasEntities()) {
        this.currentIndex = this.projectImageIds.findIndex(
          (item) => item === this.lastSelectedImageStore.getHeadItem().id,
        );
      }

      // ************ For Left Arrow*************
      if (event.keyCode === ConfigKey.ArrowLeft) {
        event.preventDefault();
        this.lastSelectedImageStore.clear();
        if (this.currentIndex !== 0) {
          if (
            Number(this.currentPageNumber) !==
            Math.ceil(this.currentIndex / this.noOfImages)
          ) {
            this.currentPageNumber = Math.ceil(
              this.currentIndex / this.noOfImages,
            );
            this.ImageIDsPerPage = Utils.getImageIdsBasedOnPage(
              this.currentPageNumber,
              this.noOfImages,
              this.projectImageIds,
            );
            const data = {
              assetIds: this.ImageIDsPerPage,
              projectId: this.projectId,
              filter: this.currentFilter,
            };
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (res) => {
                  this.currentIndex = this.currentIndex - 1;
                  this.clickRangeArr.shift();
                  this.clickRangeArr.push(this.currentIndex);
                  const availableImageId = res.assetInfoDTOList
                    .map((e) => e.id)
                    .indexOf(this.projectImageIds[this.currentIndex]);
                  this.selectedImagesStore.set(
                    this.projectImageData.assetInfoDTOList[availableImageId],
                  );
                  setTimeout(() => {
                    this.scrollSectionUpToImage(
                      this.projectImageData.assetInfoDTOList[availableImageId],
                    );
                  }, 200);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.currentIndex = this.currentIndex - 1;
            this.clickRangeArr.shift();
            this.clickRangeArr.push(this.currentIndex);
            const availableImageId = this.projectImageData.assetInfoDTOList
              .map((e) => e.id)
              .indexOf(this.projectImageIds[this.currentIndex]);
            this.selectedImagesStore.set(
              this.projectImageData.assetInfoDTOList[availableImageId],
            );
            this.scrollSectionUpToImage(
              this.projectImageData.assetInfoDTOList[availableImageId],
            );
          }
        } else {
          this.alertCustom(this.translate.instant('view-project.alert6'));
        }
        // ************ For Right Arrow*****************
      } else if (event.keyCode === ConfigKey.ArrowRight) {
        event.preventDefault();
        this.lastSelectedImageStore.clear();
        if (this.currentIndex === this.projectImageIds.length - 1) {
          this.alertCustom(this.translate.instant('view-project.alert7'));
        }
        if (this.currentIndex < this.projectImageIds.length - 1) {
          if (
            Number(this.currentPageNumber) !==
            Math.ceil((this.currentIndex + 2) / this.noOfImages)
          ) {
            this.viewProjectService.resetProjectData();
            this.currentPageNumber = Math.ceil(
              (this.currentIndex + 2) / this.noOfImages,
            );

            this.ImageIDsPerPage = Utils.getImageIdsBasedOnPage(
              this.currentPageNumber,
              this.noOfImages,
              this.projectImageIds,
            );
            const data = {
              assetIds: this.ImageIDsPerPage,
              projectId: this.projectId,
              filter: this.currentFilter,
            };
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (res) => {
                  this.currentIndex = this.currentIndex + 1;
                  this.clickRangeArr.shift();
                  this.clickRangeArr.push(this.currentIndex);
                  const availableImageId = res.assetInfoDTOList
                    .map((e) => e.id)
                    .indexOf(this.projectImageIds[this.currentIndex]);
                  this.selectedImagesStore.set(
                    this.projectImageData.assetInfoDTOList[availableImageId],
                  );
                  this.loadLastSelectedImage(
                    this.projectImageIds[this.currentIndex],
                  );
                  this.handleViewProjectRouting();
                  setTimeout(() => {
                    this.scrollsectionToTop();
                  }, 200);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.currentIndex = this.currentIndex + 1;
            this.clickRangeArr.shift();
            this.clickRangeArr.push(this.currentIndex);
            if (
              this.projectImageData &&
              this.projectImageData.assetInfoDTOList &&
              this.projectImageData.assetInfoDTOList.length > 0
            ) {
              const availableImage = this.projectImageData.assetInfoDTOList
                .map((e) => e.id)
                .indexOf(this.projectImageIds[this.currentIndex]);
              this.selectedImagesStore.set(
                this.projectImageData.assetInfoDTOList[availableImage],
              );
              this.loadLastSelectedImage(
                this.projectImageIds[this.currentIndex],
              );
              this.scrollSectionUpToImage(
                this.projectImageData.assetInfoDTOList[availableImage],
              );
            }
          }
        }
        // ************ For Up Arrow*****************
      } else if (event.keyCode === ConfigKey.ArrowUp) {
        event.preventDefault();
        this.lastSelectedImageStore.clear();
        if (this.currentIndex >= this.noOfColum) {
          const currenPageUp = Math.ceil(
            (this.currentIndex - this.noOfColum + 1) / this.noOfImages,
          );
          if (Number(this.currentPageNumber) !== currenPageUp) {
            this.currentPageNumber = currenPageUp;
            this.ImageIDsPerPage = Utils.getImageIdsBasedOnPage(
              this.currentPageNumber,
              this.noOfImages,
              this.projectImageIds,
            );
            const data = {
              assetIds: this.ImageIDsPerPage,
              projectId: this.projectId,
              filter: this.currentFilter,
            };
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (res) => {
                  this.currentIndex = this.projectImageIds.findIndex(
                    (item) =>
                      item ===
                      res.assetInfoDTOList[res.assetInfoDTOList.length - 1].id,
                  );
                  this.clickRangeArr.shift();
                  this.clickRangeArr.push(this.currentIndex);
                  const availableImageId = res.assetInfoDTOList
                    .map((e) => e.id)
                    .indexOf(this.projectImageIds[this.currentIndex]);
                  this.selectedImagesStore.set(
                    this.projectImageData.assetInfoDTOList[availableImageId],
                  );
                  this.loadLastSelectedImage(
                    this.projectImageIds[this.currentIndex],
                  );
                  this.handleViewProjectRouting();
                  this.scrollSectionUpToImage(
                    this.projectImageData.assetInfoDTOList[availableImageId],
                  );
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.currentIndex = this.currentIndex - this.noOfColum;
            this.clickRangeArr.shift();
            this.clickRangeArr.push(this.currentIndex);

            const availableImageId = this.projectImageData.assetInfoDTOList
              .map((e) => e.id)
              .indexOf(this.projectImageIds[this.currentIndex]);

            this.selectedImagesStore.set(
              this.projectImageData.assetInfoDTOList[availableImageId],
            );
            this.loadLastSelectedImage(this.projectImageIds[this.currentIndex]);
            this.scrollSectionUpToImage(
              this.projectImageData.assetInfoDTOList[availableImageId],
            );
          }
        } else {
          if (this.selectedImagesStore.hasEntities()) {
            this.alertCustom(this.translate.instant('view-project.alert8'));
          }
        }
        // ************ For Down Arrow*****************
      } else if (event.keyCode === ConfigKey.ArrowDown) {
        event.preventDefault();
        this.lastSelectedImageStore.clear();

        if (this.currentIndex < this.projectImageIds.length - this.noOfColum) {
          let currenPageDown = undefined;
          if (this.projectDetailPermission.viewSettingDTO) {
            currenPageDown = Math.ceil(
              (this.currentIndex + this.noOfColum) /
                this.projectDetailPermission.viewSettingDTO.imagePerPage,
            );
          }
          if (Number(this.currentPageNumber) !== currenPageDown) {
            this.currentPageNumber = currenPageDown;

            this.ImageIDsPerPage = Utils.getImageIdsBasedOnPage(
              this.currentPageNumber,
              this.noOfImages,
              this.projectImageIds,
            );
            const data = {
              assetIds: this.ImageIDsPerPage,
              projectId: this.projectId,
              filter: this.currentFilter,
            };
            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (res) => {
                  this.currentIndex = this.projectImageIds.findIndex(
                    (item) => item === res.assetInfoDTOList[0].id,
                  );
                  this.clickRangeArr.shift();
                  this.clickRangeArr.push(this.currentIndex);
                  const availableImageId = res.assetInfoDTOList
                    .map((e) => e.id)
                    .indexOf(this.projectImageIds[this.currentIndex]);

                  this.selectedImagesStore.set(
                    this.projectImageData.assetInfoDTOList[availableImageId],
                  );
                  this.loadLastSelectedImage(
                    this.projectImageIds[this.currentIndex],
                  );
                  this.handleViewProjectRouting();
                  this.scrollSectionUpToImage(
                    this.projectImageData.assetInfoDTOList[availableImageId],
                  );
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.currentIndex = this.currentIndex + this.noOfColum;
            this.clickRangeArr.shift();
            this.clickRangeArr.push(this.currentIndex);
            if (
              this.projectImageData &&
              this.projectImageData.assetInfoDTOList
            ) {
              const isAvailable = this.projectImageData.assetInfoDTOList
                .map(function (e) {
                  return e.id;
                })
                .indexOf(this.projectImageIds[this.currentIndex]);
              if (isAvailable === -1) {
                const imageId =
                  this.projectImageData.assetInfoDTOList[
                    this.projectImageData.assetInfoDTOList.length - 1
                  ].id;
                const currentIndexImage = this.projectImageIds.findIndex(
                  (item) => item === imageId,
                );
                const isAvailableIndex = this.projectImageData.assetInfoDTOList
                  .map(function (e) {
                    return e.id;
                  })
                  .indexOf(this.projectImageIds[currentIndexImage]);
                this.selectedImagesStore.set(
                  this.projectImageData.assetInfoDTOList[isAvailableIndex],
                );
                this.loadLastSelectedImage(
                  this.projectImageIds[currentIndexImage].id,
                );
                this.scrollSectionUpToImage(
                  this.projectImageData.assetInfoDTOList[isAvailable],
                );
              } else {
                this.selectedImagesStore.set(
                  this.projectImageData.assetInfoDTOList[isAvailable],
                );
                this.loadLastSelectedImage(
                  this.projectImageIds[this.currentIndex].id,
                );
                this.scrollSectionUpToImage(
                  this.projectImageData.assetInfoDTOList[isAvailable],
                );
              }
            }
          }
        } else {
          if (
            this.currentIndex >=
            this.projectImageData.assetInfoDTOList.length - this.noOfColum
          ) {
            this.alertCustom(this.translate.instant('view-project.alert9'));
          }
        }
      }
    }

    if (
      !this.isTaggingViewOpen() &&
      this.isDialogOpenedWithAllowedKeys() &&
      !this.viewProjectService.isInputFocused()
    ) {
      if (
        event.keyCode === ConfigKey.lowerZ ||
        event.keyCode === ConfigKey.upperZ
      ) {
        if (this.selectedImagesStore.hasEntities()) {
          this.enlargeView(this.selectedImagesStore.getTailItem());
        }
      }

      if (
        event.keyCode === ConfigKey.lowerP ||
        event.keyCode === ConfigKey.upperP
      ) {
        if (
          !this.isTaggingView1Active &&
          !this.isTaggingView2Active &&
          !this.isTaggerRole &&
          this.selectedImagesStore.hasEntities() &&
          this.priorityPixAccess &&
          !this.isConflictsActive
        ) {
          this.callPriorityPix();
        }
      }

      // Album Tagging
      if (
        this.isLeftAlbumActive ||
        this.isLeftSignOffAlbumActive ||
        this.isLeftWideEditAlbumActive
      ) {
        if (
          event.keyCode === ConfigKey.lowerT ||
          event.keyCode === ConfigKey.upperT
        ) {
          if (
            !Utils.isObjectEmpty(this.selectedLeftAlbum) &&
            !this.isTaggingView1Active &&
            !this.isTaggingView2Active
          ) {
            if (this.selectedImagesStore.hasEntities()) {
              this.callAlbumTagging(this.selectedLeftAlbum.id);
            }
          }
        }
      }

      // Selecting all on the page
      if (!this.isTaggingViewOpen()) {
        if (
          event.keyCode === ConfigKey.lowerA ||
          event.keyCode === ConfigKey.upperA
        ) {
          this.selectAllImg();
        }
      }

      // Oped Download modal
      if (
        this.hasDownloadAccess &&
        this.projectAuthority !== ProjectRole.ROLE_TALENT &&
        !this.isTaggerRole
      ) {
        if (
          event.keyCode === ConfigKey.lowerD ||
          event.keyCode === ConfigKey.upperD
        ) {
          this.downloadService
            .downloadAssets(
              this.isEnlargeView,
              this.projectDetailPermission,
              this.userAuthority,
              this.projectAuthority,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {},
              (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              },
            );
        }
      }

      // Custom Album Tagging using Tag Keys
      if (
        this.isLeftAlbumActive &&
        !this.activatedLeftProjectAlbum &&
        !this.isTaggingView1Active &&
        !this.isTaggingView2Active
      ) {
        this.selectedLeftAlbumKeys.forEach((albumKeyObj) => {
          if (event.key === albumKeyObj.tagKey.toString()) {
            if (this.selectedImagesStore.hasEntities()) {
              this.callAlbumTaggingKeys(albumKeyObj.id);
            }
          }
        });
      }

      // Project Album Tagging using Tag Keys
      if (
        this.isLeftAlbumActive &&
        this.activatedLeftProjectAlbum &&
        !this.isTaggingView1Active &&
        !this.isTaggingView2Active
      ) {
        this.selectedLeftProjectAlbumKeys.forEach((albumKeyObj) => {
          if (
            event.key.toLowerCase() ===
            albumKeyObj.tagKey.toString().toLowerCase()
          ) {
            if (this.selectedImagesStore.hasEntities()) {
              this.callProjectAlbumTaggingKeys(albumKeyObj);
              // this.logger.log('albumKeyObj.tagKey ==>', albumKeyObj.tagKey);
            }
          }
        });
      }

      if (
        event.keyCode === ConfigKey.lowerX ||
        event.keyCode === ConfigKey.upperX
      ) {
        if (
          !this.isTaggingView1Active &&
          !this.isTaggingView2Active &&
          !this.isConflictsActive
        ) {
          if (
            !this.isTaggerRole &&
            (this.userAuthority === UserRole.ROLE_ADMIN ||
              ((this.projectAuthority === ProjectRole.ROLE_EXEC ||
                (this.projectAuthority === ProjectRole.ROLE_ALBUMVIEWER &&
                  !this.projectDetailPermission.permissionDTO.execAccess)) &&
                this.projectDetailPermission.permissionDTO
                  .releaseExcludeAccess))
          ) {
            if (this.selectedImagesStore.hasEntities()) {
              if (this.selectedImagesStore.size() > 0) {
                this.onClickAdminExcludeRelease();
              }
            }
          } else if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
            if (this.selectedImagesStore.size() === 1) {
              if (
                (this.selectedProjectValue !== 3 ||
                  this.selectedProjectValue !== 4) &&
                this.selectedImagesStore.getHeadItem().status === 1
              ) {
                this.onClickTelentApproveReject(
                  this.selectedImagesStore.getHeadItem(),
                );
              }
            }
          }
        }
      }

      if (event.keyCode === ConfigKey.lowerB) {
        if (
          this.selectedImagesStore.hasEntities() &&
          !this.isTaggingView1Active &&
          !this.isTaggingView2Active &&
          !this.isConflictsActive &&
          !this.isModelWindowOpen
        ) {
          // here to call modal
          this.dialogService.open(BookMarkComponent, {
            panelClass: [
              'fs-dialog-panel',
              'fs-dialog-size-lg-panel',
              'fs-dialog-link-panel',
            ],
            data: {
              projectId: this.viewProjectService.getProjectId(),
              currentFilter: this.currentFilter,
            },
          });
        }
      }
    }
  }

  imageProtectDragStart(e) {
    e.stopPropagation();
    this.dragImages(e);
  }

  dragImages(e) {
    // please do commen out  and only isDragStart is changed as true -- Shivaji
    e.stopPropagation();
    this.getImgSizeOnViewProject =
      this.projectDetailPermission.viewSettingDTO.size;
    if (Number(this.getImgSizeOnViewProject) === 1) {
      this.setGhostWidth = 300;
      this.setGhostHeight = 200;
    } else if (Number(this.getImgSizeOnViewProject) === 2) {
      this.setGhostWidth = 200;
      this.setGhostHeight = 135;
    } else if (Number(this.getImgSizeOnViewProject) === 3) {
      this.setGhostWidth = 100;
      this.setGhostHeight = 65;
    }

    if (document.body.lastElementChild.getAttribute('class') === 'imge') {
      document.body.lastElementChild.remove();
    }
    const getElementToDragable = this.sectionToDrag.nativeElement;
    const dragableEln = getElementToDragable.cloneNode(true); // TODO try to refactor it later and do not use viewChild

    dragableEln.style.backgroundColor = 'gray';
    dragableEln.style.position = 'absolute';
    dragableEln.style.width = this.setGhostWidth + 'px';
    dragableEln.style.height = this.setGhostHeight + 'px';
    dragableEln.style.lineHeight = this.setGhostHeight + 'px';
    dragableEln.style.textAlign = 'center';
    dragableEln.style.border = '1px solid blue';
    dragableEln.style.color = '#fff';
    dragableEln.style.fontWeight = 'bold';
    dragableEln.style.fontSize = '75%';
    dragableEln.style.left = '-500px';
    dragableEln.innerHTML =
      this.selectedImagesStore.size() + ' images selected';

    dragableEln.id = 'ghostimg';
    document.body.appendChild(dragableEln);
    e.dataTransfer.setDragImage(dragableEln, 0, 0);
    e.dataTransfer.effectAllowed = 'copy';
    e.dataTransfer.dropEffect = 'copy';
  }

  onLoad(i) {
    this.imageLoaded[i] = true;
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onClickGroupSingleIcon(imgData) {
    if (imgData.exicutiveRejected === 1) {
      this.exicutiveAssetIdforToggle = imgData.id;

      this.dialogService
        .open(GroupSingleComponent, {
          data: {
            assetId: this.exicutiveAssetIdforToggle,
          },
          panelClass: [
            'fs-dialog-panel',
            'fs-dialog-width-300-panel',
            'fs-dialog-link-panel',
          ],
        })
        .subscribe((data) => {
          this.updatedObjExecutiveUserActionsFromGS(data);
        });
      this.isGroupSinglePopupShown = true;
    } else {
      this.alertCustom(this.translate.instant('view-project.alert10'));
    }
  }

  onClickDesignation(imgData) {
    imgData.executiveWideEditDTOList.forEach((list) => {
      if (list.userId === this.currentUser.id && list.actionTypeId === 1) {
        imgData.exicutiveRejected = 1;
      }
    });

    if (imgData.exicutiveRejected === 1) {
      this.exicutiveAssetIdforToggle = imgData.id;

      this.dialogService
        .open(Designation, {
          data: {
            assetId: this.exicutiveAssetIdforToggle,
          },
          panelClass: [
            'fs-dialog-panel',
            'fs-dialog-width-300-panel',
            'fs-dialog-link-panel',
          ],
        })
        .subscribe((data) => {
          this.updatedObjExecutiveUserActions(data);
        });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert10'));
    }
  }

  // Executive datg tools handller
  onClickLikeIcon(imgData) {
    const obj = this.prepareExecutiveAlbumTagObjToggle(
      'like',
      imgData.id,
      !imgData.exicutiveLike ? 1 : 0,
    );
    this.callExicutiveAlbumTagToggleAPI(obj);
  }

  updatedObjExecutiveUserActions(evt) {
    const obj = this.prepareExecutiveAlbumTagObjToggle(
      'available',
      evt.assetId,
      evt.designation,
    );
    this.callExicutiveAlbumTagToggleAPI(obj);
  }

  closeDestinationPopup(evt) {
    this.isDesignationPopupShow = evt;
  }

  updatedObjExecutiveUserActionsFromGS(evt) {
    const obj = this.prepareExecutiveAlbumTagObjToggle(
      'gs',
      evt.assetId,
      evt.groupSingle,
    );
    this.callExicutiveAlbumTagToggleAPI(obj);
  }

  closeGroupSinglePopupGS(evt) {
    this.isGroupSinglePopupShown = evt;
  }

  callExicutiveAlbumTagToggleAPI(obj: any) {
    this.viewProjectService
      .postExecutiveAlbumTagToggle(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.closeGroupSinglePopupGS(false);
          this.closeDestinationPopup(false);
          const imageIndex = this.projectImageData.assetInfoDTOList
            .map(function (e) {
              return e.id;
            })
            .indexOf(obj.assetId);

          if (this.selectedProjectValue === 3 || this.isExecutiveAlbumView) {
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].executiveSignoffDTOList = res;
          } else {
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].executiveWideEditDTOList = res;
          }

          res.forEach((element) => {
            if (element.userId === this.currentUser.id) {
              if (element.actionTypeId === 3) {
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].exicutiveRejected = 3;
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].exicutiveLike = element.like;
              } else {
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].exicutiveRejected = 1;
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].exicutiveLike = element.like;
              }
            }
          });
          if (obj.action === 'undo') {
            this.projectImageData.assetInfoDTOList[imageIndex].exicutiveLike =
              false;
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].exicutiveRejected = 0;
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  onClickExicutiveAlbumTag(actionTypeId: number, assetID: number) {
    const obj = this.prepareExecutiveAlbumTagObj(actionTypeId, assetID);
    this.viewProjectService
      .postExecutiveAlbumTag(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const imageIndex = this.projectImageData.assetInfoDTOList
            .map(function (e) {
              return e.id;
            })
            .indexOf(assetID);

          if (this.selectedProjectValue === 3 || this.isExecutiveAlbumView) {
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].executiveSignoffDTOList = res;
          } else if (this.selectedProjectValue === 4) {
            this.projectImageData.assetInfoDTOList[
              imageIndex
            ].executiveWideEditDTOList = res;
          }

          res.forEach((element) => {
            if (element.userId === this.currentUser.id) {
              if (element.actionTypeId === 3) {
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].exicutiveRejected = 3;
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].exicutiveLike = false;
              } else {
                this.projectImageData.assetInfoDTOList[
                  imageIndex
                ].exicutiveRejected = 1;
              }
            }
          });
          this.ref.markForCheck();
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  prepareExecutiveAlbumTagObj(actionTypeId: number, assetId: number) {
    return {
      actionTypeId: actionTypeId,
      albumId: this.viewProjectService.getExecutiveAlbumID(),
      assetId: assetId,
      projectId: Number(this.projectId),
      typeId: this.isExecutiveAlbumView ? 2 : this.selectedProjectValue,
    };
  }

  prepareExecutiveAlbumTagObjToggle(
    action: string,
    assetId: number,
    data: any,
  ) {
    return {
      action: action,
      albumId: this.viewProjectService.getExecutiveAlbumID(),
      assetId: assetId,
      projectId: this.projectId,
      data: data,
    };
  }

  onClickUndoIcon(imgData) {
    this.dialogService
      .openConfirmationDialog({
        message: this.translate.instant('view-project.confirm23'),
        title: this.translate.instant('view-project.confirm22'),
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (state: boolean) => {
          if (state) {
            const obj = this.prepareExecutiveAlbumTagObjToggle(
              'undo',
              imgData.id,
              '',
            );
            this.callExicutiveAlbumTagToggleAPI(obj);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  onClickTelentApproveReject(imageData) {
    const imgLeft = Utils.talentRejectionCalculation(
      imageData,
      this.talentUseSetup,
      this.projectDetailPermission,
      this.domainObjAbbr,
    );
    if (imgLeft <= 0) {
      const warningMessage = Utils.onClickTalentApproveReject(
        imageData,
        this.talentUseSetup,
        this.projectDetailPermission,
        this.domainObjAbbr,
      );
      this.alertCustom(warningMessage);
    } else {
      // Call web service
      this.projectImageData = this.viewProjectService.mergeWithProjectData(
        this.projectImageData,
      );
      const obj = {
        assetId: imageData.id,
        rejected: imageData.rejected,
        projectId: this.projectId,
        tagId: 0,
      };
      this.viewProjectService
        .putSetTalentActionRejected(obj)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
              if (res.status) {
                this.projectDetailPermission =
                  this.viewProjectService.mergeWithProjectDetailPermissionData(
                    this.projectDetailPermission,
                  );
                this.projectDetailPermission.talentProjectInfoDTO =
                  res.talentProjectInfoDTO;
                this.projectDetailPermission.talentStatDTOList =
                  res.talentStatDTOList;
                this.viewProjectService.setProjectDetailPermissionData(
                  this.projectDetailPermission,
                );
                const imgIndex = this.projectImageData.assetInfoDTOList
                  .map(function (e) {
                    return e.id;
                  })
                  .indexOf(imageData.id);
                if (imgIndex !== -1) {
                  this.projectImageData.assetInfoDTOList[imgIndex].rejected =
                    res.rejected;
                  this.ref.markForCheck();
                }
              } else {
                this.alertCustom(
                  this.translate.instant('view-project.alert11'),
                );
              }
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  onClickAdminExcludeRelease() {
    // Earlier all the below logix was inside else block now we are doing toggle for exclude release 15.05.20
    this.loaderService.displayLoader(true);
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    const obj = {
      assetIds: this.selectedImagesStore.getItemsIds(),
      currentAssetIds: Utils.getImageIdsBasedOnPage(
        this.currentPageNumber,
        this.noOfImages,
        this.projectImageIds,
      ),
      projectId: Number(this.projectId),
    };
    if (obj.assetIds.length > 1000) {
      obj.currentAssetIds.forEach((ids, index) => {
        if (obj.assetIds.indexOf(ids) !== -1) {
          this.imageLoaded[index] = false;
        }
      });
    }
    this.viewProjectService
      .putSetExcudeRelease(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            res.t.forEach((element) => {
              const index = this.projectImageData.assetInfoDTOList
                .map(function (e) {
                  return e.id;
                })
                .indexOf(element.assetId);
              if (index !== -1) {
                this.imageLoaded[index] = true;
                this.projectImageData.assetInfoDTOList[index].excluded =
                  element.excluded;
                this.projectImageData.assetInfoDTOList[index].releasedDate =
                  element.releasedDate;
              }
            });
          } else {
            this.alertCustom(res.message);
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  onRightClick() {
    return false;
  }

  openImageHistory({ imageData, historyReportType }) {
    this.currentImageID = imageData.id;
    this.historyReportType = historyReportType;
    if (
      historyReportType === 'admin' ||
      (historyReportType === 'exclusive' &&
        (this.userAuthority === UserRole.ROLE_ADMIN ||
          this.projectAuthority === ProjectRole.ROLE_EXEC) &&
        !this.isTaggerRole)
    ) {
      this.dialogService.open(ImageHistoryComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-lg-panel',
          'fs-dialog-link-panel',
        ],
        data: {
          projectId: this.viewProjectService.getProjectId(),
          currentImageID: this.currentImageID,
          historyReportType: this.historyReportType,
        },
      });
    }
  }

  openImageTransaction(imgData: ImageData) {
    if (!this.isTaggerRole) {
      this.dialogService.open(ImageTransactionComponent, {
        panelClass: [
          'fs-dialog-panel',
          'fs-dialog-size-ls-panel',
          'fs-dialog-link-panel',
        ],
        data: {
          imgData: imgData,
        },
      });
    }
  }

  customAlbumView(imgData: any) {
    this.dialogService.open(CustomAlbumComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-link-panel',
      ],
      data: { customAlbumData: imgData.customAlbums },
    });
  }

  ngOnDestroy() {
    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }
    super.ngOnDestroy();
  }
}
