import { Utils } from './../../shared/utils/utils';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { LauncherService } from './launcher.service';
import { TranslateService } from '@ngx-translate/core';
import { Group } from '../../shared/models/projectlist.model';
import { UserService } from '../../shared/services/user.service';
import { ViewProjectService } from '../../shared/services/view-project.service';
import { SidebarService } from '../project/sidebar-service/sidebar-service';
import { LoaderService } from '../../shared/services/loader.service';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { AppStateService } from '../../shared/services/app-state.service';
import { DomainConfigService } from '../../shared/services/domain-config.service';
import { DomainConfig } from '../../shared/models/domainConfig.model';
import { UnsubscriberComponent } from '../../shared/components/unsubscriber/unsubscriber.component';
import { DepartmentService } from '../department/department.service';
import { ViewGalleryPriorityPixStateService } from '../../shared/services/view-gallery-priority-pix.service';
import { DashboardEventBus } from '../dashboard-manager/dashboard-event-bus';
import { DashboardLauncherEvent } from '../dashboard-manager/dashboard-manager-rules/dashboard-launcher/events/dashboard-launcher-event';
import { DashboardLauncherHandler } from '../dashboard-manager/dashboard-manager-rules/dashboard-launcher/handler/dashboard-launcher-handler';
import { DashboardLauncherFavouriteIconHandler } from '../dashboard-manager/dashboard-manager-rules/dashboard-launcher/handler/dashboard-launcher-favourite-icon-handler';
import {ApiErrorHandlerService} from '../../shared/services/api-error-handler.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'fs-launcher',
  templateUrl: './launcher.component.html',
  styleUrls: ['./launcher.component.scss'],
})
export class LauncherComponent
  extends UnsubscriberComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('favDiv') favDiv: ElementRef;
  @ViewChild('scrollDiv') scrollDiv: ElementRef;

  tags: Array<string> = [];
  leftSideList: Group[];
  groupedProject: Group[];
  leftSideListClone: Array<any> = [];

  favProjects = [];
  favProjectlength;
  favProjectclass;
  searchList: any = [];
  sum = 100;
  scrollDistance = 1;
  scrollUpDistance = 2;
  throttle = 300;
  indexAddedOnScroll = 200;
  emptyList = false;
  domainConfig: DomainConfig;
  DEPARTMENT = 'Department';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setHeightToProjectListContainer();
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private launcherService: LauncherService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private departmentService: DepartmentService,
    private viewGalleryPriorityPixStateService: ViewGalleryPriorityPixStateService,
    private loaderService: LoaderService,
    private sessionStorage: SessionStorageService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private appStateService: AppStateService,
    private domainConfigService: DomainConfigService,
    private dashboardEventBus: DashboardEventBus,
  ) {
    super();
    this.searchList = [];
  }

  ngOnInit() {
    this.userService.resetData();
    this.userService.resetGalleryData();
    this.launcherService.setSelectedDepLogo('');
    this.viewProjectService.resetProjectID();
    this.dialogService.closeAll();
    this.viewGalleryPriorityPixStateService.setHandledPrioritySession(false);

    this.launcherService.favProjectListGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((favProjects) => {
        this.favProjects = favProjects;
      });

    this.launcherService.projectListGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.searchList = res.search;
        }
      });

    this.appStateService.displayCommonDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }) => {
        this.emptyList = loaded;
        if (this.emptyList) {
          this.router.navigateByUrl('/');
        }
      });

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainConfig) => {
        if (domainConfig) {
          this.domainConfig = domainConfig;
        }
      });

    this.getProjectList();
  }

  getfavclass = (favcount) => {
    this.favProjectlength = favcount;

    const rowOptions = [
      { range: [1, 2], class: 'onerow_fav' },
      { range: [3, 4], class: 'tworow_fav' },
      { range: [5, 6], class: 'threerow_fav' },
    ];

    for (const option of rowOptions) {
      const [min, max] = option.range;
      if (this.favProjectlength >= min && this.favProjectlength <= max) {
        this.favProjectclass = option.class;
        return;
      }
    }

    this.favProjectclass = 'norow_fav';
  };

  getWindowRef() {
    return window;
  }

  getProjectList() {
    this.groupedProject = [];
    this.loaderService.displayLoader(true);
    this.launcherService
      .getProjectListApiCall()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (data.search.length > 0 && data.group.length > 0) {
            if (data.search.length === 1) {
              this.navigateToProject('Project', data.search[0]);
            } else {
              this.searchList = data.search;
              this.groupedProject = data.group;
              this.divideArrayList(this.groupedProject);
              this.getfavclass(data.fav.length);
            }
          } else {
            this.alertCustom(this.translate.instant('launcher.emptyList'));
          }

          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.router.navigateByUrl('/');
        },
      );
  }

  alertCustom(displayText: string) {
    this.dialogService
      .openInformationDialog({
        title: this.translate.instant('view-project.confirm22'),
        message: displayText,
      })
      .subscribe(() => {
        this.appStateService.setDisplayCommonDialog({ loaded: true });
      });
  }

  divideArrayList(groupedProject) {
    this.leftSideList = groupedProject;
    this.appendItems(0, this.sum);
    this.setHeightToProjectListContainer();
  }

  addToFav(dataObj) {
    this.dashboardEventBus.emit(
      new DashboardLauncherEvent(
        new DashboardLauncherFavouriteIconHandler(
          true,
          dataObj,
          this.setHeightToProjectListContainer,
          this.getfavclass,
        ),
      ),
    );
  }

  ngAfterViewInit() {
    this.setHeightToProjectListContainer();
  }

  setHeightToProjectListContainer = () => {
    this.scrollDiv.nativeElement.style.maxHeight =
      this.getWindowRef().innerHeight -
      this.favDiv.nativeElement.clientHeight -
      346 +
      'px';
    this.scrollDiv.nativeElement.style.height =
      this.getWindowRef().innerHeight -
      this.favDiv.nativeElement.clientHeight -
      346 +
      'px';
  };

  navigateToProject(groupType, selectedProject) {
    this.dashboardEventBus.emit(
      new DashboardLauncherEvent(
        new DashboardLauncherHandler(groupType, selectedProject),
      ),
    );
  }

  updatedFavStaus(event) {
    this.setHeightToProjectListContainer();
    this.groupedProject = event;
    this.divideArrayList(this.groupedProject);
    this.getfavclass(this.favProjects.length);
  }

  onScrollDown() {
    // add another 20 items
    const start = this.sum;
    this.sum += this.indexAddedOnScroll;
    this.appendItems(start, this.sum);
  }

  addItems(startIndex, endIndex, _method) {
    this.leftSideListClone = [];
    for (let i = 0; i < this.sum; ++i) {
      if (this.leftSideList[i]) {
        this.leftSideListClone[_method](this.leftSideList[i]);
      }
    }
  }

  appendItems(startIndex, endIndex) {
    this.addItems(startIndex, endIndex, 'push');
  }

  prependItems(startIndex, endIndex) {
    this.addItems(startIndex, endIndex, 'unshift');
  }
}
