import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './layout/header/header.component';

import { HelpWindowComponent } from './layout/help/help.component';
import { DownloadManagerComponent } from './layout/download-manager/download-manager.component';
import { NumberIncrementerComponent } from './layout/number-incrementer/number-incrementer.component';
import { TranslateModule } from '@ngx-translate/core';

import { NgxWebstorageModule } from 'ngx-webstorage';

import { ImagebuttonhoverDirective } from './directive/imagebuttonhover.directive';
import { AutofocusDirective } from './directive/autofocus.directive';
import { FocusRemoverDirective } from './directive/button-focus-remover.directive';
import { ImagePreviewDirective } from './directive/image-preview.directive';
import { PreventDragDirective } from './directive/prevent-drag.directive';

// breadcrum
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { DownloadManagerService } from './layout/download-manager/download-manager.service';

import { ImageInfoComponent } from '../dashboard/project/dashboard-shared/left-menu/menus/imageinfo/imageinfo.component';
import { ImageInfoService } from '../dashboard/project/dashboard-shared/left-menu/menus/imageinfo/imageinfo.service';

import { NgSelectModule } from '@ng-select/ng-select';
import { MatDialogModule } from '@angular/material/dialog';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { PopupContainerComponent } from './components/popup-container/popup-container.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { ImageNamePanelComponent } from './components/image-name-panel/image-name-panel.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LazyModule } from './layout/lazy-load-intersection/lazy.module';
import { UserAvatarMenuPopupComponent } from './layout/header/user-avatar-menu-popup/user-avatar-menu-popup.component';
import { FullNamePipe } from './pipe/full-name.pipe';
import { PopupArrowPositionDirective } from './directive/popup-arrow-position.directive';
import { WINDOW_PROVIDERS } from './services/window.service';
import { MatSliderModule } from '@angular/material/slider';
import { SecureImagePipe } from './pipe/secure-image.pipe';
import { PublishedTagsPipe } from './pipe/published-tags.pipe';
import { SafePipe } from './pipe/safe.pipe';
import { AssetsStatusPipe } from './pipe/assets-status.pipe';
import { SortByPipe } from './pipe/sort-by.pipe';
import { FilterNameDisplayPipe } from './pipe/filter-name-display.pipe';
import { HighlightPipe } from './pipe/highlight.pipe';
import { ViewProjectService } from './services/view-project.service';
import { UrlEncryptService } from './services/url-encrypt.service';
import { ApiService } from './services/api.service';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth-guard.service';
import { LoaderService } from './services/loader.service';
import { TaggerService } from './services/tagger.service';
import { UserDataService } from './services/userdata.service';
import { AuthServerProvider } from './services/auth.jwt.service';
import { Modalstatusservice } from './services/modalstatus.service';
import { ReloadGuard } from './services/reload-guard.service';
import { CurrentStateService } from './services/current.state.service';
import { PreviousRouteService } from './services/previous-route.service';
import { DomainConfigService } from './services/domain-config.service';
import { HelperDialogComponent } from './layout/header/fs-helper-dialog/helper-dialog.component';
import { ContactDetailsComponent } from './layout/header/fs-contact-details/contact-details.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ImageNotesDialogComponent } from '../dashboard/project/dashboard-shared/left-menu/menus/imageinfo/image-info-dialog/image-notes-dialog.component';
import { SingleExcludeService } from './services/single-exclude.service';
import { AccordionPanelComponent } from './components/expansion-panel-header/accordion-panel.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ContextMenuHandlerDirective } from './directive/context-menu-handler.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TextOverflowEllipsisDirective } from './directive/text-overflow-ellipsis.directive';
import { ImageColorTagPanelComponent } from './components/image-color-tag-panel/image-color-tag-panel.component';
import { NgSelectDropDownMinHeightDirective } from './directive/ng-select-dropdown-min-height.directive';
import { AlternativeImageDirective } from './directive/alternative-image.directive';
import { LogoService } from './services/logo.service';
import { FsCookieService } from './services/fs-cookie.service';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { UnsubscriberComponent } from './components/unsubscriber/unsubscriber.component';
import { MetaDataService } from './services/meta-data.service';
import { CookieBannerService } from './components/cookie-banner/cookie-banner.service';
import { LanguageService } from './services/language.service';
import { TableHeaderDirective } from './directive/table-header.directive';
import { OverlayZIndexDirective } from './directive/overlay-z-index-directive.directive';
import { DisableCharacterInputDirective } from './directive/disable-character-input.directive';
import { IpService } from './services/ip-service.service';
import { ImageTransactionsService } from '../dashboard/project/project-detail/view-project/image-transaction/image-transactions.service';
import { ImageSrcWithAuthTokenDirective } from './directive/image-src-with-auth-token.directive';
import { ShortcutDetailsComponent } from './components/shortcut-details/shortcut-details.component';
import { DownloadService } from '../dashboard/project/dashboard-shared/top-menu/download/download.service';
import { AppUpdatesComponent } from './components/app-updates/app-updates.component';
import { AppUpdatesService } from './components/app-updates/app-updates.service';
import { PreventSpecialCharsDirective } from './directive/asset-notes-error-keys.directive';
import { AnnounceImgViewerComponent } from './components/app-updates/announce-img-viewer/announce-img-viewer.component';
import { VirtualForDirective } from './directive/virtual-for.directive';
import { ExclusiveSensitiveAssetDialogComponent } from './components/exclusive-sensitive-asset-dialog/exclusive-sensitive-asset-dialog.component';
import { PasswordVisibilityDirective } from './directive/password-visibility.directive';
import { UserTaggerRoleGuard } from './services/user-tagger-role-guard.service';
import { ExpandableListComponent } from './components/expandable-list/expandable-list.component';
import { ExpandableListTemplateDirective } from './directive/expandable-list-template.directive';
import { ProjectAssetsBaseUrl } from './pipe/asset-uase-url.pipe';
import { TreeModule } from '@bugsplat/angular-tree-component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SidebarAccordionDynamicHeightDirective } from './directive/sidebar-accordion-dynamic-height.directive';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatStepperModule } from '@angular/material/stepper';
import { AngularDeviceInformationService } from 'angular-device-information';
import { LocalizeDatePipe } from './pipe/localize-date.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DuplicateMenuService } from './services/duplicate-menu.service';
import { OriginalNamesService } from './services/original-names.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    LazyModule,
    MatTooltipModule,
    NgScrollbarModule,
    NgSelectModule,
    NgxWebstorageModule.forRoot(),
    TreeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatStepperModule,
    MatTableModule,
    PortalModule,
    OverlayModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    DragDropModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    HeaderComponent,
    SecureImagePipe,
    PublishedTagsPipe,
    SafePipe,
    ProjectAssetsBaseUrl,
    AssetsStatusPipe,
    SortByPipe,
    LocalizeDatePipe,
    PreventSpecialCharsDirective,
    AutofocusDirective,
    NgSelectDropDownMinHeightDirective,
    ContextMenuHandlerDirective,
    SidebarAccordionDynamicHeightDirective,
    AlternativeImageDirective,
    VirtualForDirective,
    PasswordVisibilityDirective,
    DisableCharacterInputDirective,
    FocusRemoverDirective,
    TextOverflowEllipsisDirective,
    ImagebuttonhoverDirective,
    PreventDragDirective,
    BreadcrumbComponent,
    TableHeaderDirective,
    ExpandableListTemplateDirective,
    DownloadManagerComponent,
    ImagePreviewDirective,
    PreventDragDirective,
    AppUpdatesComponent,
    AnnounceImgViewerComponent,
    ImageSrcWithAuthTokenDirective,
    NumberIncrementerComponent,
    ContactDetailsComponent,
    FilterNameDisplayPipe,
    HighlightPipe,
    ImageInfoComponent,
    HelpWindowComponent,
    MessageDialogComponent,
    PaginatorComponent,
    PopupContainerComponent,
    ImageNamePanelComponent,
    ExclusiveSensitiveAssetDialogComponent,
    UserAvatarMenuPopupComponent,
    ExpandableListComponent,
    ShortcutDetailsComponent,
    FullNamePipe,
    PopupArrowPositionDirective,
    OverlayZIndexDirective,
    ImageNotesDialogComponent,
    HelperDialogComponent,
    SnackBarComponent,
    AccordionPanelComponent,
    SpinnerComponent,
    ImageColorTagPanelComponent,
    CookieBannerComponent,
  ],
  providers: [
    ...WINDOW_PROVIDERS,
    ViewProjectService,
    UrlEncryptService,
    ApiService,
    LogoService,
    DownloadService,
    FsCookieService,
    AngularDeviceInformationService,
    UserService,
    MetaDataService,
    LoaderService,
    TaggerService,
    IpService,
    DuplicateMenuService,
    OriginalNamesService,
    AuthGuard,
    UserTaggerRoleGuard,
    AuthServerProvider,
    Modalstatusservice,
    UserDataService,
    CurrentStateService,
    LanguageService,
    AppUpdatesService,
    ReloadGuard,
    PreviousRouteService,
    SecureImagePipe,
    SortByPipe,
    ImageTransactionsService,
    DownloadManagerService,
    CookieBannerService,
    ImageInfoService,
    DomainConfigService,
    SingleExcludeService,
    MatDatepickerModule,
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    SecureImagePipe,
    SortByPipe,
    PublishedTagsPipe,
    SafePipe,
    AssetsStatusPipe,
    ProjectAssetsBaseUrl,
    DownloadManagerComponent,
    ShortcutDetailsComponent,
    ExpandableListComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    RouterModule,
    ImagebuttonhoverDirective,
    PreventSpecialCharsDirective,
    ImagePreviewDirective,
    PreventDragDirective,
    DisableCharacterInputDirective,
    TextOverflowEllipsisDirective,
    AutofocusDirective,
    NgSelectDropDownMinHeightDirective,
    AlternativeImageDirective,
    SidebarAccordionDynamicHeightDirective,
    PasswordVisibilityDirective,
    ImageSrcWithAuthTokenDirective,
    VirtualForDirective,
    FocusRemoverDirective,
    BreadcrumbComponent,
    AppUpdatesComponent,
    AnnounceImgViewerComponent,
    NumberIncrementerComponent,
    LocalizeDatePipe,
    ContactDetailsComponent,
    FilterNameDisplayPipe,
    HighlightPipe,
    ImageInfoComponent,
    LazyModule,
    NgxWebstorageModule,
    TreeModule,
    NgScrollbarModule,
    NgSelectModule,
    MatDialogModule,
    HelpWindowComponent,
    PaginatorComponent,
    MessageDialogComponent,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatRadioModule,
    MatTableModule,
    PortalModule,
    OverlayModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    ImageNamePanelComponent,
    ExclusiveSensitiveAssetDialogComponent,
    DragDropModule,
    FullNamePipe,
    PopupArrowPositionDirective,
    OverlayZIndexDirective,
    ExpandableListTemplateDirective,
    TableHeaderDirective,
    MatSliderModule,
    AccordionPanelComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    ContextMenuHandlerDirective,
    ImageColorTagPanelComponent,
  ],
})
export class SharedModule {}
