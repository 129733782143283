<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
    'left-menu.tab-bar.sidemenus.administration.buttonse.original-names' | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content class="d-flex flex-row">
  <div class="w-100">
    <tr class="d-flex original-names-header">
      <th class="text-start">
        {{
        'left-menu.tab-bar.sidemenus.administration.original-names.labels.id' | translate
        }}
      </th>
      <th class="text-start">
        {{
        'left-menu.tab-bar.sidemenus.administration.original-names.labels.currentName' | translate
        }}
      </th>
      <th class="text-start">
        {{
        'left-menu.tab-bar.sidemenus.administration.original-names.labels.originalName' | translate
        }}
      </th>
      <th class="text-start">
        {{
        'left-menu.tab-bar.sidemenus.administration.original-names.labels.description' | translate
        }}
      </th>
    </tr>
      <cdk-virtual-scroll-viewport
        class=""
        minBufferPx="68"
        maxBufferPx="1360"
        itemSize="34"
      >
        <tr class="original-names-container w-100 d-flex" *cdkVirtualFor="let selectedImage of originalNamesList; index as ind">
          <td class="text-start fs-break-word">{{selectedImage.id}}</td>
          <td class="text-start fs-break-word">{{selectedImage.currentName}}</td>
          <td class="text-start fs-break-word">{{selectedImage.originalName}}</td>
          <td class="text-start fs-break-word">{{selectedImage.description}}</td>
        </tr>
      </cdk-virtual-scroll-viewport>
    </div>

  <ng-container *ngIf="originalNamesList.length === 0">
    <div class="p-5 text-center">
      {{ 'global.no-record' | translate }}
    </div>
  </ng-container>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExport(exportType.Excel)"
  >
    {{
    'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
      | translate
    }}
    as xls
  </button>

  <button
    class="fs-dialog-btn w-auto ml-3"
    (click)="onClickExport(exportType.Pdf)"
  >
    {{
    'left-menu.tab-bar.sidemenus.administration.projectsummary-report.button.export'
      | translate
    }}
    as pdf
  </button>
  <button class="fs-dialog-btn w-auto ml-3" (click)="closeWindow()">
    {{ 'left-menu.tab-bar.advance-search.buttons.cancel' | translate }}
  </button>
</div>
