import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Activemodal } from '../../../../../../../shared/models/activemodal.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { ExportType } from '../../../../../../../shared/models/enum/ExportType';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-filteredfilename-list',
  templateUrl: './filteredfilename-list.component.html',
  styleUrls: ['./filteredfilename-list.component.scss'],
})
export class FilteredfilenameListComponent
  extends UnsubscriberComponent
  implements OnInit
{
  @ViewChild('listTextArea') listTextArea;
  activemodal: Activemodal = new Activemodal();
  filteredList = '';
  selectAll: boolean;
  arrListToExport: any = [];
  arrListOfIds: any = [];
  constantsGlobalData: any;
  exportType = ExportType;

  constructor(
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private userService: UserService,
    private dialogRef: MatDialogRef<FilteredfilenameListComponent>,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((idsList) => {
        this.arrListOfIds = idsList;
      });

    if (this.arrListOfIds.length) {
      this.loaderService.displayLoader(true);
      const data = {
        assetIds: this.arrListOfIds,
        projectId: this.viewProjectService.getProjectId(),
      };
      this.viewProjectService
        .getImagesNameBasedOnAssetsId(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((resData) => {
          this.loaderService.displayLoader(false);
          this.filterImageNames(resData);
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    } else {
      this.closeWindow();
      this.alertCustom('No data available');
    }
  }

  filterImageNames(imageList: any) {
    this.filteredList = '';
    for (const list of imageList) {
      this.filteredList += list + '\n';
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onClickSelectAll() {
    this.selectAll = !this.selectAll;
    const inputElem = this.listTextArea.nativeElement;
    if (this.selectAll) {
      inputElem.select();
    }
  }

  print() {
    const printContents = document.getElementById('filteredList').innerHTML;
    const popupWin = window.open(
      '',
      '_blank',
      'top=0,left=200,height=1000,width=1000',
    );
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  onClickExport(reportType) {
    this.arrListToExport = [];
    const tempArray = this.filteredList.split('\n');

    tempArray.forEach((item) => {
      this.arrListToExport.push({
        name: item,
      });
    });

    if (reportType === this.exportType.Excel) {
      this.userService.exportReport(
        'Filtered Filename List',
        this.arrListToExport,
        'FilteredFilenameList.xlsx',
      );
    } else {
      const colNames = this.userService.getColumnsName(this.arrListToExport[0]);
      this.userService.exportPDFData(this.arrListToExport, colNames);
    }
  }

  closeWindow() {
    this.selectAll = false;
    this.dialogRef.close();
  }
}
