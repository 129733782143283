<div class="container-fluid dep-exe-container">
  <div class="block-header">
    <span
      >{{ 'dashboard.galleries' | translate }} (
      {{ galleryInfo.length }} )</span
    >
    <div class="search-project-comp">
      <fs-search-project
        [searchList]="galleryInfo"
        [fromDepartment]="true"
      ></fs-search-project>
    </div>
  </div>

  <div style="overflow: initial; height: calc(100vh - 144px)">
    <ng-scrollbar autoHeightDisabled="false" trackY="'true'">
      <div class="flex-container">
        <div class="d-flex flex-wrap">
          <div
            class="flex-item project-block"
            *ngFor="let group of galleryInfo"
          >
            <div class="header-block department-block align-items-center">
              <div class="img-block">
                <img
                  [src]="'assets/images/project/' + (group.groupName | lowercase) + '.jpg' | projectAssetsBaseUrl"
                  [alternativeImage]="alternativeLogo$ | async"
                />
              </div>
              <div class="header-name">{{ group.groupName }}</div>
            </div>
            <div class="body-content" *ngIf="group.items.length">
              <div>
                <div class="fs-group-items" *ngFor="let item of group.items">
                  <a
                    class="fs-txt-white-dark-color fs-word-break-all fs-txt-white-grey-color-hover fs-cursor-pointer"
                    (click)="galListInfo(group, item)"
                  >
                    {{ item.label }}
                    {{
                      selectedProject === 3 || selectedProject === 4
                        ? ''
                        : '(' + item.setupCount + ')'
                    }}
                    {{
                      selectedProject === 3 || selectedProject === 4
                        ? ''
                        : ('dashboard.setups' | translate)
                    }}
                    {{ 'dashboard.posted' | translate }} {{ item.createdAt }}
                  </a>
                  <a
                    class="fs-txt-white-dark-color fs-txt-white-grey-color-hover add-favourite fs-cursor-pointer"
                    [title]="'dashboard.addToShortcuts' | translate"
                    *ngIf="
                      !item.fav &&
                      selectedProject !== 3 &&
                      selectedProject !== 4
                    "
                    (click)="addToFav(item)"
                  >
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
