import { PaginationService } from './../../../pagination/pagination.service';
import { Utils } from './../../../../../../shared/utils/utils';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { AlbumsService } from '../../menus/albums/albums.service';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { WideEditAdvanceSearchService } from '../wide-edit-album/wide-edit-album.service';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-wide-edit-showall',
  templateUrl: './wide-edit-showAll.component.html',
})
export class WideEditShowAllComponent
  extends UnsubscriberComponent
  implements OnInit
{
  noOfImages: any;
  constructor(
    private loaderService: LoaderService,
    private sidebarService: SidebarService,
    private viewProjectService: ViewProjectService,
    private albumsService: AlbumsService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private router: Router,
    private paginationService: PaginationService,
    private selectedImagesStore: SelectedImagesStore,
    private wideEditAdvanceSearchService: WideEditAdvanceSearchService,
  ) {
    super();
  }

  ngOnInit() {
    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
        }
      });
  }

  onClickShowAll() {
    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    const filter = 'album:' + this.viewProjectService.getExecutiveAlbumID();
    this.viewProjectService
      .callProjectImageIDs(this.viewProjectService.getProjectId(), filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1) => {
          if (res1) {
            this.viewProjectService.setCurrentFilter(filter);
            const assetsIds = Utils.getImageIdsBasedOnPage(
              1,
              this.noOfImages,
              res1,
            );
            const data = {
              assetIds: assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter: filter,
            };

            const routingProjectId = this.viewProjectService.getProjectId();
            const routingPageNum = 1;

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.router.navigate([
                    Utils.prepareFilterNavigationURL(
                      this.viewProjectService.getProjectRole(),
                      routingProjectId,
                      filter,
                      routingPageNum,
                    ),
                  ]);
                  this.paginationService.setPaginationIndex(1);
                  this.wideEditAdvanceSearchService.resetWideEditAdvanceSearchData();
                  this.handleProjectData(resData);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.loaderService.displayLoader(false);
                },
              );
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  handleProjectData(res) {
    this.selectedImagesStore.clear();
    this.sidebarService.setAlbumImageData(res);
    this.loaderService.displayLoader(false);
  }
}
