import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { MetaDataService } from '../../../../../../shared/services/meta-data.service';
import { MetadataModel } from '../../../../project-detail/view-project/metadata/metadata.model';
import { forkJoin, Observable } from 'rxjs';
import { MetadataComponent } from '../../../../project-detail/view-project/metadata/metadata.component';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { SelectedImages } from '../../../../../../shared/models/selectedImages.model';
import { CopyrightStatuses } from '../../../../project-detail/view-project/metadata/metadata.enum';

@Component({
  selector: 'fs-meta-data-menu',
  templateUrl: './meta-data-menu.component.html',
  styleUrls: ['./meta-data-menu.component.scss']
})
export class MetaDataMenuComponent extends UnsubscriberComponent implements OnInit, OnChanges {
  @Input() isExpanded: boolean;
  selectedMetaData: MetadataModel = new MetadataModel();
  selectedImage: SelectedImages = new SelectedImages({});
  isDataLoaded: boolean;

  constructor(
    private metaDataService: MetaDataService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private dialogService: DialogService,
    public selectedImagesStore: SelectedImagesStore,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpanded.currentValue) {
      this.getMetaData();
    }
  }

  ngOnInit() {
    this.selectedImagesStore.getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getMetaData();
      });
  }

  getMetaData() {
    if (this.isExpanded && this.selectedImagesStore.size() === 1) {
      const selectedItemId = this.selectedImagesStore.getAll()[0].id;

      if (this.selectedImage.id !== selectedItemId) {
        this.selectedImage = this.selectedImagesStore.getAll()[0];
        this.isDataLoaded = false;

        const getMetaData$: Observable<MetadataModel[]> = this.metaDataService.getMetaData(
          this.selectedImagesStore.getItemsIds(),
        );

        this.selectedMetaData = new MetadataModel();

        getMetaData$.subscribe(metaData => {
          this.isDataLoaded = true;
          this.handleMetaDataResponse(metaData);
        }, () => this.isDataLoaded = true
        );
      }
    }
  };

  handleMetaDataResponse(metaData: MetadataModel[]) {
    if (metaData?.length) {
      this.selectedMetaData = metaData[0];
    } else {
      this.selectedMetaData = new MetadataModel();
    }
    this.selectedMetaData.metaCopyrightStatus = this.getCopyrightStatusLabel(this.selectedMetaData.metaCopyrightStatus);
  }

  getCopyrightStatusLabel(value): string {
    const status = CopyrightStatuses.find((item) => item.value === value);
    return status ? status.label : 'Unknown';
  };

  onOpenMetadata() {
    if (!this.selectedImagesStore.hasEntities()) {
      return this.alertCustom(this.translate.instant('view-project.alert13'));
    }

    this.loaderService.displayLoader(true);

    const getMetaData$ = this.metaDataService.getMetaData(
      this.selectedImagesStore.getItemsIds(),
    );
    const getSelectedAssetsInfo$ = this.metaDataService.getSelectedAssetsInfo(this.selectedImagesStore.getItemsIds());

    forkJoin([getMetaData$, getSelectedAssetsInfo$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([metaData, assetsInfo]) => {
          this.loaderService.displayLoader(false);
          this.openMetadataDialog(metaData, assetsInfo.assetInfoDTOList);
        },
        () => {
          this.loaderService.displayLoader(false);
        },
      );
  }

  openMetadataDialog(metaData: MetadataModel[], assetInfoDTOList: any[]) {
    this.dialogService.open(MetadataComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-width-1123-panel',
        'fs-dialog-link-panel',
      ],
      listener: {
        withListener: true,
        id: 'MetadataComponent',
      },
      data: {
        metaData,
        assetInfoDTOList,
      },
    });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
