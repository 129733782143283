import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { PaginationService } from './pagination.service';
import { PaginationNextSetUpComponentComponent } from './pagination-next-set-up-component/pagination-next-set-up-component.component';
import { PaginationPrevSetUpComponentComponent } from './pagination-prev-set-up-component/pagination-prev-set-up-component.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    NgxPaginationModule,
    CommonModule,
    MatDialogModule,
    DragDropModule,
  ],
  declarations: [
    PaginationComponent,
    PaginationNextSetUpComponentComponent,
    PaginationPrevSetUpComponentComponent,
  ],
  providers: [PaginationService],
  exports: [PaginationComponent],
})
export class PaginationModule {}
