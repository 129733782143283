import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SIGNOFF, WIDE_EDIT } from '../albumType';
import { FS_POPUP_DATA } from '../../../../../../../shared/services/popup/popup.constants';
import { PopupRef } from '../../../../../../../shared/services/popup/popup-ref';
import { AlbumssharedService } from '../albums-service/albums-shared.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../shared/utils/utils';
import { AppStateService } from '../../../../../../../shared/services/app-state.service';
import * as KeyCodes from '@angular/cdk/keycodes';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  templateUrl: 'addalbum.component.html',
  styleUrls: ['addalbum.component.scss'],
  providers: [DatePipe],
})
export class AddalbumComponent extends UnsubscriberComponent implements OnInit {
  constantsGlobalData: any;
  parentAlbumID = 0;
  albumTypeId = 2;
  albumTitle = '';
  albumForm: UntypedFormGroup;
  albumType: any;
  min: Date;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private albumsSharedSer: AlbumssharedService,
    private sidebarService: SidebarService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private appStateService: AppStateService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    @Inject(FS_POPUP_DATA) private data: { albumType: string },
    private popupRef: PopupRef<AddalbumComponent>,
  ) {
    super();
    this.albumType = data.albumType;
    this.albumForm = fb.group({
      albumName: ['', Validators.required],
      dateTime: '',
    });
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const date = new Date().getDate();
    this.min = new Date(year, month, date);
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.albumTitle = this.constantsGlobalData.MODELNAMES.ADDALBUM;

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.albumTitle = 'Add sub-album for ' + album.value;
          this.parentAlbumID = album.id;
          this.albumTypeId = album.albumTypeId;
        } else {
          this.albumTitle = this.constantsGlobalData.MODELNAMES.ADDALBUM;
          this.parentAlbumID = 0;
          this.albumTypeId = 2;
        }
      });
  }

  closeAddAlbumPopup() {
    this.albumForm.reset();
    this.popupRef.hide();
  }

  onKeypressHandler(event) {
    if (event.keyCode === KeyCodes.ENTER) {
      this.submitForm();
    }
  }

  submitForm() {
    const obj = this.getObjectParam();
    this.loaderService.displayLoader(true);
    this.albumsSharedSer
      .callAddAlbumsAPI(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.viewProjectService.setSavedAlbums(res);
          this.viewProjectService.callAlbumsInfo(
            this.viewProjectService.getProjectId(),
            res.albumTypeId,
          );
          this.appStateService.setAddAlbum({
            loaded: true,
            data: Utils.addValueToObject(res, 'dialogName', this.albumType),
          });
          this.closeAddAlbumPopup();
          this.albumForm.reset();
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
          this.albumForm.reset();
        },
      );
  }

  getObjectParam() {
    const obj = {
      id: 0,
      value: this.albumForm.value.albumName,
      parentAlbumId: this.parentAlbumID,
      projectId: this.viewProjectService.getProjectId(),
      albumTypeId: this.albumTypeId,
      expireAt: null,
    };
    switch (this.albumType) {
      case SIGNOFF:
        obj.albumTypeId = 3;
        obj.parentAlbumId = 0;
        break;
      case WIDE_EDIT:
        obj.albumTypeId = 4;
        obj.parentAlbumId = 0;
        obj.expireAt = this.datepipe.transform(
          this.albumForm.value.dateTime,
          'yyyy-MM-dd HH:mm:ss',
        );
        break;
      default:
        obj.albumTypeId = this.albumTypeId;
    }
    return obj;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeAddAlbumPopup();
  }
}
