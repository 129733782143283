<div
  #imageGallery
  class="image-gallery"
  id="page-content-wrapper"
  [ngClass]="{ 'full-width': !isLeftMenuPartShown }"
>
  <div>
    <div id="mainGrid">
      <div class="db_container db_com rightside-gallerycontainer-panel">
        <div class="st-subdiv db_com" id="easyPaginate">
          <div *ngIf="projectImageData.assetInfoDTOList" id="imgList">
            <div
              *ngFor="
                let imgData of drawImageData
                  | paginate
                    : {
                        itemsPerPage: noOfImages,
                        currentPage: currentImageNum,
                        id: 'EnlargeImage',
                        totalItems: projectImageIds.length
                      };
                index as ind
              "
            >
              <div *ngIf="!isSuperZoom" class="img_dive">
                <div class="span31 imgItem">
                  <div class="imgee">
                    <a href="javascript:void(0)">
                      <span></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="image-tag-wrapper">
        <canvas
          #htmlCanvasElement
          oncontextmenu="return false;"
          class="js-paint paint-canvas"
          width="1600"
          [ngClass]="{ 'paint-enable': !disablePaint }"
        >
        </canvas>

        <div
          class="image-tag-wrapper_img"
          *ngFor="let commentObj of commentList; let i = index"
          [ngStyle]="{ top: commentObj.y + 'px', left: commentObj.x + 'px' }"
        >
          <div *ngIf="commentObj.active">
            <img
              src="/assets/images/chat.png"
              [matTooltip]="commentObj.comment"
              (dblclick)="editCommentMethod(commentObj.id, i)"
            />
          </div>
        </div>

        <fs-draw-image-comment-popup
          [editComment]="editComment"
          [ifTagEnable]="ifTagEnable"
          [ifTagEnableOffsetX]="ifTagEnableOffsetX"
          [ifTagEnableOffsetY]="ifTagEnableOffsetY"
          [isEditComment]="isEditComment"
          (deleteComment)="deleteComment($event, editIndex)"
          (hideTagForm)="hideTagForm()"
          (submitComment)="submitComment($event)"
          (submitCommentEdit)="submitCommentEdit($event)"
        ></fs-draw-image-comment-popup>
      </div>
    </div>
  </div>
</div>

<div class="tools-wrapper">
  <div
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    class="fs-cursor-move drag-block-lg tools-dialog"
  >
    <div class="tools-wrapper-content">
      <div class="drag-block-handle tools-header">
        <h4 class="modal-title" id="taggign_view_1">{{'top-menu.toolbar' | translate}}</h4>
      </div>
      <div class="tools-body">
        <ul class="toolbar-retouch">
          <div class="comment-re">
            <a
              class="button1"
              href="javascript:void(0);"
              [title]="'image-caption.buttons.comment' | translate"
              [ngClass]="{ selected: disablePaint }"
              (click)="comment()"
            >
              <i class="fa fa-lg fa-comments"></i>
            </a>
          </div>
          <div class="paint-re">
            <ul>
              <div>
                <a
                  class="button1"
                  href="javascript:void(0);"
                  [title]="'image-caption.buttons.paint' | translate"
                  [ngClass]="{ selected: !disablePaint }"
                  (click)="paint()"
                >
                  <i class="fa fa-lg fa-paint-brush"></i>
                </a>
              </div>
              <div>
                <fs-number-incrementer
                  (onNumberChanged)="setBrushLineWidth($event)"
                ></fs-number-incrementer>
              </div>
              <div>
                <input
                  class="js-color-picker"
                  [colorPicker]="color"
                  (colorPickerChange)="color = $event"
                  [style.background]="color"
                  [value]="color"
                  [cpOutputFormat]="'hex'"
                />
              </div>
              <div>
                <span class="pipe-bt mt-5"></span>
                <a
                  class="button1"
                  href="javascript:void(0);"
                  [title]="'image-caption.buttons.undo' | translate"
                  (click)="cUndo()"
                >
                  <i class="fa fa-lg fa-undo"></i>
                </a>
              </div>
            </ul>
          </div>
          <div class="delete-re">
            <a
              class="button1"
              href="javascript:void(0);"
              [title]="'image-caption.buttons.delete' | translate"
              (click)="deleteMethod()"
            >
              <i class="fa fa-lg fa-trash"></i>
            </a>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
