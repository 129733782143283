import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserDetails } from '../../../../../../shared/models/userdetails.model';
import { AlbumsService } from '../../menus/albums/albums.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { ViewProjectService } from '../../../../../../shared/services/view-project.service';
import { WideEditAdvanceSearchService } from '../wide-edit-album/wide-edit-album.service';
import { LoaderService } from '../../../../../../shared/services/loader.service';
import { SidebarService } from '../../../../sidebar-service/sidebar-service';
import { Utils } from '../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../../shared/store/selected-images-store';
import { DialogService } from '../../../../../../shared/services/dialog/dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UnsubscriberComponent } from '../../../../../../shared/components/unsubscriber/unsubscriber.component';
import { WideSearchModel } from '../../../../../../shared/models/wide-search.model';
import {ApiErrorHandlerService} from '../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-wide-edit-album-dialog',
  templateUrl: './wide-edit-album-dialog.component.html',
  styleUrls: ['./wide-edit-album-dialog.component.scss'],
})
export class WideEditAlbumDialogComponent
  extends UnsubscriberComponent
  implements OnInit
{
  approvalStatus: any;
  designation: any;
  talentAppearance: any;
  model = {};
  userDataInfo: any;
  execUserIds: any = [];
  currentUser: UserDetails = new UserDetails();
  selectedUserId = [];
  noOfImages: any;
  albumDialogForm: UntypedFormGroup;

  constructor(
    private albumsService: AlbumsService,
    private userService: UserService,
    private viewProjectService: ViewProjectService,
    private wideEditAdvanceSearchService: WideEditAdvanceSearchService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private sidebarService: SidebarService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<WideEditAlbumDialogComponent>,
    private selectedImagesStore: SelectedImagesStore,
  ) {
    super();
    this.albumDialogForm = this.fb.group({
      approvalStatus: [''],
      talentAppearance: [''],
      designation: [''],
      notes: [''],
      like: [''],
      retouch: [''],
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.selectedUserId = [];
    this.approvalStatus = '';
    this.designation = '';
    this.talentAppearance = '';

    this.wideEditAdvanceSearchService
      .getWideEditAdvanceSearchData()
      .subscribe((formData: WideSearchModel) => {
        if (!Utils.isObjectEmpty(formData)) {
          this.albumDialogForm.setValue({
            approvalStatus: formData.approvalStatus,
            talentAppearance: formData.talentAppearance,
            designation: formData.designation,
            notes: formData.notes,
            like: formData.like,
            retouch: formData.retouch,
          });
          this.selectedUserId = formData.userIds;
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.noOfImages = res.viewSettingDTO.imagePerPage;
          this.execUserIds = res.execUsers;
        }
      });
    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);
    this.albumsService.userDataInfoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.userDataInfo = this.keepexecUsersList(data);
      });
  }

  keepexecUsersList(data) {
    const tempArray = [];
    if (data.length > 0) {
      data.forEach((userGlobalDataObj) => {
        this.execUserIds.forEach((execUserId) => {
          if (userGlobalDataObj.id === execUserId) {
            tempArray.push(userGlobalDataObj);
          }
        });
      });
    }
    return tempArray;
  }

  checkIfUserSelected(userData) {
    return this.selectedUserId.includes(userData.id);
  }

  onChangedProjectUser(user) {
    const index = this.selectedUserId.indexOf(user.id);
    if (index === -1) {
      this.selectedUserId.push(user.id);
    } else {
      this.selectedUserId.splice(index, 1);
    }
  }

  submitform() {
    this.model = {
      userIds: this.selectedUserId,
      projectId: this.viewProjectService.getProjectId(),
      albumId: this.viewProjectService.getExecutiveAlbumID(),
      approvalStatus: this.albumDialogForm.get('approvalStatus').value,
      talentAppearance: this.albumDialogForm.get('talentAppearance').value,
      designation: this.albumDialogForm.get('designation').value,
      notes: !!this.albumDialogForm.get('notes').value,
      like: !!this.albumDialogForm.get('like').value,
      retouch: !!this.albumDialogForm.get('retouch').value,
    };

    this.loaderService.displayLoader(true);
    this.viewProjectService.setExecutiveAlbumView(false);
    this.wideEditAdvanceSearchService
      .postAdvanceSearch(this.model)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res && res.assetIds.length > 0) {
            this.viewProjectService.setProjectImageIDs(res.assetIds);
            const assetsIds = Utils.getImageIdsBasedOnPage(
              1,
              this.noOfImages,
              res.assetIds,
            );
            const data = {
              assetIds: assetsIds,
              projectId: this.viewProjectService.getProjectId(),
              filter:
                'wideedit:' +
                res.filter +
                '|' +
                this.viewProjectService.getExecutiveAlbumID(),
            };

            this.viewProjectService
              .callAssetsInfoBasedOnAssetsId(data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (resData) => {
                  this.selectedImagesStore.clear();
                  this.sidebarService.setAlbumImageData(resData);
                  setTimeout(() => {
                    this.viewProjectService.setCurrentFilter(data.filter);
                    this.router.navigate([
                      Utils.prepareFilterNavigationURL(
                        this.viewProjectService.getProjectRole(),
                        this.viewProjectService.getProjectId(),
                        data.filter,
                        1,
                      ),
                    ]);
                    this.viewProjectService.setCurrentFilter(data.filter);
                    this.closeWindow();
                  }, 0);
                },
                (error) => {
                  this.apiErrorHandlerService.getHandler().handle(error);
                  this.closeWindow();
                  this.loaderService.displayLoader(false);
                },
              );
          } else {
            this.closeWindow();
            this.alertCustom(this.translate.instant('view-project.alert2'));
          }
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
    this.wideEditAdvanceSearchService.setWideEditAdvanceSearchData({
      ...this.albumDialogForm.value,
      ...{ userIds: this.selectedUserId },
    });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  resetValue() {
    this.albumDialogForm.reset();
    this.wideEditAdvanceSearchService.resetWideEditAdvanceSearchData();
    this.selectedUserId = [];
    this.approvalStatus = '';
    this.designation = '';
    this.talentAppearance = '';
  }

  closeWindow() {
    this.dialogRef.close();
  }
}
