<div class="components left-side-menu-bar">
  <div class="right-content">
    <div class="sub-menu-list d-block">
      <div class="w-100 pl-2">
        <div class="w-100">
          <tree-root
            #tree1
            [nodes]="projectAlbums"
            [focused]="true"
            [options]="options"
            (toggleExpanded)="onPrejectAlbumExpanded($event)"
          >
            <ng-template
              #treeNodeTemplate
              let-node
              let-index="index"
              name="name"
            >
              <div
                [ngClass]="{ executive: node.data.executive }"
                *ngIf="node.data.id === 0"
              >
                <label [title]="'left-menu.tab-bar.sidemenus.albums.title.projectAlbum' | translate">
                  {{ 'left-menu.tab-bar.sidemenus.albums.title.projectAlbum' | translate }}
                </label>
              </div>

              <div
                [ngClass]="{ executive: node.data.executive }"
                *ngIf="node.data.id !== 0"
              >
                <label
                  (click)="onClickProjectAlbum(node.data)"
                  [title]="node.data.value"
                >
                  {{ node.data.tagKey }}={{
                  node.data.value | slice : 0 : 25
                  }}
                  ({{ node.data.assetCount }})
                </label>
              </div>
            </ng-template>
          </tree-root>
        </div>
      </div>
      <div>
        <div class="w-100 pl-2">
          <div class="w-100">
            <tree-root
              #tree
              [nodes]="albumNode"
              [options]="treeOptions"
              (event)="onEvent($event)"
              (moveNode)="onMoveNode($event)"
            >
              <ng-template
                #treeNodeTemplate
                let-node
                let-index="index"
                name="name"
              >
                <div
                  *ngIf="
                      node.data.id === 0 && node.data.ownerId !== currentUser.id
                    "
                >
                    <span class="group-album-name">
                      {{
                      node.data.ownerName ? node.data.ownerName : 'Orphaned'
                      }}
                    </span>
                </div>
                <div
                  *ngIf="
                      node.data.id === 0 && node.data.ownerId === currentUser.id
                    "
                >
                  <div>
                      <span class="group-album-name">
                        {{ 'global.myfolder' | translate }}
                      </span>
                  </div>
                </div>
                <div
                  [ngClass]="{ executive: node.data.executive }"
                  *ngIf="node.data.id !== 0"
                  fsContextMenuHandler
                  [matMenuTrigger]="matMenuTrigger"
                  [node]="node"
                  (setUpContextMenuPosition)="
                        setUpContextMenuPosition($event)
                      "
                >
                  <i
                    fsImagebuttonhover
                    focusRemover
                    aria-hidden="true"
                    (click)="onClickAlbum(index)"
                    [ngClass]="[
                        node.data.tagKey !== -1
                          ? 'folder-icon-' + node.data.tagKey
                          : 'folder-icon-blank',
                        activeAlbumFolder.id == node.data.id
                          ? 'fs-button-selected'
                          : ''
                      ]"
                    (drop)="getSelectedImagesOnDrop($event)"
                    [id]="node.data.id"
                  >
                  </i>
                  <label
                    class="folder-name-count fs-sm-txt"
                    (click)="onClickAlbumName(index)"
                    cdkOverlayOrigin
                    [title]="node.data.value"
                    (dblclick)="onDoubleClickAlbumName()"
                    (mouseenter)="onHoverOverAlbumName(node.data)"
                    (mouseleave)="onHoverRemoveOverAlbumName()"
                    [ngStyle]="{
                        color:
                          node.data.tagColor === -1
                            ? ''
                            : node.data.tagColorCode
                      }"
                  >
                    {{ node.data.value | slice : 0 : 25 }} ({{
                    node.data.assetCount
                    }})
                  </label>
                  <mat-slide-toggle
                    class="fs-toggle-dark"
                    *ngIf="node.data.executiveView"
                    (toggleChange)="handleChangeExecutive($event, index)"
                    [(ngModel)]="node.data.executiveViewLocal"
                  >
                  </mat-slide-toggle>
                </div>
              </ng-template>
            </tree-root>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="withFunctions"
></div>

<mat-menu
  #withFunctions="matMenu"
  hasBackdrop="false"
  class="fs-menu"
  [classList]="
    'overflow-hidden mw-100 fs-drop-down-menu fs-drop-down-no-padding fs-context-menu-container'
  "
>
  <ng-template matMenuContent let-node="node">
    <span
      [class.d-none]="!onlyForCurrentUser(node)"
      mat-menu-item
      class="mat-menu-text"
      [matMenuTriggerFor]="someSubMenuTag"
      [matMenuTriggerData]="{ item: node }"
    >
      {{ 'left-menu.tab-bar.sidemenus.albums.rightClick.tag' | translate }} "{{
        node.data.value
      }}"
    </span>

    <span
      [class.d-none]="!onlyForCurrentUser(node)"
      mat-menu-item
      class="mat-menu-text"
      [matMenuTriggerFor]="someSubMenuColor"
      [matMenuTriggerData]="{ item: node }"
    >
      {{ 'left-menu.tab-bar.sidemenus.albums.rightClick.color' | translate }}
      "{{ node.data.value }}"
    </span>

    <div *ngIf="adhocLinkAccess && !isTaggerRole">
      <div
        mat-menu-item
        [class.d-none]="!onlyForCurrentUser(node)"
        (click)="linkPage(node)"
        class="mat-menu-text left-menu-img-with-txt"
      >
        <img src="/assets/images/icon-link.png" alt="icon" />
        <span>
          {{
            'left-menu.tab-bar.sidemenus.albums.rightClick.adhoc' | translate
          }}
        </span>
      </div>
    </div>

    <div *ngIf="emailAccess && !isTaggerRole">
      <div
        [class.d-none]="!onlyForCurrentUser(node)"
        (click)="sendMail(node)"
        mat-menu-item
        class="mat-menu-text left-menu-img-with-txt"
      >
        <img src="/assets/images/icon-email.png" alt="icon" />
        <span>
          {{
            'left-menu.tab-bar.sidemenus.albums.rightClick.email' | translate
          }}
        </span>
      </div>
    </div>

    <div
      *ngIf="
        (userAuthority === userRole.ROLE_ADMIN || !!downloadType) &&
        !isTaggerRole
      "
      [class.d-none]="!onlyForCurrentUser(node)"
      (click)="download(node)"
    >
      <div mat-menu-item class="mat-menu-text left-menu-img-with-txt">
        <img src="/assets/images/icon-download.png" alt="icon" />
        <span>
          {{
            'left-menu.tab-bar.sidemenus.albums.rightClick.download' | translate
          }}
        </span>
      </div>
    </div>

    <div
      *ngIf="
        (userAuthority === userRole.ROLE_ADMIN || expressDownload) &&
        !isTaggerRole &&
        !hasWatermark
      "
      [class.d-none]="!onlyForCurrentUser(node)"
      (click)="onOpenExpressDownloadModal(node)"
    >
      <div mat-menu-item class="mat-menu-text left-menu-img-with-txt">
        <img src="/assets/images/down-arrow.png" alt="icon" />
        <span>
          {{
            'left-menu.tab-bar.sidemenus.albums.rightClick.expressDownload'
              | translate
          }}
        </span>
      </div>
    </div>

    <div
      *ngIf="printAccess && !isTaggerRole"
      [class.d-none]="!onlyForCurrentUser(node)"
      (click)="printTool(node)"
    >
      <div mat-menu-item class="mat-menu-text left-menu-img-with-txt">
        <img src="/assets/images/icon-print.png" alt="icon" />
        <span>
          {{ 'left-menu.tab-bar.sidemenus.albums.rightClick.pdf' | translate }}
        </span>
      </div>
    </div>

    <!-- Don't remove - Temporary enable  -->
    <div [class.d-none]="!onlyForOtherUser(node)">
      <span mat-menu-item class="mat-menu-text">
        {{
          'left-menu.tab-bar.sidemenus.albums.rightClick.noPermission'
            | translate
        }}
      </span>
    </div>
  </ng-template>
</mat-menu>

<mat-menu
  hasBackdrop="false"
  #someSubMenuTag="matMenu"
  class="fs-menu"
  [classList]="
    'fs-context-menu-container overflow-hidden mw-100 fs-drop-down-menu fs-drop-down-no-padding'
  "
>
  <ng-template matMenuContent let-item="item">
    <span class="mat-menu-text" (click)="removeTagKey(item)" mat-menu-item>
      {{ 'left-menu.tab-bar.sidemenus.albums.rightClick.clear' | translate }}
    </span>
    <div
      class="mat-menu-text"
      mat-menu-item
      *ngFor="let tag of tagKeyResponseDTOList"
      (click)="onClickOnSubMenuTag(item, tag)"
      [disabled]="tag.id !== -1"
    >
      {{ tag.tagKey }}
      <span *ngIf="tag.id !== -1"> {{ tag.value }} </span>
      <span *ngIf="tag.id === -1">
        {{
          'left-menu.tab-bar.sidemenus.albums.rightClick.available' | translate
        }}
      </span>
    </div>
  </ng-template>
</mat-menu>

<mat-menu
  hasBackdrop="false"
  #someSubMenuColor="matMenu"
  class="fs-menu"
  [classList]="
    'fs-context-menu-container overflow-hidden mw-100 fs-drop-down-menu fs-drop-down-no-padding'
  "
>
  <ng-template matMenuContent let-item="item">
    <span class="mat-menu-text" (click)="removeColorKey(item)" mat-menu-item>{{
      'left-menu.tab-bar.sidemenus.albums.rightClick.clear' | translate
    }}</span>
    <div
      class="mat-menu-text"
      mat-menu-item
      *ngFor="let tag of tagColorResponseDTOList"
      (click)="onClickOnSubMenuColor(item, tag)"
      [disabled]="tag.id !== -1"
    >
      <i class="fa fa-circle" [ngStyle]="{ color: tag.tagColorCode }"></i>
      <span *ngIf="tag.id !== -1" [ngStyle]="{ color: tag.tagColorCode }">
        {{ tag.value }}
      </span>
      <span *ngIf="tag.id === -1">
        {{
          'left-menu.tab-bar.sidemenus.albums.rightClick.available' | translate
        }}
      </span>
    </div>
  </ng-template>
</mat-menu>
