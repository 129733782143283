<div
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  mat-dialog-title
  class="fs-cursor-move d-flex justify-content-between"
>
  <div class="modal-title">
    {{
    'left-menu.tab-bar.sidemenus.administration.buttonse.original-names-report'
      | translate
    }}
  </div>

  <button class="fs-close-btn w-auto" aria-hidden="true">
    <i class="fa fa-times-circle" (click)="closeWindow()"></i>
  </button>
</div>

<div mat-dialog-content>
  <div class="fs-txt-white-dark-color font-weight-bold">
    {{
    'left-menu.tab-bar.sidemenus.administration.imagenotes-report.labels.label1'
      | translate
    }}
  </div>

  <mat-radio-group
    class="d-flex flex-column"
    [(ngModel)]="reportType"
    (change)="onNotesChange($event)"
  >
    <mat-radio-button class="fs-radio-button" value="1">
      {{
      'left-menu.tab-bar.sidemenus.administration.imagenotes-report.labels.all'
        | translate
      }}
    </mat-radio-button>

    <mat-radio-button
      class="fs-radio-button"
      [disabled]="filterName === filterEnum.ShowAll || !hasAssetIds"
      value="2"
    >
      {{
      'left-menu.tab-bar.sidemenus.administration.imagenotes-report.labels.current-filter'
        | translate
      }}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div mat-dialog-actions align="end">
  <button class="fs-dialog-btn w-auto ml-3" (click)="originalNamesReport()">
    {{
    'left-menu.tab-bar.sidemenus.administration.imagenotes-report.button.generate'
      | translate
    }}
  </button>
</div>
