import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../shared/services/api.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AssetErrorKeys } from '../../../../../shared/enum/asset-error-keys.enum';

@Injectable()
export class SendmailService {
  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private viewProjectService: ViewProjectService,
  ) {}

  sendMailAPI(mailData: any): Observable<any> {
    const path = AppSettings.SEND_MAIL;
    return this.apiService
      .postAssets(
        this.viewProjectService.getBaseUrl(),
        path,
        mailData
      )
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }
}
