import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { CaptionService } from './caption.service';
import { CaptionKeys } from './captionKeys.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { ImagecaptionService } from '../../../dashboard-shared/left-menu/menus/administration/imagecaption-report/imagecaption-report.service';
import { AssetInfo } from '../../../../../shared/models/assetInfo.model';
import { SelectedImages } from '../../../../../shared/models/selectedImages.model';
import { Activemodal } from '../../../../../shared/models/activemodal.model';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { PaginationService } from '../../../dashboard-shared/pagination/pagination.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddKeyCaptionComponentComponent } from './add-key-caption-component/add-key-caption-component.component';
import { EditKeyCaptionComponentComponent } from './edit-key-caption-component/edit-key-caption-component.component';
import { RemoveKeyCaptionComponentComponent } from './remove-key-caption-component/remove-key-caption-component.component';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../shared/store/last-selected-image-store';
import { SelectItem } from '../../../../../shared/models/select-item';
import { Utils } from '../../../../../shared/utils/utils';
import { CaptionReportComponent } from '../../../dashboard-shared/left-menu/menus/administration/imagecaption-report/caption-report/caption-report.component';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';
import {AssetErrorKeys} from '../../../../../shared/enum/asset-error-keys.enum';

@Component({
  selector: 'fs-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.scss'],
})
export class CaptionComponent extends UnsubscriberComponent implements OnInit {
  static identifier = 'CaptionComponent';
  currentImage: any;
  pageChangeFn: (item) => void;
  superZoomFn: () => void;
  superZoomClose: () => void;
  isCaption = false;
  isSortCutKey = false;
  sortCutKeys: Array<any> = [];
  keyArray: Array<any> = [];
  isAddKey = false;
  isRemoveKey = false;
  isEditKey = false;
  getAllKey: Array<object> = [];
  editedKeys: Array<object> = [];
  captionText = '';
  orgCaptionText = '';
  isNotRepeatable = true;
  isActivateCtrl = false;
  currentPageNumber: number;
  currentImageNum: number;
  imageId: number;
  pageNumber: number;
  isCaptionReport = false;
  captionReportRadio: any = 'all';
  isZoom = true;
  zoomChangeText = 'Zoom In';
  currentFilter: string;
  draggable = true;
  // Selected Image array
  projectImageData: AssetInfo = new AssetInfo();
  // lastSelectedImages: any = [];
  lastSelectedImage2: SelectedImages[] = [];
  projectId: string = null;
  captionKeys: CaptionKeys[];
  // Add Keys ngmodel
  keyIdentifier: string = null;
  keyDescription: string = null;
  editModel = [];
  activateTextArea = false;
  captionArray: SelectItem[];
  selectedCaption: string = null;
  filterKeys: Array<number> = [];
  isAutoSave = false;
  captionIndex = -1;
  radioShow = true;
  priviousButton = false;
  nextButton = false;
  selectedImageIndex = -1;
  isOverlay = false;
  closeWindowCaption;
  repotyType = '1';
  allselectedImages: any = [];
  displaySelectedImages: any = [];
  tagList: any;
  projectTagListDTO: any;
  activemodal: Activemodal = new Activemodal();
  totalProjectIds: Array<number> = [];
  projectImageIds: any;
  constantsGlobalData: any;
  existingImageCaptionData: any = [];
  parameters: {
    filterId: string;
    imageId: number;
    projectId: string;
    pageNumber: number;
    type: string;
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private captionService: CaptionService,
    private viewProjectService: ViewProjectService,
    private snackBarService: SnackBarService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private imagecaptionService: ImagecaptionService,
    private paginationService: PaginationService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<CaptionComponent>,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentImageNum: any;
      closeWindowCaptionFn: () => void;
      superZoomCloseFn: any;
      superZoomFn: any;
      routeParams: {
        filterId: string;
        projectId: string;
        imageId: number;
        pageNumber: number;
        type: string;
      };
      pageChangeFn: any;
    },
  ) {
    super();
    this.parameters = data.routeParams;
    this.currentImage = data.currentImageNum;
    this.superZoomClose = data.superZoomCloseFn;
    this.closeWindowCaption = data.closeWindowCaptionFn;
    this.superZoomFn = data.superZoomFn;
    this.pageChangeFn = data.pageChangeFn();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.initCaptionData();

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });

    this.viewProjectService.projectDataGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectImageData = data;
        }
      });

    this.imagecaptionService.reportCaptionGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.existingImageCaptionData = res;
        } else {
          this.existingImageCaptionData = [];
        }
      });

    this.captionService.allKeyData
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.getAllKey = res;
          this.editedKeys = JSON.parse(JSON.stringify(res));
        }
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectTagListDTO = data.projectTagListDTO;
        }
      });

    this.selectedImagesStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((images) => {
        this.displaySelectedImages = [];
        this.allselectedImages = [];

        if (images.length > 0) {
          let localArr = [];
          this.displaySelectedImages = images;
          this.allselectedImages = images;
          images.forEach((value) => {
            localArr = [...localArr, ...value.publishedTags];
          });
          this.allselectedImages = [...new Set(localArr)];
          if (this.projectTagListDTO.length > 0) {
            this.tagList = this.projectTagListDTO.filter((item, key) => {
              if (this.allselectedImages.indexOf(item.id) !== -1) {
                return true;
              } else {
                return false;
              }
            });
          } else {
            this.tagList = [];
          }
          this.selectedImageIndex = this.displaySelectedImages
            .map((e) => e.id)
            .indexOf(this.projectImageIds[this.currentImage - 1]);
          this.selectedImagesButtonHandle(this.selectedImageIndex);
        }
      });

    this.lastSelectedImageStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((images) => {
        this.lastSelectedImage2 = images;

        if (this.lastSelectedImageStore.hasEntities()) {
          this.setExistingCaptionValue();

          if (this.displaySelectedImages.length === 1) {
            this.displaySelectedImages = [];
            this.allselectedImages = [];
            let localArr = [];
            images.forEach((value) => {
              this.displaySelectedImages.push(value);
              this.allselectedImages.push(value);
              localArr = [...localArr, ...value.publishedTags];
            });
            this.allselectedImages = [...new Set(localArr)];
            if (this.projectTagListDTO.length > 0) {
              this.tagList = this.projectTagListDTO.filter((item, key) => {
                if (this.allselectedImages.indexOf(item.id) !== -1) {
                  return true;
                } else {
                  return false;
                }
              });
            } else {
              this.tagList = [];
            }
          }
        }
      });

    this.captionService.captionKeysGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.captionKeys = res;
        this.editModel = JSON.parse(JSON.stringify(res));
      });

    this.callCaptionKeys();
    this.callCaptionAll();
  }

  initCaptionData() {
    if (this.parameters.type === 'caption') {
      this.isCaption = true;
      this.totalProjectIds = this.viewProjectService.getProjectImageIDs();
    }
    this.currentFilter = this.parameters.filterId;
    this.currentPageNumber = this.parameters.pageNumber;
    this.imageId = this.parameters.imageId;
    this.projectId = this.parameters.projectId.slice(
      1,
      this.parameters.projectId.length,
    );
  }

  selectedImagesButtonHandle(index) {
    if (index === 0) {
      // Next Enable pre Disable
      this.priviousButton = false;
      this.nextButton = true;
    } else if (index > 0 && index < this.selectedImagesStore.size() - 1) {
      // Pre and Next Enable
      this.priviousButton = true;
      this.nextButton = true;
    } else if (index === this.selectedImagesStore.size() - 1) {
      // Pre Enable Next Disable
      this.priviousButton = true;
      this.nextButton = false;
    }
  }

  callCaptionKeys() {
    this.captionService
      .getApiCaptionKeys(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.captionKeys = res;
        this.editModel = JSON.parse(JSON.stringify(res));
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  callCaptionAll() {
    this.captionService
      .getApiCaptionAll(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.captionArray = res;
        } else {
          this.captionArray = [];
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  setExistingCaptionValue() {
    if (this.displaySelectedImages.length > 1) {
      let totalDiffCaption = 0;
      let haveCaptionSameCount = 0;

      for (let i = 0; i < this.displaySelectedImages.length; i++) {
        for (let j = i + 1; j < this.displaySelectedImages.length; j++) {
          if (
            this.displaySelectedImages[i].caption &&
            this.displaySelectedImages[j].caption
          ) {
            if (
              this.displaySelectedImages[i].caption.id ===
              this.displaySelectedImages[j].caption.id
            ) {
              haveCaptionSameCount++;
            }
          }
        }
      }
      if (haveCaptionSameCount === 0) {
        const haveCaptionInSelectedImages = this.displaySelectedImages.filter(
          (item) => item.caption && Object.keys(item.caption).length > 0,
        );
        totalDiffCaption = [
          ...new Set(
            haveCaptionInSelectedImages.map((item) => item.caption['value']),
          ),
        ].length;
      } else {
        const haveCaptionInSelectedImages = this.displaySelectedImages.filter(
          (item) => item.caption && Object.keys(item.caption).length > 0,
        );
        totalDiffCaption = [
          ...new Set(
            haveCaptionInSelectedImages.map((item) => item.caption['value']),
          ),
        ].length;
      }

      if (totalDiffCaption > 0) {
        const haveCaptionInSelectedImages = this.displaySelectedImages.filter(
          (item) => item.caption && Object.keys(item.caption).length > 0,
        );
        const ifSameCaption = haveCaptionInSelectedImages
          .map((item) => item.caption['value'])
          .every((v) => v === haveCaptionInSelectedImages[0].caption['value']);
        const unique = [
          ...new Set(
            haveCaptionInSelectedImages.map((item) => item.caption['value']),
          ),
        ].length;
        // FIXME: TypeError: Cannot read property 'caption' of undefined
        if (ifSameCaption) {
          const sameCaption = haveCaptionInSelectedImages.filter((item) => {
            return Number(item.id) === Number(this.imageId);
          });

          if (sameCaption && sameCaption.length > 0) {
            if (sameCaption[0].caption) {
              this.captionText = sameCaption[0].caption['value'];
            }
          }
        } else {
          this.captionText = unique + ' existing caption(s) in selected range';
        }
      } else {
        if (
          this.lastSelectedImage2[this.lastSelectedImageStore.size() - 1]
            .caption
        ) {
          this.captionText =
            this.lastSelectedImage2[
              this.lastSelectedImageStore.size() - 1
            ].caption.value;
        } else {
          this.captionText = '';
        }
      }
    } else {
      if (
        this.lastSelectedImage2[this.lastSelectedImageStore.size() - 1].caption
      ) {
        this.captionText =
          this.lastSelectedImage2[
            this.lastSelectedImageStore.size() - 1
          ].caption.value;
      } else {
        this.captionText = '';
      }
    }
    this.orgCaptionText = this.captionText;
  }

  onClickSelectedPreviousButton() {
    this.selectedImageIndex--;
    this.selectedImagesButtonHandle(this.selectedImageIndex);
    const imageIndex = this.projectImageIds
      .map(function (e) {
        return e;
      })
      .indexOf(this.displaySelectedImages[this.selectedImageIndex].id);

    this.callPageChnage(imageIndex + 1);
  }

  onClickSelectedNextButton() {
    this.selectedImageIndex++;
    this.selectedImagesButtonHandle(this.selectedImageIndex);
    const imageIndex = this.projectImageIds
      .map(function (e) {
        return e;
      })
      .indexOf(this.displaySelectedImages[this.selectedImageIndex].id);

    this.callPageChnage(imageIndex + 1);
  }

  onClickPrevious() {
    this.currentImageNum = this.currentImage - 1;
    this.saveCaption();
  }

  onClickNext() {
    this.currentImageNum = this.currentImage + 1;
    this.saveCaption();
  }

  superZoomCaption() {
    if (this.isZoom) {
      this.superZoomFn();
      this.isZoom = false;
      this.zoomChangeText = 'Zoom out';
    } else {
      this.superZoomClose();
      this.isZoom = true;
      this.zoomChangeText = 'Zoom In';
    }
  }

  shortCutKey() {
    this.isSortCutKey = !this.isSortCutKey;
  }

  addKeyPopUP() {
    this.dialogService.open(AddKeyCaptionComponentComponent, {
      data: {
        keyDescription: this.keyDescription,
        keyIdentifier: this.keyIdentifier,
        addKey: this.addKey,
        onBlur: this.onBlur,
        onFocus: this.onFocus,
      },
      listener: {
        withListener: true,
        id: 'AddKeyCaptionComponentComponent',
      },
      panelClass: ['fs-dialog-panel', 'fs-dialog-size-lg-panel'],
    });
  }

  removeKeyPopUP() {
    this.dialogService.open(RemoveKeyCaptionComponentComponent, {
      data: {
        onFilterKeysChange: this.onFilterKeysChange,
        removeKey: this.removeKey,
        captionKeys: this.captionKeys,
      },
      listener: {
        withListener: true,
        id: 'RemoveKeyCaptionComponentComponent',
      },
      panelClass: ['fs-dialog-panel', 'fs-dialog-size-lg-panel'],
    });
  }

  editKeyPopUP = () => {
    this.dialogService.open(EditKeyCaptionComponentComponent, {
      data: {
        editModel: this.editModel,
        editKey: this.editKey,
        onBlur: this.onBlur,
        onFocus: this.onFocus,
      },
      panelClass: ['fs-dialog-panel', 'fs-dialog-size-lg-panel'],
    });
  };

  addKey = ({ keyDescription, keyIdentifier }) => {
    let isLengthOk = true;
    this.captionKeys.filter((keys, index) => {
      if (keys['keyText'].toUpperCase() === keyIdentifier.toUpperCase()) {
        this.isNotRepeatable = false;
      }
      if (keys['keyText'].length !== 2) {
        isLengthOk = false;
      }
    });
    if (
      keyIdentifier !== '' &&
      keyIdentifier &&
      keyIdentifier.length === 2 &&
      this.isNotRepeatable &&
      isLengthOk
    ) {
      if (keyIdentifier && keyDescription) {
        const data = {
          keyText: keyIdentifier.toUpperCase(),
          projectId: this.viewProjectService.getProjectId(),
          value: keyDescription,
        };
        this.callAddKeyApi(data);
      }
    } else {
      this.isNotRepeatable = true;
      this.alertCustom(this.translate.instant('view-project.alert36'));
    }
  };

  editKey = () => {
    let isLengthOk = true;
    this.captionKeys.filter((keys, index) => {
      this.editModel.filter((keysChild, indexChild) => {
        if (
          index !== indexChild &&
          keys['keyText'].toUpperCase() === keysChild['keyText'].toUpperCase()
        ) {
          this.isNotRepeatable = false;
        }
      });
      if (keys['keyText'].length !== 2) {
        isLengthOk = false;
      }
    });

    if (!this.isNotRepeatable || !isLengthOk) {
      this.isNotRepeatable = true;
      this.alertCustom(this.translate.instant('view-project.alert37'));
    } else {
      this.callEditKeyApi();
    }
  };

  onFilterKeysChange = ({ e, removeKey }) => {
    if (e.checked) {
      this.filterKeys.push(removeKey);
    } else {
      this.filterKeys.splice(this.filterKeys.indexOf(removeKey), 1);
    }
  };

  removeKey = () => {
    if (this.filterKeys.length !== 0) {
      this.callDeleteKeyApi();
    } else {
      this.alertCustom(this.translate.instant('view-project.alert38'));
    }
  };

  saveCaption(isClickOnSave?) {
    if (!isClickOnSave) {
      isClickOnSave = false;
    }
    if (this.orgCaptionText !== this.captionText) {
      if (this.isAutoSave) {
        if (this.orgCaptionText === '' && this.captionText !== '') {
          this.callCreateCaptionAPI(false, true);
        } else {
          this.dialogService
            .openConfirmationDialog({
              message:
                this.translate.instant('confirmDialog.caption.message') +
                ' ' +
                this.displaySelectedImages.length +
                ' ' +
                this.translate.instant('confirmDialog.caption.message1'),
              title: this.translate.instant('confirmDialog.caption.title'),
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe((state: boolean) => {
              if (state) {
                setTimeout(() => {
                  this.clearImageConfirmationPopup(1);
                }, 100);
              } else {
                this.captionText = this.orgCaptionText;
                this.snackBarService.showSnackBarError(
                  this.translate.instant('confirmDialog.caption.fail'),
                );
                setTimeout(() => {
                  this.clearImageConfirmationPopup(2);
                }, 100);
              }
            });
        }
      } else {
        if (isClickOnSave) {
          if (this.orgCaptionText === '' && this.captionText !== '') {
            this.callCreateCaptionAPI(isClickOnSave);
          } else {
            this.dialogService
              .openConfirmationDialog({
                message: this.translate.instant(
                  'You are about to overwrite captions on ' +
                    this.displaySelectedImages.length +
                    ' image. Are you sure?',
                ),
                title: this.translate.instant('confirmDialog.caption.title'),
              })
              .pipe(takeUntil(this.destroy$))
              .subscribe((state: boolean) => {
                if (state) {
                  this.callCreateCaptionAPI(false, false);
                }
              });
          }
        } else {
          setTimeout(() => {
            this.clearImageConfirmationPopup(0);
          }, 100);
        }
      }
    } else if (this.orgCaptionText === '' && this.captionText !== '') {
      this.callCreateCaptionAPI(isClickOnSave);
    } else {
      if (!isClickOnSave) {
        if (this.displaySelectedImages.length > 1) {
          this.dialogService
            .openConfirmationDialog({
              message: this.translate.instant('view-project.confirm16'),
              title: '',
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe((state: boolean) => {
              if (state) {
                // FIXME: Cannot read property 'id' of undefined
                this.selectedImagesStore.set(
                  this.projectImageData.assetInfoDTOList[
                    this.currentImageNum - 1
                  ],
                );
                this.callPageChnage(this.currentImageNum);
              }
            });
        } else {
          this.callPageChnage(this.currentImageNum);
        }
      }
    }
  }

  clearImageConfirmationPopup(step) {
    switch (step) {
      case 0:
        this.dialogService
          .openConfirmationDialog({
            message: this.translate.instant('view-project.confirm16'),
            title: '',
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((state: boolean) => {
            if (state) {
              this.selectedImagesStore.set(
                this.projectImageData.assetInfoDTOList[
                  this.currentImageNum - 1
                ],
              );
              this.orgCaptionText = this.captionText;
              this.callPageChnage(this.currentImageNum);
            } else {
              this.snackBarService.showSnackBarError(
                this.translate.instant('confirmDialog.caption.fail'),
              );
              this.orgCaptionText = this.captionText;
            }
          });
        break;
      case 1:
        this.dialogService
          .openConfirmationDialog({
            message: this.translate.instant('view-project.confirm16'),
            title: '',
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((state: boolean) => {
            if (state) {
              this.callCreateCaptionAPI(false, true);
            } else {
              this.snackBarService.showSnackBarError(
                this.translate.instant('confirmDialog.caption.fail'),
              );
              this.orgCaptionText = this.captionText;
              this.callCreateCaptionAPI(false, false);
            }
          });
        break;
      case 2:
        this.dialogService
          .openConfirmationDialog({
            message: this.translate.instant('view-project.confirm16'),
            title: '',
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((state: boolean) => {
            if (state) {
              this.callCreateCaptionAPI(false, true);
            } else {
              this.snackBarService.showSnackBarError(
                this.translate.instant('confirmDialog.caption.fail'),
              );
              this.orgCaptionText = this.captionText;
            }
          });
        break;
      default:
        break;
    }
  }

  // Api Call
  callAddKeyApi(data) {
    this.captionService
      .postCraeteCaptionKey(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.captionKeys.push(res);
        this.captionService.setCaptionKeys(this.captionKeys);
        this.keyIdentifier = null;
        this.keyDescription = null;
        this.dialogService.closeDialogById(
          AddKeyCaptionComponentComponent.identifier,
        );
      },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
  }

  callEditKeyApi() {
    this.captionService
      .putCraeteCaptionKey(this.editModel)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.captionService.setCaptionKeys(this.editModel);
          this.editKeyPopUP();
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.editKeyPopUP();
        },
      );
  }

  callDeleteKeyApi() {
    this.captionService
      .deleteCaptionKey(this.filterKeys)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          for (let i = 0; i < this.filterKeys.length; i++) {
            const index = this.captionKeys
              .map(function (e) {
                return e.id;
              })
              .indexOf(this.filterKeys[i]);
            if (index !== -1) {
              this.captionKeys.splice(index, 1);
            }
          }
          this.captionService.setCaptionKeys(this.captionKeys);
          this.dialogService.closeDialogById(
            RemoveKeyCaptionComponentComponent.identifier,
          );
          this.filterKeys = [];
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.dialogService.closeDialogById(
            RemoveKeyCaptionComponentComponent.identifier,
          );
        },
      );
  }

  callCreateCaptionAPI(isCkickOnSave?, isRestImaage = false) {
    const selectedImageID = [];
    this.displaySelectedImages.forEach((element) => {
      selectedImageID.push(element.id);
    });
    const data = {
      assetId: selectedImageID,
      projectId: this.viewProjectService.getProjectId(),
      value: this.captionText,
      selectedCaption:
        this.captionIndex !== -1 ? this.captionArray[this.captionIndex] : null,
    };
    this.loaderService.displayLoader(true);
    // FIXME: undefined is not an object (evaluating 'this.captionArray.map')
    this.captionService
      .postCraeteCaption(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const index = this.captionArray
            .map(function (e) {
              return e.value;
            })
            .indexOf(res.value);
          if (index === -1) {
            this.captionArray.push(res);
          }
          const caps = {
            id: res.value,
            value: res.label,
          };
          this.loaderService.displayLoader(false);
          this.setSavedCaptionToMainObject(caps);
          this.orgCaptionText = this.captionText;
          this.snackBarService.showSnackBar(
            this.translate.instant('confirmDialog.caption.success'),
          );
          if (!isCkickOnSave) {
            const haveCaptionInSelectedImages =
              this.displaySelectedImages.filter(
                (item) => item.caption && Object.keys(item.caption).length > 0,
              );
            if (haveCaptionInSelectedImages.length > 1) {
              if (isRestImaage) {
                this.selectedImagesStore.set(
                  this.projectImageData.assetInfoDTOList[
                    this.currentImageNum - 1
                  ],
                );

                this.orgCaptionText = this.captionText;
              }
              this.callPageChnage(this.currentImageNum);
            } else {
              this.callPageChnage(this.currentImageNum);
            }
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
        },
      );
  }

  setSavedCaptionToMainObject(obj) {
    if (this.displaySelectedImages.length > 1) {
      this.displaySelectedImages.forEach((image) => {
        const imageIndex = this.projectImageData.assetInfoDTOList
          .map(function (e) {
            return e.id;
          })
          .indexOf(image.id);
        // FIXME: caption of undefined
        if (imageIndex !== -1) {
          this.projectImageData.assetInfoDTOList[imageIndex].caption = obj;
        }
      });
    } else {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map(function (e) {
          return e.id;
        })
        .indexOf(
          this.displaySelectedImages[this.displaySelectedImages.length - 1].id,
        );
      this.projectImageData.assetInfoDTOList[imageIndex].caption = obj;
    }
    this.viewProjectService.setProjectData(this.projectImageData);
  }

  findKeysInField(e) {
    if (this.isActivateCtrl) {
      this.keyArray.push(e);
      let lastStr = '';
      if (this.keyArray.length >= 2) {
        lastStr =
          this.keyArray[this.keyArray.length - 2] +
          this.keyArray[this.keyArray.length - 1];
      }
      this.captionKeys.map((item, index) => {
        const concantStr = '';
        if (
          item['keyText'] === lastStr.toUpperCase() ||
          item['keyText'] === lastStr.toLowerCase()
        ) {
          this.captionText =
            this.captionText.substring(0, this.captionText.length - 2) +
            item['value'];
          lastStr = '';
          this.isActivateCtrl = false;
        }
      });
    }
  }

  closeWindow() {
    this.closeWindowCaption();
    this.dialogRef.close();
  }

  onCaptionChange() {
    this.imagecaptionService.setCaptionType(this.repotyType);
  }

  captionReport() {
    this.dialogService.open(CaptionReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-auto-height-panel',
      ],
      data: {
        existingCaptionData: [],
      },
    });
  }

  captionReportExisting() {
    this.dialogService.open(CaptionReportComponent, {
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-lg-panel',
        'fs-dialog-link-panel',
        'fs-dialog-auto-height-panel',
      ],
      data: {
        existingCaptionData: this.existingImageCaptionData,
      },
      listener: {
        withListener: true,
        id: 'CaptionReportComponent',
      },
    });
  }

  removeCaption() {
    this.dialogService
      .openConfirmationDialog({
        message:
          'Are you sure want to remove caption from ' +
          this.lastSelectedImage2[this.lastSelectedImageStore.size() - 1].name +
          ' ?',
        title: '',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: boolean) => {
        if (state) {
          this.loaderService.displayLoader(true);
          this.captionService
            .removeCaption(
              this.viewProjectService.getProjectId(),
              this.lastSelectedImage2[this.lastSelectedImageStore.size() - 1]
                .id,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.captionText = '';
              this.orgCaptionText = '';
              this.projectImageData =
                this.viewProjectService.mergeWithProjectData(
                  this.projectImageData,
                );

              const index = this.projectImageData.assetInfoDTOList
                .map((e) => e.id)
                .indexOf(
                  this.lastSelectedImage2[
                    this.lastSelectedImageStore.size() - 1
                  ].id,
                );

              this.projectImageData.assetInfoDTOList[index].caption = null;
              this.loaderService.displayLoader(false);
            },(error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              });
        }
      },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
  }

  onFocus = () => {
    this.draggable = false;
    this.activateTextArea = true;
  };

  onBlur = () => {
    this.draggable = true;
    this.activateTextArea = false;
  };

  selectedItems(value) {
    this.captionIndex = this.captionArray
      .map(function (e) {
        return e.value;
      })
      .indexOf(value);
    if (this.captionIndex !== -1) {
      this.captionText = this.captionArray[this.captionIndex].label;
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyEvents(event: KeyboardEvent) {
    if (this.isActivateCtrl) {
      this.findKeysInField(event.key);
    }

    if (event.keyCode === 17) {
      if (this.activateTextArea) {
        this.isActivateCtrl = !this.isActivateCtrl;
      }
    }
  }

  @HostListener('window:mouseup', ['$event'])
  handleMouseUp() {
    if (
      this.elementRef.nativeElement.getElementsByClassName('dialog-wrapper')[0]
    ) {
      this.elementRef.nativeElement.getElementsByClassName(
        'dialog-wrapper',
      )[0].style.visibility = 'visible';
      this.radioShow = true;
    }
  }

  mouseDownHide() {
    if (
      this.elementRef.nativeElement.getElementsByClassName('dialog-wrapper')[0]
    ) {
      this.elementRef.nativeElement.getElementsByClassName(
        'dialog-wrapper',
      )[0].style.visibility = 'hidden';
      this.radioShow = false;
    }
  }

  mouseUpShow() {
    if (
      this.elementRef.nativeElement.getElementsByClassName('dialog-wrapper')[0]
    ) {
      this.elementRef.nativeElement.getElementsByClassName(
        'dialog-wrapper',
      )[0].style.visibility = 'visible';
      this.radioShow = true;
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  callPageChnage(no: any) {
    if (no) {
      this.currentImage = no;
      this.pageChangeFn(no);
    }
  }
}
