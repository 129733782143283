import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { UserDetails } from '../models/userdetails.model';
import { AppSettings } from '../../appSettings/appSettings';
import { map } from 'rxjs/operators';
import {
  MetadataModel,
  MetadataPayloadModel,
  MetadataTransactionDetails,
} from '../../dashboard/project/project-detail/view-project/metadata/metadata.model';
import { ViewProjectService } from './view-project.service';
import { FilterEnum } from '../enum/filter.enum';
import { SelectedImagesStore } from '../store/selected-images-store';
import { AssetInfo } from '../models/assetInfo.model';
import { AssetNavigateActionType, } from '../../dashboard/project/project-detail/view-project/metadata/metadata.enum';
import { LoaderService } from './loader.service';
import { MetadataComponent } from '../../dashboard/project/project-detail/view-project/metadata/metadata.component';
import { DialogService } from './dialog/dialog.service';
import { ImageTransactionsService } from '../../dashboard/project/project-detail/view-project/image-transaction/image-transactions.service';

@Injectable()
export class MetaDataService {
  private currentUserSubject = new BehaviorSubject<UserDetails>(
    new UserDetails(),
  );
  public currentUserGlobal = this.currentUserSubject.asObservable();

  constructor(
    private apiService: ApiService,
    public viewProjectService: ViewProjectService,
    private selectedImageStore: SelectedImagesStore,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private imageTransactionsService: ImageTransactionsService,
  ) {}

  getMetaData(assetIds): Observable<MetadataModel[]> {
    const path = AppSettings.META_DATA_LIST;
    return this.apiService
      .post(path, { assetIds: assetIds })
      .pipe(map((res) => res));
  }

  getSelectedAssetsInfo(assetIds: number[]): Observable<AssetInfo> {
    const data = {
      assetIds: assetIds,
      projectId: this.viewProjectService.getProjectId(),
      projectRoleId: this.viewProjectService.getProjectRole(),
      filter: FilterEnum.ShowAll,
    };
    const path = AppSettings.ASSETS_INFO_BASEDON_ASSETS_ID;
    return this.apiService.post(path, data).pipe(map((res) => res));
  }

  updateAssetMetaData(
    projectId: number,
    projectName: string,
    assetUpdateItems: MetadataPayloadModel[],
    transactionDetails: MetadataTransactionDetails,
  ) {
    const path = AppSettings.META_DATA_UPDATE;
    return this.apiService
      .post(path, {
        projectId: projectId,
        projectName: projectName,
        assetUpdateItems: assetUpdateItems,
        transactionDetails,
      })
      .pipe(map((res) => res));
  }

  isLastAssetSelected(id: number): boolean {
    const projectImageIDs = this.viewProjectService.getProjectImageIDs();
    return projectImageIDs.indexOf(id) === projectImageIDs.length - 1;
  }

  isFirstAssetSelected(id: number): boolean {
    return this.viewProjectService.getProjectImageIDs().indexOf(id) === 0;
  }

  isSingleAssetSelected(): boolean {
    return this.selectedImageStore.size() === 1;
  }

  updateMetaDataAndNavigate(action: AssetNavigateActionType, assetId: number, assetUpdateItems: MetadataPayloadModel[]) {
    return this.updateAssetMetaData(
      Number(this.viewProjectService.getProjectId()),
      this.viewProjectService.getProjectDetailPermissionData().projectName,
      assetUpdateItems,
      this.imageTransactionsService.getTransactionDetails(),
      ).pipe(map(
        () => {
          this.loaderService.displayLoader(false);
          this.navigateAsset(action, assetId);
        },
        () => {
          this.loaderService.displayLoader(false);
        }));
  }

  navigateAsset(direction: AssetNavigateActionType, assetId: number): void {
    this.dialogService.closeDialogById('MetadataComponent');

    const projectImageIDs = this.viewProjectService.getProjectImageIDs();
    const currentIndex = projectImageIDs.indexOf(assetId);

    let newAssetId: number | null = null;

    if (currentIndex !== -1) {
      switch (direction) {
        case AssetNavigateActionType.Next:
          newAssetId = currentIndex < projectImageIDs.length - 1 ? projectImageIDs[currentIndex + 1] : null;
          break;
        case AssetNavigateActionType.Previous:
          newAssetId = currentIndex > 0 ? projectImageIDs[currentIndex - 1] : null;
          break;
        case AssetNavigateActionType.Last:
          newAssetId = currentIndex < projectImageIDs.length - 1 ? projectImageIDs[projectImageIDs.length - 1] : null;
          break;
        case AssetNavigateActionType.First:
          newAssetId = currentIndex > 0 ? projectImageIDs[0] : null;
          break;
        default:
          break;
      }
    }

    this.callAssetMetaData(newAssetId);
  }

  callAssetMetaData(assetId: number) {
    this.loaderService.displayLoader(true);

    const getMetaData$ = this.getMetaData([assetId]);
    const getSelectedAssetsInfo$ = this.getSelectedAssetsInfo([assetId]);

    forkJoin([getMetaData$, getSelectedAssetsInfo$])
      .subscribe(
        ([metaData, assetsInfo]) => {
          this.selectedImageStore.clear();
          this.selectedImageStore.set(assetsInfo.assetInfoDTOList);

          this.loaderService.displayLoader(false);
          this.dialogService.open(MetadataComponent, {
            panelClass: [
              'fs-dialog-panel',
              'fs-dialog-width-1123-panel',
              'fs-dialog-link-panel',
            ],
            listener: {
              withListener: true,
              id: 'MetadataComponent',
            },
            data: {
              metaData,
              assetInfoDTOList: assetsInfo.assetInfoDTOList,
            },
          });
        },
        () => {
          this.loaderService.displayLoader(false);
        },
      );
  }
}
