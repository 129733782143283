import { Component, HostListener, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ImageexclusivesService } from './image-exclusives.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { AddExclusivesComponent } from './add-exclusives/add-exclusives.component';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { ImageExclusivesDialogComponent } from './image-exclusives-dialog-component/image-exclusives-dialog-component.component';
import { SelectedImagesStore } from '../../../../../../../shared/store/selected-images-store';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

enum exlusiveValue {
  Addnew = 'addnew',
}

interface ExclusiveValue {
  value: string;
  label: string;
}

@Component({
  selector: 'fs-image-exclusives',
  templateUrl: './image-exclusives.component.html',
  styleUrls: ['./image-exclusives.component.scss'],
  providers: [DatePipe],
})
export class ImageExclusivesComponent
  extends UnsubscriberComponent
  implements OnInit
{
  arrListSelectedImagesData: any = [];
  msgpopup = false;
  companyNameList: Array<ExclusiveValue> = [];
  isDisableExclusive = true;
  popupMsg = '';
  exclusivesHoldDuration = 'manaully';
  exclusives: any = null;
  projectImageData: AssetInfo = new AssetInfo();
  exclusivesContactPerson: any;
  exclusivesReason: any;
  dateTime: Date;
  min: Date;
  exclusivesPayload: any;
  assetIds = [];
  isEdit = false;
  addNewOption = {
    value: 'addnew',
    label: this.translate.instant(
      'left-menu.tab-bar.sidemenus.administration.image-exclusives.dropdown.add-new',
    ),
  };
  deleteUpdateId: number;
  constantsGlobalData: any;

  constructor(
    private imageExclusive: ImageexclusivesService,
    private translate: TranslateService,
    private modalService: Modalstatusservice,
    private viewProjectService: ViewProjectService,
    private loaderService: LoaderService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    public datepipe: DatePipe,
    public dialogService: DialogService,
    private selectedImagesStore: SelectedImagesStore,
    private dialogRef: MatDialogRef<ImageExclusivesComponent>,
  ) {
    super();
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const date = new Date().getDate();
    this.min = new Date(year, month, date);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.exclusives = null;

    this.imageExclusive.exclusivesUserListDataSubjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.length) {
          this.exclusives = res[res.length - 1];
        }
      });

    this.initImageSelectedData();
  }

  initImageSelectedData() {
    this.msgpopup = false;
    this.clearValues();
    this.loaderService.displayLoader(true);
    this.dateTime = null;
    this.imageExclusive
      .callGetImageExclusiveslist(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.companyNameList = res;
        this.companyNameList.splice(0, 0, this.addNewOption);
        this.arrListSelectedImagesData = this.readSelectedImagesValue();
        this.checkIfAllSelectedImageExclusive();
        if (
          this.selectedImagesStore.size() === 1 &&
          !this.arrListSelectedImagesData[0].exclusive
        ) {
          this.clearValues();
          this.isEdit = false;
          this.loaderService.displayLoader(false);
        } else {
          if (this.selectedImagesStore.hasEntities()) {
            this.callAPIforGetDataOFSelectedImage();
          } else {
            this.closeWindow();
            this.openImageExclusivesDialog();
            this.popupMsg = 'No Image(s) selected';
            this.isEdit = false;
            this.loaderService.displayLoader(false);
          }
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  openImageExclusivesDialog() {
    this.dialogService.open(ImageExclusivesDialogComponent, {
      data: {
        popupMsg: this.popupMsg,
      },
      panelClass: [
        'fs-dialog-panel',
        'fs-dialog-size-md-panel',
        'fs-dialog-message-base-panel',
      ],
    });
  }

  readSelectedImagesValue() {
    return this.selectedImagesStore.getAll();
  }

  checkIfAllSelectedImageExclusive() {
    let exclusiveImages = true;
    this.assetIds = [];

    this.selectedImagesStore.getAll().forEach((image) => {
      if (!image.exclusive) {
        exclusiveImages = false;
      }
      this.assetIds.push(image.id);
    });
    this.assetIds = this.selectedImagesStore.getItemsIds();
    return exclusiveImages;
  }

  onClickCheckbox(value: string) {
    this.exclusivesHoldDuration = value;
    if (this.exclusivesHoldDuration === 'tillDate') {
      this.isDisableExclusive = false;
    } else {
      this.dateTime = null;
      this.isDisableExclusive = true;
    }
  }

  callAPIforGetDataOFSelectedImage() {
    this.imageExclusive
      .getExclusiveData(
        this.viewProjectService.getProjectId(),
        this.selectedImagesStore.getItemsIds().toString(),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((getResponseData) => {
        if (getResponseData.status === true && getResponseData.id !== 0) {
          if (Object.keys(getResponseData).length) {
            this.exclusivesContactPerson = getResponseData.contactPerson;
            this.exclusives = {
              label: this.companyNameList.filter(
                (name) => name.value === getResponseData.exclusiveId,
              )[0].label,
              value: getResponseData.exclusiveId,
            };
            const dateString = getResponseData.expirationDate;
            if (dateString) {
              this.dateTime = new Date(dateString);
            }
            this.exclusivesReason = getResponseData.reason;
            this.deleteUpdateId = getResponseData.id;
            if (getResponseData.expirationDate) {
              this.exclusivesHoldDuration = 'tillDate';
            }
            this.isEdit = true;
            this.loaderService.displayLoader(false);
          }
        } else if (
          getResponseData.status === true &&
          getResponseData.id === 0
        ) {
          //**//
        } else {
          this.closeWindow();
          this.alertCustom(getResponseData.message);
        }
        this.loaderService.displayLoader(false);

        this.imageExclusive.exclusivesUserListDataSubjectGlobal
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            if (res.length) {
              this.exclusives = res[res.length - 1];
            }
          });
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  submitExclusive() {
    this.loaderService.displayLoader(true);
    if (this.isEdit) {
      this.imageExclusive
        .submitEditExclusiveForm(this.preparePayload())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (res.status) {
              this.updateProjectData(res.t);
              this.alertCustom(this.translate.instant('view-project.alert15'));
              this.closeWindow();
            } else {
              this.alertCustom(res.message);
            }
            this.loaderService.displayLoader(false);
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.imageExclusive
        .submitExclusiveForm(this.preparePayload())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (res.status) {
              this.updateProjectData(res.t);
              this.closeWindow();
            } else {
              this.alertCustom(res.message);
            }
            this.loaderService.displayLoader(false);
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.loaderService.displayLoader(false);
          },
        );
    }
  }

  updateProjectData(res) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    res.forEach((eachObj) => {
      const imageIndex = this.projectImageData.assetInfoDTOList
        .map(function (e) {
          return e.id;
        })
        .indexOf(eachObj.id);

      if (imageIndex !== -1) {
        this.projectImageData.assetInfoDTOList[imageIndex].exclusive =
          eachObj.value;
        if (eachObj.value) {
          this.projectImageData.assetInfoDTOList[imageIndex].exclusiveHistory =
            eachObj.value;
        }
      }
    });
  }

  closeWindow() {
    this.imageExclusive.setExClusiveUserListData([]);
    this.dialogRef.close();
  }

  addExclusive(e) {
    if (e.value === exlusiveValue.Addnew) {
      this.dialogService
        .open(AddExclusivesComponent, {
          panelClass: [
            'fs-dialog-panel',
            'fs-dialog-size-lg-panel',
            'fs-dialog-link-panel',
          ],
        })
        .subscribe(() => {
          this.initImageSelectedData();
        });
    }
  }

  clearValues() {
    this.exclusivesContactPerson = '';
    this.dateTime = null;
    this.exclusivesReason = '';
    this.exclusivesHoldDuration = 'manaully';
  }

  preparePayload() {
    this.exclusivesPayload = {
      assetIds: this.assetIds,
      contactPerson: this.exclusivesContactPerson,
      exclusiveId: this.exclusives ? this.exclusives.value : '',
      expirationDate: this.dateTime
        ? this.datepipe.transform(this.dateTime, 'yyyy-MM-dd')
        : '',
      projectId: this.viewProjectService.getProjectId(),
      reason: this.exclusivesReason,
    };
    return this.exclusivesPayload;
  }

  holdExclusive() {
    if (this.assetIds.length > 0) {
      const payload = {
        assetIds: this.assetIds,
        projectId: this.viewProjectService.getProjectId(),
      };
      this.imageExclusive
        .removeExclusive(payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe((responseData) => {
          if (responseData.status) {
            this.updateProjectData(responseData.t);
            this.alertCustom(this.translate.instant('view-project.alert16'));
            this.closeWindow();
          }
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    } else {
      this.alertCustom(this.translate.instant('view-project.alert17'));
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  selectValueComparator = (a, b) => a?.value === b?.value;
}
