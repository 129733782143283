import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppSettings } from '../../../../../appSettings/appSettings';
import { ApiService } from '../../../../../shared/services/api.service';
import { CaptionKeys } from './captionKeys.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AssetErrorKeys } from '../../../../../shared/enum/asset-error-keys.enum';

/*
  Generated class for the DbService provider.
  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/

@Injectable()
export class CaptionService {
  private keysData = new BehaviorSubject<any>([]);
  allKeyData = this.keysData.asObservable();

  private captionKeys = new BehaviorSubject<any>([]);
  captionKeysGLobal = this.captionKeys.asObservable();

  // global variables
  DataKeys: Array<object> = [];

  constructor(
    private translate: TranslateService,
    private apiService: ApiService,
  ) {}

  // Model Binding
  setCaptionKeys(data) {
    this.captionKeys.next(data);
  }

  // Service
  getApiCaptionKeys(projectId): Observable<CaptionKeys[]> {
    const path = AppSettings.CAPTION_KEYS(projectId);
    return this.apiService.get(path).pipe(
      map((data) => {
        this.setCaptionKeys(data);
        return data;
      }),
    );
  }

  removeCaption(projectId, assetsId): Observable<CaptionKeys[]> {
    const path = AppSettings.REMOVE_CAPTION(projectId, assetsId);
    return this.apiService.delete(path).pipe(map((data) => data));
  }

  postCraeteCaptionKey(data): Observable<any> {
    const path = AppSettings.CREATE_CAPTION_KEY;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  putCraeteCaptionKey(data): Observable<any> {
    const path = AppSettings.CREATE_CAPTION_KEY;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  deleteCaptionKey(data): Observable<any> {
    const path = AppSettings.DELETE_CAPTIONKEY(data.join(','));
    return this.apiService.delete(path).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getApiCaptionAll(projectId): Observable<any> {
    const path = AppSettings.CAPTION_ALL(projectId);
    return this.apiService.get(path).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  postCraeteCaption(data): Observable<any> {
    const path = AppSettings.CREATE_CAPTION;
    return this.apiService
      .post(path, data)
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }
}
