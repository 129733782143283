import { LauncherService } from './../launcher/launcher.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExecutiveService } from './executive.service';
import { LoaderService } from '../../shared/services/loader.service';
import { ViewProjectService } from '../../shared/services/view-project.service';
import { takeUntil } from 'rxjs/operators';
import { UnsubscriberComponent } from '../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-executive',
  templateUrl: './executive.component.html',
  styleUrls: ['./executive.component.scss'],
})
export class ExecutiveComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  galleryInfo: any = [];
  routerObser: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private executiveService: ExecutiveService,
    private loaderService: LoaderService,
    private launcherService: LauncherService,
    private viewProjectService: ViewProjectService,
  ) {
    super();
  }

  ngOnInit() {
    this.routerObser = this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((parameters) => {
        this.getExecutiveProjects(parameters.execid);
      });
  }

  getExecutiveProjects(executiveId) {
    this.loaderService.displayLoader(true);
    setTimeout(() => {
      this.executiveService
        .getExecutiveDataApiCall(executiveId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.galleryInfo = data;
          this.loaderService.displayLoader(false);
        },(error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }, 0);
  }

  galListInfo(group, groupItems) {
    if (groupItems.useSetup === false) {
      this.viewProjectService.setExecutiveAlbumID(groupItems.value);
      this.router.navigate([
        '/dashboard/viewproject/' + group.groupId + '/filter/showall/page/',
        1,
      ]);
    }
  }

  ngOnDestroy() {
    if (this.routerObser) {
      this.routerObser.unsubscribe();
    }
    super.ngOnDestroy();
  }
}
