import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../appSettings/appSettings';
import { ApiService } from './api.service';
import { Tagger } from '../models/tagger.model';

@Injectable()
export class TaggerService {
  private taggerData = new BehaviorSubject<Tagger>(new Tagger());
  public taggerDataGlobal = this.taggerData.asObservable();

  constructor(private apiService: ApiService) {}

  setTaggingData(data) {
    this.taggerData.next(data);
  }

  resetTaggingData() {
    this.taggerData.next(new Tagger());
  }

  postTaggingStart(data): Observable<any> {
    const path = AppSettings.TAGGING_START;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        if (res.status) {
          this.setTaggingData(data);
        }
        return res;
      }),
    );
  }

  postTaggingConfirm(data): Observable<any> {
    const path = AppSettings.TAGGING_CONFIRM;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        if (res.status) {
          this.setTaggingData(data);
        }
        return res;
      }),
    );
  }

  postTaggingImages(data): Observable<any> {
    const path = AppSettings.TAGGING_IMAGES;
    return this.apiService.post(path, data)
  }

  postAuditTaggingImages(data): Observable<any> {
    const path = AppSettings.AUDIT_TAG;
    return this.apiService.post(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  putTaggingStop(data): Observable<any> {
    const path = AppSettings.TAGGING_STOP;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        this.resetTaggingData();
        return res;
      }),
    );
  }

  putTaggingSubmitForReview(data): Observable<any> {
    const path = AppSettings.TAGGING_SUBMIT_FOR_REVIEW;
    return this.apiService.put(path, data).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
