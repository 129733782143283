<div class="container-download-manager" *ngIf="isDownloadEnabled">
  <span class="collapsable" (click)="hideAndShow()">
    <img src="assets/images/{{ icon }}" />
  </span>

  <div class="item-contain" [@slideInOut]="animationState">
    <button
      type="button"
      class="btn btn-clear-default clear"
      aria-hidden="true"
      (click)="clearAll()"
    >
      Clear All
    </button>
    <div class="download-list">
      <div *ngFor="let item of downloadItems; let i = index">
        <div
          class="d-flex flex-column"
          *ngIf="!item.isExpressDownload; else expressDownload"
        >
          <span title="{{ item.filename }}">
            <div class="current p-0" *ngIf="item.downloadedPercent !== -1">
              {{ item.downloadedPercent + '%' }}
            </div>
            <mat-progress-spinner
              [color]="item.error ? '#b71414' : '#45ccce'"
              mode="determinate"
              [value]="item.downloadedPercent">
            </mat-progress-spinner>
          </span>

          <div class="file-name p-0">{{ item.filename1 }}</div>
          <button
            title="Abort download"
            type="button"
            class="fs-close-btn w-auto"
            data-dismiss="modal"
            aria-hidden="true"
            (click)="abortDownload(item, i)"
          >
            <i class="fa fa-times-circle"></i>
          </button>
        </div>

        <ng-template #expressDownload>
          <div class="w-100 d-flex justify-content-center">
            <mat-spinner></mat-spinner>
          </div>
          <div class="mf-sngle ftr-btns">
            <form>
              <div>
                <button (click)="onClickExpressDownloadFile(item)">
                  Express Download
                </button>
              </div>
            </form>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
