import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AppConfig } from '../../../../../app.configuration';
import { SelectedImage } from '../download/download.model';
import { ImageTypeDetails } from '../../../../../shared/models/image-type-details.model';
import { Utils } from '../../../../../shared/utils/utils';
import { AssetType } from '../../../../../shared/models/asset-type.model';
import { NavigationEnd, Router } from '@angular/router';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { DownloadManagerService } from '../../../../../shared/layout/download-manager/download-manager.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { IpService } from '../../../../../shared/services/ip-service.service';
import { UserService } from '../../../../../shared/services/user.service';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../shared/store/last-selected-image-store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { UserDetails } from '../../../../../shared/models/userdetails.model';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { DownloadService } from '../download/download.service';
import { TopmenusService } from '../top-menu.service';
import { LauncherService } from '../../../../launcher/launcher.service';
import {
  AssetInfoData,
  AssetsDetailSingleSize,
  ExpressDownloadAssetsDataModel,
  ExpressDownloadTypes,
} from './express-download-assets-data.model';
import { SnackBarService } from '../../../../../shared/services/snack-bar.service';
import { ExpressDownloadStoreService } from '../../../../../shared/services/express-download-store.service';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import { DownloadType } from '../../../../../shared/models/enum/download-type';
import { ProjectRole } from '../../../../../shared/enum/project-role.enum';
import {ExpressDownloadErrorKeysEnum} from './express-download-error-keys.enum';
import {AssetErrorKeys} from '../../../../../shared/enum/asset-error-keys.enum';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-express-download',
  templateUrl: './express-download.component.html',
  styleUrls: ['./express-download.component.scss'],
  providers: [DownloadService],
})
export class ExpressDownloadComponent
  extends UnsubscriberComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('stepper') stepper;
  assetDetail: AssetsDetailSingleSize[];
  token: any;
  appConfig = AppConfig;
  selectedImagesList: SelectedImage[] = [];
  fileKeys = [];
  imageTypeDisplay: ImageTypeDetails[] = [];
  projectID;
  selectedImageType;
  imgsize = 300;
  imageContainerSize = 153;
  imagesPerRow = 3;
  constantsGlobalData: any;
  isEnlargeView = false;
  utils = Utils;
  projectDetailPermission: any;
  assetType = AssetType;
  imageSize: any;
  downloadOnlyLA: boolean;
  hasWatermark: boolean;
  downloadType: number;
  mediaShuttleLink: string;
  total: number;
  projectAuthority: string;
  userAuthority: string;
  assetsCount: number;
  isAttachedDialogClosed = false;
  selectedDownloadType: string;
  downloadTypes;
  DOWNLOAD_TYPES = [
    {
      id: ExpressDownloadTypes.HIGH_RES_JPEG,
      name: 'HIGH RES JPEG',
      trans: this.translate.instant(
        'top-menu.expressDownload.sizeOptions.highResJPEG',
      ),
    },
  ];

  constructor(
    private router: Router,
    public viewProjectService: ViewProjectService,
    private downloadService: DownloadService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private downloadManagerService: DownloadManagerService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private logger: NGXLogger,
    private dialogService: DialogService,
    private topmenuService: TopmenusService,
    private snackBarService: SnackBarService,
    private expressDownloadStoreService: ExpressDownloadStoreService,
    private ipService: IpService,
    private launcherService: LauncherService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    private dialogRef: MatDialogRef<ExpressDownloadComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ExpressDownloadAssetsDataModel,
  ) {
    super();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    evt: KeyboardEvent,
  ) {
    this.closeWindow();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.downloadTypes = JSON.parse(JSON.stringify(this.DOWNLOAD_TYPES));
    this.selectedDownloadType = this.DOWNLOAD_TYPES[0].id;

    if (this.data.token) {
      this.mediaShuttleLink = this.data.token;
      this.assetsCount = this.data.assetsCount;
    }

    this.dialogService
      .getDialogState('ExpressDownloadComponent')
      .state$.subscribe((item) => {
        if (item.closed) {
          this.isAttachedDialogClosed = item.closed;
        }
      });

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority: string) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority: string) => {
        this.projectAuthority = projectAuthority;
      });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
      }
    });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectDetailPermission = data;
          this.downloadType =
            this.projectDetailPermission.permissionDTO.downloadType;
          this.downloadOnlyLA =
            this.projectDetailPermission.permissionDTO.downloadOnlyLA;
          this.hasWatermark =
            this.projectDetailPermission.permissionDTO.watermark;
          this.checkAndAddRawToDownloadTypes();
          this.checkAndAddVideoToDownloadTypes();
        } else {
          this.projectDetailPermission = undefined;
        }
      });
  }

  ngAfterViewInit() {
    this.goToStep(this.data.stepIndex);
  }

  checkAndAddVideoToDownloadTypes() {
    if (this.data.videoAssets?.length) {
      this.downloadTypes.push({
        id: ExpressDownloadTypes.VIDEO,
        name: 'Video',
        trans: this.translate.instant(
          'top-menu.expressDownload.sizeOptions.video',
        ),
      });
    }
  }

  checkAndAddRawToDownloadTypes() {
    if (
      (this.projectAuthority === ProjectRole.ROLE_EXEC &&
        this.downloadType === DownloadType.ALL) ||
      this.userAuthority === UserRole.ROLE_ADMIN
    ) {
      if (!this.hasWatermark) {
        this.downloadTypes.push({
          id: ExpressDownloadTypes.RAW,
          name: 'Raw',
          trans: this.translate.instant(
            'top-menu.expressDownload.sizeOptions.raw',
          ),
        });
      }
    }
  }

  onCallExpressDownload(): void {
    this.stepper.next();
    this.topmenuService.postAssetsInfodata(this.getAssetsInfoData()).subscribe(
      (res: { status: boolean; message: string; t: AssetInfoData[] }) => {
        if (res.status) {
          const obj: any = Utils.getAssetDetaillObj(
            res.message,
            res.t,
            this.viewProjectService.getProjectId(),
            this.projectDetailPermission,
            this.viewProjectService.getCurrentUsername(),
            this.selectedDownloadType,
          );
          this.topmenuService.postAssetsDetailSingleSize(obj).subscribe(
            (resData: {
              assetDetail: AssetsDetailSingleSize[];
              numberOfAssets: number;
              token: string;
            }) => {
              this.assetDetail = resData.assetDetail;

              if (!this.assetDetail.length) {
                this.alertCustom(
                  this.translate.instant(
                    'top-menu.expressDownload.emptyImageSize',
                  ),
                );
                this.closeWindow();
              } else {
                this.callExpressDownload(
                  this.assetDetail,
                  resData.numberOfAssets,
                );
              }
              this.loaderService.displayLoader(false);
            },
            (errorData) => {
              this.apiErrorHandlerService.getHandler().handle(errorData);

              this.closeWindow();
              this.loaderService.displayLoader(false);
            },
          );
        } else {
          this.closeWindow();
          this.loaderService.displayLoader(false);
          this.alertCustom(res.message);
        }
      },
      (error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.closeWindow();
        this.loaderService.displayLoader(false);
      },
    );
  }

  getAssetsInfoData() {
    return this.data.isFromTopMenu
      ? {
          assetIds: this.isEnlargeView
            ? [this.lastSelectedImageStore.getHeadItem().id]
            : this.selectedDownloadType === ExpressDownloadTypes.VIDEO
            ? this.data.videoAssets.map((asset) => asset.id)
            : this.selectedImagesStore.getItemsIds(),
          filter: 'download',
          projectId: this.viewProjectService.getProjectId(),
          authorities: [this.userAuthority],
        }
      : {
          assetIds:
            this.selectedDownloadType === ExpressDownloadTypes.VIDEO
              ? this.data.videoAssets.map((asset) => asset.id)
              : this.data.assetIds,
          filter: 'download',
          projectId: this.viewProjectService.getProjectId(),
          authorities: [this.userAuthority],
        };
  }
  callExpressDownload(
    selectedAssetDetails: AssetsDetailSingleSize[],
    numberOfAssets: number,
  ): void {
    const expressDownloadPayloadData = {
      ip: this.ipService.userIPAddress$,
      assetIds: selectedAssetDetails.map(
        (item: AssetsDetailSingleSize) => item.id,
      ),
      userId: this.userService.getCurrentUser().id,
      authorityName: this.userAuthority,
      userName: this.userService.getFullUserName(),
      projectName: this.projectDetailPermission.projectName,
      locator: Utils.getLocatorIds(selectedAssetDetails),
      projectId: this.viewProjectService.getProjectId(),
      downloadSize: selectedAssetDetails.reduce(
        (totalSize, item: AssetsDetailSingleSize) => totalSize + item.size,
        0,
      ),
      token: this.token,
      browserInfo: this.ipService.browserInfo$,
      imageSize: this.selectedDownloadType,
      email: this.userService.getCurrentUser().email,
    };
    this.downloadService
      .expressDownloadFiles<typeof expressDownloadPayloadData>(
        expressDownloadPayloadData,
      )
      .subscribe(
        (resData) => {
          const lastIndexStep = this.stepper.steps.length - 1;
          this.mediaShuttleLink = resData.tokenUrl;
          this.assetsCount = numberOfAssets;
          this.loaderService.displayLoader(false);
          if (this.isAttachedDialogClosed) {
            this.expressDownloadStoreService.setExpressDownloadResults({
              assetsCount: selectedAssetDetails.length,
              albumId: this.data.albumId,
              id: resData.packageId,
              lastIndexStep,
              token: resData.tokenUrl,
            });
          } else {
            this.goToStep(lastIndexStep);
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
          this.closeWindow();
        },
      );
  }

  goToStep(index: number): void {
    if (index !== 0) {
      setTimeout(() => {
        this.stepper.selectedIndex = index;
      }, 0);
    }
  }

  changeDownloadSize(event: { value: string; label: string }): void {
    this.selectedDownloadType = event.value;
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  closeWindow() {
    this.imageTypeDisplay = [];
    this.dialogService.closeDialogById('ExpressDownloadComponent');
  }
}
