import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../shared/utils/utils';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../shared/store/last-selected-image-store';
import { UserService } from '../../../../../shared/services/user.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { Router } from '@angular/router';
import { SelectedImages } from '../../../../../shared/models/selectedImages.model';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDropList,
  DragRef,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { MatChipInputEvent } from '@angular/material/chips';
import { AssetType } from '../../../../../shared/models/asset-type.model';
import { MetaDataService } from '../../../../../shared/services/meta-data.service';
import {
  MetadataModel,
  MetadataPayloadModel,
  ParameterNameToValueModel,
} from './metadata.model';
import { EmptyObject } from '../../../../../shared/models/empty-object.model';
import { AssetInfoDTOList } from '../../../../../shared/models/assetInfo.model';
import { IpService } from '../../../../../shared/services/ip-service.service';
import { AssetNavigateActionType, CopyrightStatuses } from './metadata.enum';
import { ImageTransactionsService } from '../image-transaction/image-transactions.service';

@Component({
  selector: 'fs-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss'],
})
export class MetadataComponent
  extends UnsubscriberComponent
  implements OnInit, AfterViewInit {
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  authorities: string;
  metadataForm: FormGroup;
  selectedImage: SelectedImages[] = [];
  imageKeys = [];
  imageTypeDisplay = [];
  fileKeys = [];
  selectedImagesList;
  utils = Utils;
  assetType = AssetType;
  projectImageIDs: number[] = [];
  currentIndex: number;
  keywords = [];
  personInImage = [];
  addOnBlur = true;
  VARIOUS_DATA = this.translate.instant(
    'left-menu.tab-bar.sidemenus.administration.meta-data.placeholder.various-data',
  );
  KEYWORDS = 'Keywords';
  CREATOR = 'Creator';
  PERSON_IN_IMAGE = 'PersonInImage';
  MARKED = 'Marked';
  dataVariousFields = {} as any;
  copyrightStatuses = CopyrightStatuses;

  private target: CdkDropList = null;
  private targetIndex: number;
  private source: CdkDropList = null;
  private sourceIndex: number;
  private dragRef: DragRef = null;
  boxWidth = '200px';
  boxHeight = '200px';
  assetNavigateActionType = AssetNavigateActionType;

  constructor(
    private renderer: Renderer2,
    private modalService: Modalstatusservice,
    private translate: TranslateService,
    public viewProjectService: ViewProjectService,
    private router: Router,
    private fb: FormBuilder,
    private imageTransactionsService: ImageTransactionsService,
    private loaderService: LoaderService,
    private ipService: IpService,
    private dialogRef: MatDialogRef<MetadataComponent>,
    private userService: UserService,
    private selectedImageStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    private dialogService: DialogService,
    public metaDataService: MetaDataService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      metaData: MetadataModel[];
      assetInfoDTOList: AssetInfoDTOList[];
    },
  ) {
    super();
  }

  ngOnInit(): void {
    this.createFormControls();
    this.initMetaDataFormValues(this.data.metaData);

    this.selectedImagesList = this.selectedImageStore.getAll();

    if (this.metaDataService.isSingleAssetSelected()) {
      this.projectImageIDs = this.viewProjectService.getProjectImageIDs();
      const currentAssetId = this.data.assetInfoDTOList[0].id;
      this.currentIndex = this.projectImageIDs.indexOf(currentAssetId);
    }
  }

  ngAfterViewInit() {
    this.removePlaceholderFromPersonShownField();
  }

  createFormControls() {
    this.metadataForm = this.fb.group({
      creator: ['', Validators.required],
      credit: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      source: ['', Validators.required],
      marked: ['', Validators.required],
      copyrightNotice: ['', Validators.required],
      headline: ['', Validators.required],
      personInImage: [[]],
      keywords: [[]],
    });
  }

  initMetaDataFormValues(data: MetadataModel[]): void {
    const metaData: MetadataModel =
      this.getSimilarValuesAndClearDifferent(data);

    this.metadataForm.patchValue({
      creator: metaData.metaCreator,
      credit: metaData.metaCredit,
      title: metaData.metaTitle,
      description: metaData.metaDescription,
      source: metaData.metaSource,
      marked: metaData.metaCopyrightStatus,
      copyrightNotice: metaData.metaCopyrightNotice,
      headline: metaData.metaHeadline,
      personInImage: metaData.metaPersonsShown ? metaData.metaPersonsShown : [],
      keywords: metaData.metaKeywords ? metaData.metaKeywords : [],
    });
  }

  placeholderText(text) {
    return this.selectedImageStore.size() === 1
      ? this.translate.instant(text)
      : this.VARIOUS_DATA;
  }

  personShownPlaceholderText(text) {
    const areTagsEmpty = this.data.assetInfoDTOList.every(
      (assetInfo) =>
        assetInfo.publishedTags.length === 0 &&
        assetInfo.generalTags.length === 0,
    );

    const arePersonsShownEmpty =
      this.data?.metaData?.every((item) => !item.metaPersonsShown) ?? true;

    if (
      this.selectedImageStore.size() === 1 ||
      (areTagsEmpty && arePersonsShownEmpty)
    ) {
      return this.translate.instant(text);
    }

    return this.VARIOUS_DATA;
  }

  getSimilarValuesAndClearDifferent(arr): MetadataModel {
    const uniqueValues = new MetadataModel();
    const dataVariousFields = {};

    if (arr === null || arr?.length === 0) return uniqueValues;

    if (this.selectedImageStore.size() !== arr.length) {
      uniqueValues.metaCopyrightStatus = '';
      return uniqueValues
    };

    const referenceObj = arr[0];

    const isSingleImageSelected = this.selectedImageStore.size() === 1;
    const isArrLengthOne = arr.length === 1;

    if (isSingleImageSelected && isArrLengthOne) {
      return this.singleImageSelected(referenceObj);
    }

    for (const key in referenceObj) {
      uniqueValues[key] = arr.every(
        (obj) => JSON.stringify(obj[key]) === JSON.stringify(referenceObj[key]),
      )
        ? referenceObj[key] : '';
      dataVariousFields[key] = uniqueValues[key] !== '';
    }

    return uniqueValues;
  }

  singleImageSelected(referenceObj: MetadataModel): MetadataModel {
    referenceObj.metaCopyrightStatus = referenceObj.metaCopyrightStatus || 'null';
    return referenceObj;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  addKeyWord(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim()) {
      this.metadataForm.controls.keywords.value.push(value.trim());
      this.metadataForm.controls.keywords.markAsDirty();
    }

    if (input) {
      input.value = '';
    }
  }

  remove(keyword): void {
    const index = this.metadataForm.controls.keywords.value.indexOf(keyword);
    if (index >= 0) {
      this.metadataForm.controls.keywords.value.splice(index, 1);
      this.metadataForm.controls.keywords.markAsDirty();
    }
  }

  edit(keyword: string, event: MatChipInputEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(keyword);
      return;
    }

    const index = this.metadataForm.get('keywords').value.indexOf(keyword);

    if (index > 0) {
      this.metadataForm.get('keywords').value[index] = value;
    }
  }

  onDropListDropped() {
    if (!this.target) {
      return;
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement;
    const placeholderParentElement: HTMLElement =
      placeholderElement.parentElement;

    placeholderElement.style.display = 'none';

    placeholderParentElement.removeChild(placeholderElement);
    placeholderParentElement.appendChild(placeholderElement);
    placeholderParentElement.insertBefore(
      this.source.element.nativeElement,
      placeholderParentElement.children[this.sourceIndex],
    );

    if (this.placeholder._dropListRef.isDragging()) {
      this.placeholder._dropListRef.exit(this.dragRef);
    }

    this.target = null;
    this.source = null;
    this.dragRef = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(
        this.metadataForm.get('personInImage').value,
        this.sourceIndex,
        this.targetIndex,
      );
      this.metadataForm.get('personInImage').markAsDirty();
    }
  }

  onDropListEntered({ item, container }: CdkDragEnter) {
    if (container === this.placeholder) {
      return;
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement;
    const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
    const dropElement: HTMLElement = container.element.nativeElement;
    const dragIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      this.source ? placeholderElement : sourceElement,
    );
    const dropIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      dropElement,
    );

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = item.dropContainer;

      placeholderElement.style.width = this.boxWidth + 'px';
      placeholderElement.style.height = this.boxHeight + 40 + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = container;
    this.dragRef = item._dragRef;

    placeholderElement.style.display = '';

    dropElement.parentElement.insertBefore(
      placeholderElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement,
    );

    this.placeholder._dropListRef.enter(
      item._dragRef,
      item.element.nativeElement.offsetLeft,
      item.element.nativeElement.offsetTop,
    );
  }

  trimWhitespace(event: any, controllerName: string) {
    const value = event.target.value;
    if (value.trim() === '' && value.startsWith(' ')) {
      this.metadataForm.get(controllerName).markAsPristine();
      event.target.value = value.trimStart();
    }
  }

  onSave(): void {
    if (Object.keys(this.getAllChangedFields()).length) {
      this.loaderService.displayLoader(true);
      this.metaDataService
        .updateAssetMetaData(
          Number(this.viewProjectService.getProjectId()),
          this.viewProjectService.getProjectDetailPermissionData().projectName,
          this.getMetaDataValues(),
          this.imageTransactionsService.getTransactionDetails(),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.loaderService.displayLoader(false);
            this.closeWindow();
            this.dialogService.openInformationDialog({
              title: this.translate.instant('view-project.confirm22'),
              message: this.translate.instant(
                'left-menu.tab-bar.sidemenus.administration.meta-data.meta-data-confirmation',
              ),
            });
          },
          () => {
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.dialogService.openInformationDialog({
        title: this.translate.instant('view-project.confirm22'),
        message: this.translate.instant(
          'left-menu.tab-bar.sidemenus.administration.meta-data.empty-fields-warning',
        ),
      });
    }
  }

  getMetaDataValues(): MetadataPayloadModel[] {
    const assetUpdateItems = [];

    const changedFields = this.getAllChangedFields();

    this.data.assetInfoDTOList.map((asset: SelectedImages) => {
      assetUpdateItems.push({
        fileName: asset.name,
        batchName: asset.batchName,
        assetId: asset.id,
        parameterNameToValueMap: changedFields,
      });
    });

    return assetUpdateItems;
  }

  removePlaceholderFromPersonShownField(): void {
    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement;
    const placeholderParentElement: HTMLElement =
      placeholderElement.parentElement;

    placeholderElement.style.display = 'none';

    placeholderParentElement.removeChild(placeholderElement);
    placeholderParentElement.appendChild(placeholderElement);
  }

  getAllChangedFields(): ParameterNameToValueModel | EmptyObject {
    const changedFields: ParameterNameToValueModel | {} = {};
    Object.keys(this.metadataForm.value).forEach((key) => {
      if (this.metadataForm.controls[key].dirty) {
        const capitalizedValue = key.charAt(0).toUpperCase() + key.slice(1);

        changedFields[capitalizedValue] =
          capitalizedValue === this.KEYWORDS ||
          capitalizedValue === this.PERSON_IN_IMAGE ||
          capitalizedValue === this.MARKED
            ? this.metadataForm.value[key] === 'null' ? null : `${this.metadataForm.value[key]}`
            : this.metadataForm.value[key].trim();

        if (capitalizedValue === this.PERSON_IN_IMAGE) {
          changedFields[capitalizedValue] =
            this.metadataForm.value[key].join(',');
        }
      }
    });
    return changedFields;
  }

  closeWindow() {
    this.dialogRef.close();
  }

  navigateToAsset(action: AssetNavigateActionType) {
    if (Object.keys(this.getAllChangedFields()).length) {
      this.dialogService
        .openConfirmationDialog({
          message: this.translate.instant('left-menu.tab-bar.sidemenus.administration.meta-data.save-confirmation'),
          title: this.translate.instant('view-project.confirm2'),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          this.loaderService.displayLoader(true);
          if (state) {
            this.metaDataService.updateMetaDataAndNavigate(
              action,
              this.data.assetInfoDTOList[0].id,
              this.getMetaDataValues()
            ).pipe(takeUntil(this.destroy$)).subscribe();
          } else {
            this.metaDataService.navigateAsset(action, this.data.assetInfoDTOList[0].id);
          }
        });
    } else {
      this.metaDataService.navigateAsset(action, this.data.assetInfoDTOList[0].id);
    }
  }
}
