import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConflictsService } from './conflicts.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppStateService } from '../../../../../../../shared/services/app-state.service';
import { AssetInfo } from '../../../../../../../shared/models/assetInfo.model';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { Utils } from '../../../../../../../shared/utils/utils';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../../../../../../../shared/services/dialog/dialog.service';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-conflicts',
  templateUrl: './conflicts.component.html',
  styleUrls: ['./conflicts.component.scss'],
  providers: [ConflictsService],
})
export class ConflictsComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  static identifier = 'ConflictsComponent';
  taggingList = [];
  projectImageData: AssetInfo = new AssetInfo();
  noOfImages: any;
  filter: string;
  projectImageIds: any;
  constantsGlobalData: any;

  constructor(
    private router: Router,
    private modalStatusService: Modalstatusservice,
    private conflictsService: ConflictsService,
    private loaderService: LoaderService,
    private viewProjectService: ViewProjectService,
    private translate: TranslateService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private dialogService: DialogService,
    private appStateService: AppStateService,
    private dialogRef: MatDialogRef<ConflictsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private applicationSettingsService: AppStateService,
  ) {
    super();
    this.filter = data.filter;
    this.projectImageIds = data.response;
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.appStateService.setConflicts({ loaded: true });

    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    this.callTaggingStart();

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.taggingList = JSON.parse(JSON.stringify(data.projectTagListDTO));
          this.noOfImages = data.viewSettingDTO.imagePerPage;
        }
      });

    this.viewProjectService.conflictsTagginData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.taggingList.forEach((eachObj, index) => {
          if (eachObj.hotkey === data.hotKey) {
            this.taggingList[index].imageCount = data.count;
          }
        });
      });
  }

  callTaggingStart() {
    this.loaderService.displayLoader(true);
    this.conflictsService
      .getAuditTaggStart(this.viewProjectService.getProjectId())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.updateExistingCount(res);
          this.callConflictsData();
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  callConflictsData() {
    this.resetAuditConfig();
    if (this.projectImageIds) {
      this.viewProjectService.setIsAuditViewOpen(true);
      this.viewProjectService.setCurrentFilter(this.filter);
      const assetsIds = Utils.getImageIdsBasedOnPage(
        1,
        this.noOfImages,
        this.projectImageIds,
      );
      const data = {
        assetIds: assetsIds,
        projectId: this.viewProjectService.getProjectId(),
        filter: this.filter,
        view: this.constantsGlobalData.MODELNAMES.CONFLICTS,
      };

      this.viewProjectService
        .callAssetsInfoBasedOnAssetsId(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (resData) => {
            if (resData.assetInfoDTOList.length > 0) {
              this.projectImageData.assetInfoDTOList = resData.assetInfoDTOList;
              this.viewProjectService.setProjectData(this.projectImageData);
              this.router.navigate([
                Utils.prepareFilterNavigationURL(
                  this.viewProjectService.getProjectRole(),
                  this.viewProjectService.getProjectId(),
                  this.filter,
                  1,
                ),
              ]);
              this.loaderService.displayLoader(false);
            } else {
              this.loaderService.displayLoader(false);
              this.dialogRef.close();
              this.alertCustom(this.translate.instant('view-project.alert2'));
            }
          },
          (error) => {
            this.apiErrorHandlerService.getHandler().handle(error);
            this.viewProjectService.setIsAuditViewOpen(false);
            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.loaderService.displayLoader(false);
      this.viewProjectService.setIsAuditViewOpen(false);
      this.dialogRef.close();
      this.alertCustom(this.translate.instant('view-project.alert2'));
    }
  }

  createModel() {
    return {
      projectId: this.viewProjectService.getProjectId(),
    };
  }

  updateExistingCount(data) {
    this.taggingList.forEach((eachObj) => {
      const imageIndex = data.t.counts
        .map(function (e) {
          return e.id;
        })
        .indexOf(eachObj.id);

      if (imageIndex !== -1) {
        eachObj.imageCount = data.t.counts[imageIndex].value;
      }
    });
  }

  closeWindow() {
    this.dialogRef.close();
    this.viewProjectService.setIsAuditViewOpen(false);
  }

  resetAuditConfig() {
    this.applicationSettingsService.setNoConflicts({ loaded: false });
    this.applicationSettingsService.setBypass2ndtagger({ loaded: false });
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  ngOnDestroy() {
    this.appStateService.setConflicts({ loaded: false });
    this.viewProjectService.setIsAuditViewOpen(false);
    super.ngOnDestroy();
  }
}
