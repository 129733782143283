<div class="sidebar-header">
  <div
    class="main-role-exec"
    *ngIf="
      userAuthority === userRole.ROLE_EXTERNAL &&
      projectAuthority === projectRole.ROLE_EXEC
    "
  >
    <div *ngIf="selectedProjectValue !== 4 && selectedProjectValue !== 3">
      <!-- Dropdown filter will be first follow by Advanced Search. By Abhi-->
      <div #externalExecTopSideBarContainer>
        <div
          [hidden]="!currentState.displaySetting.leftMenu.items.customeFilter"
          class="top-stickey-first pl-2 pr-2"
        >
          <fs-customfilter></fs-customfilter>
        </div>

        <div
          *ngIf="currentState.displaySetting.leftMenu.items.advanceSearch"
          class="top-stickey"
          [ngClass]="
          this.constantsGlobalData.MODELNAMES.ADVANCESEARCHFILTERSTATUS == true
            ? 'top-stickey-second-open'
            : 'top-stickey-second'
        "
        >
          <div class="sidebar-header">
            <div class="search-filter">
              <a
                (click)="openSearchDialog()"
                class="text-uppercase filter-btn global-help-icon-wrapper"
                data-toggle="modal"
                type="button"
              >
                {{ 'left-menu.tab-bar.title3' | translate }}
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="currentState.displaySetting.leftMenu.items.projectInfo">
          <fs-projectinfo></fs-projectinfo>
        </div>
      </div>
      <div class="fs-accordion-container fs-accordion-margin"
           #externalExecAccordion
           fsSidebarAccordionDynamicHeight
           [topSideBarContainer]="externalExecTopSideBarContainer"
           [sidenavElementRef]="sidenavElementRef"
           [sidenavPadding]="10"
           [albumAccordionHeader]="30"
      >
        <div *ngIf="currentState.displaySetting.leftMenu.items.menu.visible">
          <fs-tab-bar-menus
            [accordionElemRef]="externalExecAccordion"
          ></fs-tab-bar-menus>
        </div>
        <div *ngIf="currentState.displaySetting.leftMenu.items.retouch">
          <fs-retouch></fs-retouch>
        </div>
      </div>
    </div>
  </div>

  <div
    class="main-role-exec"
    *ngIf="
      userAuthority === userRole.ROLE_EXTERNAL &&
      projectAuthority === projectRole.ROLE_EXEC
    "
  >
    <div class="main-role-exec-wide" *ngIf="selectedProjectValue === 4">
      <div #externalWideTopSideBarContainer>
        <fs-retouch
          *ngIf="currentState.displaySetting.leftMenu.items.retouch"
        ></fs-retouch>
        <fs-wide-edit-showall
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
        ></fs-wide-edit-showall>
        <fs-wide-edit-album
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
        ></fs-wide-edit-album>
        <fs-executive-projectinfo
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
        ></fs-executive-projectinfo>
      </div>
      <div class="fs-accordion-container"
           fsSidebarAccordionDynamicHeight
           [topSideBarContainer]="externalWideTopSideBarContainer"
           [sidenavElementRef]="sidenavElementRef"
           [sidenavPadding]="0"
           [albumAccordionHeader]="0"
      >
        <div *ngIf="currentState.displaySetting.leftMenu.items.menu.visible">
          <fs-tab-bar-menus></fs-tab-bar-menus>
        </div>
      </div>
    </div>
  </div>

  <div
    class="main-role-exec"
    *ngIf="
      userAuthority === userRole.ROLE_EXTERNAL &&
      projectAuthority === projectRole.ROLE_EXEC
    "
  >
    <div class="main-role-exec-signoff" *ngIf="selectedProjectValue === 3">
      <div #externalSignoffTopSideBarContainer>
        <fs-retouch
          *ngIf="currentState.displaySetting.leftMenu.items.retouch"
        ></fs-retouch>
        <fs-executive-projectinfo
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.signoff"
        ></fs-executive-projectinfo>
      </div>
      <div class="fs-accordion-container"
           fsSidebarAccordionDynamicHeight
           [topSideBarContainer]="externalSignoffTopSideBarContainer"
           [sidenavElementRef]="sidenavElementRef"
           [sidenavPadding]="0"
           [albumAccordionHeader]="0"
      >
        <div *ngIf="currentState.displaySetting.leftMenu.items.menu.visible">
          <fs-tab-bar-menus></fs-tab-bar-menus>
        </div>
      </div>
    </div>
  </div>

  <div class="mainrole_admin" *ngIf="userAuthority === userRole.ROLE_ADMIN">
    <div *ngIf="selectedProjectValue !== 4 && selectedProjectValue !== 3">
      <!-- Dropdown filter will be first follow by Advanced Search. By Abhi-->
      <div #adminExecTopSideBarContainer>
        <div
          [hidden]="!currentState.displaySetting.leftMenu.items.customeFilter"
          class="top-stickey-first pl-2 pr-2"
        >
          <fs-customfilter></fs-customfilter>
        </div>

        <div
          *ngIf="currentState.displaySetting.leftMenu.items.advanceSearch"
          class="top-stickey"
          [ngClass]="
          this.constantsGlobalData.MODELNAMES.ADVANCESEARCHFILTERSTATUS == true
            ? 'top-stickey-second-open'
            : 'top-stickey-second'
        "
        >
          <div class="sidebar-header">
            <div class="search-filter">
              <a
                (click)="openSearchDialog()"
                class="text-uppercase filter-btn global-help-icon-wrapper"
                data-toggle="modal"
                type="button"
              >
                {{ 'left-menu.tab-bar.title3' | translate }}
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="currentState.displaySetting.leftMenu.items.projectInfo">
          <fs-projectinfo></fs-projectinfo>
        </div>
      </div>
      <div class="fs-accordion-container fs-accordion-margin"
           #adminExecAccordion
           fsSidebarAccordionDynamicHeight
           [topSideBarContainer]="adminExecTopSideBarContainer"
           [sidenavElementRef]="sidenavElementRef"
           [sidenavPadding]="15"
           [albumAccordionHeader]="30"
      >
        <div *ngIf="currentState.displaySetting.leftMenu.items.menu.visible">
          <fs-tab-bar-menus
            [accordionElemRef]="adminExecAccordion"
          ></fs-tab-bar-menus>
        </div>
        <div *ngIf="currentState.displaySetting.leftMenu.items.retouch">
          <fs-retouch></fs-retouch>
        </div>
      </div>
    </div>
  </div>

  <div class="mainrole_admin" *ngIf="userAuthority === userRole.ROLE_ADMIN">
    <div class="main-role-admin-wide" *ngIf="selectedProjectValue === 4">
      <div #adminWideTopSideBarContainer>
        <fs-retouch
          *ngIf="currentState.displaySetting.leftMenu.items.retouch"
        ></fs-retouch>
        <fs-wide-edit-showall
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
        ></fs-wide-edit-showall>
        <fs-wide-edit-album
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
        ></fs-wide-edit-album>
        <fs-executive-projectinfo
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.wideedit"
        ></fs-executive-projectinfo>
      </div>

      <div class="fs-accordion-container"
           fsSidebarAccordionDynamicHeight
           [topSideBarContainer]="adminWideTopSideBarContainer"
           [sidenavElementRef]="sidenavElementRef"
           [sidenavPadding]="0"
           [albumAccordionHeader]="0"
      >
        <div *ngIf="currentState.displaySetting.leftMenu.items.menu.visible">
          <fs-tab-bar-menus></fs-tab-bar-menus>
        </div>
      </div>
    </div>
  </div>

  <div class="mainrole_admin" *ngIf="userAuthority === userRole.ROLE_ADMIN">
    <div class="main-role-admin-signoff" *ngIf="selectedProjectValue === 3">
      <div #adminSignoffTopSideBarContainer>
        <fs-retouch
          *ngIf="currentState.displaySetting.leftMenu.items.retouch"
        ></fs-retouch>
        <fs-executive-projectinfo
          *ngIf="currentState.displaySetting.leftMenu.items.menu.items.signoff"
        ></fs-executive-projectinfo>
      </div>
      <div class="fs-accordion-container"
           fsSidebarAccordionDynamicHeight
           [topSideBarContainer]="adminSignoffTopSideBarContainer"
           [sidenavElementRef]="sidenavElementRef"
           [sidenavPadding]="0"
           [albumAccordionHeader]="0"
      >
        <div *ngIf="currentState.displaySetting.leftMenu.items.menu.visible">
          <fs-tab-bar-menus></fs-tab-bar-menus>
        </div>
      </div>
    </div>
  </div>

  <div
    class="main-role-talent"
    *ngIf="projectAuthority === projectRole.ROLE_TALENT"
  >
    <div #talentTopSideBarContainer>
      <div
        class="pr-2 pl-2"
        [hidden]="!currentState.displaySetting.leftMenu.items.customeFilter"
      >
        <fs-talent-customfilter></fs-talent-customfilter>
      </div>

      <div *ngIf="currentState.displaySetting.leftMenu.items.advanceSearch">
        <fs-finalize-images></fs-finalize-images>
        <fs-talent-proritypix-butons></fs-talent-proritypix-butons>
      </div>

      <div *ngIf="currentState.displaySetting.leftMenu.items.projectInfo">
        <fs-talent-projectinfo> </fs-talent-projectinfo>
      </div>
    </div>
    <div class="fs-accordion-container"
         fsSidebarAccordionDynamicHeight
         [topSideBarContainer]="talentTopSideBarContainer"
         [sidenavElementRef]="sidenavElementRef"
         [sidenavPadding]="16"
         [albumAccordionHeader]="0"
    >
      <div *ngIf="currentState.displaySetting.leftMenu.items.menu.visible">
        <fs-tab-bar-menus></fs-tab-bar-menus>
      </div>
    </div>
  </div>

  <div
    class="mainrole_admin"
    *ngIf="projectAuthority === projectRole.ROLE_ALBUMVIEWER"
  >
    <div #albumViewerTopSideBarContainer>
      <div *ngIf="currentState.displaySetting.leftMenu.items.projectInfo">
        <fs-projectinfo></fs-projectinfo>
      </div>
    </div>
    <div class="fs-accordion-container fs-accordion-margin"
         #externalAlbumViewerAccordion
         fsSidebarAccordionDynamicHeight
         [topSideBarContainer]="albumViewerTopSideBarContainer"
         [sidenavElementRef]="sidenavElementRef"
         [sidenavPadding]="0"
         [albumAccordionHeader]="30"
    >
      <div *ngIf="currentState.displaySetting.leftMenu.items.menu.visible">
        <fs-tab-bar-menus
          [accordionElemRef]="externalAlbumViewerAccordion"
        ></fs-tab-bar-menus>
      </div>
    </div>
  </div>
</div>
