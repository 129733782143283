import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AlbumsService } from '../../albums/albums.service';
import { PopupRef } from '../../../../../../../shared/services/popup/popup-ref';
import { FS_POPUP_DATA } from '../../../../../../../shared/services/popup/popup.constants';
import { UserDetails } from '../../../../../../../shared/models/userdetails.model';
import { AlbumssharedService } from '../albums-service/albums-shared.service';
import { SidebarService } from '../../../../../sidebar-service/sidebar-service';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ViewProjectService } from '../../../../../../../shared/services/view-project.service';
import { LoaderService } from '../../../../../../../shared/services/loader.service';
import { Modalstatusservice } from '../../../../../../../shared/services/modalstatus.service';
import { ConstantsGlobal } from '../../../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../../../../../../../shared/utils/utils';
import { AppStateService } from '../../../../../../../shared/services/app-state.service';
import { UnsubscriberComponent } from '../../../../../../../shared/components/unsubscriber/unsubscriber.component';
import {ApiErrorHandlerService} from '../../../../../../../shared/services/api-error-handler.service';

@Component({
  templateUrl: 'editalbum.component.html',
  styleUrls: ['editalbum.component.scss'],
})
export class EditalbumComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  albumData: any;
  albumType: string;
  permissions: any = [];
  userDataInfo: any;
  currentUser: UserDetails = new UserDetails();
  constantsGlobalData: any;
  execUserIds: any = [];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeEditAlbumPopup();
  }

  constructor(
    private albumSharedService: AlbumssharedService,
    private sidebarService: SidebarService,
    private albumsService: AlbumsService,
    private userService: UserService,
    private viewProjectService: ViewProjectService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private loaderService: LoaderService,
    private modalService: Modalstatusservice,
    private appStateService: AppStateService,
    private popupRef: PopupRef<EditalbumComponent>,
    @Inject(FS_POPUP_DATA) private data: { albumType: string },
  ) {
    super();
    this.albumType = data.albumType;
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!Utils.isObjectEmpty(res)) {
          this.execUserIds = res.execUsers;
        }
      });

    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);
    this.albumsService.userDataInfoGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.userDataInfo = this.keepexecUsersList(data);
      });

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        if (!Utils.isObjectEmpty(album)) {
          this.albumData = album;
        }
      });

    this.cunstructPermissionData(this.albumData);
  }

  keepexecUsersList(data) {
    const tempArray = [];
    if (data.length > 0) {
      data.forEach((userGlobalDataObj) => {
        this.execUserIds.forEach((execUserId) => {
          if (userGlobalDataObj.id === execUserId) {
            tempArray.push(userGlobalDataObj);
          }
        });
      });
    }
    return tempArray;
  }

  cunstructPermissionData(data) {
    this.loaderService.displayLoader(true);
    this.permissions = [];

    if (this.userDataInfo.length > 0) {
      this.userDataInfo.forEach((element) => {
        const perData = {
          id: element.id,
          name: element.firstName + ' ' + element.lastName,
          permissionType: 0,
        };
        this.permissions.push(perData);
        if (data.permission.length === 0) {
          if (element.id === data.ownerId) {
            const index = this.permissions
              .map(function (e) {
                return e.id;
              })
              .indexOf(element.id);
            if (index !== -1) {
              this.permissions[index].permissionType = 2;
            }
          }
        }
      });
    }

    if (data.permission.length) {
      data.permission.forEach((element2) => {
        const index = this.permissions
          .map(function (e) {
            return e.id;
          })
          .indexOf(element2.userId);
        if (index !== -1) {
          this.permissions[index].permissionType = element2.permission;
        }
      });
    }
    this.loaderService.displayLoader(false);
  }

  closeEditAlbumPopup() {
    this.popupRef.hide();
  }

  addEditAlbumPopup() {
    this.loaderService.displayLoader(true);
    this.albumSharedService
      .callAddAlbumPermission(this.prepareObjectForAddPermission())
      .subscribe(
        (res) => {
          this.appStateService.setEditAlbum({
            loaded: true,
            data: Utils.addValueToObject(res, 'dialogName', this.albumType),
          });
          this.closeEditAlbumPopup();
          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  prepareObjectForAddPermission() {
    const permissionObj = [];
    this.permissions.forEach((element) => {
      const obj = {
        userId: element.id,
        permission: element.permissionType,
      };
      permissionObj.push(obj);
    });

    return {
      id: this.albumData.id,
      projectId: Number(this.viewProjectService.getProjectId()),
      permission: permissionObj,
    };
  }
}
