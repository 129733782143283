export const environment = {
  production: true,
  log: false,
  logRocket: false,
  version: '8.2.20240715.1',
  environmentName: 'Production',
  apiUrlList: ['https://webimage.editfs.com/api/'],
  apiUrl: 'https://gateway.editfs.com/',
  assetBaseUrl: ['https://downloads.editfs.com/api/'], // This is for Upload, Download, Email, PDF etc for assets,
  projectAssetBaseUrl: 'https://project-assets.editfs.com/', // This is for help videos, announces, branding, project images
  internalBaseUrl: [
    'https://webimage.editfs.com/api/',
    'https://bur-webimage.editfs.com/api/',
  ], // super zoom
};
