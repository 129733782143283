import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { SendmailService } from './sendmail.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { SelectedImages } from '../../../../../shared/models/selectedImages.model';
import { Utils } from '../../../../../shared/utils/utils';
import { ViewProjectService } from '../../../../../shared/services/view-project.service';
import { Modalstatusservice } from '../../../../../shared/services/modalstatus.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../../shared/constants/constants';
import { takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../../shared/store/last-selected-image-store';
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { UnsubscriberComponent } from '../../../../../shared/components/unsubscriber/unsubscriber.component';
import { IpService } from '../../../../../shared/services/ip-service.service';
import { UserService } from '../../../../../shared/services/user.service';
import { SelectedImage } from '../download/download.model';
import { DownloadManagerService } from '../../../../../shared/layout/download-manager/download-manager.service';
import { UserRole } from '../../../../../shared/enum/user-role.enum';
import {AssetErrorKeys} from '../../../../../shared/enum/asset-error-keys.enum';
import {ApiErrorHandlerService} from '../../../../../shared/services/api-error-handler.service';

class Emaildata {
  toEmail: string[] = [];
  ccEmail: string[] = [];
  subject: string;
  messageBody: string;
}

@Component({
  selector: 'fs-sendmail',
  templateUrl: './sendmail.component.html',
  styleUrls: ['./sendmail.component.scss'],
})
export class SendmailComponent extends UnsubscriberComponent implements OnInit {
  lastSelectedImage2: SelectedImages[] = [];
  isEnlargeView = false;
  emailData: Emaildata = new Emaildata();
  assetDetail: any;
  token: any;
  imageKeys = [];
  fileKeys = [];
  imageTypeDisplay = [];
  selectedImagesList: any = [];
  arrListEmailTo: any = [];
  arrListEmailCC: any = [];
  constantsGlobalData: any;
  utils = Utils;
  totalAttachedSize = 0;
  projectDetailPermission: any;
  projectAuthority: string;
  userAuthority: string;
  imageSize: any;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.closeWindow();
  }

  constructor(
    private router: Router,
    public viewProjectService: ViewProjectService,
    private modalService: Modalstatusservice,
    private translate: TranslateService,
    private sendmailService: SendmailService,
    private loaderService: LoaderService,
    private ipService: IpService,
    private userService: UserService,
    private dialogRef: MatDialogRef<SendmailComponent>,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private downloadManagerService: DownloadManagerService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1
        ) {
          this.isEnlargeView = true;
        } else {
          this.isEnlargeView = false;
        }
      }
    });

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.lastSelectedImageStore
      .getCollection()
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageArray) => {
        this.lastSelectedImage2 = imageArray;
      });

    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!Utils.isObjectEmpty(data)) {
          this.projectDetailPermission = data;
        } else {
          this.projectDetailPermission = undefined;
        }
      });

    if (this.data) {
      this.assetDetail = this.data.assetDetail;
      this.token = this.data.token;
      this.imageKeys = Object.keys(this.assetDetail.Image);
      this.fileKeys = Object.keys(this.assetDetail.Other);
      this.emailData = new Emaildata();
      if (this.imageKeys.length > 0 && this.fileKeys.length === 0) {
        this.imageTypeDisplay = [];
        let imageCount = 0;
        const fileCount = 0;
        let totalSize = 0;
        this.imageKeys.forEach((imagKeys, index) => {
          totalSize = 0;
          imageCount = this.assetDetail.Image[imagKeys].length;
          this.assetDetail.Image[imagKeys].forEach((type) => {
            totalSize = totalSize + type.size;
          });
          const obj = {
            name: imagKeys + ' (' + imageCount + ')',
            key: imagKeys,
            value: index + 1,
            imageCount: imageCount,
            fileCount: fileCount,
            totalSize: totalSize / 1024,
          };
          this.imageTypeDisplay.push(obj);
        });
        this.onChangeImageType(this.imageTypeDisplay[0]);
      } else if (this.imageKeys.length === 0 && this.fileKeys.length > 0) {
        this.imageTypeDisplay = [];
        const imageCount = 0;
        let fileCount = 0;
        let totalSize = 0;
        this.fileKeys.forEach((fileKeys) => {
          fileCount = fileCount + this.assetDetail.Other[fileKeys].length;
          this.assetDetail.Other[fileKeys].forEach((type) => {
            totalSize = totalSize + type.size;
          });
        });

        const obj = {
          name: 'Total file ' + fileCount,
          key: 'Other',
          value: 1,
          imageCount: imageCount,
          fileCount: fileCount,
          totalSize: totalSize / 1024,
        };
        this.imageTypeDisplay.push(obj);
        this.fistTimeFilesLoad();
      } else if (this.imageKeys.length > 0 && this.fileKeys.length > 0) {
        this.imageTypeDisplay = [];
        let imageCount = 0;
        let fileCount = 0;
        let totalSize = 0;
        this.imageKeys.forEach((imagKeys, index) => {
          totalSize = 0;
          fileCount = 0;
          imageCount = this.assetDetail.Image[imagKeys].length;
          this.assetDetail.Image[imagKeys].forEach((type) => {
            totalSize = totalSize + type.size;
          });
          this.fileKeys.forEach((fileKeys) => {
            fileCount = fileCount + this.assetDetail.Other[fileKeys].length;
            this.assetDetail.Other[fileKeys].forEach((type) => {
              totalSize = totalSize + type.size;
            });
          });
          const obj = {
            name:
              imagKeys + ' IMAGE (' + imageCount + ') && FILES ' + fileCount,
            key: imagKeys,
            imageCount: imageCount,
            value: index + 1,
            fileCount: fileCount,
            totalSize: totalSize / 1024,
          };
          this.imageTypeDisplay.push(obj);
        });

        this.fistTimeSmallImageLoad();
      }
    }
  }

  fistTimeSmallImageLoad() {
    this.selectedImagesList = [];
    this.assetDetail.Image.SMALL.forEach((element) => {
      const titleName = element.name;
      element.name =
        element.name.substr(0, element.name.lastIndexOf('.')) + '.jpg';
      const obj = {
        id: element.id,
        size: element.size,
        name: element.name,
        titleName: titleName,
        locator: element.locator,
        assetType: 'image',
        batchId: element.batchId,
        batchName: element.batchName,
        batchLocator: element.batchLocator,
      };
      this.selectedImagesList.push(obj);
    });

    this.fileKeys.forEach((fileKeys) => {
      this.assetDetail.Other[fileKeys].forEach((element) => {
        const obj = {
          id: element.id,
          size: element.size,
          name: element.name,
          url: '',
          locator: element.locator,
          assetType: fileKeys.toLowerCase(),
        };
        this.selectedImagesList.push(obj);
      });
    });
  }

  fistTimeFilesLoad() {
    this.selectedImagesList = [];
    this.fileKeys.forEach((fileKeys) => {
      this.assetDetail.Other[fileKeys].forEach((element) => {
        const obj = {
          id: element.id,
          size: element.size,
          name: element.name,
          url: '',
          locator: element.locator,
          assetType: fileKeys.toLowerCase(),
        };
        this.selectedImagesList.push(obj);
      });
    });
  }

  onChangeImageType(type) {
    this.imageSize = this.downloadManagerService.getImageItemWidth(type.key);

    this.totalAttachedSize = type.totalSize;
    this.selectedImagesList = [];
    this.assetDetail.Image[type.key].forEach((element) => {
      const titleName = element.name;
      element.name =
        element.name.substr(0, element.name.lastIndexOf('.')) + '.jpg';
      const obj = {
        id: element.id,
        size: element.size,
        name: element.name,
        titleName: titleName,
        locator: element.locator,
        assetType: 'image',
        batchId: element.batchId,
        batchName: element.batchName,
        batchLocator: element.batchLocator,
      };
      this.selectedImagesList.push(obj);
    });

    this.fileKeys.forEach((fileKeys) => {
      this.assetDetail.Other[fileKeys].forEach((element) => {
        const obj = {
          id: element.id,
          size: element.size,
          name: element.name,
          url: '',
          locator: element.locator,
          assetType: fileKeys.toLowerCase(),
        };
        this.selectedImagesList.push(obj);
      });
    });
  }

  sendMail(emailData) {
    this.loaderService.displayLoader(true);
    if (Object.keys(this.emailData).length !== 0) {
      const obj = {
        ip: this.ipService.userIPAddress$,
        browserInfo: this.ipService.browserInfo$,
        assetIds: this.selectedImagesList.map((item: SelectedImage) => item.id),
        userId: this.userService.getCurrentUser().id,
        authorityName:
          this.userAuthority === UserRole.ROLE_ADMIN
            ? this.userAuthority
            : this.projectAuthority,
        userName: this.userService.getFullUserName(),
        projectName:
          this.viewProjectService.getProjectDetailPermissionData().projectName,
        to: this.arrListEmailTo.length > 0 ? this.arrListEmailTo : [],
        cc: this.arrListEmailCC.length > 0 ? this.arrListEmailCC : [],
        content: Object.prototype.hasOwnProperty.call(emailData, 'messageBody')
          ? emailData.messageBody
          : '',
        locator: Utils.getLocatorIds(this.selectedImagesList),
        projectId: this.viewProjectService.getProjectId(),
        subject: Object.prototype.hasOwnProperty.call(emailData, 'subject')
          ? emailData.subject
          : '',
        zipCount: 0,
        token: this.token,
        imageSize: this.imageSize,
      };

      this.loaderService.displayLoader(true);
      this.sendmailService
        .sendMailAPI(obj)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (res.status) {
              this.alertCustom(this.translate.instant('view-project.alert31'));
            }
            this.loaderService.displayLoader(false);
            this.closeWindow();
          },
          (errorData) => {
            this.apiErrorHandlerService.getHandler().handle(errorData);

            this.loaderService.displayLoader(false);
          },
        );
    } else {
      this.alertCustom(this.translate.instant('view-project.alert32'));
    }
  }

  closeWindow() {
    this.arrListEmailTo = [];
    this.arrListEmailCC = [];
    this.dialogRef.close();
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onAdValidateEmail(emailId, whichEmil) {
    this.callOnAddTabEmail(emailId, whichEmil);
  }

  onRemoveEmailId(whichEmil: string, index: number) {
    switch (whichEmil) {
      case 'to':
        this.arrListEmailTo.splice(index, 1);
        this.emailData.toEmail = [...this.arrListEmailTo];
        break;
      case 'cc':
        this.arrListEmailCC.splice(index, 1);
        this.emailData.ccEmail = [...this.arrListEmailCC];
        break;
    }
  }

  callOnAddTabEmail(event: MatChipInputEvent, whichEmil: string) {
    switch (whichEmil) {
      case 'to':
        this.updateToEmails(event);
        break;
      case 'cc':
        this.updateCcEmails(event);
        break;
    }
  }

  private updateToEmails(event: MatChipInputEvent) {
    if (
      (!this.arrListEmailTo.length ||
        !this.arrListEmailTo.includes(event.value)) &&
      event.value
    ) {
      this.arrListEmailTo = [...this.arrListEmailTo, event.value];
      this.emailData.toEmail = [...this.arrListEmailTo];
    }

    event.input.value = '';
  }

  private updateCcEmails(event: MatChipInputEvent) {
    if (
      (!this.arrListEmailCC.length ||
        !this.arrListEmailCC.includes(event.value)) &&
      event.value
    ) {
      this.arrListEmailCC = [...this.arrListEmailCC, event.value];
      this.emailData.ccEmail = [...this.arrListEmailCC];
    }

    event.input.value = '';
  }
}
